import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DROPDOWN_CATEGORIES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectRisk,
  useEditProjectRisk,
} from "../../hooks/projects/useProjectRisks";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetDropdownValues } from "../../hooks/useDropdownValues";
import { RiskFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import {
  getContactDropdownValues,
  getDropdownValues,
} from "../../utils/GetDropdownValue";

/**
 * RiskForm component for creating and editing risk details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag indicating if the form is in editing mode.
 * @param {RiskFormValues} [props.details] - The details of the risk (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the form.
 * @returns {JSX.Element} The rendered component.
 */
const RiskForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: RiskFormValues;
  canUserEdit?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.details?.length > 60,
  );
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);
  const { setDayModalName } = useDayModalContext();

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isCreating, createProjectRisk } = useCreateProjectRisk();
  const { isEditing, editProjectRisk } = useEditProjectRisk();
  const { isContactsLoading, contacts } = useGetContacts();

  const typeDropdown = useMemo(
    () => getDropdownValues(DROPDOWN_CATEGORIES.RISK_TYPE, dropdownItems),
    [dropdownItems],
  );

  const typeId = useMemo(
    () =>
      typeDropdown.find((value) => value.name === details?.risk_type)?.id || "",
    [typeDropdown, details?.risk_type],
  );

  const ratingDropdown = useMemo(
    () => getDropdownValues(DROPDOWN_CATEGORIES.RISK_RATINGS, dropdownItems),
    [dropdownItems],
  );

  const ratingId = useMemo(
    () =>
      ratingDropdown.find((value) => value.name === details?.risk_rank)?.id ||
      "",
    [ratingDropdown, details?.risk_rank],
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
    setFocus,
    reset,
  } = useForm<RiskFormValues>({
    defaultValues: {
      risk_type: typeId,
      risk_rank: ratingId,
      risk_control: details?.risk_control || "",
      details: details?.details || "",
      responsible_person_id: details?.responsible_person_id || 0,
    },
  });

  useEffect(() => {
    if (!isEditingMode) setFocus("risk_type");
    if (dropdownItems) {
      reset({
        risk_type: typeId,
        risk_rank: ratingId,
        risk_control: details?.risk_control || "",
        details: details?.details || "",
        responsible_person_id: details?.responsible_person_id || 0,
      });
    }
  }, [
    setFocus,
    isEditingMode,
    reset,
    dropdownItems,
    typeId,
    ratingId,
    details,
  ]);

  const onSubmit: SubmitHandler<RiskFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectRisk(
        {
          riskDetails: {
            ...data,
            risk_type:
              typeDropdown.find((value) => value.id === data.risk_type)?.name ||
              "",
            risk_rank:
              ratingDropdown.find((value) => value.id === data.risk_rank)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project risk saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectRisk(
        {
          riskDetails: {
            ...data,
            risk_type:
              typeDropdown.find((value) => value.id === data.risk_type)?.name ||
              "",
            risk_rank:
              ratingDropdown.find((value) => value.id === data.risk_rank)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project risk saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof RiskFormValues, id, { shouldDirty: true });
  };

  if (isDropdownLoading || isEditing || isCreating || isContactsLoading)
    return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Type *</label>
              <Controller
                control={control}
                name="risk_type"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="risk_type"
                    title=""
                    data={typeDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.risk_type && (
                <ErrorMessage errorMessage={errors.risk_type.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Rating *</label>
              <Controller
                control={control}
                name="risk_rank"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="risk_rank"
                    title=""
                    data={ratingDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.risk_rank && (
                <ErrorMessage errorMessage={errors.risk_rank.message} />
              )}
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Control</label>
              <StyledInput
                type="text"
                {...register("risk_control")}
                autoComplete="off"
                warning={errors?.risk_control?.message}
                readOnly={isViewingMode}
              />
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("details", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.details?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.details && (
                <ErrorMessage errorMessage={errors.details.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Owner</label>
              <Controller
                control={control}
                name="responsible_person_id"
                render={({ field: { value } }) => (
                  <Dropdown
                    id="responsible_person_id"
                    title=""
                    data={getContactDropdownValues(contacts)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    showOnTop
                    isViewingMode={isViewingMode}
                  />
                )}
              />
            </LabelAndInput>
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default RiskForm;
