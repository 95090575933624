import { useQuery } from "@tanstack/react-query";
import { verifyUserAPI } from "../../services/apiAuth";

/**
 * @returns to verify new user
 */
export const useVerifyUser = (token: string) => {
  const {
    isPending: isLoading,
    data,
    error,
  } = useQuery({
    queryFn: () => verifyUserAPI(token),
    queryKey: ["user-verified"],
  });

  return { isLoading, data, error };
};
