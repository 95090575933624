import {
  ServiceType,
  StatusType,
  UpsertServiceType,
} from "../types/statusTypes";
import { client } from "./AxiosClient";

export const getAllStatusValuesAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_STATUS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as StatusType[];
};

export const getAllServiceValuesAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_SERVICE, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as ServiceType[];
};

export const createBulkServiceValuesAPI = async (data: UpsertServiceType[]) => {
  const response = await client.post(
    import.meta.env.VITE_SERVICE + "bulkcreate/",
    data,
  );
  return response.data as string;
};

export const deleteBulkServiceValuesAPI = async (data: number[]) => {
  const response = await client.delete(
    import.meta.env.VITE_SERVICE + "bulkdelete/",
    { data },
  );
  return response.data as string;
};
