import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getTenantAPI, updateTenantAPI } from "../../services/apiAuth";
import { TenantUpdateType } from "../../types/authTypes";
import { QUERY_KEYS } from "../../common/Constants";

export const useGetTenant = (domain: string) => {
  const {
    isPending: isLoading,
    data: tenant,
    error,
  } = useQuery({
    queryFn: () => getTenantAPI(domain),
    queryKey: [QUERY_KEYS.TENANT],
    enabled: !!domain,
  });

  return { isLoading, tenant, error };
};

export const useUpdateTenant = () => {
  const queryClient = useQueryClient();

  const {
    isPending: isUpdating,
    mutate: updateTenant,
    error,
  } = useMutation({
    mutationFn: ({
      title,
      azure_container_name,
      code,
      config,
      is_active,
      phone_number,
      physical_address,
      postal_address,
      registration_id,
      id,
    }: TenantUpdateType) =>
      updateTenantAPI({
        title,
        azure_container_name,
        code,
        config,
        is_active,
        phone_number,
        physical_address,
        postal_address,
        registration_id,
        id,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TENANT],
      });
    },
  });

  return { isUpdating, updateTenant, error };
};
