import styled from "styled-components";
import DeleteCon from "../../assets/DeleteCon.svg?react";

import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Eye from "../../assets/Eye.svg?react";
import Info from "../../assets/Info.svg?react";

import { useEffect, useMemo, useState } from "react";
import Spinner from "../../ui/Spinner";

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import toast from "react-hot-toast";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  MODAL_NAMES,
  MODAL_TYPE,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useActiveModalContext } from "../../context/ModalContext";
import { useDeleteProjectExpense } from "../../hooks/finances/useCost";
import { useDeleteProjectCostCentre } from "../../hooks/finances/useProjectCostCentres";
import { useDeleteProjectWBS } from "../../hooks/finances/useProjectWBS";
import { useDeleteProjectDetails } from "../../hooks/projects/useDeleteProjectDetails";
import { useDeleteProjectConsultation } from "../../hooks/projects/useProjectConsultation";
import { useDeleteProjectFinance } from "../../hooks/projects/useProjectFinance";
import { useDeleteProjectImpact } from "../../hooks/projects/useProjectImpacts";
import { useDeleteProjectRisk } from "../../hooks/projects/useProjectRisks";
import {
  useDeleteProjectScope,
  useUpdateScopeRanking,
} from "../../hooks/projects/useProjectScope";
import { useDeleteProjectStakeholder } from "../../hooks/projects/useProjectStakeholders";
import {
  useDeleteProjectSuccess,
  useUpdateSuccessRanking,
} from "../../hooks/projects/useProjectSuccess";
import { useDeleteProjectWorkforce } from "../../hooks/projects/useProjectWorkforce";
import { useUpdateRanking } from "../../hooks/projects/useUpsertProjectDetails";
import { useGetContacts } from "../../hooks/useGetContacts";
import {
  ProjectCostCentre,
  ProjectExpense,
  ProjectWBS,
  UpsertProjectExpense,
} from "../../types/financeTypes";
import {
  ConsultationFormValues,
  FinanceFormValues,
  ImpactFormValues,
  MilestoneFormValues,
  ProjectCostCentreFormValues,
  ProjectWBSFormValues,
  RiskFormValues,
  ScopeFormValues,
  StakeholderFormValues,
  SuccessMeasureFormValues,
  TaskFormValues,
  WorkforceFormValues,
} from "../../types/formFields";
import { Milestone, Task } from "../../types/milestoneTypes";
import {
  ProjectConsultationType,
  ProjectDetailsType,
  ProjectFinanceType,
  ProjectImpactType,
  ProjectRiskType,
  ProjectScope,
  ProjectStakeholdersType,
  ProjectSuccessMeasure,
  ProjectWorkforceType,
} from "../../types/projectTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import DeletePopup from "../../ui/DeletePopup";
import {
  SortableItemsList,
  SortableListRenderer,
  SortableScopeListRenderer,
  SortableSuccessListRenderer,
} from "../../ui/FormElements";
import {
  HeightTransitionDiv,
  TableContainer,
} from "../../ui/HeightTransitionElements";
import { InfoTooltip } from "../../ui/InfoToolTip";
import Modal from "../../ui/Modal";
import NoDataCard from "../../ui/NoDataCard";
import {
  getFormattedCurrency,
  getRAGStatus,
  getUserPermissions,
} from "../../utils/common";
import ConsultationForm from "../forms/ConsultationForm";
import DetailsForm from "../forms/DetailsForm";
import FinanceForm from "../forms/FinanceForm";
import ImpactForm from "../forms/ImpactForm";
import ProjectCostCentreForm from "../forms/ProjectCostCentreForm";
import ProjectExpenseForm from "../forms/ProjectExpenseForm";
import ProjectWBSForm from "../forms/ProjectWBSForm";
import RiskForm from "../forms/RiskForm";
import ScopeForm from "../forms/ScopeForm";
import StakeholderForm from "../forms/StakeholderForm";
import SuccessMeasureForm from "../forms/SuccessMeasureForm";
import WorkforceForm from "../forms/WorkforceForm";
import MilestoneForm from "../milestones/MilestoneForm";
import { useDeleteProjectMilestone } from "../milestones/useMilestones";
import TaskForm from "../tasks/TasksForm";
import { useDeleteProjectTask } from "../tasks/useTasks";
import { RAGIndicator } from "../../ui/RAGIndicator";

const DetailTableHeader = styled.div`
  display: grid;
  padding-left: 2.5rem;
  padding-right: 2rem;
  grid-template-columns: 1fr 5fr 0.3fr;
  background-color: var(--color-grey-400);
  height: 8rem;
  gap: 2rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 700;
  font-size: 1.2rem;
  justify-items: start;
  text-transform: capitalize;
  color: var(--color-grey-900);
`;

const DetailsTableRow = styled.div<{ disabled?: boolean }>`
  display: grid;
  margin-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 2rem;
  grid-template-columns: 1fr 5fr 0.3fr;
  gap: 2rem;
  background-color: white;
  border: 2px dashed var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  & svg {
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
  }
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
`;

const EditDeleteContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  position: relative;
  z-index: 1;
  & svg {
    &:first-child {
      height: 2rem;
      width: 2rem;
    }
    &:last-child {
      pointer-events: ${(props) => (props.disabled ? "none" : "")};
      opacity: ${(props) => (props.disabled ? "0.5" : "1")};
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
  }
`;

const TableRowsContainer = styled.div`
  padding-bottom: 2rem;
`;

const TableTitle = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.2rem;
  color: var(--color-grey-500);
  align-items: center;

  :last-child {
    font-size: 1.2rem;
    color: var(--color-grey-900);
    text-transform: capitalize;
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
  min-width: 9.6rem;
`;

export const ActionItemP = styled.p`
  font-size: 1.4rem;
  color: var(--color-button-secondary);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  display: flex;
  padding: 0 0 1rem;
  margin-left: auto;
  align-items: center;
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const ScopeTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 0.5fr 1.2fr 2fr 3fr 0.3fr;
`;

const SuccessTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1fr 2fr 3fr 0.3fr;
`;

const StakeholderTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(3, 1fr) 1.5fr 3fr 0.3fr;
`;

const StakeholderTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(3, 1fr) 1.5fr 3fr 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const RiskTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1fr 2fr 2fr repeat(2, 1fr) 0.3fr;
`;

const RiskTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1fr 2fr 2fr repeat(2, 1fr) 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const ConsultationTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(4, 1fr) 1fr 3fr 0.3fr;
`;

const ConsultationTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(4, 1fr) 1fr 3fr 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const ImpactTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(2, 1fr) 4fr 0.3fr;
`;

const ImpactTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(2, 1fr) 4fr 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const WorkforceTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(6, 1fr) 0.3fr;
`;

const WorkforceTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(6, 1fr) 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const FinancialTableHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(5, 1fr) 2.5fr 0.3fr;
`;

const FinancialTableRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(5, 1fr) 2.5fr 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const MilestoneTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1.5fr 2fr repeat(5, 1fr) 0.3fr;
`;

const MilestoneTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1.5fr 2fr repeat(5, 1fr) 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const TaskTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1.5fr 2fr 1.5fr repeat(4, 1fr) 0.3fr;
`;

const TaskTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1.5fr 2fr 1.5fr repeat(4, 1fr) 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const ProjectCostCentreTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1.5fr 2fr 1.5fr repeat(2, 1fr) 0.3fr;
`;

const ProjectCostCentreTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1.5fr 2fr 1.5fr repeat(2, 1fr) 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const ProjectWBSTableHeader = styled(DetailTableHeader)`
  grid-template-columns: 1.5fr 2fr 1.5fr 0.3fr;
`;

const ProjectWBSTableRow = styled(DetailsTableRow)`
  grid-template-columns: 1.5fr 2fr 1.5fr 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const ProjectExpenseHeader = styled(DetailTableHeader)`
  grid-template-columns: repeat(3, 1.5fr) 1fr 1fr 0.3fr;
`;

const ProjectExpenseRow = styled(DetailsTableRow)`
  grid-template-columns: repeat(3, 1.5fr) 1fr 1fr 0.3fr;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

/**
 * Renders a table for project details with drag-and-drop functionality.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {string} props.type - The type of project detail
 * @param {string} props.detailName - The name of the project detail
 * @param {ProjectDetailsType[]} [props.projectDetails] - The project details
 * @param {Error | null} props.error - Error object if any
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const DetailsTableRenderer = ({
  projectId,
  type,
  detailName,
  projectDetails,
  error,
  isActive,
  userRole,
}: {
  projectId: string;
  type: string;
  detailName: string;
  projectDetails: ProjectDetailsType[] | undefined;
  error: Error | null;
  isActive: boolean;
  userRole: string;
}) => {
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState({
    detailId: -1,
    description: "",
    sequence: 1,
  });

  const [currentProjectDetails, setCurrentProjectDetails] = useState<
    ProjectDetailsType[]
  >([]);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  useEffect(() => {
    if (projectDetails) {
      setCurrentProjectDetails(projectDetails);
    }
  }, [projectDetails]);

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectDetails } = useDeleteProjectDetails(type);

  const { isLoading, editRanks } = useUpdateRanking(type);

  const handleDelete = (detailId: number) => {
    deleteProjectDetails(
      { detailId },
      {
        onSuccess: () => toast.success("Project detail removed successfully."),
      },
    );
  };

  const handleEdit = (
    description: string,
    detailId: number,
    sequence: number,
    detailName: string,
  ) => {
    setDetails({
      description,
      detailId,
      sequence,
    });
    setCurrentModalName(`${detailName}`);
    setIsProjectDetailsOpen(true);
  };

  const handleSave = () => {
    const latestRanks = currentProjectDetails.map((details, index) => ({
      id: details.id,
      sequence: index + 1,
    }));
    editRanks(latestRanks, {
      onSuccess: () => {
        toast.success(
          `Project ${detailName.toLowerCase()} sequencing saved successfully.`,
        );
        setIsSaveEnabled(false);
      },
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectDetailsType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor((row) => row.sequence, {
        header: "Rank",
        id: "rank",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: projectDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });

  // drag and drop setup
  const getCurrentIndexPos = (
    id: number | string,
    items: ProjectDetailsType[],
  ) => items.findIndex((item) => item.id === id);

  const handleDragEnd = (
    event: DragEndEvent,
    setItems: React.Dispatch<React.SetStateAction<ProjectDetailsType[]>>,
  ) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setItems((items) => {
      const originalPos = getCurrentIndexPos(active.id, items);
      const newPos = getCurrentIndexPos(over?.id, items);
      return arrayMove(items, originalPos, newPos);
    });
    setIsSaveEnabled(true);
  };

  const discardReorder = () => {
    if (projectDetails) {
      setCurrentProjectDetails(projectDetails);
    }
    setIsSaveEnabled(false);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isDeleting || isLoading) return <Spinner />;

  if (error || !projectDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title={detailName?.toLowerCase()}
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(`${detailName}`);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={`${detailName === MODAL_NAMES.GOALS ? MODAL_NAMES.OBJECTIVES : detailName}`}
            type={MODAL_TYPE.NEW}
            wrapperId={`${detailName}`}
            modalWidth="40%"
          >
            <DetailsForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              type={type}
              isEditing={false}
              detailLength={0}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <DetailTableHeader id={`project-details-${type}`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span key={header.id} style={{ cursor: "pointer" }}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </DetailTableHeader>
        <TableRowsContainer>
          <DndContext
            collisionDetection={closestCorners}
            onDragEnd={(e) => handleDragEnd(e, setCurrentProjectDetails)}
            sensors={sensors}
            id="ranks-container"
          >
            <SortableItemsList>
              {currentProjectDetails.map((item, index) => (
                <SortableContext
                  items={currentProjectDetails}
                  strategy={verticalListSortingStrategy}
                  key={item.id}
                >
                  <SortableListRenderer
                    item={item}
                    key={item.id}
                    index={index}
                    length={currentProjectDetails.length}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    detailName={detailName}
                    canUserDelete={canUserDelete}
                    canUserEdit={canUserEdit}
                  />
                </SortableContext>
              ))}
            </SortableItemsList>
          </DndContext>
        </TableRowsContainer>
        {isSaveEnabled && (
          <SaveContainer>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={discardReorder}
            >
              <Discard />
              Cancel
            </ButtonCTA>
            <ButtonCTA
              onClick={handleSave}
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
            >
              {" "}
              <CheckMark
                height={"1.4rem"}
                width={"1.4rem"}
                fill={"var(--color-button-secondary)"}
                stroke={"var(--color-button-secondary)"}
              />{" "}
              Save{" "}
            </ButtonCTA>
          </SaveContainer>
        )}
        <Modal
          isOpen={isProjectDetailsOpen}
          title={`${detailName === "GOALS" ? "Objectives" : detailName}`}
          wrapperId={`${detailName}`}
          modalWidth="40%"
        >
          <DetailsForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            type={type}
            details={details}
            isEditing
            detailLength={projectDetails.length}
            canUserEdit={canUserEdit}
          />
        </Modal>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialScopeState: ScopeFormValues = {
  description: "",
  in_scope: true,
  title: "",
  id: 0,
  sequence: 0,
};

/**
 * Renders a table for project scope details with drag-and-drop functionality.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {ProjectScope[]} [props.scopeDetails] - The project scope details
 * @param {Error | null} props.error - Error object if any
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const ScopeTableRenderer = ({
  projectId,
  scopeDetails,
  error,
  isActive,
  userRole,
}: {
  projectId: string;
  scopeDetails: ProjectScope[] | undefined;
  error: Error | null;
  isActive: boolean;
  userRole: string;
}) => {
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState(initialScopeState);

  const [currentProjectScope, setCurrentProjectScope] = useState<
    ProjectScope[]
  >([]);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  useEffect(() => {
    if (scopeDetails) {
      setCurrentProjectScope(scopeDetails);
    }
  }, [scopeDetails]);

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectScope } = useDeleteProjectScope();

  const { isLoading, editScopeRanks } = useUpdateScopeRanking();

  const handleEdit = (details: ProjectScope) => {
    setDetails({
      ...details,
    });
    setCurrentModalName(`Edit ${MODAL_NAMES.SCOPE}`);
    setIsProjectDetailsOpen(true);
  };

  const handleDelete = (detailId: number) => {
    deleteProjectScope(
      { detailId },
      {
        onSuccess: () => toast.success("Project scope removed successfully."),
      },
    );
  };

  const handleSave = () => {
    const latestRanks = currentProjectScope.map((details, index) => ({
      id: details.id,
      sequence: index + 1,
    }));
    editScopeRanks(latestRanks, {
      onSuccess: () => {
        toast.success(`Project scope sequencing saved successfully.`);
        setIsSaveEnabled(false);
      },
    });
  };

  const discardReorder = () => {
    if (scopeDetails) {
      setCurrentProjectScope(scopeDetails);
    }
    setIsSaveEnabled(false);
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectScope>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.sequence, {
        header: "Rank",
        id: "row_id",
      }),
      columnHelper.accessor("in_scope", {
        header: "In Scope",
        id: "in_scope",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: scopeDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // drag and drop setup
  const getCurrentIndexPos = (
    id: number | string,
    items: ProjectDetailsType[],
  ) => items.findIndex((item) => item.id === id);

  const handleDragEnd = (
    event: DragEndEvent,
    setItems: React.Dispatch<React.SetStateAction<ProjectScope[]>>,
  ) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setItems((items) => {
      const originalPos = getCurrentIndexPos(active.id, items);
      const newPos = getCurrentIndexPos(over?.id, items);
      return arrayMove(items, originalPos, newPos);
    });
    setIsSaveEnabled(true);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isLoading || isDeleting) return <Spinner />;

  if (error || !scopeDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title={"scope"}
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.SCOPE);
              setIsProjectDetailsOpen(true);
            }}
          />{" "}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.SCOPE}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.SCOPE}
            modalWidth="40%"
          >
            <ScopeForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              detailLength={0}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <ScopeTableHeader id={`project-scope`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </ScopeTableHeader>
        <TableRowsContainer>
          <DndContext
            collisionDetection={closestCorners}
            onDragEnd={(e) => handleDragEnd(e, setCurrentProjectScope)}
            sensors={sensors}
            id="ranks-container"
          >
            <SortableItemsList>
              {currentProjectScope.map((item, index) => (
                <SortableContext
                  items={currentProjectScope}
                  strategy={verticalListSortingStrategy}
                  key={item.id}
                >
                  <SortableScopeListRenderer
                    item={item}
                    key={item.id}
                    index={index}
                    length={currentProjectScope.length}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    canUserDelete={canUserDelete}
                    canUserEdit={canUserEdit}
                  />
                </SortableContext>
              ))}
            </SortableItemsList>
          </DndContext>
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.SCOPE}
            wrapperId={`Edit ${MODAL_NAMES.SCOPE}`}
            modalWidth="40%"
          >
            <ScopeForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              detailLength={scopeDetails.length}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
        {isSaveEnabled && (
          <SaveContainer>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={discardReorder}
            >
              <Discard />
              Cancel
            </ButtonCTA>
            <ButtonCTA
              onClick={handleSave}
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
            >
              {" "}
              <CheckMark
                height={"1.4rem"}
                width={"1.4rem"}
                fill={"var(--color-button-secondary)"}
                stroke={"var(--color-button-secondary)"}
              />{" "}
              Save{" "}
            </ButtonCTA>
          </SaveContainer>
        )}
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialSuccessState: SuccessMeasureFormValues = {
  source_of_benefit: "",
  description: "",
  sequence: 0,
};

/**
 * Renders a table for project success measures with drag-and-drop functionality.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {ProjectSuccessMeasure[]} [props.successDetails] - The project success measures
 * @param {Error | null} props.error - Error object if any
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const SuccessTableRenderer = ({
  projectId,
  successDetails,
  error,
  isActive,
  userRole,
}: {
  projectId: string;
  successDetails: ProjectSuccessMeasure[] | undefined;
  error: Error | null;
  isActive: boolean;
  userRole: string;
}) => {
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] =
    useState<SuccessMeasureFormValues>(initialSuccessState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const [currentProjectSuccessMeasure, setCurrentProjectSuccessMeasure] =
    useState<ProjectSuccessMeasure[]>([]);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  useEffect(() => {
    if (successDetails) {
      setCurrentProjectSuccessMeasure(successDetails);
    }
  }, [successDetails]);
  const { isLoading, editSuccessRanks } = useUpdateSuccessRanking();

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectSuccessMeasure } = useDeleteProjectSuccess();

  const handleEdit = (details: ProjectSuccessMeasure) => {
    setDetails({
      ...details,
    });
    setCurrentModalName(`Edit ${MODAL_NAMES.SUCCESS}`);
    setIsProjectDetailsOpen(true);
  };

  const handleDelete = (detailId: number) => {
    deleteProjectSuccessMeasure(
      { detailId },
      {
        onSuccess: () =>
          toast.success("Project success measure removed successfully."),
      },
    );
  };

  const handleSave = () => {
    const latestRanks = currentProjectSuccessMeasure.map((details, index) => ({
      id: details.id,
      sequence: index + 1,
    }));
    editSuccessRanks(latestRanks, {
      onSuccess: () => {
        toast.success(`Project success sequencing saved successfully.`);
        setIsSaveEnabled(false);
      },
    });
  };

  const discardReorder = () => {
    if (successDetails) {
      setCurrentProjectSuccessMeasure(successDetails);
    }
    setIsSaveEnabled(false);
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectSuccessMeasure>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.sequence, {
        header: "Rank",
        id: "row_id",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("source_of_benefit", {
        header: "Source of Benefit",
        id: "source_of_benefit",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: successDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // drag and drop setup
  const getCurrentIndexPos = (
    id: number | string,
    items: ProjectDetailsType[],
  ) => items.findIndex((item) => item.id === id);

  const handleDragEnd = (
    event: DragEndEvent,
    setItems: React.Dispatch<React.SetStateAction<ProjectSuccessMeasure[]>>,
  ) => {
    const { active, over } = event;
    if (!over || active.id === over?.id) return;
    setItems((items) => {
      const originalPos = getCurrentIndexPos(active.id, items);
      const newPos = getCurrentIndexPos(over?.id, items);
      return arrayMove(items, originalPos, newPos);
    });
    setIsSaveEnabled(true);
  };

  const sensors = useSensors(useSensor(PointerSensor));

  if (isDeleting || isLoading) return <Spinner />;

  if (error || !successDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project success measures"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.SUCCESS);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.SUCCESS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.SUCCESS}
            modalWidth="40%"
          >
            <SuccessMeasureForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              detailLength={0}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <SuccessTableHeader id={`project-success-measure`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </SuccessTableHeader>
        <TableRowsContainer>
          <DndContext
            collisionDetection={closestCorners}
            onDragEnd={(e) => handleDragEnd(e, setCurrentProjectSuccessMeasure)}
            sensors={sensors}
            id="ranks-container"
          >
            <SortableItemsList>
              {currentProjectSuccessMeasure.map((item, index) => (
                <SortableContext
                  items={currentProjectSuccessMeasure}
                  strategy={verticalListSortingStrategy}
                  key={item.id}
                >
                  <SortableSuccessListRenderer
                    item={item}
                    key={item.id}
                    index={index}
                    length={currentProjectSuccessMeasure.length}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    canUserDelete={canUserDelete}
                    canUserEdit={canUserEdit}
                  />
                </SortableContext>
              ))}
            </SortableItemsList>
          </DndContext>
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.SUCCESS}
            wrapperId={`Edit ${MODAL_NAMES.SUCCESS}`}
            modalWidth="40%"
          >
            <SuccessMeasureForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              detailLength={successDetails.length}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
        {isSaveEnabled && (
          <SaveContainer>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={discardReorder}
            >
              <Discard />
              Cancel
            </ButtonCTA>
            <ButtonCTA
              onClick={handleSave}
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
            >
              {" "}
              <CheckMark
                height={"1.4rem"}
                width={"1.4rem"}
                fill={"var(--color-button-secondary)"}
                stroke={"var(--color-button-secondary)"}
              />{" "}
              Save{" "}
            </ButtonCTA>
          </SaveContainer>
        )}
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialStakeholderState = {
  details: "",
  email: "",
  name: "",
  type: "",
  team_role: "",
};

/**
 * Renders a table for project stakeholders.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {ProjectStakeholdersType[]} [props.stakeholderDetails] - The project stakeholder details
 * @param {Error | null} props.error - Error object if any
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const StakeholderTableRenderer = ({
  projectId,
  isActive,
  stakeholderDetails,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  stakeholderDetails: ProjectStakeholdersType[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<StakeholderFormValues>(
    initialStakeholderState,
  );
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectStakeholder } =
    useDeleteProjectStakeholder();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (detailId: string) => {
    deleteProjectStakeholder(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project stakeholder removed successfully."),
      },
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectStakeholdersType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
      }),
      columnHelper.accessor("email", {
        header: "Email",
        id: "email",
      }),
      columnHelper.accessor("stakeholder_type", {
        header: "Stakeholder Type",
        id: "type",
      }),
      columnHelper.accessor("project_team_role", {
        header: "Role",
        id: "role",
      }),
      columnHelper.accessor("details", {
        header: "Description",
        id: "details",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: stakeholderDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !stakeholderDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project stakeholders"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.STAKEHOLDERS);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.STAKEHOLDERS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.STAKEHOLDERS}
            modalWidth="30%"
          >
            <StakeholderForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <StakeholderTableHeader id={`project-stakeholders`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </StakeholderTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <StakeholderTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("name")}</span>
              </TableTitle>
              <span>{row.getValue("email")}</span>
              <span>{row.getValue("type")}</span>
              <span>{row.getValue("role")}</span>
              <p style={{ position: "relative" }}>
                {(row.getValue("details") as string)?.length > 80
                  ? `${(row.getValue("details") as string).substring(0, 80)}...`
                  : row.getValue("details") || "N/A"}
              </p>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      type: row.getValue("type"),
                      name: row.getValue("name"),
                      team_role: row.getValue("role"),
                      details: row.getValue("details"),
                      email: row.getValue("email"),
                      id: row.getValue("row_id"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.STAKEHOLDERS}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </StakeholderTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.STAKEHOLDERS}
            wrapperId={`Edit ${MODAL_NAMES.STAKEHOLDERS}`}
            modalWidth="30%"
          >
            <StakeholderForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialConsultationState: ConsultationFormValues = {
  title: "",
  details: "",
  communication_method: "",
  message: "",
  who: "",
  when: "",
};

/**
 * Renders a table for project consultation plans.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {ProjectConsultationType[]} [props.consultationDetails] - The project consultation details
 * @param {Error | null} props.error - Error object if any
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const ConsultationTableRenderer = ({
  projectId,
  isActive,
  consultationDetails,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  consultationDetails: ProjectConsultationType[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ConsultationFormValues>(
    initialConsultationState,
  );
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();

  const { isDeleting, deleteProjectConsultation } =
    useDeleteProjectConsultation();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (detailId: string) => {
    deleteProjectConsultation(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project consultation plan removed successfully."),
      },
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectConsultationType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("communication_method", {
        header: "Communication Method",
        id: "communication_method",
      }),
      columnHelper.accessor("who", {
        header: "Target Audience",
        id: "who",
      }),
      columnHelper.accessor("when", {
        header: "Date",
        id: "when",
      }),
      columnHelper.accessor("message", {
        header: "Message",
        id: "message",
      }),
      columnHelper.accessor("details", {
        header: "Description",
        id: "details",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: consultationDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !consultationDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project consultation plan"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.CONSULTATION);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.CONSULTATION}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.CONSULTATION}
            modalWidth="30%"
          >
            <ConsultationForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <ConsultationTableHeader id={`project-consultations`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </ConsultationTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <ConsultationTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("title")}</span>
              </TableTitle>
              <span>{row.getValue("communication_method")}</span>
              <span>{row.getValue("who")}</span>
              <span>
                {moment(row.getValue("when")).format(DAY_FORMATS.DAY_FIRST)}
              </span>
              <span>{row.getValue("message")}</span>
              <span>
                {(row.getValue("details") as string)?.length > 80
                  ? `${(row.getValue("details") as string).substring(0, 80)}...`
                  : row.getValue("details") || "N/A"}
              </span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      title: row.getValue("title"),
                      who: row.getValue("who"),
                      when: moment(row.getValue("when")).format(
                        DAY_FORMATS.YEAR_FIRST,
                      ),
                      details: row.getValue("details"),
                      communication_method: row.getValue(
                        "communication_method",
                      ),
                      message: row.getValue("message"),
                      id: row.getValue("row_id"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.CONSULTATION}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </ConsultationTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.CONSULTATION}
            wrapperId={`Edit ${MODAL_NAMES.CONSULTATION}`}
            modalWidth="30%"
          >
            <ConsultationForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialRiskState: RiskFormValues = {
  risk_control: "",
  details: "",
  responsible_person_id: 0,
  risk_rank: "",
  risk_type: "",
};

/**
 * Renders a table for project risks.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {ProjectRiskType[]} [props.riskDetails] - The project risk details
 * @param {Error | null} props.error - Error object if any
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const RiskTableRenderer = ({
  projectId,
  isActive,
  riskDetails,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  riskDetails: ProjectRiskType[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<RiskFormValues>(initialRiskState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectRisk } = useDeleteProjectRisk();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (detailId: string) => {
    deleteProjectRisk(
      { detailId: +detailId },
      {
        onSuccess: () => toast.success("Project risk removed successfully."),
      },
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectRiskType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("risk_type", {
        header: "Type",
        id: "risk_type",
      }),
      columnHelper.accessor("details", {
        header: "Description",
        id: "details",
      }),
      columnHelper.accessor("risk_control", {
        header: "Control",
        id: "risk_control",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Owner",
          id: "responsible",
        },
      ),
      columnHelper.accessor("risk_rank", {
        header: "Rating",
        id: "risk_rank",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: riskDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        responsible_person_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !riskDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project risks"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.RISKS);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.RISKS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.RISKS}
            modalWidth="30%"
          >
            <RiskForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <RiskTableHeader id={`project-risks`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </RiskTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <RiskTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("risk_type")}</span>
              </TableTitle>
              <span>
                {(row.getValue("details") as string)?.length > 80
                  ? `${(row.getValue("details") as string).substring(0, 80)}...`
                  : row.getValue("details") || "N/A"}
              </span>
              <span>{row.getValue("risk_control")}</span>
              <span>{row.getValue("responsible")}</span>
              <span>{row.getValue("risk_rank")}</span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      risk_type: row.getValue("risk_type"),
                      risk_control: row.getValue("risk_control"),
                      details: row.getValue("details"),
                      risk_rank: row.getValue("risk_rank"),
                      id: row.getValue("row_id"),
                      responsible_person_id: row.getValue(
                        "responsible_person_id",
                      ),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.RISKS}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </RiskTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.RISKS}
            wrapperId={`Edit ${MODAL_NAMES.RISKS}`}
            modalWidth="30%"
          >
            <RiskForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialImpactState: ImpactFormValues = {
  details: "",
  target: "",
  title: "",
};

/**
 * Renders a table for project impacts.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {ProjectImpactType[]} [props.impactDetails] - The project impact details
 * @param {Error | null} props.error - Error object if any
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const ImpactTableRenderer = ({
  projectId,
  isActive,
  impactDetails,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  impactDetails: ProjectImpactType[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ImpactFormValues>(initialImpactState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectImpact } = useDeleteProjectImpact();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (detailId: string) => {
    deleteProjectImpact(
      { detailId: +detailId },
      {
        onSuccess: () => toast.success("Project impact removed successfully."),
      },
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectImpactType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("target", {
        header: "Target",
        id: "target",
      }),
      columnHelper.accessor("details", {
        header: "Description",
        id: "details",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: impactDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !impactDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project impacts"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.IMPACTS);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.IMPACTS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.IMPACTS}
            modalWidth="30%"
          >
            <ImpactForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <ImpactTableHeader id={`project-impacts`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </ImpactTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <ImpactTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("title")}</span>
              </TableTitle>
              <span>{row.getValue("target")}</span>
              <span>
                {(row.getValue("details") as string)?.length > 80
                  ? `${(row.getValue("details") as string).substring(0, 80)}...`
                  : row.getValue("details") || "N/A"}
              </span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      title: row.getValue("title"),
                      target: row.getValue("target"),
                      details: row.getValue("details"),
                      id: row.getValue("row_id"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.IMPACTS}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </ImpactTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.IMPACTS}
            wrapperId={`Edit ${MODAL_NAMES.IMPACTS}`}
            modalWidth="30%"
          >
            <ImpactForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialWorkforceState: WorkforceFormValues = {
  resource_type: "",
  hours: 0,
  start_date: "",
  end_date: "",
  ongoing: "",
  id: 0,
  staff: "",
};

/**
 * Renders a table for project workforce details.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {ProjectWorkforceType[]} [props.workforceDetails] - The project workforce details
 * @param {Error | null} props.error - Error object if any
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const WorkforceTableRenderer = ({
  projectId,
  isActive,
  workforceDetails,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  workforceDetails: ProjectWorkforceType[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<WorkforceFormValues>(
    initialWorkforceState,
  );
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const { setDayModalName } = useDayModalContext();

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectWorkforce } = useDeleteProjectWorkforce();

  const handleDelete = (detailId: string) => {
    deleteProjectWorkforce(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project workforce removed successfully."),
      },
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectWorkforceType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("resource_type", {
        header: "Type",
        id: "resource_type",
      }),
      columnHelper.accessor("staff", {
        header: "Name",
        id: "staff",
      }),
      columnHelper.accessor("hours", {
        header: "Estimated Effort",
        id: "hours",
      }),
      columnHelper.accessor("start_date", {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor("end_date", {
        header: "End Date",
        id: "end_date",
      }),
      columnHelper.accessor("ongoing", {
        header: "Ongoing",
        id: "ongoing",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: workforceDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !workforceDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project workforces"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.WORKFORCE);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.WORKFORCE}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.WORKFORCE}
            modalWidth="30%"
          >
            <WorkforceForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <WorkforceTableHeader id={`project-workforces`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </WorkforceTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <WorkforceTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("resource_type")}</span>
              </TableTitle>
              <span>{row.getValue("staff")}</span>
              <span>{row.getValue("hours")}</span>
              <span>
                {row.getValue("start_date")
                  ? moment(row.getValue("start_date")).format(
                      DAY_FORMATS.DAY_FIRST,
                    )
                  : "N/A"}
              </span>
              <span>
                {row.getValue("end_date")
                  ? moment(row.getValue("end_date")).format(
                      DAY_FORMATS.DAY_FIRST,
                    )
                  : "N/A"}
              </span>
              <span>{row.getValue("ongoing")}</span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      resource_type: row.getValue("resource_type"),
                      hours: row.getValue("hours"),
                      start_date: moment(row.getValue("start_date")).format(
                        DAY_FORMATS.YEAR_FIRST,
                      ),
                      end_date: moment(row.getValue("end_date")).format(
                        DAY_FORMATS.YEAR_FIRST,
                      ),
                      ongoing: row.getValue("ongoing"),
                      id: row.getValue("row_id"),
                      staff: row.getValue("staff"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.WORKFORCE}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </WorkforceTableRow>
          ))}
        </TableRowsContainer>
        <Modal
          isOpen={isProjectDetailsOpen}
          title={MODAL_NAMES.WORKFORCE}
          wrapperId={`Edit ${MODAL_NAMES.WORKFORCE}`}
          modalWidth="30%"
        >
          <WorkforceForm
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            details={details}
            isEditingMode
            canUserEdit={canUserEdit}
          />
        </Modal>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialFinanceState: FinanceFormValues = {
  actual_amount: null,
  est_amount: null,
  commited_amount: null,
  budgeted_amount: null,
  comments: "",
  cost_type: "",
  details: "",
  end_year: "",
  financial_type: "",
  funding_source: "",
  ongoing: "",
  percentage_per_year: 0,
  start_year: "",
  id: 0,
  project_id: 0,
  section_id: null,
};

/**
 * Renders a table for project financial details.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {ProjectFinanceType[]} [props.financeDetails] - The project finance details
 * @param {Error | null} props.error - Error object if any
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const FinanceTableRenderer = ({
  projectId,
  isActive,
  financeDetails,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  financeDetails: ProjectFinanceType[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] =
    useState<FinanceFormValues>(initialFinanceState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectFinance } = useDeleteProjectFinance();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (detailId: string) => {
    deleteProjectFinance(
      { detailId: +detailId },
      {
        onSuccess: () =>
          toast.success("Project financials removed successfully."),
      },
    );
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectFinanceType>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("financial_type", {
        header: "Type",
        id: "financial_type",
      }),
      columnHelper.accessor("est_amount", {
        header: "Estimated Amount",
        id: "est_amount",
      }),
      columnHelper.accessor("actual_amount", {
        header: "Actual Amount",
        id: "actual_amount",
      }),
      columnHelper.accessor("commited_amount", {
        header: "Committed Amount",
        id: "commited_amount",
      }),
      columnHelper.accessor("budgeted_amount", {
        header: "Budgeted Amount",
        id: "budgeted_amount",
      }),
      columnHelper.accessor("balance_amount", {
        header: "Balance Amount",
        id: "balance_amount",
      }),
      columnHelper.accessor("funding_source", {
        header: "Source",
        id: "funding_source",
      }),
      columnHelper.accessor("start_year", {
        header: "Start Year",
        id: "start_year",
      }),
      columnHelper.accessor("end_year", {
        header: "End Year",
        id: "end_year",
      }),
      columnHelper.accessor("percentage_per_year", {
        header: "% per year",
        id: "percentage_per_year",
      }),
      columnHelper.accessor("ongoing", {
        header: "Ongoing",
        id: "ongoing",
      }),
      columnHelper.accessor("cost_type", {
        header: "cost_type",
        id: "cost_type",
      }),
      columnHelper.accessor("details", {
        header: "Description",
        id: "details",
      }),
      columnHelper.accessor("comments", {
        header: "comments",
        id: "comments",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: financeDetails ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        cost_type: false,
        ongoing: false,
        percentage_per_year: false,
        comments: false,
        actual_amount: false,
        budgeted_amount: false,
        commited_amount: false,
        balance_amount: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !financeDetails?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project financials"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.FINANCE);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.FINANCE}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.FINANCE}
          >
            <FinanceForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <FinancialTableHeader id={`project-financials`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </FinancialTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <FinancialTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("financial_type")}</span>
              </TableTitle>
              <span>{getFormattedCurrency(row.getValue("est_amount"))}</span>
              <span>{row.getValue("funding_source")}</span>
              <span>{moment(row.getValue("start_year")).format("YYYY")}</span>
              <span>{moment(row.getValue("end_year")).format("YYYY")}</span>
              <p style={{ position: "relative" }}>
                {(row.getValue("details") as string)?.length > 80
                  ? `${(row.getValue("details") as string).substring(0, 80)}...`
                  : row.getValue("details") || "N/A"}
              </p>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      financial_type: row.getValue("financial_type"),
                      actual_amount: row.getValue("actual_amount"),
                      est_amount: row.getValue("est_amount"),
                      commited_amount: row.getValue("commited_amount"),
                      budgeted_amount: row.getValue("budgeted_amount"),
                      balance_amount: row.getValue("balance_amount"),
                      details: row.getValue("details"),
                      start_year: row.getValue("start_year"),
                      end_year: row.getValue("end_year"),
                      ongoing: row.getValue("ongoing"),
                      cost_type: row.getValue("cost_type"),
                      percentage_per_year: row.getValue("percentage_per_year"),
                      funding_source: row.getValue("funding_source"),
                      comments: row.getValue("comments"),
                      project_id: +projectId,
                      section_id: null,
                      id: row.getValue("row_id"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.FINANCE}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </FinancialTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.FINANCE}
            wrapperId={`Edit ${MODAL_NAMES.FINANCE}`}
          >
            <FinanceForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialMilestoneState: MilestoneFormValues = {
  end_year: "",
  percentage_of_project: 0,
  responsible_person_id: 0,
  start_year: "",
  title: "",
  description: "",
  sequence: "",
  service_id: 0,
  status_id: 0,
  id: 0,
  est_end_date: "",
  est_start_date: "",
  wbs_id: 0,
};

/**
 * Renders a table for project milestones.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {Milestone[]} [props.milestones] - The project milestones
 * @param {Error | null} props.error - Error object if any
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const MilestoneTableRenderer = ({
  projectId,
  milestones,
  error,
  isActive,
  userRole,
}: {
  projectId: string;
  milestones: Milestone[] | undefined;
  error: Error | null;
  isActive: boolean;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "title", desc: false },
  ]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<MilestoneFormValues>(
    initialMilestoneState,
  );
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectMilestone } = useDeleteProjectMilestone();
  const { setDayModalName } = useDayModalContext();

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const handleDelete = (milestoneId: string) => {
    deleteProjectMilestone(
      { milestoneId: +milestoneId },
      {
        onSuccess: () =>
          toast.success("Project milestone removed successfully."),
      },
    );
  };

  const columnHelper = createColumnHelper<Milestone>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "row_id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor((row) => row.start_year, {
        header: "Start Year",
        id: "start_year",
      }),
      columnHelper.accessor((row) => row.end_year, {
        header: "End Year",
        id: "end_year",
      }),
      columnHelper.accessor((row) => row.est_start_date, {
        header: "Estimated Start Year",
        id: "est_start_date",
      }),
      columnHelper.accessor((row) => row.est_end_date, {
        header: "Estimated End Year",
        id: "est_end_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        },
      ),
      columnHelper.accessor("percentage_of_project", {
        header: "% of Project",
        id: "percentage_of_project",
      }),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_completed",
      }),
      columnHelper.accessor("status.title", {
        header: "Progress Status",
        id: "status",
      }),
      columnHelper.accessor("status_id", {
        id: "status_id",
      }),
      columnHelper.accessor("service_id", {
        id: "service_id",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
      columnHelper.accessor("wbs_id", {
        id: "wbs_id",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: milestones ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        status_id: false,
        service_id: false,
        responsible_person_id: false,
        est_start_date: false,
        est_end_date: false,
        percentage_of_project: false,
        wbs_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !milestones?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project milestones"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.MILESTONES);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.MILESTONES}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.MILESTONES}
          >
            <MilestoneForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              isEditingMode={false}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <MilestoneTableHeader id="project-milestones-table-header">
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </MilestoneTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <MilestoneTableRow
              key={row.id}
              id={`${row.id}-milestones`}
              disabled={isDeleting}
            >
              <TableTitle>
                <RAGIndicator
                  $color={getRAGStatus(
                    row.getValue("est_start_date"),
                    row.getValue("est_end_date"),
                    row.getValue("start_year"),
                    row.getValue("end_year"),
                    row.getValue("status"),
                  )}
                />
                <span>{row.getValue("title")}</span>
              </TableTitle>
              <span>
                {(row.getValue("description") as string)?.length > 80
                  ? `${(row.getValue("description") as string).substring(0, 80)}...`
                  : row.getValue("description") || "N/A"}
              </span>
              <span>
                {row.getValue("start_year")
                  ? moment(row.getValue("start_year")).format("YYYY")
                  : "N/A"}
              </span>
              <span>
                {" "}
                {row.getValue("end_year")
                  ? moment(row.getValue("end_year")).format("YYYY")
                  : "N/A"}
              </span>
              <span>{row.getValue("responsible")}</span>
              <span style={{ paddingLeft: "1.5rem" }}>
                {Number(row.getValue("percentage_completed") || 0).toFixed(2)}
              </span>

              <Pill
                $progressStatus={
                  row.getValue("status") as keyof typeof SCHEDULE_MAP
                }
              >
                <span>{row.getValue("status")}</span>
              </Pill>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      title: row.getValue("title"),
                      description: row.getValue("description"),
                      status_id: row.getValue("status_id"),
                      start_year: row.getValue("start_year")
                        ? moment(row.getValue("start_year")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      end_year: row.getValue("end_year")
                        ? moment(row.getValue("end_year")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      est_start_date: row.getValue("est_start_date")
                        ? moment(row.getValue("est_start_date")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      est_end_date: row.getValue("est_end_date")
                        ? moment(row.getValue("est_end_date")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      id: row.getValue("row_id"),
                      sequence: "sequence",
                      service_id: row.getValue("service_id"),
                      percentage_of_project: row.getValue(
                        "percentage_of_project",
                      ),
                      responsible_person_id: row.getValue(
                        "responsible_person_id",
                      ),
                      wbs_id: row.getValue("wbs_id"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.MILESTONES}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </MilestoneTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.MILESTONES}
            wrapperId={`Edit ${MODAL_NAMES.MILESTONES}`}
          >
            <MilestoneForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isEditingMode
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialTaskState: TaskFormValues = {
  due_date: "",
  milestone_id: 0,
  percentage_completed: 0,
  percentage_of_milestone: 0,
  priority: "",
  responsible_person_id: 0,
  service_id: 0,
  start_date: "",
  status_id: 0,
  title: "",
  description: "",
  id: 0,
  est_end_date: "",
  est_start_date: "",
};

/**
 * Renders a table for project tasks.
 *
 * @param {Object} props - Component props
 * @param {Milestone[]} [props.milestones] - The project milestones
 * @param {boolean} props.isActive - Flag to indicate if the table is active
 * @param {string} props.userRole - The role of the user
 * @returns {JSX.Element} The rendered component
 */
export const TaskTableRenderer = ({
  milestones,
  isActive,
  userRole,
}: {
  milestones: Milestone[] | undefined;
  isActive: boolean;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "title", desc: false },
  ]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<TaskFormValues>(initialTaskState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectTask } = useDeleteProjectTask();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (taskId: string) => {
    deleteProjectTask(
      { taskId: +taskId },
      {
        onSuccess: () => toast.success("Project task removed successfully."),
      },
    );
  };

  const tasks = useMemo(
    () =>
      milestones
        ?.map((milestone) => milestone.tasks)
        ?.flat()
        ?.filter((task) => !task.is_deleted)
        ?.sort((a, b) =>
          moment(a.due_date).isBefore(moment(b.due_date)) ? -1 : 1,
        ),
    [milestones],
  );

  const columnHelper = createColumnHelper<Task>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "row_id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor(
        (row) =>
          milestones?.find((milestone) => milestone.id === row.milestone_id)
            ?.title || "N/A",
        {
          header: "Milestone",
          id: "milestone",
        },
      ),
      columnHelper.accessor((row) => row.start_date, {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor((row) => row.due_date, {
        header: "Due Date",
        id: "due_date",
      }),
      columnHelper.accessor((row) => row.est_start_date, {
        header: "Estimated Start Date",
        id: "est_start_date",
      }),
      columnHelper.accessor((row) => row.est_end_date, {
        header: "Estimated End Date",
        id: "est_end_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        },
      ),
      columnHelper.accessor("percentage_of_milestone", {
        header: "% of Milestone",
        id: "percentage_of_milestone",
      }),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_completed",
      }),
      columnHelper.accessor("status.title", {
        header: "Progress Status",
        id: "status",
      }),
      columnHelper.accessor("status_id", {
        id: "status_id",
      }),
      columnHelper.accessor("service_id", {
        id: "service_id",
      }),
      columnHelper.accessor("milestone_id", {
        id: "milestone_id",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
      columnHelper.accessor("wbs_id", {
        id: "wbs_id",
      }),
    ],
    [columnHelper, milestones],
  );

  // React table config
  const table = useReactTable({
    data: tasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        status_id: false,
        service_id: false,
        milestone_id: false,
        percentage_of_milestone: false,
        responsible_person_id: false,
        start_date: false,
        est_start_date: false,
        est_end_date: false,
        wbs_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (!tasks?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project tasks"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.TASKS);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.TASKS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.TASKS}
          >
            <TaskForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              isEditingMode={false}
              milestones={milestones}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <TaskTableHeader id="project-tasks-table-header">
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </TaskTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <TaskTableRow
              key={row.id}
              id={`${row.id}-tasks`}
              disabled={isDeleting}
            >
              <TableTitle>
                <RAGIndicator
                  $color={getRAGStatus(
                    row.getValue("est_start_date"),
                    row.getValue("est_end_date"),
                    row.getValue("start_date"),
                    row.getValue("due_date"),
                    row.getValue("status"),
                  )}
                />
                <span>{row.getValue("title")}</span>
              </TableTitle>
              <span>
                {(row.getValue("description") as string)?.length > 80
                  ? `${(row.getValue("description") as string).substring(0, 80)}...`
                  : row.getValue("description") || "N/A"}
              </span>
              <span>{row.getValue("milestone")}</span>
              <span>
                {row.getValue("due_date")
                  ? moment(row.getValue("due_date")).format(
                      DAY_FORMATS.DAY_FIRST,
                    )
                  : "N/A"}
              </span>
              <span>{row.getValue("responsible")}</span>
              <span style={{ paddingLeft: "1.5rem" }}>
                {Number(row.getValue("percentage_completed") || 0).toFixed(2)}
              </span>
              <Pill
                $progressStatus={
                  row.getValue("status") as keyof typeof SCHEDULE_MAP
                }
              >
                <span>{row.getValue("status")}</span>
              </Pill>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      title: row.getValue("title"),
                      description: row.getValue("description"),
                      status_id: row.getValue("status_id"),
                      start_date: row.getValue("start_date")
                        ? moment(row.getValue("start_date")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      due_date: row.getValue("due_date")
                        ? moment(row.getValue("due_date")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      est_start_date: row.getValue("est_start_date")
                        ? moment(row.getValue("est_start_date")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      est_end_date: row.getValue("est_end_date")
                        ? moment(row.getValue("est_end_date")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : "",
                      id: row.getValue("row_id"),
                      service_id: row.getValue("service_id"),
                      percentage_of_milestone: row.getValue(
                        "percentage_of_milestone",
                      ),
                      percentage_completed: row.getValue(
                        "percentage_completed",
                      ),
                      milestone_id: row.getValue("milestone_id"),
                      responsible_person_id: row.getValue(
                        "responsible_person_id",
                      ),
                      wbs_id: row.getValue("wbs_id"),
                      priority: "priority",
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.TASKS}`);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </TaskTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.TASKS}
            wrapperId={`Edit ${MODAL_NAMES.TASKS}`}
          >
            <TaskForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              details={details}
              isEditingMode
              milestones={milestones}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialCostCentreState: ProjectCostCentreFormValues = {
  cost_centre_id: 0,
  project_id: 0,
};
export const ProjectCostCentreTableRenderer = ({
  projectId,
  isActive,
  costCentres,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  costCentres: ProjectCostCentre[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ProjectCostCentreFormValues>(
    initialCostCentreState,
  );
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isPending: isDeleting, deleteProjectCostCentre } =
    useDeleteProjectCostCentre();
  const { setDayModalName } = useDayModalContext();
  const { contacts } = useGetContacts();

  const handleDelete = (id: string) => {
    deleteProjectCostCentre(+id, {
      onSuccess: () =>
        toast.success("Project cost centre removed successfully."),
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectCostCentre>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("costcentre.id", {
        header: "cost_centre_id",
        id: "cost_centre_id",
      }),
      columnHelper.accessor("costcentre.title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("costcentre.description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("costcentre.contact_id", {
        header: "Responsible Person",
        id: "contact_id",
      }),
      columnHelper.accessor("costcentre.start_date", {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor("costcentre.end_date", {
        header: "End Date",
        id: "end_date",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: costCentres ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        cost_centre_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !costCentres?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project Cost Centres"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.COST_CENTRE);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.COST_CENTRE}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.COST_CENTRE}
            modalWidth="50%"
          >
            <ProjectCostCentreForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <ProjectCostCentreTableHeader id={`project-cost-centre`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </ProjectCostCentreTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <ProjectCostCentreTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("title")}</span>
              </TableTitle>
              <span>{row.getValue("description")}</span>
              <span>
                {contacts?.find(
                  (contact) => contact.id === row.getValue("contact_id"),
                )?.first_name +
                  " " +
                  contacts?.find(
                    (contact) => contact.id === row.getValue("contact_id"),
                  )?.last_name}
              </span>
              <span>
                {row.getValue("start_date")
                  ? moment(row.getValue("start_date")).format(
                      DAY_FORMATS.DAY_FIRST,
                    )
                  : "N/A"}
              </span>
              <span>
                {row.getValue("end_date")
                  ? moment(row.getValue("end_date")).format(
                      DAY_FORMATS.DAY_FIRST,
                    )
                  : "N/A"}
              </span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      cost_centre_id: row.getValue("cost_centre_id"),
                      project_id: +projectId,
                    });
                    setCurrentModalName(MODAL_NAMES.COST_CENTRE);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </ProjectCostCentreTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.COST_CENTRE}
            wrapperId={MODAL_NAMES.COST_CENTRE}
            modalWidth="50%"
          >
            <ProjectCostCentreForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isViewingMode
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialWBSState: ProjectWBSFormValues = {
  project_id: 0,
  wbs_id: 0,
};

export const ProjectWBSTableRenderer = ({
  projectId,
  isActive,
  wbs,
  costCentres,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  wbs: ProjectWBS[] | undefined;
  costCentres: ProjectCostCentre[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] = useState<ProjectWBSFormValues>(initialWBSState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isPending: isDeleting, deleteProjectWBS } = useDeleteProjectWBS();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (id: string) => {
    deleteProjectWBS(+id, {
      onSuccess: () => toast.success("Project WBS removed successfully."),
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectWBS>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("wbs.id", {
        header: "wbs_id",
        id: "wbs_id",
      }),
      columnHelper.accessor("wbs.title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("wbs.description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("wbs.cost_centre_id", {
        header: "Cost Centre",
        id: "cost_centre_id",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: wbs ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        wbs_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !wbs?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Project WBS"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.WBS);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.WBS}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.WBS}
            modalWidth="50%"
          >
            <ProjectWBSForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <ProjectWBSTableHeader id={`project-wbs`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </ProjectWBSTableHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <ProjectWBSTableRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <TableTitle>
                <span>{row.getValue("title")}</span>
              </TableTitle>
              <span>{row.getValue("description")}</span>
              <span>
                {costCentres?.find(
                  (costCentre) =>
                    costCentre.costcentre_id === row.getValue("cost_centre_id"),
                )?.costcentre.title || "N/A"}
              </span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      wbs_id: row.getValue("wbs_id"),
                      project_id: +projectId,
                    });
                    setCurrentModalName(MODAL_NAMES.WBS);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </ProjectWBSTableRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.WBS}
            wrapperId={MODAL_NAMES.WBS}
            modalWidth="50%"
          >
            <ProjectWBSForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              isViewingMode
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};

const initialCostState: UpsertProjectExpense = {
  project_id: 0,
  cost_centre_id: 0,
  wbs_id: 0,
  est_amount: 0,
  actual_amount: 0,
  ext_erp_id: "",
  description: "",
  project_financial_id: 0,
  id: 0,
};
export const ProjectExpenseTableRenderer = ({
  projectId,
  isActive,
  expenses,
  costCentres,
  wbs,
  error,
  userRole,
}: {
  projectId: string;
  isActive: boolean;
  expenses: ProjectExpense[] | undefined;
  costCentres: ProjectCostCentre[] | undefined;
  wbs: ProjectWBS[] | undefined;
  error: Error | null;
  userRole: string;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [details, setDetails] =
    useState<UpsertProjectExpense>(initialCostState);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isPending: isDeleting, deleteProjectExpense } =
    useDeleteProjectExpense();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (id: string) => {
    deleteProjectExpense(+id, {
      onSuccess: () => toast.success("Project expense removed successfully."),
    });
  };

  // projects table setup
  const columnHelper = createColumnHelper<ProjectExpense>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Id",
        id: "row_id",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("cost_centre_id", {
        header: "Cost Centre",
        id: "cost_centre_id",
      }),
      columnHelper.accessor("wbs_id", {
        header: "WBS",
        id: "wbs_id",
      }),
      columnHelper.accessor("est_amount", {
        header: "Estimated Amount",
        id: "est_amount",
      }),
      columnHelper.accessor("actual_amount", {
        header: "Actual Amount",
        id: "actual_amount",
      }),
      columnHelper.accessor("ext_erp_id", {
        header: "External ERP ID",
        id: "ext_erp_id",
      }),
      columnHelper.accessor("project_financial_id", {
        header: "Funding ID",
        id: "project_financial_id",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: expenses ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnVisibility: {
        row_id: false,
        ext_erp_id: false,
        project_financial_id: false,
      },
    },
    onSortingChange: setSorting,
  });

  if (error || !expenses?.length)
    return (
      <TableContainer $isActive={isActive}>
        <HeightTransitionDiv>
          <NoDataCard
            title="Expenses"
            buttonLinked={canUserEdit}
            height="6rem"
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.EXPENSE);
              setIsProjectDetailsOpen(true);
            }}
          />
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.EXPENSE}
            type={MODAL_TYPE.NEW}
            wrapperId={MODAL_NAMES.EXPENSE}
            modalWidth="50%"
          >
            <ProjectExpenseForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              canUserEdit={canUserEdit}
            />
          </Modal>
        </HeightTransitionDiv>
      </TableContainer>
    );

  return (
    <TableContainer $isActive={isActive}>
      <HeightTransitionDiv>
        <ProjectExpenseHeader id={`project-expenses`}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          {/* this is to ensure that grid is aligned */}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <DeleteCon style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </ProjectExpenseHeader>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <ProjectExpenseRow
              key={row.id}
              id={`${row.id}-details`}
              disabled={isDeleting}
            >
              <span>{row.getValue("description")}</span>
              <span>
                {costCentres?.find(
                  (costCentre) =>
                    costCentre.costcentre_id === row.getValue("cost_centre_id"),
                )?.costcentre.title || "N/A"}
              </span>
              <span>
                {wbs?.find((wbs) => wbs.wbs_id === row.getValue("wbs_id"))?.wbs
                  .title || "N/A"}
              </span>
              <span>{getFormattedCurrency(row.getValue("est_amount"))}</span>
              <span>{getFormattedCurrency(row.getValue("actual_amount"))}</span>
              <EditDeleteContainer disabled={!canUserDelete}>
                <Eye
                  onClick={() => {
                    setDetails({
                      est_amount: row.getValue("est_amount"),
                      actual_amount: row.getValue("actual_amount"),
                      description: row.getValue("description"),
                      cost_centre_id: row.getValue("cost_centre_id"),
                      wbs_id: row.getValue("wbs_id"),
                      project_id: +projectId,
                      ext_erp_id: row.getValue("ext_erp_id"),
                      project_financial_id: row.getValue(
                        "project_financial_id",
                      ),
                      id: row.getValue("row_id"),
                    });
                    setCurrentModalName(MODAL_NAMES.EXPENSE);
                    setIsProjectDetailsOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("row_id")}`}
                  onDelete={() => handleDelete(row.getValue("row_id"))}
                />
              </EditDeleteContainer>
            </ProjectExpenseRow>
          ))}
          <Modal
            isOpen={isProjectDetailsOpen}
            title={MODAL_NAMES.EXPENSE}
            wrapperId={MODAL_NAMES.EXPENSE}
            modalWidth="50%"
          >
            <ProjectExpenseForm
              handleClose={() => {
                setCurrentModalName("");
                setIsProjectDetailsOpen(false);
              }}
              projectId={projectId}
              details={details}
              canUserEdit={canUserEdit}
              isEditingMode
            />
          </Modal>
        </TableRowsContainer>
      </HeightTransitionDiv>
    </TableContainer>
  );
};
