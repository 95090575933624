import { useState } from "react";
import styled from "styled-components";
import Edit from "../../assets/EditPencil.svg?react";
import MainProfileIcon from "../../assets/MainProfileIcon.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Plus from "../../assets/Plus.svg?react";
import Search from "../../assets/Search.svg?react";
import {
  BUTTON_TYPES,
  MODAL_NAMES,
  MODAL_TYPE,
  ROLES,
} from "../../common/Constants";
import { USER_STATUS_MAP } from "../../common/ScheduleMap";
import { useActiveModalContext } from "../../context/ModalContext";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetTenant } from "../../hooks/auth/useTenant";
import ButtonCTA from "../../ui/ButtonCTA";
import { ProjectHeader, SideContainer } from "../../ui/HeaderComponents";
import Icons from "../../ui/Icons";
import Modal from "../../ui/Modal";
import Spinner from "../../ui/Spinner";
import ProfileLink from "../navigation/ProfileLink";
import CreateEditProject from "../projects/CreateProject";
import UserProfileForm from "../forms/UserProfileForm";
import OrganizationForm from "../forms/OrganizationForm";
import { useGetUserRoles } from "../../hooks/auth/useRoles";

const ProfileLayout = styled.div`
  background-color: white;
  border-radius: var(--border-radius-xl);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  & svg {
    cursor: pointer;
  }
`;

const MainContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  & h1 {
    font-size: 2.4rem;
  }
  & p {
    color: var(--color-ref-no);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof USER_STATUS_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => USER_STATUS_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
  min-width: 9.6rem;
`;

const UserInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 3px dashed #ebeff6;
`;

const OrgInfoGrid = styled(UserInfoGrid)`
  grid-template-columns: repeat(2, 1fr);
`;

const UserGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const OrgHeader = styled.div`
  display: flex;
  flex-direction: column;
  & h2 {
    color: var(--color-ref-no);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
  & h1 {
    font-size: 2.4rem;
  }
`;

/**
 * ProfileOverview component displays the user's profile information and organization details.
 * It allows editing of the profile and organization information through modals.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const ProfileOverview = () => {
  const { setCurrentModalName } = useActiveModalContext();
  const [isNewProjectOpen, setIsNewProjectOpen] = useState(false);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [isEditOrgOpen, setIsEditOrgOpen] = useState(false);

  const { isLoading: isUserLoading, user } = useGetCurrentUser();
  const { isUserRoleLoading, userRoles } = useGetUserRoles(user?.id || "0");

  const isAdmin = !!userRoles?.some(
    (role) => role.role.title.toUpperCase() === ROLES.ADMIN,
  );

  const domain = user?.email.split("@")[1] || "";

  const { isLoading, tenant } = useGetTenant(domain);

  if (isLoading || isUserLoading || isUserRoleLoading) return <Spinner />;

  return (
    <>
      <ProjectHeader>
        <h1>Profile</h1>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.PROJECT);
              setIsNewProjectOpen(true);
            }}
          >
            new project
            <Plus />
          </ButtonCTA>
          <Modal
            isOpen={isNewProjectOpen}
            title="Create Project"
            wrapperId={MODAL_NAMES.PROJECT}
            modalWidth="60%"
          >
            <CreateEditProject
              handleClose={() => {
                setCurrentModalName("");
                setIsNewProjectOpen(false);
              }}
              isEditingMode={false}
            />
          </Modal>
        </SideContainer>
      </ProjectHeader>
      <ProfileLayout>
        <MainContainer>
          <MainProfileIcon />
          <Header>
            <h1>{user?.full_name}</h1>
            <p>{user?.config?.job_title}</p>
          </Header>
          <Pill
            $progressStatus={
              user?.config?.status as keyof typeof USER_STATUS_MAP
            }
          >
            <span>{user?.config?.status}</span>
          </Pill>
          <Edit
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.PROFILE);
              setIsEditProfileOpen(true);
            }}
            style={{ marginLeft: "auto", height: "1.6rem", width: "1.6rem" }}
          />
        </MainContainer>
        <UserInfoGrid>
          <UserGridItem>
            <h2>email</h2>
            <h1>{user?.email}</h1>
          </UserGridItem>
          <UserGridItem>
            <h2>phone</h2>
            <h1>{user?.config?.phone}</h1>
          </UserGridItem>
          <UserGridItem>
            <h2>department</h2>
            <h1>{user?.config?.department}</h1>
          </UserGridItem>
          <UserGridItem>
            <h2>location</h2>
            <h1>{user?.config?.location}</h1>
          </UserGridItem>
        </UserInfoGrid>
        <Modal
          isOpen={isEditProfileOpen}
          title={MODAL_NAMES.PROFILE}
          type={MODAL_TYPE.EDIT}
          wrapperId={MODAL_NAMES.PROFILE}
        >
          <UserProfileForm
            autoRedirect={false}
            handleClose={() => {
              setCurrentModalName("");
              setIsEditProfileOpen(false);
            }}
          />
        </Modal>
      </ProfileLayout>
      <ProfileLayout>
        <MainContainer>
          <OrgHeader>
            <h2>{tenant?.registration_id}</h2>
            <h1>{tenant?.title}</h1>
          </OrgHeader>
          {isAdmin && (
            <Edit
              style={{ marginLeft: "auto", height: "1.6rem", width: "1.6rem" }}
              onClick={() => {
                setCurrentModalName(MODAL_NAMES.ORGANIZATION);
                setIsEditOrgOpen(true);
              }}
            />
          )}
        </MainContainer>
        <OrgInfoGrid>
          <UserGridItem>
            <h2>physical address</h2>
            <h1>{tenant?.physical_address}</h1>
          </UserGridItem>
          <UserGridItem>
            <h2>postal address</h2>
            <h1>{tenant?.postal_address}</h1>
          </UserGridItem>
        </OrgInfoGrid>
        <Modal
          isOpen={isEditOrgOpen}
          title={MODAL_NAMES.ORGANIZATION}
          wrapperId={MODAL_NAMES.ORGANIZATION}
          type={MODAL_TYPE.EDIT}
        >
          <OrganizationForm
            autoRedirect={false}
            handleClose={() => {
              setCurrentModalName("");
              setIsEditOrgOpen(false);
            }}
          />
        </Modal>
      </ProfileLayout>
    </>
  );
};

export default ProfileOverview;
