import { Helmet } from "react-helmet";
import ProfileOverview from "../features/profile/ProfileOverview";

/**
 * Profile component displays the user's profile information.
 * @returns {JSX.Element} The rendered Profile component.
 */
const Profile = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Profile</title>
      </Helmet>
      <ProfileOverview />
    </>
  );
};

export default Profile;
