import { Helmet } from "react-helmet";
import AdminOverview from "../features/admin/AdminOverview";

/**
 * Admin component for rendering the admin overview page.
 *
 * @returns {JSX.Element} The rendered component
 */
const Admin = () => {
  // protect route from unauthorized users -- only admin can access
  return (
    <>
      <Helmet>
        <title>Capital Works | Admin</title>
      </Helmet>
      <AdminOverview />
    </>
  );
};

export default Admin;
