import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import styled from "styled-components";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
  ROLES,
  USER_STATUS,
} from "../../common/Constants";
import {
  useCreateUserProfile,
  useUpdateUserProfile,
} from "../../hooks/auth/useRegisterUser";
import { useGetAllRoles } from "../../hooks/auth/useRoles";
import { SignUpCred, UserDetailType } from "../../types/authTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  LabelAndInput,
  StyledInput,
  StyledSection,
  StyledTwoFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getItemDropdownValues } from "../../utils/GetDropdownValue";

import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  margin-left: auto;
  font-size: 1.4rem;
  font-weight: 700;
`;

const Switch = styled.div`
  position: relative;
  width: 3.9rem;
  height: 2rem;
  background: #b3b3b3;
  border-radius: var(--border-radius-2xl);
  padding: 0.5rem;

  &:before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--border-radius-2xl);
    top: 50%;
    left: 0.2rem;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: var(--color-button-secondary);

    &:before {
      transform: translate(2rem, -50%);
    }
  }
`;

/**
 * AddUserForm component allows creating or updating a user profile.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {() => void} props.handleClose - Function to close the form.
 * @param {boolean} [props.isEditMode] - Flag to indicate if the form is in edit mode.
 * @param {UserDetailType | null} props.userDetails - The user details for editing.
 * @returns {JSX.Element} The rendered component.
 */
const AddUserForm = ({
  handleClose,
  isEditMode,
  userDetails,
}: {
  handleClose: () => void;
  isEditMode?: boolean;
  userDetails: UserDetailType | null;
}) => {
  const { isRolesLoading, roles } = useGetAllRoles();
  const { setDayModalName } = useDayModalContext();

  const defaultRoleId = isEditMode
    ? userDetails?.role_id?.toString()
    : roles
        ?.find((role) => role.title?.toUpperCase() === ROLES.VIEWER)
        ?.id?.toString();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setFocus,
    setValue,
    control,
    reset,
  } = useForm<SignUpCred>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      first_name: userDetails?.full_name.split(" ")[0] || "",
      last_name: userDetails?.full_name.split(" ")[1] || "",
      email: userDetails?.email || "",
      role_id: defaultRoleId,
      config: {
        ...userDetails?.config,
        status: userDetails?.config?.status === USER_STATUS.ACTIVE,
      },
    },
  });

  useEffect(() => {
    setFocus("first_name");
    if (roles) {
      reset({
        role_id: defaultRoleId,
      });
    }
  }, [setFocus, reset, roles, defaultRoleId]);

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof SignUpCred, id, { shouldDirty: true });
  };

  const { isCreating, createUserProfile } = useCreateUserProfile();
  const { isUpdating, updateUserProfile } = useUpdateUserProfile();
  const { isLoading, user } = useGetCurrentUser();

  const domain = user?.email.split("@")[1];

  const onSubmit: SubmitHandler<SignUpCred> = (data) => {
    const userData = {
      ...data,
      full_name: `${data.first_name} ${data.last_name}`,
      login_name: data.email,
      config: {
        status: isEditMode
          ? data.config?.status
            ? USER_STATUS.ACTIVE
            : USER_STATUS.INACTIVE
          : USER_STATUS.INVITED,
        is_profile_updated: false, // update for edit mode when passing in the data
      },
    };

    if (isEditMode) {
      updateUserProfile(
        {
          userDetails: {
            ...userDetails,
            ...userData,
            id: userDetails?.id || "",
            role_id: +(userData?.role_id || 0),
            isactive: data.config.status,
            config: {
              ...userDetails?.config,
              status: userData.config.status,
            },
          },
          current_role_id: +(userDetails?.role_id || 0),
        },
        {
          onSuccess: () => {
            toast.success("User updated successfully");
            handleClose();
          },
        },
      );
    } else {
      createUserProfile(userData, {
        onSuccess: () => {
          toast.success("User created successfully");
          handleClose();
        },
      });
    }
  };

  if (isCreating || isRolesLoading || isUpdating || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {/* ability to edit the status of the user is only available when the
      user has accepted the invite */}
          {isEditMode &&
            userDetails?.config?.status !== USER_STATUS.INVITED && (
              <Controller
                name="config.status"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Label>
                    <span>Active</span>
                    <Input
                      checked={value}
                      type="checkbox"
                      onChange={onChange}
                    />
                    <Switch />
                  </Label>
                )}
              />
            )}
          <StyledTwoFieldSection>
            <LabelAndInput>
              <label> First Name * </label>
              <StyledInput
                type="text"
                {...register("first_name", {
                  required: "This field is required",
                })}
                autoComplete="off"
                warning={errors?.first_name?.message}
              />
              {errors?.first_name && (
                <ErrorMessage errorMessage={errors.first_name.message} />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label> Last Name *</label>
              <StyledInput
                type="text"
                {...register("last_name", {
                  required: "This field is required",
                })}
                autoComplete="off"
                warning={errors?.last_name?.message}
              />
              {errors?.last_name && (
                <ErrorMessage errorMessage={errors.last_name.message} />
              )}
            </LabelAndInput>
          </StyledTwoFieldSection>
          <StyledTwoFieldSection>
            <LabelAndInput>
              <label>Email *</label>
              <StyledInput
                type="email"
                {...register("email", {
                  required: "This field is required",
                  validate: (value) =>
                    value.endsWith(`@${domain}`) ||
                    `Email must be from the ${domain} domain`,
                })}
                autoComplete="off"
                readOnly={isEditMode}
                warning={errors?.email?.message}
              />
              {errors?.email && (
                <ErrorMessage errorMessage={errors.email.message} />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>Role *</label>
              <Controller
                control={control}
                name="role_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="role_id"
                    title=""
                    data={getItemDropdownValues(roles)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search={false}
                    showOnTop
                  />
                )}
              />
              {errors?.role_id && (
                <ErrorMessage errorMessage={errors.role_id.message} />
              )}
            </LabelAndInput>
          </StyledTwoFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            Cancel
            <Discard />
          </ButtonCTA>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            $buttonType={BUTTON_TYPES.SECONDARY}
            type="submit"
          >
            {isEditMode ? "Update" : "Create"}
            <CheckMark />
          </ButtonCTA>
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default AddUserForm;
