import { Helmet } from "react-helmet";
import ProjectDetails from "../features/projects/ProjectDetails";

/**
 * ProjectDetailsPage component displays the details of a project.
 * @returns {JSX.Element} The rendered ProjectDetailsPage component.
 */
const ProjectDetailsPage = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Details</title>
      </Helmet>
      <ProjectDetails />
    </>
  );
};

export default ProjectDetailsPage;
