import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createTaskAPI,
  deleteTaskAPI,
  updateTaskAPI,
} from "../../services/apiMilestones";
import { UpsertTask } from "../../types/milestoneTypes";

export const useCreateProjectTask = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectTask,
    error,
  } = useMutation({
    mutationFn: ({ taskDetails }: { taskDetails: UpsertTask }) =>
      createTaskAPI(taskDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TASKS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECTS],
      });
    },
  });

  return { isCreating, createProjectTask, error };
};

export const useEditProjectTask = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectTask,
    error,
  } = useMutation({
    mutationFn: ({
      taskDetails,
      taskId,
    }: {
      taskDetails: UpsertTask;
      taskId: number;
    }) => updateTaskAPI(taskDetails, taskId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TASKS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECTS],
      });
    },
  });

  return { isEditing, editProjectTask, error };
};

export const useDeleteProjectTask = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectTask,
    error,
  } = useMutation({
    mutationFn: ({ taskId }: { taskId: number }) => deleteTaskAPI(taskId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.TASKS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.MILESTONES],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECTS],
      });
    },
  });

  return { isDeleting, deleteProjectTask, error };
};
