import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectWorkforceAPI,
  deleteProjectWorkforceAPI,
  getProjectWorkforcesAPI,
  updateProjectWorkforceAPI,
} from "../../services/apiProjects";
import { UpsertProjectWorkforce } from "../../types/projectTypes";

export const useGetProjectWorkforces = (id: string) => {
  const {
    isPending: isWorkforceLoading,
    data: workforceDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectWorkforcesAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_WORKFORCE, id],
    retry: false,
    enabled: !!id,
  });

  return { isWorkforceLoading, workforceDetails, error };
};

export const useCreateProjectWorkforce = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectWorkforce,
    error,
  } = useMutation({
    mutationFn: ({
      workforceDetails,
    }: {
      workforceDetails: UpsertProjectWorkforce;
    }) => createProjectWorkforceAPI(workforceDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_WORKFORCE],
      });
    },
  });

  return { isCreating, createProjectWorkforce, error };
};

export const useEditProjectWorkforce = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectWorkforce,
    error,
  } = useMutation({
    mutationFn: ({
      workforceDetails,
      detailId,
    }: {
      workforceDetails: UpsertProjectWorkforce;
      detailId: number;
    }) => updateProjectWorkforceAPI(workforceDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_WORKFORCE],
      });
    },
  });

  return { isEditing, editProjectWorkforce, error };
};

export const useDeleteProjectWorkforce = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectWorkforce,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectWorkforceAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_WORKFORCE],
      });
    },
  });

  return { isDeleting, deleteProjectWorkforce, error };
};
