import axios from "axios";
import { refreshTokenAPI } from "./apiAuth";
import moment from "moment";

export const client = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

// request interceptor to add bearer token to all requests
client.interceptors.request.use(
  async (config) => {
    const {
      location: { host },
    } = window;
    const token = localStorage.getItem("accessDetails" + host);
    if (token) {
      const { access_token } = JSON.parse(token);
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// response interceptor to refetch and add bearer token if we get unauthorized response from APIs
client.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      (error?.response?.status === 403 || error?.response?.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const {
        location: { host },
      } = window;
      const accessDetailsString = localStorage.getItem("accessDetails" + host);
      if (!accessDetailsString) return Promise.reject(error);
      const { refresh_token, expires_at } = JSON.parse(accessDetailsString);

      let newAccessDetails;
      if (moment().isAfter(moment(expires_at))) {
        newAccessDetails = await refreshTokenAPI(refresh_token);
        localStorage.setItem(
          "accessDetails" + host,
          JSON.stringify(newAccessDetails),
        );
      }

      client.defaults.headers.common["Authorization"] =
        `Bearer ${newAccessDetails?.access_token}`;
      return client(originalRequest);
    }
    return Promise.reject(error);
  },
);
