import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import EmailSentIcon from "../assets/EmailSent.svg?react";
import AtenLogo from "../assets/aten-logo.svg?react";
import { BUTTON_SIZES, BUTTON_TYPES } from "../common/Constants";
import ButtonCTA from "../ui/ButtonCTA";

const LoginLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  flex-direction: column;
  background-image: url("/BGLines.svg");
  gap: 4rem;
`;

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  width: fit-content;
  border-radius: var(--border-radius-2xl);
  padding: 6rem;
  max-width: 48rem;
`;

const scaleAnimation = keyframes`
  0% {
      transform: scale(100%);
  }
  50% {
    transform: scale(60%);
  }
  100% {
      transform: scale(100%); 
  }
`;

const EmailIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  & svg {
    animation: ${scaleAnimation} 2s ease-in-out;
  }
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: var(--color-version);
`;

/**
 * Used to render email sent page
 * @returns EmailSent component
 */
const EmailSent = () => {
  const navigate = useNavigate();
  return (
    <LoginLayout>
      <Helmet>
        <title>Capital Works | Login</title>
      </Helmet>
      <FormContainer>
        <EmailIconDiv>
          <EmailSentIcon />
          <h1>Email Sent!</h1>
        </EmailIconDiv>
        <p>
          An email with a verification link has been sent to your inbox. Please
          click on it to verify and complete sign up.
        </p>
        <ButtonCTA
          $buttonSize={BUTTON_SIZES.MEDIUM_LARGE}
          $buttonType={BUTTON_TYPES.SECONDARY}
          onClick={() => navigate("/login")}
        >
          Login
        </ButtonCTA>
      </FormContainer>
      <LogoDiv>
        <p>{import.meta.env.VITE_APP_VERSION} | Powered by</p>
        <AtenLogo />
      </LogoDiv>
    </LoginLayout>
  );
};

export default EmailSent;
