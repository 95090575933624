import moment from "moment";
import { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @description Used to reroute unauthenticated users to the login page
 * @returns children if user is authenticated
 */
const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const {
    location: { host },
  } = window;
  useEffect(() => {
    const accessDetailsString = localStorage.getItem("accessDetails" + host);
    const loginTime = localStorage.getItem("loginTime");
    // user will be auto logged out after 28 days
    const isLoginTimeExpired = moment().diff(moment(loginTime), "days") > 28;
    if (!accessDetailsString || isLoginTimeExpired) {
      localStorage.clear();
      navigate("/login");
    }
  });

  return <div>{children}</div>;
};

export default ProtectedRoute;
