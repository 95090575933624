import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";

import { BUTTON_SIZES, BUTTON_TYPES } from "../../common/Constants";
import { useGetCostCentres } from "../../hooks/finances/useCostCentre";
import {
  useCreateProjectCostCentre,
  useGetProjectCostCentres,
} from "../../hooks/finances/useProjectCostCentres";
import { ProjectCostCentreFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  LabelAndInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getItemDropdownValues } from "../../utils/GetDropdownValue";
import { useMemo, useState } from "react";
import { CostCentre } from "../../types/financeTypes";
import moment from "moment";
import styled from "styled-components";
import { useGetContacts } from "../../hooks/useGetContacts";
import toast from "react-hot-toast";

const EachCostCentre = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const CostCentreTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(2, 1fr) 2fr;
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

/**
 * ProjectCostCentreForm component for creating and editing project details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @returns {JSX.Element} The rendered component.
 */
const ProjectCostCentreForm = ({
  projectId,
  handleClose,
  details,
  isViewingMode,
}: {
  projectId: string;
  handleClose: () => void;
  details?: ProjectCostCentreFormValues;
  isViewingMode?: boolean;
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectCostCentreFormValues>();

  const { isPending, createProjectCostCentre } = useCreateProjectCostCentre();
  const { isLoading, costCentres } = useGetCostCentres();
  const { isContactsLoading, contacts } = useGetContacts();
  const {
    isLoading: isProjectCostLoading,
    projectCostCentres,
    error,
  } = useGetProjectCostCentres(+projectId);

  const [selectedCostCentre, setSelectedCostCentre] =
    useState<CostCentre | null>(
      costCentres?.find((cc) => cc.id === details?.cost_centre_id) || null,
    );

  // Filter cost centres that are not already added to the project
  const filteredCostCentres = useMemo(
    () =>
      costCentres?.filter(
        (cc) =>
          !projectCostCentres?.find((pcc) => pcc.costcentre_id === cc.id) ||
          cc.id === details?.cost_centre_id,
      ),
    [costCentres, projectCostCentres, details?.cost_centre_id],
  );

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof ProjectCostCentreFormValues, +id);
    setSelectedCostCentre(costCentres?.find((cc) => cc.id === +id) || null);
  };

  const selectedContact = contacts?.find(
    (contact) => contact.id === selectedCostCentre?.contact_id,
  );

  const onSubmit: SubmitHandler<ProjectCostCentreFormValues> = (
    data,
    event,
  ) => {
    event?.preventDefault();
    createProjectCostCentre(
      {
        project_id: +projectId,
        costcentre_id: data.cost_centre_id,
      },
      {
        onSuccess: () => {
          toast.success("Cost Centre added to project successfully");
          handleClose();
        },
      },
    );
  };

  if (error) throw new Error(error.message);

  if (isPending || isLoading || isContactsLoading || isProjectCostLoading)
    return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {selectedCostCentre && (
            <EachCostCentre key={selectedCostCentre.id}>
              <CostCentreTitle>
                {"Cost Centre - " + selectedCostCentre.title}
              </CostCentreTitle>
              <ProjectInfoGrid>
                <ProjectGridItem>
                  <h2>Description</h2>
                  <h1>{selectedCostCentre.description || "N/A"}</h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Start Date</h2>
                  <h1>
                    {selectedCostCentre?.start_date
                      ? moment(selectedCostCentre.start_date).format(
                          "DD/MM/YYYY",
                        )
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>End Date</h2>
                  <h1>
                    {selectedCostCentre?.end_date
                      ? moment(selectedCostCentre.end_date).format("DD/MM/YYYY")
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Responsible Person</h2>
                  <h1>{`${selectedContact?.first_name} ${selectedContact?.last_name}`}</h1>
                </ProjectGridItem>
              </ProjectInfoGrid>
            </EachCostCentre>
          )}
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Cost Centre</label>
              <Controller
                control={control}
                name="cost_centre_id"
                rules={{ required: "This field is required" }}
                defaultValue={details?.cost_centre_id}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="cost_centre_id"
                    title=""
                    data={getItemDropdownValues(filteredCostCentres)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={!!details?.cost_centre_id}
                  />
                )}
              />

              {errors?.cost_centre_id && (
                <ErrorMessage errorMessage={errors.cost_centre_id.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={handleClose}
          >
            {!isViewingMode ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
    </>
  );
};

export default ProjectCostCentreForm;
