import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import EmailVerifiedIcon from "../assets/EmailVerified.svg?react";
import AtenLogo from "../assets/aten-logo.svg?react";
import { BUTTON_SIZES, BUTTON_TYPES } from "../common/Constants";
import ButtonCTA from "../ui/ButtonCTA";
import { useVerifyUser } from "../hooks/auth/useVerifyUser";
import Spinner from "../ui/Spinner";
import { useEffect } from "react";
import toast from "react-hot-toast";

const LoginLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  flex-direction: column;
  background-image: url("/BGLines.svg");
  gap: 4rem;
`;

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  width: fit-content;
  border-radius: var(--border-radius-2xl);
  padding: 6rem;
  max-width: 48rem;
`;

const scaleAnimation = keyframes`
  0% {
      transform: scale(100%);
  }
  50% {
    transform: scale(60%);
  }
  100% {
      transform: scale(100%); 
  }
`;

const EmailIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  & svg {
    animation: ${scaleAnimation} 1s ease;
  }
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: var(--color-version);
`;

const EmailVerified = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const { isLoading, error, data } = useVerifyUser(token || "");

  useEffect(() => {
    let timeout: number;
    if (data) {
      toast.success("Email verified successfully, redirecting...", {
        duration: 3000,
      });
      timeout = setTimeout(() => {
        navigate("/login");
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [data, navigate]);

  if (isLoading)
    return (
      <LoginLayout>
        <FormContainer>
          <Spinner />
        </FormContainer>
      </LoginLayout>
    );

  if (error) throw new Error(error.message);

  return (
    <LoginLayout>
      <Helmet>
        <title>Capital Works | Login</title>
      </Helmet>
      <FormContainer>
        <EmailIconDiv>
          <EmailVerifiedIcon />
          <h1>Email verified!</h1>
        </EmailIconDiv>
        <p>
          Thank you for verifying your email, you can now login to your account.
        </p>
        <p style={{ marginBottom: "-2rem" }}>
          Click here, if you aren't redirected automatically.
        </p>
        <ButtonCTA
          $buttonSize={BUTTON_SIZES.MEDIUM_LARGE}
          $buttonType={BUTTON_TYPES.SECONDARY}
          onClick={() => navigate("/login")}
        >
          Login
        </ButtonCTA>
      </FormContainer>
      <LogoDiv>
        <p>{import.meta.env.VITE_APP_VERSION} | Powered by</p>
        <AtenLogo />
      </LogoDiv>
    </LoginLayout>
  );
};

export default EmailVerified;
