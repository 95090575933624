import { useQuery } from "@tanstack/react-query";
import {
  getAllRolesAPI,
  getAllUserRolesAPI,
  getUserRolesAPI,
} from "../../services/apiRoles";
import { QUERY_KEYS } from "../../common/Constants";

export const useGetAllRoles = () => {
  const {
    isPending: isRolesLoading,
    data: roles,
    error,
  } = useQuery({
    queryFn: () => getAllRolesAPI(),
    queryKey: [QUERY_KEYS.ROLES],
  });

  return {
    isRolesLoading,
    roles,
    error,
  };
};

export const useGetUserRoles = (userId: string) => {
  const {
    isPending: isUserRoleLoading,
    data: userRoles,
    error,
  } = useQuery({
    queryFn: () => getUserRolesAPI(userId),
    queryKey: [QUERY_KEYS.USER_ROLES, userId],
    enabled: !!userId,
  });

  return {
    isUserRoleLoading,
    userRoles,
    error,
  };
};

export const useGetAllUserRoles = () => {
  const {
    isPending: isLoading,
    data: allUserRoles,
    error,
  } = useQuery({
    queryFn: () => getAllUserRolesAPI(),
    queryKey: [QUERY_KEYS.ALL_USER_ROLES],
    retry: false,
  });

  return {
    isLoading,
    allUserRoles,
    error,
  };
};
