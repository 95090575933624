import styled from "styled-components";
import ArrowUpRight from "../../assets/ArrowUpRight.svg?react";
import Assumptions from "../../assets/Assumptions.svg?react";
import BackButton from "../../assets/BackButton.svg?react";
import BGRationale from "../../assets/BGRationale.svg?react";
import Constraints from "../../assets/Constraints.svg?react";
import ConsultationIcon from "../../assets/ConsultationIcon.svg?react";
import Contract from "../../assets/Contract.svg?react";
import CostCentreIcon from "../../assets/CostCentreIcon.svg?react";
import Dependencies from "../../assets/Dependencies.svg?react";
import DownArrow from "../../assets/DownArrow.svg?react";
import Edit from "../../assets/EditPencil.svg?react";
import Expand from "../../assets/Expand.svg?react";
import FinanceIcon from "../../assets/FinanceIconTable.svg?react";
import Goals from "../../assets/Goals.svg?react";
import ImpactIcon from "../../assets/ImpactIcon.svg?react";
import MilestoneIcon from "../../assets/MilestonesIconTable.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Paper from "../../assets/Paper.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import RiskIcon from "../../assets/RiskIconTable.svg?react";
import Scope from "../../assets/Scope.svg?react";
import Search from "../../assets/Search.svg?react";
import Solutions from "../../assets/Solutions.svg?react";
import StakeholderIcon from "../../assets/StakeholderIcon.svg?react";
import SuccessMeasure from "../../assets/SuccessMeasure.svg?react";
import TaskIcon from "../../assets/TaskIconTable.svg?react";
import WBSIcon from "../../assets/WBSIcon.svg?react";
import WorkforceIcon from "../../assets/WorkforceIcon.svg?react";

import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import Spinner from "../../ui/Spinner";
import ToggleButton from "../../ui/ToggleButton";
import ProfileLink from "../navigation/ProfileLink";
import { useGetMilestoneByProjectId } from "./useGetMilestoneByProjectId";
import { useGetProjectById } from "./useGetProjectById";

import moment from "moment";
import toast from "react-hot-toast";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
  MODAL_TYPE,
  PINNED_ITEM_TYPES,
  PROJECT_DETAILS_API_VALUES,
  ROLES,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useActiveModalContext } from "../../context/ModalContext";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetUserRoles } from "../../hooks/auth/useRoles";
import { useGetProjectExpenses } from "../../hooks/finances/useCost";
import { useGetProjectCostCentres } from "../../hooks/finances/useProjectCostCentres";
import { useGetProjectWBS } from "../../hooks/finances/useProjectWBS";
import { useGetProjectDetails } from "../../hooks/projects/useGetProjectDetails";
import { useGetProjectConsultations } from "../../hooks/projects/useProjectConsultation";
import { useGetProjectFinances } from "../../hooks/projects/useProjectFinance";
import { useGetProjectImpacts } from "../../hooks/projects/useProjectImpacts";
import { useGetProjectRisks } from "../../hooks/projects/useProjectRisks";
import { useGetProjectScope } from "../../hooks/projects/useProjectScope";
import { useGetProjectStakeholders } from "../../hooks/projects/useProjectStakeholders";
import { useGetProjectSuccess } from "../../hooks/projects/useProjectSuccess";
import { useGetProjectWorkforces } from "../../hooks/projects/useProjectWorkforce";
import { useGetDropdownValues } from "../../hooks/useDropdownValues";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { SectionTitle, StyledTableSection } from "../../ui/FormElements";
import {
  CountSpan,
  ProjectHeader,
  SideContainer,
} from "../../ui/HeaderComponents";
import Modal from "../../ui/Modal";
import { RAGIndicator } from "../../ui/RAGIndicator";
import { getRAGStatus } from "../../utils/common";
import ConsultationForm from "../forms/ConsultationForm";
import DetailsForm from "../forms/DetailsForm";
import FinanceForm from "../forms/FinanceForm";
import ImpactForm from "../forms/ImpactForm";
import ProjectCostCentreForm from "../forms/ProjectCostCentreForm";
import ProjectExpenseForm from "../forms/ProjectExpenseForm";
import ProjectWBSForm from "../forms/ProjectWBSForm";
import RiskForm from "../forms/RiskForm";
import ScopeForm from "../forms/ScopeForm";
import StakeholderForm from "../forms/StakeholderForm";
import SuccessMeasureForm from "../forms/SuccessMeasureForm";
import WorkforceForm from "../forms/WorkforceForm";
import MilestoneForm from "../milestones/MilestoneForm";
import TaskForm from "../tasks/TasksForm";
import CreateEditProject from "./CreateProject";
import {
  ConsultationTableRenderer,
  DetailsTableRenderer,
  FinanceTableRenderer,
  ImpactTableRenderer,
  MilestoneTableRenderer,
  ProjectCostCentreTableRenderer,
  ProjectExpenseTableRenderer,
  ProjectWBSTableRenderer,
  RiskTableRenderer,
  ScopeTableRenderer,
  StakeholderTableRenderer,
  SuccessTableRenderer,
  TaskTableRenderer,
  WorkforceTableRenderer,
} from "./ProjectInfoTables";

const ProjectInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 2rem 0;
  align-items: end;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const Heading = styled.div`
  display: flex;
  gap: 2rem;
  & span {
    font-size: 1.4rem;
    font-weight: 400;
  }
  & h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const SummaryLayout = styled.div<{ $isSummaryExpanded: boolean }>`
  background-color: white;
  border-radius: var(--border-radius-xl);
  height: ${(props) => (props.$isSummaryExpanded ? "26rem" : "12rem")};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const ExpandContractContainer = styled.div<{ $isSummaryExpanded: boolean }>`
  justify-self: end;
  align-self: flex-end;
  cursor: pointer;
  & svg:hover {
    transform: ${(props) =>
      props.$isSummaryExpanded ? "scale(0.9)" : "scale(1.1)"};
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
  min-width: 9.6rem;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RAGDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const StyledRefNo = styled.p`
  font-size: 1.2rem;
  color: var(--color-ref-no);
`;

const StyledProjectTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 3px dashed #ebeff6;
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const ToggleDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Toggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
  width: fit-content;
  margin-bottom: 2rem;
`;

const AddItemContainer = styled.div`
  padding-bottom: 1.5rem;
`;

const SideContainerLocal = styled(SideContainer)`
  gap: 1rem;
`;

const TOGGLE_MAP = {
  INFO: "Information",
  DETAILS: "Details",
  RISKS: "Consultation/Risks",
  RESOURCES: "Resources",
  MILESTONES: "Milestones/Tasks",
  CLOSURE: "Closure",
};

const INITIAL_EXPANSION_STATE = {
  RATIONALE: false,
  GOALS: false,
  SOLUTIONS: false,
  ASSUMPTIONS: false,
  DEPENDENCIES: false,
  CONSTRAINTS: false,
  SCOPE: false,
  SUCCESS: false,
  STAKEHOLDERS: false,
  CONSULTATION: false,
  RISKS: false,
  IMPACTS: false,
  FINANCE: false,
  WORKFORCE: false,
  MILESTONES: false,
  TASKS: false,
  COST_CENTRE: false,
  WBS: false,
  EXPENDITURE: false,
};

/**
 * @returns individual project summary by project ID
 */
const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setCurrentModalName } = useActiveModalContext();

  const activeToggle = searchParams.get("activeToggle") || TOGGLE_MAP.DETAILS;

  // pass in url to expand all sections of selected toggle when redirected
  const expand = searchParams.get("expand") || "";

  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const [tableExpansions, setTableExpansions] = useState(
    INITIAL_EXPANSION_STATE,
  );

  const [expandAll, setExpandAll] = useState(false);

  // expand all sections of selected toggle when redirected
  useEffect(() => {
    if (expand) {
      setTableExpansions((prev) => ({
        ...prev,
        MILESTONES: expand === "milestones",
        TASKS: expand === "tasks",
      }));
    }
  }, [expand]);

  const handleSearchParams = (value: string) => {
    searchParams.set("activeToggle", value);
    searchParams.delete("expand");
    setSearchParams(searchParams);
    setExpandAll(false);
    setTableExpansions(INITIAL_EXPANSION_STATE);
  };

  const handleExpandAll = () => {
    setExpandAll((prev) => !prev);
    if (activeToggle === TOGGLE_MAP.RESOURCES) {
      setTableExpansions({
        ...tableExpansions,
        FINANCE: !expandAll,
        WORKFORCE: !expandAll,
        COST_CENTRE: !expandAll,
        EXPENDITURE: !expandAll,
        WBS: !expandAll,
      });
    } else if (activeToggle === TOGGLE_MAP.DETAILS) {
      setTableExpansions({
        ...tableExpansions,
        RATIONALE: !expandAll,
        GOALS: !expandAll,
        SOLUTIONS: !expandAll,
        ASSUMPTIONS: !expandAll,
        DEPENDENCIES: !expandAll,
        CONSTRAINTS: !expandAll,
        SCOPE: !expandAll,
        SUCCESS: !expandAll,
      });
    } else if (activeToggle === TOGGLE_MAP.RISKS) {
      setTableExpansions({
        ...tableExpansions,
        STAKEHOLDERS: !expandAll,
        CONSULTATION: !expandAll,
        RISKS: !expandAll,
        IMPACTS: !expandAll,
      });
    } else if (activeToggle === TOGGLE_MAP.MILESTONES) {
      setTableExpansions({
        ...tableExpansions,
        MILESTONES: !expandAll,
        TASKS: !expandAll,
      });
    }
  };

  // API data
  const { isLoading: isSavingPin, pinItem } = useSavePinItem();
  const { isLoading: isRemovingPin, removePinItem } = useRemovePinItem();
  const { user } = useGetCurrentUser();
  const { isUserRoleLoading, userRoles } = useGetUserRoles(user?.id || "");

  const userRole =
    userRoles?.find((role) => {
      if (role.project_id) return role.project_id === projectId;
      return role;
    })?.role?.title || "";

  const canUserAdd = userRole !== ROLES.VIEWER;

  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || "",
  );
  const pinnedProjectIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.PROJECT)
        return { value: item.value, id: item.id };
    }) || [];

  const { isLoading: isProjectLoading, project } = useGetProjectById(
    projectId || "1",
    pinnedProjectIds,
  );
  const {
    isLoading: isMilestoneLoading,
    milestones,
    error,
  } = useGetMilestoneByProjectId(projectId || "1");

  const {
    isDetailLoading: isRationaleLoading,
    projectDetails: rationaleDetails,
    error: rationaleError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.BACKGROUND,
  );

  const {
    isDetailLoading: isGoalsLoading,
    projectDetails: goalsDetails,
    error: goalsError,
  } = useGetProjectDetails(projectId || "0", PROJECT_DETAILS_API_VALUES.GOALS);

  const {
    isDetailLoading: isSolutionsLoading,
    projectDetails: solutionsDetails,
    error: solutionsError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.SOLUTION,
  );

  const {
    isDetailLoading: isAssumptionsLoading,
    projectDetails: assumptionsDetails,
    error: assumptionsError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.ASSUMPTION,
  );

  const {
    isDetailLoading: isDependenciesLoading,
    projectDetails: dependenciesDetails,
    error: dependenciesError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.DEPENDENCY,
  );

  const {
    isDetailLoading: isConstraintsLoading,
    projectDetails: constraintsDetails,
    error: constraintsError,
  } = useGetProjectDetails(
    projectId || "0",
    PROJECT_DETAILS_API_VALUES.CONSTRAINT,
  );

  const {
    isScopeLoading,
    scopeDetails,
    error: scopeError,
  } = useGetProjectScope(projectId || "0");

  const {
    isSuccessLoading,
    successDetails,
    error: successMeasureError,
  } = useGetProjectSuccess(projectId || "0");

  const {
    isStakeholderLoading,
    stakeholderDetails,
    error: stakeholderError,
  } = useGetProjectStakeholders(projectId || "0");

  const {
    isConsultationLoading,
    consultationDetails,
    error: consultationError,
  } = useGetProjectConsultations(projectId || "0");

  const {
    isRisksLoading,
    riskDetails,
    error: riskError,
  } = useGetProjectRisks(projectId || "0");

  const {
    isImpactLoading,
    impactDetails,
    error: impactError,
  } = useGetProjectImpacts(projectId || "0");

  const {
    isWorkforceLoading,
    workforceDetails,
    error: workforceError,
  } = useGetProjectWorkforces(projectId || "0");

  const {
    isFinanceLoading,
    financeDetails,
    error: financeError,
  } = useGetProjectFinances(projectId || "0");

  const {
    isLoading: isCostCentreLoading,
    projectCostCentres,
    error: costCentreError,
  } = useGetProjectCostCentres(+(projectId || 0));

  const {
    isLoading: isProjectWBSLoading,
    projectWBS,
    error: projectWBSError,
  } = useGetProjectWBS(+(projectId || 0));

  const {
    isLoading: isProjectExpenseLoading,
    projectExpenses,
    error: projectExpenseError,
  } = useGetProjectExpenses(+(projectId || 0));

  // pre-fetch dropdown values
  useGetDropdownValues();
  useGetContacts();
  useGetServiceValues();

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLSpanElement>,
    pinId?: number,
  ) => {
    event?.stopPropagation();
    handlePinningFromContext(type, value, savePin, pinId);
  };

  const handlePinningFromContext = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number,
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        },
      );
    } else if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        },
      );
    }
  };

  const disablePin = isSavingPin || isRemovingPin;

  if (
    getIsLoading(
      isProjectLoading,
      isMilestoneLoading,
      isPinnedItemsLoading,
      isRationaleLoading,
      isGoalsLoading,
      isSolutionsLoading,
      isAssumptionsLoading,
      isDependenciesLoading,
      isConstraintsLoading,
      isScopeLoading,
      isSuccessLoading,
      isStakeholderLoading,
      isConsultationLoading,
      isRisksLoading,
      isImpactLoading,
      isWorkforceLoading,
      isFinanceLoading,
      isUserRoleLoading,
      isCostCentreLoading,
      isProjectWBSLoading,
      isProjectExpenseLoading,
    )
  ) {
    return <Spinner />;
  }

  return (
    <>
      <ProjectHeader>
        <Heading>
          <Icons onClick={() => navigate(-1)}>
            <BackButton />
          </Icons>
          <div>
            <h1>
              Projects <span>| Details </span>
            </h1>
            <h2>{project?.title}</h2>
          </div>
        </Heading>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            $buttonSize={BUTTON_SIZES.MEDIUM_LARGE}
            onClick={() => navigate(`/projects/overview/${projectId}`)}
          >
            Project Overview
            <ArrowUpRight />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <ProjectInfoHeader>
        <h1>Project Information</h1>
        <SideContainer>
          {project?.isPinned ? (
            <Icons
              onClick={(event) =>
                handlePinning(
                  PINNED_ITEM_TYPES.PROJECT,
                  `${project?.id}`,
                  false,
                  event,
                  project?.pinId,
                )
              }
              disabled={disablePin}
            >
              <PinFilled />
            </Icons>
          ) : (
            <Icons
              onClick={(event) =>
                handlePinning(
                  PINNED_ITEM_TYPES.PROJECT,
                  `${project?.id}`,
                  true,
                  event,
                )
              }
              disabled={disablePin}
            >
              <PushPin />
            </Icons>
          )}
          {canUserAdd && (
            <Icons
              onClick={() => {
                setCurrentModalName(MODAL_NAMES.PROJECT);
                setIsProjectDetailsOpen(true);
              }}
            >
              <Edit />{" "}
            </Icons>
          )}
          <Icons>
            <Paper />{" "}
          </Icons>
        </SideContainer>
        <Modal
          isOpen={isProjectDetailsOpen}
          wrapperId={MODAL_NAMES.PROJECT}
          title={MODAL_NAMES.PROJECT}
          type={MODAL_TYPE.EDIT}
          modalWidth="60%"
        >
          <CreateEditProject
            handleClose={() => {
              setCurrentModalName("");
              setIsProjectDetailsOpen(false);
            }}
            projectId={projectId}
            projectDetails={project}
            isEditingMode
          />
        </Modal>
      </ProjectInfoHeader>
      {/* project basic information */}
      <SummaryLayout $isSummaryExpanded={summaryExpanded}>
        <TitleSection>
          <RAGDiv>
            <RAGIndicator
              $color={getRAGStatus(
                project?.est_start_year || "",
                project?.est_end_year || "",
                project?.start_year || "",
                project?.end_year || "",
                project?.status?.title || "",
              )}
              $height="6rem"
            />
            <VerticalDiv>
              <StyledRefNo>{project?.id}</StyledRefNo>
              <StyledProjectTitle> {project?.title} </StyledProjectTitle>
              <p>{project?.project_definition}</p>
            </VerticalDiv>
          </RAGDiv>
          <SideContainer style={{ alignSelf: "start" }}>
            <Pill
              $progressStatus={
                project?.status?.title as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{project?.status?.title}</span>
            </Pill>
            <ExpandContractContainer
              $isSummaryExpanded={summaryExpanded}
              onClick={() => setSummaryExpanded((prev) => !prev)}
            >
              {summaryExpanded ? <Contract /> : <Expand />}
            </ExpandContractContainer>
          </SideContainer>
        </TitleSection>
        {summaryExpanded && (
          <>
            <ProjectInfoGrid>
              <ProjectGridItem>
                <h2>Start Date</h2>
                <h1>
                  {project?.start_year
                    ? moment(project?.start_year).format("DD/MM/YYYY")
                    : "N/A"}
                </h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>End Date</h2>
                <h1>
                  {project?.end_year
                    ? moment(project?.end_year).format("DD/MM/YYYY")
                    : "N/A"}
                </h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Percentage Complete</h2>
                <h1>{project?.percentage_complete?.toFixed(2) || 0}</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Project Owner</h2>
                <h1>{`${project?.owner.first_name} ${project?.owner.last_name}`}</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Project Manager</h2>
                <h1>{`${project?.responsible.first_name} ${project?.responsible.last_name}`}</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Program</h2>
                <h1>Program</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Type</h2>
                <h1>{project?.project_type.title}</h1>
              </ProjectGridItem>
              <ProjectGridItem>
                <h2>Location</h2>
                <h1>{project?.location}</h1>
              </ProjectGridItem>
            </ProjectInfoGrid>
          </>
        )}
      </SummaryLayout>
      <ToggleDiv>
        <Toggles>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.DETAILS}
            onClick={() => handleSearchParams(TOGGLE_MAP.DETAILS)}
          >
            {TOGGLE_MAP.DETAILS}
          </ToggleButton>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.RESOURCES}
            onClick={() => handleSearchParams(TOGGLE_MAP.RESOURCES)}
          >
            {TOGGLE_MAP.RESOURCES}
          </ToggleButton>{" "}
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.RISKS}
            onClick={() => handleSearchParams(TOGGLE_MAP.RISKS)}
            $buttonSize="xl"
          >
            {TOGGLE_MAP.RISKS}
          </ToggleButton>{" "}
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.MILESTONES}
            onClick={() => handleSearchParams(TOGGLE_MAP.MILESTONES)}
            $buttonSize="xl"
          >
            {TOGGLE_MAP.MILESTONES}
          </ToggleButton>
          <ToggleButton
            $buttonActive={activeToggle === TOGGLE_MAP.CLOSURE}
            onClick={() => handleSearchParams(TOGGLE_MAP.CLOSURE)}
          >
            {TOGGLE_MAP.CLOSURE}
          </ToggleButton>
        </Toggles>
        <Icons onClick={handleExpandAll}>
          {expandAll ? <Contract /> : <Expand />}
        </Icons>
      </ToggleDiv>
      {/* project details */}
      {activeToggle === TOGGLE_MAP.DETAILS && (
        <>
          {/* bg/rationale */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.RATIONALE}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  RATIONALE: !prev.RATIONALE,
                }))
              }
            >
              <SideContainerLocal>
                <BGRationale />
                <h1>
                  {MODAL_NAMES.RATIONALE}{" "}
                  <CountSpan>[{rationaleDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.BACKGROUND}
              detailName={MODAL_NAMES.RATIONALE}
              projectDetails={rationaleDetails}
              error={rationaleError}
              isActive={tableExpansions.RATIONALE}
              userRole={userRole}
            />
            {tableExpansions.RATIONALE && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.RATIONALE);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.RATIONALE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.RATIONALE}
              modalWidth="40%"
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.BACKGROUND}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  rationaleDetails ? rationaleDetails.slice(-1)[0]?.sequence : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          {/* goals */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.GOALS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  GOALS: !prev.GOALS,
                }))
              }
            >
              <SideContainerLocal>
                <Goals />
                <h1>
                  {MODAL_NAMES.OBJECTIVES}{" "}
                  <CountSpan>[{goalsDetails?.length || 0}]</CountSpan>{" "}
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.GOALS}
              detailName={MODAL_NAMES.GOALS}
              projectDetails={goalsDetails}
              error={goalsError}
              isActive={tableExpansions.GOALS}
              userRole={userRole}
            />
            {tableExpansions.GOALS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.OBJECTIVES);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.OBJECTIVES}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.OBJECTIVES}
              modalWidth="40%"
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.GOALS}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  goalsDetails ? goalsDetails.slice(-1)[0]?.sequence : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          {/* scopes */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.SCOPE}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  SCOPE: !prev.SCOPE,
                }))
              }
            >
              <SideContainerLocal>
                <Scope />
                <h1>
                  {MODAL_NAMES.SCOPE}{" "}
                  <CountSpan>[{scopeDetails?.length || 0}]</CountSpan>{" "}
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <ScopeTableRenderer
              projectId={projectId || "0"}
              scopeDetails={scopeDetails}
              error={scopeError}
              isActive={tableExpansions.SCOPE}
              userRole={userRole}
            />
            {tableExpansions.SCOPE && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.SCOPE);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.SCOPE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.SCOPE}
              modalWidth="40%"
            >
              <ScopeForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                detailLength={
                  scopeDetails ? scopeDetails.slice(-1)[0]?.sequence : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          {/* solutions */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.SOLUTIONS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  SOLUTIONS: !prev.SOLUTIONS,
                }))
              }
            >
              <SideContainerLocal>
                <Solutions />
                <h1>
                  {MODAL_NAMES.SOLUTIONS}{" "}
                  <CountSpan>[{solutionsDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.SOLUTION}
              detailName={MODAL_NAMES.SOLUTIONS}
              projectDetails={solutionsDetails}
              error={solutionsError}
              isActive={tableExpansions.SOLUTIONS}
              userRole={userRole}
            />
            {tableExpansions.SOLUTIONS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.SOLUTIONS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.SOLUTIONS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.SOLUTIONS}
              modalWidth="40%"
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.SOLUTION}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  solutionsDetails ? solutionsDetails.slice(-1)[0]?.sequence : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          {/* assumptions */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.ASSUMPTIONS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  ASSUMPTIONS: !prev.ASSUMPTIONS,
                }))
              }
            >
              <SideContainerLocal>
                <Assumptions />
                <h1>
                  {MODAL_NAMES.ASSUMPTIONS}{" "}
                  <CountSpan>[{assumptionsDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.ASSUMPTION}
              detailName={MODAL_NAMES.ASSUMPTIONS}
              projectDetails={assumptionsDetails}
              error={assumptionsError}
              isActive={tableExpansions.ASSUMPTIONS}
              userRole={userRole}
            />
            {tableExpansions.ASSUMPTIONS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.ASSUMPTIONS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.ASSUMPTIONS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.ASSUMPTIONS}
              modalWidth="40%"
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.ASSUMPTION}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  assumptionsDetails
                    ? assumptionsDetails.slice(-1)[0]?.sequence
                    : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>{" "}
          {/* dependencies */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.DEPENDENCIES}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  DEPENDENCIES: !prev.DEPENDENCIES,
                }))
              }
            >
              <SideContainerLocal>
                <Dependencies />
                <h1>
                  {MODAL_NAMES.DEPENDENCIES}{" "}
                  <CountSpan>[{dependenciesDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.DEPENDENCY}
              detailName={MODAL_NAMES.DEPENDENCIES}
              projectDetails={dependenciesDetails}
              error={dependenciesError}
              isActive={tableExpansions.DEPENDENCIES}
              userRole={userRole}
            />
            {tableExpansions.DEPENDENCIES && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.DEPENDENCIES);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.DEPENDENCIES}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.DEPENDENCIES}
              modalWidth="40%"
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.DEPENDENCY}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  dependenciesDetails
                    ? dependenciesDetails.slice(-1)[0]?.sequence
                    : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>{" "}
          {/* constraints */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.CONSTRAINTS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  CONSTRAINTS: !prev.CONSTRAINTS,
                }))
              }
            >
              <SideContainerLocal>
                <Constraints />
                <h1>
                  {MODAL_NAMES.CONSTRAINTS}{" "}
                  <CountSpan>[{constraintsDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <DetailsTableRenderer
              projectId={projectId || "0"}
              type={PROJECT_DETAILS_API_VALUES.CONSTRAINT}
              detailName={MODAL_NAMES.CONSTRAINTS}
              projectDetails={constraintsDetails}
              error={constraintsError}
              isActive={tableExpansions.CONSTRAINTS}
              userRole={userRole}
            />
            {tableExpansions.CONSTRAINTS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.CONSTRAINTS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.CONSTRAINTS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.CONSTRAINTS}
              modalWidth="40%"
            >
              <DetailsForm
                projectId={projectId || "0"}
                type={PROJECT_DETAILS_API_VALUES.CONSTRAINT}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditing={false}
                detailLength={
                  constraintsDetails
                    ? constraintsDetails.slice(-1)[0]?.sequence
                    : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          {/* measure success */}
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.SUCCESS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  SUCCESS: !prev.SUCCESS,
                }))
              }
            >
              <SideContainerLocal>
                <SuccessMeasure />
                <h1>
                  {MODAL_NAMES.SUCCESS}{" "}
                  <CountSpan>[{successDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <SuccessTableRenderer
              projectId={projectId || "0"}
              successDetails={successDetails}
              error={successMeasureError}
              isActive={tableExpansions.SUCCESS}
              userRole={userRole}
            />
            {tableExpansions.SUCCESS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.SUCCESS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.SUCCESS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.SUCCESS}
              modalWidth="40%"
            >
              <SuccessMeasureForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                detailLength={
                  successDetails ? successDetails.slice(-1)[0]?.sequence : 0
                }
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
        </>
      )}
      {/* resources and finances */}
      {activeToggle === TOGGLE_MAP.RESOURCES && (
        <>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.FINANCE}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  FINANCE: !prev.FINANCE,
                }))
              }
            >
              <SideContainerLocal>
                <FinanceIcon height={"2.4rem"} width={"2.4rem"} />
                <h1>
                  {MODAL_NAMES.FINANCE}{" "}
                  <CountSpan>[{financeDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <FinanceTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.FINANCE}
              financeDetails={financeDetails}
              error={financeError}
              userRole={userRole}
            />
            {tableExpansions.FINANCE && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.FINANCE);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.FINANCE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.FINANCE}
            >
              <FinanceForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  EXPENDITURE: !prev.EXPENDITURE,
                }))
              }
              $isActive={tableExpansions.EXPENDITURE}
            >
              <SideContainerLocal>
                <CostCentreIcon />
                <h1>
                  {MODAL_NAMES.EXPENDITURE}{" "}
                  <CountSpan>[{projectExpenses?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <ProjectExpenseTableRenderer
              projectId={projectId || "0"}
              costCentres={projectCostCentres}
              wbs={projectWBS}
              expenses={projectExpenses}
              error={projectExpenseError}
              userRole={userRole}
              isActive={tableExpansions.EXPENDITURE}
            />
            {tableExpansions.EXPENDITURE && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.EXPENSE);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.EXPENSE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.EXPENSE}
              modalWidth="50%"
            >
              <ProjectExpenseForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.WORKFORCE}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  WORKFORCE: !prev.WORKFORCE,
                }))
              }
            >
              <SideContainerLocal>
                <WorkforceIcon />
                <h1>
                  {MODAL_NAMES.WORKFORCE}{" "}
                  <CountSpan>[{workforceDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <WorkforceTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.WORKFORCE}
              workforceDetails={workforceDetails}
              error={workforceError}
              userRole={userRole}
            />
            {tableExpansions.WORKFORCE && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.WORKFORCE);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.WORKFORCE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.WORKFORCE}
              modalWidth="30%"
            >
              <WorkforceForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  COST_CENTRE: !prev.COST_CENTRE,
                }))
              }
              $isActive={tableExpansions.COST_CENTRE}
            >
              <SideContainerLocal>
                <CostCentreIcon />
                <h1>
                  {MODAL_NAMES.COST_CENTRE}{" "}
                  <CountSpan>[{projectCostCentres?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <ProjectCostCentreTableRenderer
              projectId={projectId || "0"}
              costCentres={projectCostCentres}
              error={costCentreError}
              userRole={userRole}
              isActive={tableExpansions.COST_CENTRE}
            />
            {tableExpansions.COST_CENTRE && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.COST_CENTRE);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.COST_CENTRE}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.COST_CENTRE}
              modalWidth="50%"
            >
              <ProjectCostCentreForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.WBS}
              onClick={() =>
                setTableExpansions((prev) => ({ ...prev, WBS: !prev.WBS }))
              }
            >
              <SideContainerLocal>
                <WBSIcon />
                <h1>
                  {MODAL_NAMES.WBS}{" "}
                  <CountSpan>[{projectWBS?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <ProjectWBSTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.WBS}
              wbs={projectWBS}
              error={projectWBSError}
              costCentres={projectCostCentres}
              userRole={userRole}
            />
            {tableExpansions.WBS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.WBS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.WBS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.WBS}
              modalWidth="40%"
            >
              <ProjectWBSForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
              />
            </Modal>
          </StyledTableSection>
        </>
      )}
      {/* consultations and risks */}
      {activeToggle === TOGGLE_MAP.RISKS && (
        <>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.STAKEHOLDERS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  STAKEHOLDERS: !prev.STAKEHOLDERS,
                }))
              }
            >
              <SideContainerLocal>
                <StakeholderIcon />
                <h1>
                  {MODAL_NAMES.STAKEHOLDERS}{" "}
                  <CountSpan>[{stakeholderDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <StakeholderTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.STAKEHOLDERS}
              stakeholderDetails={stakeholderDetails}
              error={stakeholderError}
              userRole={userRole}
            />
            {tableExpansions.STAKEHOLDERS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.STAKEHOLDERS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.STAKEHOLDERS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.STAKEHOLDERS}
              modalWidth="30%"
            >
              <StakeholderForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.CONSULTATION}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  CONSULTATION: !prev.CONSULTATION,
                }))
              }
            >
              <SideContainerLocal>
                <ConsultationIcon />
                <h1>
                  {MODAL_NAMES.CONSULTATION}{" "}
                  <CountSpan>[{consultationDetails?.length || 0}] </CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <ConsultationTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.CONSULTATION}
              consultationDetails={consultationDetails}
              error={consultationError}
              userRole={userRole}
            />
            {tableExpansions.CONSULTATION && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.CONSULTATION);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.CONSULTATION}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.CONSULTATION}
              modalWidth="30%"
            >
              <ConsultationForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.RISKS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  RISKS: !prev.RISKS,
                }))
              }
            >
              <SideContainerLocal>
                <RiskIcon />
                <h1>
                  {MODAL_NAMES.RISKS}{" "}
                  <CountSpan>[{riskDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <RiskTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.RISKS}
              riskDetails={riskDetails}
              error={riskError}
              userRole={userRole}
            />
            {tableExpansions.RISKS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.RISKS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.RISKS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.RISKS}
              modalWidth="30%"
            >
              <RiskForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.IMPACTS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  IMPACTS: !prev.IMPACTS,
                }))
              }
            >
              <SideContainerLocal>
                <ImpactIcon />
                <h1>
                  {MODAL_NAMES.IMPACTS}{" "}
                  <CountSpan>[{impactDetails?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <ImpactTableRenderer
              projectId={projectId || "0"}
              isActive={tableExpansions.IMPACTS}
              impactDetails={impactDetails}
              error={impactError}
              userRole={userRole}
            />
            {tableExpansions.IMPACTS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.IMPACTS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.IMPACTS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.IMPACTS}
              modalWidth="30%"
            >
              <ImpactForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
        </>
      )}

      {activeToggle === TOGGLE_MAP.MILESTONES && (
        <>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.MILESTONES}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  MILESTONES: !prev.MILESTONES,
                }))
              }
            >
              <SideContainerLocal>
                <MilestoneIcon />
                <h1>
                  {MODAL_NAMES.MILESTONES}{" "}
                  <CountSpan>[{milestones?.length || 0}]</CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <MilestoneTableRenderer
              projectId={projectId || "0"}
              milestones={milestones}
              error={error}
              isActive={tableExpansions.MILESTONES}
              userRole={userRole}
            />
            {tableExpansions.MILESTONES && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.MILESTONES);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.MILESTONES}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.MILESTONES}
            >
              <MilestoneForm
                projectId={projectId || "0"}
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
          <StyledTableSection>
            <SectionTitle
              $isActive={tableExpansions.TASKS}
              onClick={() =>
                setTableExpansions((prev) => ({
                  ...prev,
                  TASKS: !prev.TASKS,
                }))
              }
            >
              <SideContainerLocal>
                <TaskIcon />
                <h1>
                  {MODAL_NAMES.TASKS}{" "}
                  <CountSpan>
                    [
                    {milestones
                      ?.map((milestone) => milestone.tasks)
                      ?.flat()
                      ?.filter((task) => !task.is_deleted)?.length || 0}
                    ]
                  </CountSpan>
                </h1>
              </SideContainerLocal>
              <DownArrow />
            </SectionTitle>
            <TaskTableRenderer
              milestones={milestones}
              isActive={tableExpansions.TASKS}
              userRole={userRole}
            />
            {tableExpansions.TASKS && canUserAdd && (
              <AddItemContainer>
                <ButtonCTA
                  onClick={() => {
                    setCurrentModalName(MODAL_NAMES.TASKS);
                    setIsProjectDetailsOpen(true);
                  }}
                  $buttonSize={BUTTON_SIZES.SMALL}
                >
                  + Add
                </ButtonCTA>
              </AddItemContainer>
            )}
            <Modal
              isOpen={isProjectDetailsOpen}
              title={MODAL_NAMES.TASKS}
              type={MODAL_TYPE.NEW}
              wrapperId={MODAL_NAMES.TASKS}
            >
              <TaskForm
                handleClose={() => {
                  setCurrentModalName("");
                  setIsProjectDetailsOpen(false);
                }}
                isEditingMode={false}
                milestones={milestones}
                canUserEdit={canUserAdd}
              />
            </Modal>
          </StyledTableSection>
        </>
      )}
    </>
  );
};

export default ProjectDetails;

const getIsLoading = (...loadingStates: boolean[]): boolean =>
  loadingStates.some((state) => state);
