import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import { getProjectDetailsAPI } from "../../services/apiProjects";

export const useGetProjectDetails = (id: string, type: string) => {
  const {
    isPending: isDetailLoading,
    data: projectDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectDetailsAPI(+id, type),
    queryKey: [QUERY_KEYS.PROJECT_DETAILS, type, id],
    select: (data) => data.sort((a, b) => a.sequence - b.sequence),
    retry: false,
    enabled: !!id,
  });

  return { isDetailLoading, projectDetails, error };
};
