import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ArrowLeft2 from "../../assets/ArrowLeft2.svg?react";
import ArrowRightWhite from "../../assets/ArrowRightWhite.svg?react";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Location from "../../assets/LocationBlack.svg?react";

import { DayPicker } from "react-day-picker";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  MODAL_NAMES,
} from "../../common/Constants";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetContacts } from "../../hooks/useGetContacts";
import {
  // useGetDropdownValues,
  useGetProjectTypeDropdownValues,
} from "../../hooks/useDropdownValues";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useGetStatusValues } from "../../hooks/useGetStatusValues";
import { ProjectFormValues } from "../../types/formFields";
import { Project } from "../../types/projectTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  SecondaryPositionSvg,
  SectionPart,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
  StyledTwoFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getItemDropdownValues,
} from "../../utils/GetDropdownValue";
import { useCreateProject, useEditProject } from "./useProjects";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";

const H1 = styled.h1`
  color: var(--color-section-headers);
`;

const NextDiv = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: auto;
`;

const PageNumber = styled.p`
  font-weight: 700;
  color: var(--color-section-headers);
  font-size: 1.4rem;
`;

const HeadingPageDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Component for creating or editing a project.
 *
 * @param {Object} props - The component props.
 * @param {Project} [props.projectDetails] - The details of the project to edit.
 * @param {string} [props.projectId] - The ID of the project to edit.
 * @param {() => void} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag to indicate if the form is in editing mode.
 * @returns {JSX.Element} The CreateEditProject component.
 */
const CreateEditProject = ({
  projectDetails,
  projectId,
  handleClose,
  isEditingMode,
}: {
  projectDetails?: Project;
  projectId?: string;
  handleClose: () => void;
  isEditingMode: boolean;
}) => {
  const navigate = useNavigate();
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const [isCommentFieldExpanded, setIsCommentFieldExpanded] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const { setDayModalName } = useDayModalContext();

  // api data
  const { isProjectTypeLoading, projectTypeDropdown } =
    useGetProjectTypeDropdownValues();
  const { isContactsLoading, contacts } = useGetContacts();
  const { isStatusLoading, statuses } = useGetStatusValues();
  const { isServiceLoading, services } = useGetServiceValues();
  // form handler
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
    setFocus,
    getValues,
    resetField,
  } = useForm<ProjectFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      title: projectDetails?.title || "",
      project_type_id: projectDetails?.project_type_id || 0,
      location: projectDetails?.location || "",
      project_definition: projectDetails?.project_definition || "",
      program_id: projectDetails?.businesscase_id || 0,
      service_id: projectDetails?.service_id || 0,
      owner_id: projectDetails?.owner_id || 0,
      responsible_person_id: projectDetails?.responsible_person_id || 0,
      est_start_year: projectDetails?.est_start_year || "",
      est_end_year: projectDetails?.est_end_year || "",
      start_year: projectDetails?.start_year || "",
      end_year: projectDetails?.end_year || "",
      status_id: projectDetails?.status_id || 1,
      progress_status: projectDetails?.status.title || "",
      comments: projectDetails?.comments || "",
    },
  });

  useEffect(() => {
    setFocus("title");
  }, [setFocus]);

  const { isCreating, createProject } = useCreateProject();
  const { isEditing, editProject } = useEditProject();

  const onSubmit: SubmitHandler<ProjectFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProject(
        {
          projectDetails: {
            ...data,
            is_CEO_KFA: true,
            is_director_KFA: true,
            is_incorporate_businessplan: true,
            parent_id: null,
            path: "",
            businesscase_id: null,
            theme_id: null,
            directorate_id: null,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
          },
        },
        {
          onSuccess: (data) => {
            toast.success(`Project saved successfully.`);
            navigate(`/projects/details/${data.id}`);
            handleClose();
          },
        },
      );
    } else {
      editProject(
        {
          projectDetails: {
            ...data,
            is_CEO_KFA: true,
            is_director_KFA: true,
            is_incorporate_businessplan: true,
            parent_id: null,
            path: "",
            businesscase_id: null,
            theme_id: null,
            directorate_id: null,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
          },
          projectId: projectId ? +projectId : 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof ProjectFormValues, id, { shouldDirty: true });
  };

  if (
    isContactsLoading ||
    isStatusLoading ||
    isServiceLoading ||
    isProjectTypeLoading ||
    isCreating ||
    isEditing
  )
    return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {currentPage === 1 && (
            <>
              <HeadingPageDiv>
                <H1>Details</H1>
                {<PageNumber> [{currentPage + "/2"}] </PageNumber>}
              </HeadingPageDiv>
              <StyledTwoFieldSection>
                <LabelAndInput>
                  <label>Title *</label>
                  <StyledInput
                    type="text"
                    {...register("title", {
                      required: "This field is required",
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                    })}
                    autoComplete="off"
                    warning={errors?.title?.message}
                  />
                  {errors?.title && (
                    <ErrorMessage errorMessage={errors.title.message} />
                  )}
                </LabelAndInput>
                <LabelAndInput>
                  <label>Type *</label>
                  <Controller
                    control={control}
                    name="project_type_id"
                    rules={{ required: "This field is required" }}
                    render={({ field: { value } }) => (
                      <Dropdown
                        id="project_type_id"
                        title=""
                        data={getItemDropdownValues(projectTypeDropdown)}
                        onSelect={handleSelect}
                        selectedId={`${value}`}
                        bgColor="var(--color-grey-100)"
                      />
                    )}
                  />

                  {errors?.project_type_id && (
                    <ErrorMessage
                      errorMessage={errors.project_type_id.message}
                    />
                  )}
                </LabelAndInput>{" "}
              </StyledTwoFieldSection>
              <StyledTwoFieldSection>
                <LabelAndInput>
                  <label>Location</label>
                  <div style={{ position: "relative" }}>
                    <StyledInput
                      type="text"
                      style={{ width: "100%" }}
                      {...register("location")}
                      autoComplete="off"
                      warning={errors?.location?.message}
                    />
                    <SecondaryPositionSvg>
                      <Location />
                    </SecondaryPositionSvg>
                  </div>
                </LabelAndInput>
              </StyledTwoFieldSection>
              <StyledSingleFieldSection>
                <LabelAndInput>
                  <label>Definition *</label>
                </LabelAndInput>
                <ExpandableDiv
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                >
                  <StyledFullWidthInput
                    height={isBackgroundFieldExpanded ? "20rem" : ""}
                    {...register("project_definition", {
                      required: "This field is required",
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                      maxLength: {
                        value: 250,
                        message: "Please enter at most 250 characters",
                      },
                    })}
                    autoComplete="off"
                    warning={errors?.project_definition?.message}
                  />
                  <PositionedSvg
                    onClick={() =>
                      setIsBackgroundFieldExpanded((prev) => !prev)
                    }
                  >
                    {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                  </PositionedSvg>
                </ExpandableDiv>
                {errors?.project_definition && (
                  <ErrorMessage
                    errorMessage={errors.project_definition.message}
                  />
                )}
              </StyledSingleFieldSection>
              <StyledTwoFieldSection>
                <LabelAndInput>
                  <label>
                    Program{" "}
                    <span style={{ fontWeight: 400 }}>
                      (Is this part of a program?)
                    </span>{" "}
                  </label>
                  <Controller
                    control={control}
                    name="program_id"
                    render={({ field: { value } }) => (
                      <Dropdown
                        id="program_id"
                        title=""
                        data={[]}
                        onSelect={handleSelect}
                        selectedId={`${value}`}
                        bgColor="var(--color-grey-100)"
                        showOnTop
                      />
                    )}
                  />
                </LabelAndInput>
                <LabelAndInput>
                  <label>Department *</label>
                  <Controller
                    control={control}
                    name="service_id"
                    rules={{ required: "This field is required" }}
                    render={({ field: { value } }) => (
                      <Dropdown
                        id="service_id"
                        title=""
                        data={getItemDropdownValues(services)}
                        onSelect={handleSelect}
                        selectedId={`${value}`}
                        bgColor="var(--color-grey-100)"
                        search
                        showOnTop
                      />
                    )}
                  />
                  {errors?.service_id && (
                    <ErrorMessage errorMessage={errors.service_id.message} />
                  )}
                </LabelAndInput>{" "}
              </StyledTwoFieldSection>
            </>
          )}
          {currentPage === 2 && (
            <>
              <SectionPart>
                <HeadingPageDiv>
                  <H1>Ownership</H1>
                  <PageNumber>[{currentPage + "/2"}] </PageNumber>
                </HeadingPageDiv>
                <StyledTwoFieldSection>
                  <LabelAndInput>
                    <label>Project Owner *</label>
                    <Controller
                      control={control}
                      name="owner_id"
                      rules={{ required: "This field is required" }}
                      render={({ field: { value } }) => (
                        <Dropdown
                          id="owner_id"
                          title=""
                          data={getContactDropdownValues(contacts)}
                          onSelect={handleSelect}
                          selectedId={`${value}`}
                          bgColor="var(--color-grey-100)"
                          search
                        />
                      )}
                    />

                    {errors?.owner_id && (
                      <ErrorMessage errorMessage={errors.owner_id.message} />
                    )}
                  </LabelAndInput>
                  <LabelAndInput>
                    <label>Project Manager</label>
                    <Controller
                      control={control}
                      name="responsible_person_id"
                      render={({ field: { value } }) => (
                        <Dropdown
                          id="responsible_person_id"
                          title=""
                          data={getContactDropdownValues(contacts)}
                          onSelect={handleSelect}
                          selectedId={`${value}`}
                          bgColor="var(--color-grey-100)"
                          search
                        />
                      )}
                    />
                  </LabelAndInput>
                </StyledTwoFieldSection>
              </SectionPart>
              <SectionPart>
                <H1>Duration</H1>
                <StyledTwoFieldSection>
                  <PositionedInput>
                    <label>Estimated Start Date</label>
                    <StyledInput
                      aria-label="Date"
                      type="text"
                      value={
                        getValues("est_start_year")
                          ? moment(getValues("est_start_year")).format(
                              DAY_FORMATS.DAY_FIRST,
                            )
                          : projectDetails?.est_start_year
                            ? moment(projectDetails?.est_start_year).format(
                                DAY_FORMATS.DAY_FIRST,
                              )
                            : ""
                      }
                      warning={errors?.est_start_year?.message}
                      onChange={() => {}}
                    />
                    {errors?.est_start_year && (
                      <ErrorMessage
                        errorMessage={errors.est_start_year.message}
                      />
                    )}
                    {getValues("est_start_year") ||
                    projectDetails?.est_start_year ? (
                      <ClearMessage
                        onClick={() => {
                          resetField("est_start_year", { defaultValue: "" });
                          if (projectDetails) {
                            projectDetails.est_start_year = "";
                          }
                        }}
                      >
                        Clear
                      </ClearMessage>
                    ) : (
                      ""
                    )}
                    <PositionedCalendar>
                      <Calendar
                        onClick={() => {
                          setDayModalName(DATE_MODAL_NAMES.EST_START);
                        }}
                      />
                    </PositionedCalendar>
                    <Controller
                      control={control}
                      name="est_start_year"
                      rules={{
                        // min: {
                        //   value: getMinDate(isEditingMode),
                        //   message: "Please enter a valid start date",
                        // },
                        max: {
                          value: getValues("end_year")
                            ? moment(getValues("end_year")).format(
                                DAY_FORMATS.YEAR_FIRST,
                              )
                            : "",
                          message: "Please enter a valid start date",
                        },
                        deps: ["est_end_year"],
                      }}
                      render={({ field: { onChange, onBlur } }) => (
                        <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_START}>
                          <DayPicker
                            mode="single"
                            selected={new Date(getValues().est_start_year)}
                            onSelect={(e) => {
                              onChange(() =>
                                setValue(
                                  "est_start_year",
                                  moment(e).format(DAY_FORMATS.YEAR_FIRST),
                                ),
                              );
                              setDayModalName("");
                            }}
                            onDayClick={onBlur}
                            captionLayout="dropdown"
                            endMonth={getEndMonth()}
                            defaultMonth={
                              getValues().est_start_year
                                ? new Date(getValues().est_start_year)
                                : new Date()
                            }
                            disabled={{
                              // before: projectDetails?.est_start_year
                              //   ? new Date(projectDetails?.est_start_year)
                              //   : new Date(),
                              after: getValues("est_end_year")
                                ? new Date(getValues("est_end_year"))
                                : getEndMonth(),
                            }}
                          />
                        </DayPickerModal>
                      )}
                    />
                  </PositionedInput>
                  <PositionedInput>
                    <label>Estimated End Date</label>
                    <StyledInput
                      aria-label="Date"
                      type="text"
                      value={
                        getValues("est_end_year")
                          ? moment(getValues("est_end_year")).format(
                              DAY_FORMATS.DAY_FIRST,
                            )
                          : projectDetails?.est_end_year
                            ? moment(projectDetails?.est_end_year).format(
                                DAY_FORMATS.DAY_FIRST,
                              )
                            : ""
                      }
                      warning={errors?.est_end_year?.message}
                      onChange={() => {}}
                    />
                    {errors?.est_end_year && (
                      <ErrorMessage
                        errorMessage={errors.est_end_year.message}
                      />
                    )}
                    {getValues("est_end_year") ||
                    projectDetails?.est_end_year ? (
                      <ClearMessage
                        onClick={() => {
                          resetField("est_end_year", { defaultValue: "" });
                          if (projectDetails) {
                            projectDetails.est_end_year = "";
                          }
                        }}
                      >
                        Clear
                      </ClearMessage>
                    ) : (
                      ""
                    )}
                    <PositionedCalendar>
                      <Calendar
                        onClick={() => {
                          setDayModalName(DATE_MODAL_NAMES.EST_END);
                        }}
                      />
                    </PositionedCalendar>
                    <Controller
                      control={control}
                      name="est_end_year"
                      rules={{
                        min: {
                          value:
                            (getValues("est_start_year") &&
                              moment(getValues("est_start_year")).format(
                                DAY_FORMATS.YEAR_FIRST,
                              )) ||
                            getMinDate(isEditingMode),
                          message: "Please enter a valid end date",
                        },
                        deps: ["est_start_year"],
                      }}
                      render={({ field: { onChange, onBlur } }) => (
                        <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_END}>
                          <DayPicker
                            mode="single"
                            selected={new Date(getValues().est_end_year)}
                            onSelect={(e) => {
                              onChange(() =>
                                setValue(
                                  "est_end_year",
                                  moment(e).format(DAY_FORMATS.YEAR_FIRST),
                                ),
                              );
                              setDayModalName("");
                            }}
                            onDayClick={onBlur}
                            captionLayout="dropdown"
                            endMonth={getEndMonth()}
                            defaultMonth={
                              getValues().est_end_year
                                ? new Date(getValues().est_end_year)
                                : new Date()
                            }
                            disabled={{
                              before: getValues("est_start_year")
                                ? new Date(getValues("est_start_year"))
                                : new Date(),
                              after: getEndMonth(),
                            }}
                          />
                        </DayPickerModal>
                      )}
                    />
                  </PositionedInput>
                </StyledTwoFieldSection>
                <StyledTwoFieldSection>
                  <PositionedInput>
                    <label>Actual Start Date</label>
                    <StyledInput
                      aria-label="Date"
                      type="text"
                      value={
                        getValues("start_year")
                          ? moment(getValues("start_year")).format(
                              DAY_FORMATS.DAY_FIRST,
                            )
                          : projectDetails?.start_year
                            ? moment(projectDetails?.start_year).format(
                                DAY_FORMATS.DAY_FIRST,
                              )
                            : ""
                      }
                      warning={errors?.start_year?.message}
                      onChange={() => {}}
                    />
                    {errors?.start_year && (
                      <ErrorMessage errorMessage={errors.start_year.message} />
                    )}
                    {getValues("start_year") || projectDetails?.start_year ? (
                      <ClearMessage
                        onClick={() => {
                          resetField("start_year", { defaultValue: "" });
                          if (projectDetails) {
                            projectDetails.start_year = "";
                          }
                        }}
                      >
                        Clear
                      </ClearMessage>
                    ) : (
                      ""
                    )}
                    <PositionedCalendar>
                      <Calendar
                        onClick={() => {
                          setDayModalName(DATE_MODAL_NAMES.START);
                        }}
                      />
                    </PositionedCalendar>
                    <Controller
                      control={control}
                      name="start_year"
                      rules={{
                        // min: {
                        //   value: getMinDate(isEditingMode),
                        //   message: "Please enter a valid start date",
                        // },
                        max: {
                          value: getValues("end_year")
                            ? moment(getValues("end_year")).format(
                                DAY_FORMATS.YEAR_FIRST,
                              )
                            : "",
                          message: "Please enter a valid start date",
                        },
                        deps: ["end_year"],
                      }}
                      render={({ field: { onChange, onBlur } }) => (
                        <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                          <DayPicker
                            mode="single"
                            selected={new Date(getValues().start_year)}
                            onSelect={(e) => {
                              onChange(() =>
                                setValue(
                                  "start_year",
                                  moment(e).format(DAY_FORMATS.YEAR_FIRST),
                                ),
                              );
                              setDayModalName("");
                            }}
                            onDayClick={onBlur}
                            captionLayout="dropdown"
                            endMonth={getEndMonth()}
                            defaultMonth={
                              getValues().start_year
                                ? new Date(getValues().start_year)
                                : new Date()
                            }
                            disabled={{
                              // before: projectDetails?.start_year
                              //   ? new Date(projectDetails?.start_year)
                              //   : new Date(),
                              after: getValues("end_year")
                                ? new Date(getValues("end_year"))
                                : getEndMonth(),
                            }}
                          />
                        </DayPickerModal>
                      )}
                    />
                  </PositionedInput>
                  <PositionedInput>
                    <label>Actual End Date</label>
                    <StyledInput
                      aria-label="Date"
                      type="text"
                      value={
                        getValues("end_year")
                          ? moment(getValues("end_year")).format(
                              DAY_FORMATS.DAY_FIRST,
                            )
                          : projectDetails?.end_year
                            ? moment(projectDetails?.end_year).format(
                                DAY_FORMATS.DAY_FIRST,
                              )
                            : ""
                      }
                      warning={errors?.end_year?.message}
                      onChange={() => {}}
                    />
                    {errors?.end_year && (
                      <ErrorMessage errorMessage={errors.end_year.message} />
                    )}
                    {getValues("end_year") || projectDetails?.end_year ? (
                      <ClearMessage
                        onClick={() => {
                          resetField("end_year", { defaultValue: "" });
                          if (projectDetails) {
                            projectDetails.end_year = "";
                          }
                        }}
                      >
                        Clear
                      </ClearMessage>
                    ) : (
                      ""
                    )}
                    <PositionedCalendar>
                      <Calendar
                        onClick={() => {
                          setDayModalName(DATE_MODAL_NAMES.END);
                        }}
                      />
                    </PositionedCalendar>
                    <Controller
                      control={control}
                      name="end_year"
                      rules={{
                        min: {
                          value:
                            (getValues("start_year") &&
                              moment(getValues("start_year")).format(
                                DAY_FORMATS.YEAR_FIRST,
                              )) ||
                            getMinDate(isEditingMode),
                          message: "Please enter a valid end date",
                        },
                        deps: ["start_year"],
                      }}
                      render={({ field: { onChange, onBlur } }) => (
                        <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                          <DayPicker
                            mode="single"
                            selected={new Date(getValues().end_year)}
                            onSelect={(e) => {
                              onChange(() =>
                                setValue(
                                  "end_year",
                                  moment(e).format(DAY_FORMATS.YEAR_FIRST),
                                ),
                              );
                              setDayModalName("");
                            }}
                            onDayClick={onBlur}
                            captionLayout="dropdown"
                            endMonth={getEndMonth()}
                            defaultMonth={
                              getValues().end_year
                                ? new Date(getValues().end_year)
                                : new Date()
                            }
                            disabled={{
                              before: getValues("start_year")
                                ? new Date(getValues("start_year"))
                                : new Date(),
                              after: getEndMonth(),
                            }}
                          />
                        </DayPickerModal>
                      )}
                    />
                  </PositionedInput>
                </StyledTwoFieldSection>
              </SectionPart>
              <SectionPart>
                <H1>Status</H1>
                <StyledSingleFieldSection>
                  <LabelAndInput>
                    <label>Initiative Decision</label>
                    <Controller
                      control={control}
                      name="status_id"
                      render={({ field: { value } }) => (
                        <Dropdown
                          id="status_id"
                          title=""
                          data={getItemDropdownValues(statuses)}
                          onSelect={handleSelect}
                          selectedId={`${value}`}
                          bgColor="var(--color-grey-100)"
                          search
                        />
                      )}
                    />
                  </LabelAndInput>
                  {isEditingMode && (
                    <LabelAndInput>
                      <label>Progress Status</label>
                      <Controller
                        control={control}
                        name="progress_status"
                        render={({ field: { value } }) => (
                          <Dropdown
                            id="progress_status"
                            title=""
                            data={getItemDropdownValues(statuses)}
                            onSelect={handleSelect}
                            selectedId={`${value}`}
                            bgColor="var(--color-grey-100)"
                          />
                        )}
                      />
                    </LabelAndInput>
                  )}
                </StyledSingleFieldSection>
              </SectionPart>
              <StyledSingleFieldSection>
                <H1>Comments</H1>
                <ExpandableDiv height={isCommentFieldExpanded ? "20rem" : ""}>
                  <StyledFullWidthInput
                    height={isCommentFieldExpanded ? "20rem" : ""}
                    {...register("comments", {
                      maxLength: {
                        value: 250,
                        message: "Please enter at most 250 characters",
                      },
                    })}
                    autoComplete="off"
                    warning={errors?.comments?.message}
                  />
                  <PositionedSvg
                    onClick={() => setIsCommentFieldExpanded((prev) => !prev)}
                  >
                    {isCommentFieldExpanded ? <Contract /> : <Expand />}
                  </PositionedSvg>
                </ExpandableDiv>
              </StyledSingleFieldSection>
            </>
          )}
        </StyledSection>
        <BottomNav style={{ justifyContent: "space-between" }}>
          {currentPage === 2 && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={handlePreviousPage}
            >
              <ArrowLeft2 height="1.8rem" width="1.8rem" />
              Back
            </ButtonCTA>
          )}
          <NextDiv>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={() => {
                if (isDirty) {
                  setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
                } else {
                  handleClose();
                }
              }}
            >
              Cancel
              <Discard />
            </ButtonCTA>
            {currentPage === 1 && (
              <ButtonCTA
                $buttonSize={BUTTON_SIZES.SMALL}
                $buttonType={BUTTON_TYPES.SECONDARY}
                type="button"
                onClick={handleNextPage}
              >
                Next
                <ArrowRightWhite />
              </ButtonCTA>
            )}
            {currentPage === 2 && (
              <ButtonCTA
                $buttonSize={BUTTON_SIZES.SMALL}
                $buttonType={BUTTON_TYPES.SECONDARY}
                type="submit"
              >
                Save
                <CheckMark />
              </ButtonCTA>
            )}
          </NextDiv>
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default CreateEditProject;
