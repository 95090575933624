import AdminIcon from "../../assets/AdminIcon.svg?react";
import AdminOutline from "../../assets/AdminOutline.svg?react";
import ArrowDown from "../../assets/ArrowDown.svg?react";
import Documents from "../../assets/Document.svg?react";
import DocumentFilled from "../../assets/DocumentFilled.svg?react";
import DownArrow from "../../assets/DownArrowSmall.svg?react";
import Programs from "../../assets/Folder.svg?react";
import ProgramsFilled from "../../assets/FolderFilled.svg?react";
import Home from "../../assets/Home.svg?react";
import HomeFilled from "../../assets/HomeFilled.svg?react";
import Logout from "../../assets/Logout.svg?react";

import ArrowUp from "../../assets/ArrowUp.svg?react";
import UpArrow from "../../assets/UpArrow.svg?react";

import Reports from "../../assets/Reports.svg?react";
import ReportsFilled from "../../assets/ReportsFilled.svg?react";

import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useOverview } from "../../context/OverviewActiveContext";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetUserRoles } from "../../hooks/auth/useRoles";
import { ROLES } from "../../common/Constants";
import { useQueryClient } from "@tanstack/react-query";

const NavContainer = styled.div<{
  $isOverviewActive?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$isOverviewActive && "center"};
  padding: 0 1rem;
`;

const StyledLink = styled.div<{
  $active?: boolean;
  $isOverviewActive?: boolean;
  $color?: string;
}>`
  cursor: pointer;
  display: flex;
  gap: 1rem;
  color: ${(props) => props.$color || "white"};
  align-items: center;
  font-size: 1.2rem;
  height: 6rem;
  padding: 0 0 0 ${(props) => (props.$isOverviewActive ? "" : "4rem")};
  background-color: ${(props) =>
    props.$active && !props.$isOverviewActive && "#393F48"};
  border-radius: var(--border-radius-lg);
`;

const StyledDropdownLink = styled(StyledLink)<{
  $isExpanded?: boolean;
  $isFullyExpanded?: boolean;
  $isOverviewActive?: boolean;
}>`
  height: ${(props) =>
    props.$isOverviewActive
      ? "6rem"
      : props.$isFullyExpanded
        ? "16rem"
        : props.$isExpanded
          ? "10rem"
          : "6rem"};
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  align-items: unset;
  background-color: ${(props) =>
    props.$isExpanded && !props.$isOverviewActive && "#393F48"};
  margin: ${(props) => (props?.$isExpanded ? "1rem 0" : "0")};
`;

const StyledSecondaryDropdown = styled.div<{ $isOverviewActive?: boolean }>`
  display: ${(props) => (props.$isOverviewActive ? "none" : "flex")};
  flex-direction: column;
  padding: 0 4rem;
  gap: 1.5rem;
  color: white;
  font-weight: 700;

  & a {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
  }
`;

const StyledSecondaryTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

/**
 * NavLinks component renders the navigation links for the application.
 * It includes links for Home, Programs, Reports, Documents, and Admin (if the user is an admin).
 * It also handles the sign-out functionality.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const NavLinks = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isOverviewActive, setIsOverviewActive } = useOverview();
  const [isProgramsExpanded, setIsProgramsExpanded] = useState(false);
  const [isProjectsExpanded, setIsProjectsExpanded] = useState(false);
  const queryClient = useQueryClient();

  const { user } = useGetCurrentUser();
  const { userRoles } = useGetUserRoles(user?.id || "");

  const isAdmin = !!userRoles?.some(
    (role) => role.role.title.toUpperCase() === ROLES.ADMIN,
  );

  const handleNavClick = (pathName: string) => {
    navigate(pathName);
    setIsOverviewActive(false);
  };

  const handleDropdownClick = (val: boolean) => {
    setIsProgramsExpanded(val);
    if (!val) setIsProjectsExpanded(val);
  };

  /**
   * handleSignOut function clears the local storage, clears the query client cache, and navigates to the login page.
   */
  const handleSignOut = () => {
    localStorage.clear();
    queryClient.clear();
    navigate("/login");
  };

  return (
    <NavContainer $isOverviewActive={isOverviewActive}>
      <StyledLink
        onClick={() => handleNavClick("/home")}
        $isOverviewActive={isOverviewActive}
        $active={pathname.includes("home")}
      >
        {pathname.includes("home") ? <HomeFilled /> : <Home />}
        {!isOverviewActive && <span>Home</span>}
      </StyledLink>
      <StyledDropdownLink
        $isExpanded={isProgramsExpanded}
        $isFullyExpanded={isProjectsExpanded}
        $isOverviewActive={isOverviewActive}
      >
        <StyledLink
          $isOverviewActive={isOverviewActive}
          $active={pathname.includes("programs")}
          onClick={() => setIsOverviewActive(false)}
        >
          {pathname.includes("programs") ? <ProgramsFilled /> : <Programs />}
          {!isOverviewActive && (
            <>
              <span onClick={() => handleNavClick("/programs")}>Programs</span>
              {isProgramsExpanded ? (
                <ArrowUp onClick={() => handleDropdownClick(false)} />
              ) : (
                <ArrowDown onClick={() => handleDropdownClick(true)} />
              )}
            </>
          )}
        </StyledLink>
        {isProgramsExpanded && !isOverviewActive && (
          <StyledSecondaryDropdown $isOverviewActive={isOverviewActive}>
            <StyledSecondaryTitle>
              <p onClick={() => handleNavClick("/projects")}>Projects </p>
              {isProjectsExpanded ? (
                <UpArrow onClick={() => setIsProjectsExpanded(false)} />
              ) : (
                <DownArrow onClick={() => setIsProjectsExpanded(true)} />
              )}
            </StyledSecondaryTitle>
            {isProjectsExpanded && !isOverviewActive && (
              <>
                <Link to="/milestones">{"Milestones >"}</Link>
                <Link to="/tasks">{"Tasks >"}</Link>
                {/* <Link to="/subtasks">{"Subtasks >"}</Link> */}
              </>
            )}
          </StyledSecondaryDropdown>
        )}
      </StyledDropdownLink>{" "}
      <StyledLink
        onClick={() => handleNavClick("/reports")}
        $isOverviewActive={isOverviewActive}
        $active={pathname.includes("reports")}
      >
        {pathname.includes("reports") ? <ReportsFilled /> : <Reports />}
        {!isOverviewActive && <span>Reports</span>}
      </StyledLink>{" "}
      <StyledLink
        onClick={() => handleNavClick("/documents")}
        $isOverviewActive={isOverviewActive}
        $active={pathname.includes("documents")}
      >
        {pathname.includes("documents") ? <DocumentFilled /> : <Documents />}
        {!isOverviewActive && <span>Documents</span>}
      </StyledLink>
      {isAdmin && (
        <StyledLink
          onClick={() => handleNavClick("/admin")}
          $isOverviewActive={isOverviewActive}
          $active={pathname.includes("admin")}
        >
          {pathname.includes("admin") ? <AdminIcon /> : <AdminOutline />}
          {!isOverviewActive && <span>Settings</span>}
        </StyledLink>
      )}
      <StyledLink
        onClick={handleSignOut}
        $isOverviewActive={isOverviewActive}
        $color={"var(--color-button-secondary)"}
      >
        <Logout />
        {!isOverviewActive && <span>Sign Out</span>}
      </StyledLink>
    </NavContainer>
  );
};

export default NavLinks;
