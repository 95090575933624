import { AxiosError } from "axios";
import {
  Milestone,
  Task,
  UpsertMilestone,
  UpsertTask,
} from "../types/milestoneTypes";
import { client } from "./AxiosClient";

export const getAllMilestonesAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_ALL_MILESTONES, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as Milestone[];
};

export const getAllTasksAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_ALL_TASKS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as Task[];
};

export const getMilestonesByProjectAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_MILESTONES_BY_PROJECT + `${projectId}`,
  );

  if (response.status === 204) return [];

  return response.data as Milestone[];
};

export const getTasksByMilestone = async (milestoneId: number) => {
  try {
    const response = await client.get(
      import.meta.env.VITE_TASKS_BY_MILESTONE + `${milestoneId}`,
    );

    if (response.status === 204) return [];

    return response.data as Task[];
  } catch (error: unknown) {
    // todo handle errors for other APIs
    const knownError = error as AxiosError;
    throw new Error(knownError.message);
  }
};

export const createMilestoneAPI = async (milestoneDetails: UpsertMilestone) => {
  const response = await client.post(import.meta.env.VITE_ALL_MILESTONES, {
    ...milestoneDetails,
  });
  return response.data as Milestone;
};

export const updateMilestoneAPI = async (
  milestoneDetails: UpsertMilestone,
  milestoneId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_ALL_MILESTONES + `${milestoneId}`,
    {
      ...milestoneDetails,
    },
  );
  return response.data as Milestone;
};

export const deleteMilestoneAPI = async (taskId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_ALL_MILESTONES + `${taskId}`,
  );
  return response.data as string;
};

export const createTaskAPI = async (taskDetails: UpsertTask) => {
  const response = await client.post(import.meta.env.VITE_ALL_TASKS, {
    ...taskDetails,
    wbs_id: null, //todo: change this to the correct value
  });
  return response.data as Task;
};

export const updateTaskAPI = async (
  taskDetails: UpsertTask,
  taskId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_ALL_TASKS + `${taskId}`,
    {
      ...taskDetails,
    },
  );
  return response.data as Task;
};

export const deleteTaskAPI = async (taskId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_ALL_TASKS + `${taskId}`,
  );
  return response.data as string;
};
