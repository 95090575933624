import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectStakeholderAPI,
  deleteProjectStakeholderAPI,
  getProjectStakeholdersAPI,
  updateProjectStakeholderAPI,
} from "../../services/apiProjects";
import { UpsertProjectStakeholder } from "../../types/projectTypes";

export const useGetProjectStakeholders = (id: string) => {
  const {
    isPending: isStakeholderLoading,
    data: stakeholderDetails,
    error,
  } = useQuery({
    queryFn: () => getProjectStakeholdersAPI(+id),
    queryKey: [QUERY_KEYS.PROJECT_STAKEHOLDERS, id],
    retry: false,
    enabled: !!id,
  });

  return { isStakeholderLoading, stakeholderDetails, error };
};

export const useCreateProjectStakeholder = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isCreating,
    mutate: createProjectStakeholder,
    error,
  } = useMutation({
    mutationFn: ({
      stakeholderDetails,
    }: {
      stakeholderDetails: UpsertProjectStakeholder;
    }) => createProjectStakeholderAPI(stakeholderDetails),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_STAKEHOLDERS],
      });
    },
  });

  return { isCreating, createProjectStakeholder, error };
};

export const useEditProjectStakeholder = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isEditing,
    mutate: editProjectStakeholder,
    error,
  } = useMutation({
    mutationFn: ({
      stakeholderDetails,
      detailId,
    }: {
      stakeholderDetails: UpsertProjectStakeholder;
      detailId: number;
    }) => updateProjectStakeholderAPI(stakeholderDetails, detailId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_STAKEHOLDERS],
      });
    },
  });

  return { isEditing, editProjectStakeholder, error };
};

export const useDeleteProjectStakeholder = () => {
  const queryClient = useQueryClient();
  const {
    isPending: isDeleting,
    mutate: deleteProjectStakeholder,
    error,
  } = useMutation({
    mutationFn: ({ detailId }: { detailId: number }) =>
      deleteProjectStakeholderAPI(detailId || 0),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_STAKEHOLDERS],
      });
    },
  });

  return { isDeleting, deleteProjectStakeholder, error };
};
