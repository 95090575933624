import { FC, PropsWithChildren, useEffect } from "react";
import { useGetCurrentUser } from "../hooks/auth/useGetCurrentUser";
import { useGetUserRoles } from "../hooks/auth/useRoles";
import { ROLES } from "../common/Constants";

/**
 * AdminProtectedRoute component restricts access to its children to admin users only.
 * Redirects non-admin users to the home page.
 *
 * @component
 * @param {PropsWithChildren} props - The component props.
 * @returns {JSX.Element | null} The rendered component or null if loading.
 */
const AdminProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading: isUserLoading, user } = useGetCurrentUser();
  const { isUserRoleLoading, userRoles, error } = useGetUserRoles(
    user?.id ?? "",
  );
  const isAdmin = !!userRoles?.some(
    (role) => role.role.title.toUpperCase() === ROLES.ADMIN,
  );
  useEffect(() => {
    if (userRoles && !isAdmin) {
      // Redirect to the home page if the user is not an admin
      window.location.href = "/home";
    }
    if (error) {
      throw new Error("Unable to authorize user as admin");
    }
  }, [isAdmin, userRoles, error]);

  if (isUserLoading || isUserRoleLoading) {
    return null;
  }

  return <>{children}</>;
};

export default AdminProtectedRoute;
