export const SORT_OPTIONS = {
  PAST: "Past",
  RECENT: "Recent",
  PRIORITY: "Priority",
};

export const DEFAULT_SORT_OPTIONS = [
  { id: "1", name: SORT_OPTIONS.RECENT },
  { id: "2", name: SORT_OPTIONS.PAST },
  { id: "3", name: SORT_OPTIONS.PRIORITY },
];

export const BUTTON_SIZES = {
  SMALL: "small",
  LARGE: "large",
  MEDIUM_LARGE: "medium",
};

export const BUTTON_TYPES = {
  SECONDARY: "secondary",
};

export const TASK_STATUS = {
  TODO: "Not Started",
  PENDING: "Pending",
  COMPLETED: "Completed",
  INPROGRESS: "In Progress",
  HOLD: "On Hold",
  ASSIGNED: "Assigned",
  CANCELED: "Cancelled",
};

export const QUERY_KEYS = {
  PROJECT: "project",
  PROJECTS: "projects",
  PROJECT_TYPE: "project-type",
  TASKS: "tasks",
  MILESTONES: "milestones",
  RELATED_PROJECTS: "related-projects",
  PINNED_ITEMS: "pinned-items",
  DROPDOWN_ITEMS: "dropdown-items",
  CONTACTS: "contacts",
  STATUS: "status",
  SERVICE: "service",
  PROJECT_SCOPE: "project-scope",
  PROJECT_SUCCESS: "project-success",
  PROJECT_DETAILS: "project-details",
  PROJECT_STAKEHOLDERS: "project-stakeholders",
  PROJECT_RISKS: "project-risks",
  PROJECT_IMPACTS: "project-impacts",
  PROJECT_CONSULTATION: "project-consultation",
  PROJECT_WORKFORCE: "project-workforce",
  PROJECT_FINANCE: "project-finance",
  USER: "user",
  ALL_USERS: "all-users",
  ALL_USER_ROLES: "all-user-roles",
  ROLES: "roles",
  USER_ROLES: "user-roles",
  TENANT: "tenant",
  WBS: "wbs",
  PROJECT_WBS: "project-wbs",
  PROJECT_COST_CENTRES: "project-cost-centres",
  COST_CENTRE: "cost-centre",
  PROJECT_EXPENSE: "project-expense",
};

export const PINNED_ITEM_TYPES = {
  PROJECT: "project",
  MILESTONE: "milestone",
  TASK: "task",
  SUBTASK: "subtask",
};

export const DROPDOWN_CATEGORIES = {
  FUNDING_SOURCE: "Funding source",
  EXPENSE_TYPE: "Expense type",
  RESOURCE_TYPE: "Resource types",
  RISK_RATINGS: "Risk ratings",
  STAKEHOLDERS: "Stakeholders",
  STAKEHOLDER_ROLES: "Stakeholder Roles",
  STAKEHOLDER_TYPE: "Stakeholder types",
  PROJECT_TYPE: "Project types",
  RISK_TYPE: "Risk types",
  PROJECT_STATUS_TYPE: "Project status types",
  SERVICES: "Departments",
};

export const PROJECT_DETAILS_API_VALUES = {
  SCOPE: "VITE_PROJECT_SCOPE",
  GOALS: "VITE_PROJECT_GOALS",
  ASSUMPTION: "VITE_PROJECT_ASSUMPTION",
  DEPENDENCY: "VITE_PROJECT_DEPENDENCY",
  CONSTRAINT: "VITE_PROJECT_CONSTRAINT",
  SUCCESS: "VITE_PROJECT_SUCCESS",
  SOLUTION: "VITE_PROJECT_SOLUTION",
  BACKGROUND: "VITE_PROJECT_BACKGROUND",
};

export const MODAL_NAMES = {
  PROJECT: "PROJECT",
  SCOPE: "SCOPE",
  GOALS: "GOALS",
  OBJECTIVES: "OBJECTIVES",
  ASSUMPTIONS: "ASSUMPTIONS",
  DEPENDENCIES: "DEPENDENCIES",
  CONSTRAINTS: "CONSTRAINTS",
  SUCCESS: "SUCCESS CRITERIA",
  SOLUTIONS: "SOLUTIONS",
  STAKEHOLDERS: "STAKEHOLDERS",
  CONSULTATION: "CONSULTATION",
  RISKS: "RISKS",
  IMPACTS: "IMPACTS",
  WORKFORCE: "WORKFORCE",
  FINANCE: "FUNDING",
  MILESTONES: "MILESTONES",
  TASKS: "TASKS",
  RATIONALE: "BACKGROUND/ RATIONALE",
  NEW_USER: "NEW_USER",
  PROFILE: "PROFILE",
  ORGANIZATION: "ORGANIZATION",
  COST_CENTRE: "COST CENTRE",
  WBS: "WBS",
  PROJECT_WBS: "PROJECT WBS",
  CONFIRM_CLOSE_POPUP: "CONFIRM_CLOSE_POPUP",
  CONFIRM_CANCEL: "CONFIRM_CANCEL",
  UPDATE_DROPDOWN: "UPDATE_DROPDOWN",
  EXPENSE: "EXPENSE",
  EXPENDITURE: "EXPENDITURE",
};

export const DATE_MODAL_NAMES = {
  EST_START: "est-start",
  EST_END: "est-end",
  START: "start-date",
  END: "end-date",
  DELETE: "delete",
};

export const DAY_FORMATS = {
  YEAR_FIRST: "YYYY-MM-DD",
  DAY_FIRST: "DD-MM-YYYY",
};

export const MODAL_TYPE = {
  NEW: "New",
  EDIT: "Edit",
};

export const ROLES = {
  ADMIN: "ADMIN",
  SUPERUSER: "SUPERUSER",
  VIEWER: "VIEWER",
  EDITOR: "EDITOR",
};

export const PHONE_REGEX =
  /^\+?(\d{1,4})?[-\s]?\(?\d{1,4}\)?[-\s]?\d{3,4}[-\s]?\d{3,4}[-\s]?\d{3,4}$/;

// Country code mapping (with Australian code included)
export const COUNTRY_CODE_FORMATS: { [key: string]: string } = {
  "1": "+1 (###) ###-####", // USA, Canada, etc.
  "44": "+44 #### ### ###", // UK
  "91": "+91 #####-#####", // India
  "61": "+61 # #### ####", // Australia
  // Add more as needed
};

export const USER_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  INVITED: "Invited",
};

export const RAG = {
  GREEN: "#72D7C4",
  AMBER: "#F5AA42",
  RED: "#FD6B58",
};
