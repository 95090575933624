export const SCHEDULE_MAP = {
  "Not started": "#7E869B",
  "Not Started": "#7E869B",
  Approved: "#1EB8F9",
  Assigned: "#1EB8F9",
  "In Progress": "#B66DFF",
  "On Hold": "#FE642B",
  Complete: "#35A893",
  Completed: "#35A893",
  Pending: "#FF8A00",
  Cancelled: "#E53D3D",
};

export const USER_STATUS_MAP = {
  Active: "#35A893",
  Inactive: "#7E869B",
  Invited: "#B060FF",
};
