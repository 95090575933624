import styled from "styled-components";

export const InfoTooltip = styled.div`
  position: absolute;
  top: 0;
  right: 105%;
  background-color: var(--color-grey-900);
  padding: 1.5rem;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  color: white;
  width: 25rem;
  box-shadow: var(--box-shadow-1);
  z-index: 10;
  text-transform: none;
`;
