import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createCostCentreApi,
  deleteCostCentreApi,
  getCostCentresApi,
  updateCostCentreApi,
} from "../../services/apiFinances";
import { UpsertCostCentre } from "../../types/financeTypes";

/**
 * Custom hook to get cost centres.
 * @returns {Object} - An object containing cost centres data, loading state, and error state.
 */
export const useGetCostCentres = () => {
  const { data, isLoading, error } = useQuery({
    queryFn: getCostCentresApi,
    queryKey: [QUERY_KEYS.COST_CENTRE],
  });

  return { costCentres: data, isLoading, error };
};

/**
 * Custom hook to create a cost centre.
 * @returns {Object} - An object containing the createCostCentre function, pending state, and error state.
 */
export const useCreateCostCentre = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createCostCentre,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertCostCentre) => createCostCentreApi(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.COST_CENTRE],
      });
    },
  });

  return { createCostCentre, isPending, error };
};

/**
 * Custom hook to update a cost centre.
 * @returns {Object} - An object containing the updateCostCentre function, pending state, and error state.
 */
export const useUpdateCostCentre = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateCostCentre,
    isPending,
    error,
  } = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpsertCostCentre }) =>
      updateCostCentreApi(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.COST_CENTRE],
      });
    },
  });

  return { updateCostCentre, isPending, error };
};

/**
 * Custom hook to delete a cost centre.
 * @returns {Object} - An object containing the deleteCostCentre function, pending state, and error state.
 */
export const useDeleteCostCentre = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteCostCentre,
    isPending,
    error,
  } = useMutation({
    mutationFn: (id: number) => deleteCostCentreApi(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.COST_CENTRE],
      });
    },
  });

  return { deleteCostCentre, isPending, error };
};
