import {
  AccessTokenResponse,
  SignUpCred,
  Tenant,
  TenantUpdateType,
  User,
  UserProfileUpdate,
} from "../types/authTypes";
import { client } from "./AxiosClient";

export const accessTokenAPI = async ({ username = "", password = "" }) => {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);

  const response = await client.post(
    import.meta.env.VITE_ACCESS_TOKEN_EP,
    data,
  );

  return response.data as AccessTokenResponse;
};

export const refreshTokenAPI = async (refresh_token: string) => {
  const response = await client.post(import.meta.env.VITE_REFRESH_TOKEN_EP, {
    refresh_token,
  });

  return response.data as AccessTokenResponse;
};

export const getUserApi = async () => {
  const response = await client.get(import.meta.env.VITE_USER);
  return response.data as User;
};

export const registerUserAPI = async ({
  email,
  password,
  first_name,
  last_name,
  config,
  isactive,
}: SignUpCred) => {
  const response = await client.post(import.meta.env.VITE_REGISTER_USER_EP, {
    email,
    hashed_password: password,
    first_name,
    last_name,
    full_name: `${first_name} ${last_name}`,
    login_name: email,
    config,
    isactive,
  });

  return response.data as User;
};

export const verifyUserAPI = async (token: string) => {
  const response = await client.get(
    import.meta.env.VITE_VERIFY_USER_EP + token,
  );
  return response.data as string;
};

export const getTenantAPI = async (domain: string) => {
  const response = await client.get(import.meta.env.VITE_GET_TENANT + domain);
  return response.data as Tenant;
};

export const updateTenantAPI = async (tenantDetails: TenantUpdateType) => {
  const tenantId = tenantDetails.id;
  delete tenantDetails.id;
  const response = await client.patch(
    import.meta.env.VITE_TENANT_EP + tenantId,
    tenantDetails,
  );
  return response.data as Tenant;
};

export const createUserProfileAPI = async ({
  email,
  first_name,
  last_name,
  password,
  role_id,
  config,
  isactive,
}: SignUpCred) => {
  const response = await client.post(import.meta.env.VITE_USER_MGMT + role_id, {
    email,
    hashed_password: password || "test", // dummy password
    first_name,
    last_name,
    config,
    full_name: `${first_name} ${last_name}`,
    login_name: email,
    isactive,
  });

  return response.data as User;
};

export const updateUserProfileAPI = async (
  userDetails: UserProfileUpdate,
  current_role_id: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_USER_MGMT + "update_user_with_role/" + userDetails.id,
    userDetails,
    {
      params: {
        current_role_id,
      },
    },
  );
  return response.data as User;
};

export const deleteUserProfileAPI = async (userId: string) => {
  const response = await client.delete(import.meta.env.VITE_USER_MGMT + userId);
  return response.data as string;
};
