import HomeOverview from "../features/Home/HomeOverview";
import { Helmet } from "react-helmet";
/**
 * @description this is the home page component used for routing
 * @returns Home page overview
 */
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Home</title>
      </Helmet>
      <HomeOverview />
    </>
  );
};

export default Home;
