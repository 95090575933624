import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AtenLogo from "../assets/aten-logo.svg?react";
import Eye from "../assets/Eye.svg?react";
import EyeClosed from "../assets/EyeClosed.svg?react";

import { AxiosError } from "axios";
import { BUTTON_SIZES, BUTTON_TYPES } from "../common/Constants";
import { useAccessToken } from "../hooks/auth/useAccessToken";
import { useRegisterUser } from "../hooks/auth/useRegisterUser";
import { LoginCred, SignUpCred } from "../types/authTypes";
import ButtonCTA from "../ui/ButtonCTA";
import { ErrorMessage } from "../ui/FormElements";

const LoginLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  flex-direction: column;
  background-image: url("/BGLines.svg");
  gap: 4rem;
`;

const Title = styled.h1`
  color: white;
  font-weight: 400;
`;

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: fit-content;
  border-radius: var(--border-radius-2xl);
  padding: 6rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  & p {
    font-size: 1.2rem;
    align-self: center;
  }
`;

const StyledInput = styled.input<{ warning?: string }>`
  background-color: var(--color-grey-100);
  ::placeholder {
    color: var(-color-placeholder-grey);
  }
  width: 30rem;
  height: 4.8rem;
  border: transparent;
  border-radius: var(--border-radius-2l);
  padding: 0 1rem;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

const StyledSpan = styled.span`
  font-size: 1.2rem;
  color: var(--color-button-secondary);
  font-weight: 700;
  margin-top: -1rem;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: var(--color-version);
`;

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  & p {
    font-size: 1.2rem;
    align-self: center;
  }
`;

const SignUpFormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  max-width: 30rem;
`;

const PasswordInput = styled(StyledInput)`
  position: relative;
`;

const StyledEyeIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 2.6rem;
  transform: translateY(-50%);
  cursor: pointer;
`;

/**
 * Login component for user authentication.
 *
 * @returns {JSX.Element} The Login component.
 */
const Login = () => {
  const [loginError, setLoginError] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCred>();

  const {
    register: registerSignUp,
    handleSubmit: handleSubmitSignUp,
    formState: { errors: errorsSignUp },
  } = useForm<SignUpCred>({ mode: "all" });

  const [isSignUp, setIsSignUp] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const {
    location: { host },
  } = window;

  useEffect(() => {
    const token = localStorage.getItem("accessDetails" + host);
    if (token) {
      navigate("/home");
    }
  }, [navigate, host]);

  const { getAccessToken, isLoading } = useAccessToken();
  const { isRegistering, registerUser } = useRegisterUser();

  const onSubmit: SubmitHandler<LoginCred> = (data) => {
    getAccessToken(
      {
        ...data,
      },
      {
        onSettled: (_, error) => {
          if (error) {
            const newError = error as AxiosError;
            setLoginError(
              (newError?.response?.data as { detail?: string })?.detail ||
                "An error occurred while logging in.",
            );
          } else {
            navigate("/profile/1");
          }
        },
      },
    );
  };

  const onSubmitSignUp: SubmitHandler<SignUpCred> = (data) => {
    registerUser(
      {
        ...data,
        config: {
          is_profile_updated: false,
          is_org_updated: false,
        },
      },
      {
        onSuccess: () => navigate("/email-verification"),
        onError: (error) => {
          const newError = error as AxiosError;
          let data = (newError?.response?.data as { detail?: string })?.detail;

          if (data?.includes("Organization found")) {
            data =
              "This organization already exists, please contact your administrator.";
          }
          setSignUpError(
            data || "An error occurred while creating your account.",
          );
        },
      },
    );
  };

  return (
    <>
      <LoginLayout>
        <Helmet>
          <title>Capital Works | Login</title>
        </Helmet>
        <Title>
          capital<strong>WORX</strong>
        </Title>
        {isSignUp ? (
          <FormContainer>
            <h1>Create Account</h1>
            <SignUpForm onSubmit={handleSubmitSignUp(onSubmitSignUp)}>
              <SignUpFormLayout>
                <InputDiv>
                  <StyledInput
                    type="text"
                    placeholder="First Name *"
                    {...registerSignUp("first_name", {
                      required: "This field is required",
                    })}
                    warning={errorsSignUp.first_name?.message}
                  />
                  {errorsSignUp.first_name && (
                    <ErrorMessage
                      errorMessage={errorsSignUp.first_name.message}
                    />
                  )}
                </InputDiv>
                <InputDiv>
                  <StyledInput
                    type="text"
                    placeholder="Last Name *"
                    {...registerSignUp("last_name", {
                      required: "This field is required",
                    })}
                    warning={errorsSignUp.last_name?.message}
                  />
                  {errorsSignUp.last_name && (
                    <ErrorMessage
                      errorMessage={errorsSignUp.last_name.message}
                    />
                  )}
                </InputDiv>
                <InputDiv>
                  <StyledInput
                    type="text"
                    placeholder="Email *"
                    {...registerSignUp("email", {
                      required: "This field is required",
                    })}
                    autoComplete="new-password"
                    warning={errorsSignUp.email?.message}
                  />
                  {errorsSignUp.email && (
                    <ErrorMessage errorMessage={errorsSignUp.email.message} />
                  )}
                </InputDiv>
                <InputDiv>
                  <PasswordInput
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Set Password *"
                    {...registerSignUp("password", {
                      required: "This field is required",
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                    })}
                    autoComplete="new-password"
                    warning={errorsSignUp.password?.message}
                  />
                  <StyledEyeIcon>
                    {passwordVisible ? (
                      <EyeClosed onClick={() => setPasswordVisible(false)} />
                    ) : (
                      <Eye onClick={() => setPasswordVisible(true)} />
                    )}
                  </StyledEyeIcon>
                  {errorsSignUp.password ? (
                    errorsSignUp.password.type === "pattern" ? (
                      <ErrorMessage
                        errorMessage="Password must be at least 8 characters, contain an
                        uppercase letter, a lowercase letter, a number, and a
                        special character."
                      />
                    ) : (
                      <ErrorMessage errorMessage="This field is required" />
                    )
                  ) : null}
                </InputDiv>
              </SignUpFormLayout>
              {signUpError && <ErrorMessage errorMessage={signUpError} />}
              <ButtonCTA
                $buttonType={BUTTON_TYPES.SECONDARY}
                $buttonSize={BUTTON_SIZES.LARGE}
                disabled={isRegistering}
              >
                Sign Up
              </ButtonCTA>
              <p onClick={() => setIsSignUp(false)}>
                Already have an account? <StyledSpan> Sign In!</StyledSpan>
              </p>
            </SignUpForm>
          </FormContainer>
        ) : (
          <FormContainer>
            <h1>Login</h1>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <StyledInput
                type="text"
                placeholder="Username"
                {...register("username", {
                  required: "This field is required",
                })}
                warning={errors.username?.message}
              />
              {errors.username && (
                <ErrorMessage errorMessage={errors.username.message} />
              )}
              <InputDiv>
                <PasswordInput
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", {
                    required: "This field is required",
                  })}
                  autoComplete="current-password"
                  warning={errors.password?.message}
                />
                <StyledEyeIcon>
                  {passwordVisible ? (
                    <EyeClosed onClick={() => setPasswordVisible(false)} />
                  ) : (
                    <Eye onClick={() => setPasswordVisible(true)} />
                  )}
                </StyledEyeIcon>
                {errors.password && (
                  <ErrorMessage errorMessage={errors.password.message} />
                )}
              </InputDiv>
              <StyledSpan>Forgot password?</StyledSpan>
              {loginError && <ErrorMessage errorMessage={loginError} />}
              <ButtonCTA
                $buttonType={BUTTON_TYPES.SECONDARY}
                $buttonSize={BUTTON_SIZES.LARGE}
                disabled={isLoading}
              >
                Login
              </ButtonCTA>
              <p onClick={() => setIsSignUp(true)}>
                Don't have an account? <StyledSpan> Sign up!</StyledSpan>
              </p>
            </StyledForm>
          </FormContainer>
        )}

        <LogoDiv>
          <p>{import.meta.env.VITE_APP_VERSION} | Powered by</p>
          <AtenLogo />
        </LogoDiv>
      </LoginLayout>
    </>
  );
};

export default Login;
