import styled from "styled-components";

export const TableContainer = styled.div<{ $isActive: boolean }>`
  display: grid;
  grid-template-rows: ${(props) => (props.$isActive ? "1fr" : "0fr")};
  transition: grid-template-rows 0.3s;
`;

export const HeightTransitionDiv = styled.div`
  overflow: hidden;
`;
