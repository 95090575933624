import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root {
  /* Grey */
  --color-grey-100: #F0F2F7;
  --color-grey-200: #e5e7eb;
  --color-grey-300: #92929D;
  --color-grey-400: #CFD5E3;
  --color-grey-400-50: #CFD5E350;
  --color-grey-600: #ebeff6;

  --color-grey-500: #7C8499;
  --color-grey-900: #22252A;
  --color-placeholder-grey: #606B6C;
  --color-version: #7A7F7F;
  --color-ref-no: #AFB1B6;

  --color-no-data-bg: #DEE2EB;
  --color-no-data-text: #7E869B;

  --color-button-secondary:#EB3C7C;
  --color-recent:#0F2444;
  --color-view-button:#0034EC;
  --color-location: #1862D2;
  --color-in-progress:#9E51EC;
  --color-percent-complete:#16B25E;
  --color-percent-complete-sec: #4BC7A2;
  --color-warning: #d62728;
  --color-section-headers:#ad5676;

  --color-projects: #FFF0F6;

  // budgets
  --color-budget-remaining: #CF8AF9;
  --color-budget-in-use:#FAC300;
  --color-budget-used:#6A6E77;
  --color-budget-used-sec:#CFD5E3;

  --backdrop-color: rgba(255, 255, 255, 0.1);

  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.04);
  --shadow-md: 0px 0.6rem 2.4rem rgba(0, 0, 0, 0.06);
  --shadow-lg: 0.2rem 2.4rem 3.2rem rgba(0, 0, 0, 0.12);

  --border-radius-tiny: 3px;
  --border-radius-sm: 5px;
  --border-radius-md: 7px;
  --border-radius-lg: 9px;
  --border-radius-2l: 13px;
  --border-radius-3l: 16px;
  --border-radius-xl: 20px;
  --border-radius-2xl: 40px;
  --border-radius-max: 999px;

  // embla carousel settings
  --slide-spacing: 2rem;
  --slide-size: 33%;

}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  transition: all 0.3s, border 0.3s, font 0s;
  scrollbar-color: #22252A #D4D8E2;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  ::-webkit-scrollbar-button {
  width: 0px;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Rubik', sans-serif;
  transition: all 0.3s;
  min-height: 100vh;
  line-height: 1.5;
  font-size: 1.4rem;
  background-color: #22252A;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

button {
  cursor: pointer;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}

*:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

select:disabled,
input:disabled, input:read-only:not([type="checkbox"]) {
  border: 2px solid var(--color-grey-100) !important;
  background-color: unset;
  pointer-events: none;
}

input::placeholder {
  font-size: 1.4rem;
}

button:has(svg) {
  line-height: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
}

h1 {
  font-weight: 700;
}

img {
  max-width: 100%;
}

.rdp-dropdown {
  padding: 1rem;
}

.rdp-root {
  --rdp-day-height: 4rem;
  --rdp-day-width: 4rem;
  --rdp-accent-color: var(--color-button-secondary);
  --rdp-weekday-padding: 1.5rem 0;
  --rdp-outside-opacity: 0.5;
}

.rdp-month_grid {
  margin-top: 1rem;
}

.rdp-weekday {
  font-weight: 700;
  font-size: 1.2rem;
}

.rdp-weeks {
  margin-top: 1rem;
}

.rdp-selected {
  color: white !important;
  background-color: var(--color-button-secondary);
  border: none;
  border-radius: 999px;
  font-size: medium;
}

.rdp-today:not(.rdp-outside) {
  color: var(--color-button-secondary);
  font-weight: 700;
}

.rdp-day_button {
  border-radius: 999px;
}

.rdp-outside {
  pointer-events: none;
}

`;

export default GlobalStyles;
