import DeleteCon from "../../assets/DeleteCon.svg?react";
import Eye from "../../assets/Eye.svg?react";
import Filter from "../../assets/Filter.svg?react";
import Info from "../../assets/Info.svg?react";
import More from "../../assets/More.svg?react";
import Notification from "../../assets/Notification.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import Plus from "../../assets/Plus.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import Search from "../../assets/Search.svg?react";
import Upload from "../../assets/Upload.svg?react";

import styled from "styled-components";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import NumberSpan from "../../ui/NumberSpan";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  DEFAULT_SORT_OPTIONS,
  MODAL_NAMES,
  MODAL_TYPE,
  PINNED_ITEM_TYPES,
  SORT_OPTIONS,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useActiveModalContext } from "../../context/ModalContext";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetUserRoles } from "../../hooks/auth/useRoles";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { MilestoneFormValues } from "../../types/formFields";
import { Milestone } from "../../types/milestoneTypes";
import DeletePopup from "../../ui/DeletePopup";
import Dropdown from "../../ui/Dropdown";
import { ProjectHeader, SideContainer } from "../../ui/HeaderComponents";
import { InfoTooltip } from "../../ui/InfoToolTip";
import Modal from "../../ui/Modal";
import NoDataCard from "../../ui/NoDataCard";
import PageNumbers from "../../ui/PageNumbers";
import Spinner from "../../ui/Spinner";
import { getRAGStatus, getUserPermissions } from "../../utils/common";
import DebouncedInput from "../../utils/DebouncedInput";
import { useGetAllMilestones } from "../Home/useGetMilestones";
import ProfileLink from "../navigation/ProfileLink";
import { useProjects } from "../projects/useProjects";
import MilestoneForm from "./MilestoneForm";
import { useDeleteProjectMilestone } from "./useMilestones";
import { RAGIndicator } from "../../ui/RAGIndicator";

const HeaderSpan = styled.span`
  font-size: 1.6rem;
  color: var(--color-grey-300);
`;

const SearchFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const TableRowsContainer = styled.div`
  max-height: 62dvh;
  overflow: auto;
`;

const TableHeader = styled.div`
  display: grid;
  margin-top: 1.5rem;
  padding-left: 2.5rem;
  grid-template-columns: 1.5fr 1.5fr repeat(5, 1fr) 0.8fr;
  gap: 1.2rem;
  background-color: var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 700;
  font-size: 1.2rem;
  justify-items: start;
  min-width: 120rem;
`;

const TableRow = styled.div`
  display: grid;
  margin: 1.5rem 0;
  padding-left: 2.5rem;
  grid-template-columns: 1.5fr 1.5fr repeat(5, 1fr) 0.8fr;
  gap: 1.2rem;
  background-color: white;
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;

  & svg {
    cursor: pointer;
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
  min-width: 9.6rem;
`;

const Title = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  color: var(--color-grey-500);
  cursor: pointer;
  align-items: center;

  :last-child {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--color-grey-900);
    text-decoration: underline;
    text-transform: capitalize;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 2rem 0;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const NoDataDiv = styled.div`
  margin-top: 2rem;
`;

export const ItemActions = styled.div<{
  disabled?: boolean;
  $isPinDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  gap: 1.5rem;
  & svg {
    height: 1.6rem;
    width: 1.6rem;

    &:not(:last-child) {
      width: 2rem;
      height: 2rem;
    }

    &:first-child {
      pointer-events: ${(props) => (props.$isPinDisabled ? "none" : "")};
      opacity: ${(props) => (props.$isPinDisabled ? "0.5" : "1")};
      cursor: ${(props) => (props.$isPinDisabled ? "not-allowed" : "")};
    }

    &:last-child {
      pointer-events: ${(props) => (props.disabled ? "none" : "")};
      opacity: ${(props) => (props.disabled ? "0.5" : "1")};
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
  }
  padding-right: 1.5rem;
`;

const initialMilestoneState: MilestoneFormValues = {
  end_year: "",
  percentage_of_project: 0,
  responsible_person_id: 0,
  start_year: "",
  title: "",
  description: "",
  sequence: "",
  service_id: 0,
  status_id: 0,
  id: 0,
  est_end_date: "",
  est_start_date: "",
};

/**
 * @returns Milestone overview with a table of all milestones
 */
const AllMilestones = () => {
  // state
  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false);
  const [details, setDetails] = useState<MilestoneFormValues>(
    initialMilestoneState,
  );
  const [milestoneProjectId, setMilestoneProjectId] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "isPinned", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const navigate = useNavigate();

  // API data
  const { pinItem, isLoading: isPinning } = useSavePinItem();
  const { removePinItem, isLoading: isRemovingPin } = useRemovePinItem();
  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || "",
  );
  const pinnedMilestoneIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.MILESTONE)
        return { value: item.value, id: item.id };
    }) || [];

  const {
    isMilestonesLoading,
    milestones,
    error: milestoneError,
  } = useGetAllMilestones(pinnedMilestoneIds);
  const { isLoading: isProjectsLoading, projects, error } = useProjects();

  const { isUserRoleLoading, userRoles } = useGetUserRoles(user?.id || "");

  const userRole =
    userRoles?.find((role) => {
      return !role.project_id;
    })?.role?.title || "";

  const { canUserDelete, canUserEdit } = useMemo(
    () => getUserPermissions(userRole),
    [userRole],
  );

  const { setCurrentModalName } = useActiveModalContext();
  const { isDeleting, deleteProjectMilestone } = useDeleteProjectMilestone();
  const { setDayModalName } = useDayModalContext();

  const handleDelete = (milestoneId: string) => {
    deleteProjectMilestone(
      { milestoneId: +milestoneId },
      {
        onSuccess: () =>
          toast.success("Project milestone removed successfully."),
      },
    );
  };

  // milestones table setup
  const columnHelper = createColumnHelper<Milestone>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("project_id", {
        header: "project_id",
        id: "project_id",
      }),
      columnHelper.accessor("isPinned", {
        header: "isPinned",
        id: "isPinned",
      }),
      columnHelper.accessor("pinId", {
        header: "pinId",
        id: "pinId",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor(
        (row) =>
          projects?.find((project) => project.id === row.project_id)?.title,
        {
          header: "Project",
          id: "project",
        },
      ),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("start_year", {
        header: "Start Date",
        id: "start_year",
      }),
      columnHelper.accessor("end_year", {
        header: "End Date",
        id: "end_year",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        },
      ),
      columnHelper.accessor("percentage_of_project", {
        header: "% of Project",
        id: "percentage_of_project",
      }),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_completed",
      }),
      columnHelper.accessor("status.title", {
        header: "Progress Status",
        id: "status",
      }),
      columnHelper.accessor((row) => row.est_start_date, {
        header: "Estimated Start Year",
        id: "est_start_date",
      }),
      columnHelper.accessor((row) => row.est_end_date, {
        header: "Estimated End Year",
        id: "est_end_date",
      }),
      columnHelper.accessor("status_id", {
        id: "status_id",
      }),
      columnHelper.accessor("service_id", {
        id: "service_id",
      }),
      columnHelper.accessor("responsible_person_id", {
        id: "responsible_person_id",
      }),
      columnHelper.accessor("wbs_id", {
        id: "wbs_id",
      }),
      columnHelper.accessor("updated", {
        id: "updated",
        sortingFn: (a, b) => {
          const diff = moment(a.original.updated).diff(
            moment(b.original.updated),
          );
          return diff > 0 ? 1 : diff < 0 ? -1 : 0;
        },
      }),
    ],
    [columnHelper, projects],
  );

  // React table config
  const table = useReactTable({
    data: milestones ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        isPinned: false,
        pinId: false,
        project_id: false,
        status_id: false,
        service_id: false,
        responsible_person_id: false,
        est_start_date: false,
        est_end_date: false,
        description: false,
        percentage_of_project: false,
        wbs_id: false,
        updated: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  const handleSelect = (id: string) => {
    const sortOrder = DEFAULT_SORT_OPTIONS.find((item) => item.id === id);
    const startYearColumn = table.getColumn("start_year");
    const endYearColumn = table.getColumn("end_year");
    const updateColumn = table.getColumn("updated");

    if (sortOrder?.name === SORT_OPTIONS.RECENT) {
      updateColumn?.toggleSorting(true);
      startYearColumn?.clearSorting();
      endYearColumn?.clearSorting();
    } else if (sortOrder?.name === SORT_OPTIONS.PAST) {
      startYearColumn?.toggleSorting(true);
      endYearColumn?.clearSorting();
    } else {
      startYearColumn?.clearSorting();
      endYearColumn?.toggleSorting(true);
    }
  };

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number,
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        },
      );
    }
    if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        },
      );
    }
  };

  if (error || milestoneError)
    throw new Error("Unable to fetch data, please retry.");

  if (
    isMilestonesLoading ||
    isPinnedItemsLoading ||
    isDeleting ||
    isProjectsLoading ||
    isUserRoleLoading
  )
    return <Spinner />;

  return (
    <>
      <ProjectHeader>
        <h1>
          Milestones
          <HeaderSpan>{` [${milestones?.length || 0}]`}</HeaderSpan>
        </h1>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          {canUserEdit && (
            <ButtonCTA
              $buttonType={BUTTON_TYPES.SECONDARY}
              onClick={() => {
                setIsMilestoneOpen(true);
                setCurrentModalName(MODAL_NAMES.MILESTONES);
              }}
            >
              new milestone
              <Plus />
            </ButtonCTA>
          )}
        </SideContainer>
      </ProjectHeader>
      <Modal
        isOpen={isMilestoneOpen}
        title={MODAL_NAMES.MILESTONES}
        type={MODAL_TYPE.NEW}
        wrapperId={MODAL_NAMES.MILESTONES}
      >
        <MilestoneForm
          projectId=""
          handleClose={() => {
            setCurrentModalName("");
            setIsMilestoneOpen(false);
          }}
          isEditingMode={false}
          showProjectSelector
          canUserEdit={canUserEdit}
        />
      </Modal>
      <SearchFilter>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
        </FlexContainer>
        <FlexContainer>
          <Dropdown
            id="milestones"
            title="Recent"
            data={DEFAULT_SORT_OPTIONS}
            onSelect={handleSelect}
            selectedId="1"
            search={false}
          />
          <Icons>
            <Filter />
          </Icons>
          <Icons>
            <Upload />
          </Icons>
          <Icons>
            <More height={20} width={20} />
          </Icons>
        </FlexContainer>
      </SearchFilter>
      <TableHeader id="milestones-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          )),
        )}
        <ItemActions>
          <Info
            onMouseEnter={() => setIsInfoVisible(true)}
            onMouseLeave={() => setIsInfoVisible(false)}
            style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
          />
          {isInfoVisible && (
            <InfoTooltip>
              <span>Click on a header to sort the table by that column.</span>
            </InfoTooltip>
          )}
          <DeleteCon style={{ visibility: "hidden" }} />
          <DeleteCon style={{ visibility: "hidden" }} />
        </ItemActions>
      </TableHeader>
      {milestones?.length ? (
        <>
          <TableRowsContainer>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} id={`${row.id}-milestones`}>
                <Title
                  onClick={() =>
                    navigate(
                      `/projects/details/${row.getValue("project_id")}?activeToggle=Milestones%2FTasks&expand=milestones`,
                    )
                  }
                >
                  <RAGIndicator
                    $color={getRAGStatus(
                      row.getValue("est_start_date"),
                      row.getValue("est_end_date"),
                      row.getValue("start_year"),
                      row.getValue("end_year"),
                      row.getValue("status"),
                    )}
                  />
                  <span>{row.getValue("title")}</span>
                </Title>
                <span>{row.getValue("project")}</span>
                {/* <span>
                  {(row.getValue("description") as string)?.length > 100
                    ? `${(row.getValue("description") as string).substring(0, 100)}...`
                    : row.getValue("description") || "N/A"}
                </span> */}
                <span>
                  {row.getValue("start_year")
                    ? moment(row.getValue("start_year")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : "N/A"}
                </span>
                <span>
                  {row.getValue("end_year")
                    ? moment(row.getValue("end_year")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : "N/A"}
                </span>
                <span>{row.getValue("responsible")}</span>
                <span style={{ paddingLeft: "1.5rem" }}>
                  {Number(row.getValue("percentage_completed") || 0).toFixed(2)}
                </span>
                <Pill
                  $progressStatus={
                    row.getValue("status") as keyof typeof SCHEDULE_MAP
                  }
                >
                  <span>{row.getValue("status")}</span>
                </Pill>
                {/* <ContextMenu
                  listItems={getMilestoneContextMenu(
                    row.getValue("id"),
                    row.getValue("isPinned"),
                    row.getValue("pinId"),
                    handlePinning,
                    row.getValue("project_id"),
                  )}
                  id="milestones-table-menu"
                  top={() =>
                    getOffset("milestones-table-header", `${row.id}-milestones`)
                  }
                /> */}
                <ItemActions
                  disabled={!canUserDelete}
                  $isPinDisabled={isPinning || isRemovingPin}
                >
                  {row.getValue("isPinned") ? (
                    <PinFilled
                      onClick={() =>
                        handlePinning(
                          PINNED_ITEM_TYPES.MILESTONE,
                          `${row.getValue("id")}`,
                          false,
                          row.getValue("pinId"),
                        )
                      }
                    />
                  ) : (
                    <PushPin
                      onClick={() =>
                        handlePinning(
                          PINNED_ITEM_TYPES.MILESTONE,
                          `${row.getValue("id")}`,
                          true,
                        )
                      }
                    />
                  )}
                  <Eye
                    onClick={() => {
                      setDetails({
                        title: row.getValue("title"),
                        description: row.getValue("description"),
                        status_id: row.getValue("status_id"),
                        start_year: row.getValue("start_year")
                          ? moment(row.getValue("start_year")).format(
                              DAY_FORMATS.YEAR_FIRST,
                            )
                          : "",
                        end_year: row.getValue("end_year")
                          ? moment(row.getValue("end_year")).format(
                              DAY_FORMATS.YEAR_FIRST,
                            )
                          : "",
                        est_start_date: row.getValue("est_start_date")
                          ? moment(row.getValue("est_start_date")).format(
                              DAY_FORMATS.YEAR_FIRST,
                            )
                          : "",
                        est_end_date: row.getValue("est_end_date")
                          ? moment(row.getValue("est_end_date")).format(
                              DAY_FORMATS.YEAR_FIRST,
                            )
                          : "",
                        id: row.getValue("id"),
                        sequence: "sequence",
                        service_id: row.getValue("service_id"),
                        percentage_of_project: row.getValue(
                          "percentage_of_project",
                        ),
                        responsible_person_id: row.getValue(
                          "responsible_person_id",
                        ),
                        wbs_id: row.getValue("wbs_id"),
                      });
                      setCurrentModalName(`Edit ${MODAL_NAMES.MILESTONES}`);
                      setIsMilestoneOpen(true);
                      setMilestoneProjectId(row.getValue("project_id"));
                    }}
                  />
                  <DeleteCon
                    onClick={() =>
                      setDayModalName(
                        `${DATE_MODAL_NAMES.DELETE}-${row.getValue("id")}`,
                      )
                    }
                  />
                  <DeletePopup
                    wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("id")}`}
                    onDelete={() => handleDelete(row.getValue("id"))}
                  />
                </ItemActions>
              </TableRow>
            ))}
            <Modal
              isOpen={isMilestoneOpen}
              title={MODAL_NAMES.MILESTONES}
              wrapperId={`Edit ${MODAL_NAMES.MILESTONES}`}
            >
              <MilestoneForm
                handleClose={() => {
                  setCurrentModalName("");
                  setIsMilestoneOpen(false);
                }}
                projectId={milestoneProjectId.toString()}
                details={details}
                isEditingMode
                canUserEdit={canUserEdit}
              />
            </Modal>
          </TableRowsContainer>

          <Pagination>
            <ItemCount>
              <span style={{ fontSize: "1.2rem" }}>Show:</span>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 10}
                onClick={() => table.setPageSize(10)}
              >
                10
              </NumberSpan>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 20}
                onClick={() => table.setPageSize(20)}
              >
                20
              </NumberSpan>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 50}
                onClick={() => table.setPageSize(50)}
              >
                50
              </NumberSpan>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 100}
                onClick={() => table.setPageSize(100)}
              >
                100
              </NumberSpan>
            </ItemCount>
            <PageNumbers table={table} />
          </Pagination>
        </>
      ) : (
        <NoDataDiv>
          <NoDataCard height="8rem" title="Milestones" />
        </NoDataDiv>
      )}
    </>
  );
};

// const getMilestoneContextMenu = (
//   milestoneId: number,
//   isPinned: boolean,
//   pinId?: number,
//   pinningHandler?: PinHandler,
//   project_id?: number,
// ) => {
//   return [
//     {
//       title: isPinned ? "Unpin" : "Pin",
//       to: "",
//       imageComponent: () => (isPinned ? PushPinSlash : PushPin),
//       pinningHandler,
//       pinningDetails: {
//         type: PINNED_ITEM_TYPES.MILESTONE,
//         value: milestoneId,
//         savePin: !isPinned,
//         pinId,
//       },
//     },
//     {
//       title: "Milestone Overview",
//       to: `/projects/details/${project_id}?activeToggle=Milestones%2FTasks&expand=milestones`,
//       imageComponent: () => Overview,
//     },
//     {
//       title: "View on WorxOnline",
//       to: ``,
//       imageComponent: () => WO,
//     },
//     {
//       title: "Delete",
//       to: `/milestones/${milestoneId}`,
//       imageComponent: () => Trash,
//     },
//   ];
// };

export default AllMilestones;
