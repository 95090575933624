import { DropdownType, UpsertDropdownType } from "../types/dropdownTypes";
import { ProjectTypesType, UpsertServiceType } from "../types/statusTypes";
import { client } from "./AxiosClient";

export const getAllDropdownsAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_DROPDOWN_ITEMS, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as DropdownType[];
};

export const getProjectTypeDropdown = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_PROJECT_TYPE, {
    params: {
      skip,
      limit,
    },
  });
  return response.data as ProjectTypesType[];
};

export const updateDropdownItemsAPI = async (data: DropdownType[]) => {
  const response = await client.put(
    import.meta.env.VITE_DROPDOWN_ITEMS + "bulkupdate/",
    { data },
  );
  return response.data as string;
};

export const createDropdownBulkAPI = async (data: UpsertDropdownType[]) => {
  const response = await client.post(
    import.meta.env.VITE_DROPDOWN_ITEMS + "bulkcreate/",
    data,
  );
  return response.data as string;
};

export const deleteBulkDropdownAPI = async (data: number[]) => {
  const response = await client.delete(
    import.meta.env.VITE_DROPDOWN_ITEMS + "bulkdelete/",
    { data },
  );
  return response.data as string;
};

export const createBulkProjectTypeAPI = async (data: UpsertServiceType[]) => {
  const response = await client.post(
    import.meta.env.VITE_PROJECT_TYPE + "bulkcreate/",
    data,
  );
  return response.data as string;
};

export const deleteBulkProjectTypeAPI = async (data: number[]) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_TYPE + "bulkdelete/",
    { data },
  );
  return response.data as string;
};
