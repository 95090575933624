import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";
import {
  useCreateProjectConsultation,
  useEditProjectConsultation,
} from "../../hooks/projects/useProjectConsultation";
import { ConsultationFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";

import moment from "moment";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import DayPickerModal from "../../ui/DayPickerModal";
import { getEndMonth, getStartMonth } from "../../utils/common";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";

/**
 * ConsultationForm component for creating and editing consultation details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag indicating if the form is in editing mode.
 * @param {ConsultationFormValues} [props.details] - The details of the consultation (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the form (optional).
 * @returns {JSX.Element} The rendered component.
 */
const ConsultationForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: ConsultationFormValues;
  canUserEdit?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.details?.length > 60,
  );
  const { setDayModalName } = useDayModalContext();
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setFocus,
    control,
    getValues,
    resetField,
    setValue,
  } = useForm<ConsultationFormValues>({
    defaultValues: {
      title: details?.title || "",
      who: details?.who || "",
      when: details?.when || "",
      details: details?.details || "",
      communication_method: details?.communication_method || "",
      message: details?.message || "",
    },
  });

  useEffect(() => {
    if (!isEditingMode) setFocus("title");
  }, [setFocus, isEditingMode]);

  const { isCreating, createProjectConsultation } =
    useCreateProjectConsultation();
  const { isEditing, editProjectConsultation } = useEditProjectConsultation();

  const onSubmit: SubmitHandler<ConsultationFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectConsultation(
        {
          consultationDetails: {
            ...data,
            when: moment(data.when).format(DAY_FORMATS.YEAR_FIRST),
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project consultation details saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectConsultation(
        {
          consultationDetails: {
            ...data,
            when: moment(data.when).format(DAY_FORMATS.YEAR_FIRST),
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project consultation details saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };

  if (isCreating || isEditing) return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Title *</label>
              <StyledInput
                type="text"
                {...register("title", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                autoComplete="off"
                warning={errors?.title?.message}
                readOnly={isViewingMode}
              />
              {errors?.title && (
                <ErrorMessage errorMessage={errors.title.message} />
              )}
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Target Audience *</label>
              <StyledInput
                type="text"
                {...register("who", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                warning={errors?.who?.message}
                readOnly={isViewingMode}
              />
              {errors?.who && (
                <ErrorMessage errorMessage={errors.who.message} />
              )}
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <PositionedInput>
              <label>By When *</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("when")
                    ? moment(getValues("when")).format("DD-MM-YYYY")
                    : details?.when
                      ? moment(details?.when).format("DD-MM-YYYY")
                      : ""
                }
                onChange={() => {}}
                warning={errors?.when?.message}
                readOnly={isViewingMode}
              />
              {errors?.when && (
                <ErrorMessage errorMessage={errors.when.message} />
              )}
              {getValues("when") || details?.when
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => resetField("when", { defaultValue: "" })}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.START)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="when"
                rules={{ required: "This field is required" }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().when)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "when",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      startMonth={getStartMonth(details?.when)}
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().when
                          ? new Date(getValues().when)
                          : new Date()
                      }
                      disabled={{ before: new Date() }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("details", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.details?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.details && (
                <ErrorMessage errorMessage={errors.details.message} />
              )}
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Methods of Communication</label>
              <StyledInput
                type="text"
                {...register("communication_method")}
                autoComplete="off"
                warning={errors?.communication_method?.message}
                readOnly={isViewingMode}
              />
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Link</label>
              <StyledInput
                type="text"
                {...register("message")}
                autoComplete="off"
                warning={errors?.message?.message}
                readOnly={isViewingMode}
              />
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default ConsultationForm;
