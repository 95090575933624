import { useQuery } from "@tanstack/react-query";
import { getUserApi } from "../../services/apiAuth";
import { QUERY_KEYS } from "../../common/Constants";
import { getAllUsersAPI } from "../../services/apiContacts";

/**
 * @returns the currently logged in user's details
 */
export const useGetCurrentUser = () => {
  const {
    isPending: isLoading,
    data: user,
    error,
  } = useQuery({
    queryFn: () => getUserApi(),
    queryKey: [QUERY_KEYS.USER],
  });

  return { isLoading, user, error };
};

export const useGetAllUsers = () => {
  const {
    isPending: isLoading,
    data: users,
    error,
  } = useQuery({
    queryFn: () => getAllUsersAPI(),
    queryKey: [QUERY_KEYS.ALL_USERS],
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
  });

  return { isLoading, users, error };
};
