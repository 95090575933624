import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import DeleteCon from "../assets/DeleteCon.svg?react";
import Eye from "../assets/Eye.svg?react";
import Rearrange from "../assets/Rearrange.svg?react";
import Warning from "../assets/Warning.svg?react";

import { DATE_MODAL_NAMES } from "../common/Constants";
import { useDayModalContext } from "../context/DayPickerModalContext";
import {
  ProjectDetailsType,
  ProjectScope,
  ProjectSuccessMeasure,
} from "../types/projectTypes";
import DeletePopup from "./DeletePopup";

export const StyledSection = styled.section`
  border-radius: var(--border-radius-xl);
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  margin-bottom: 1rem;
`;

export const SectionPart = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  border-bottom: 3px dashed var(--color-grey-200);
`;

export const StyledTableSection = styled(StyledSection)`
  gap: 0;
  /* padding: 1.5rem 2rem 0; */
  background-color: white;
  min-width: 100rem;
`;

export const StyledTableSectionPart = styled(SectionPart)`
  gap: 0;
  margin-bottom: 2rem;
  border: none;
`;

export const SectionTitle = styled.div<{ $isActive?: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 2rem;
  cursor: pointer;
  & svg:last-child {
    transform: ${(props) => (props.$isActive ? "rotate(180deg)" : "")};
  }
`;

export const LabelAndInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & label {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;

    & span {
      font-size: 1.4rem;
      font-style: italic;
      font-weight: 500;
    }
  }
`;

export const StyledInput = styled.input<{ warning?: string }>`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  height: 4.8rem;
  font-weight: 400;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

export const StyledCheckbox = styled.input`
  height: 4rem;
  width: 2rem;
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  &:checked {
    accent-color: var(--color-button-secondary);
  }
  cursor: pointer;
`;

export const StyledCheckboxLabel = styled(LabelAndInput)`
  flex-direction: row;
  height: 4.8rem;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
`;

export const StyledThreeFieldSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

export const StyledTwoFieldSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`;

export const StyledSingleFieldSection = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
`;

export const StyledFullWidthInput = styled.textarea<{
  height?: string;
  warning?: string;
}>`
  width: 100%;
  height: ${(props) => props.height || "4.8rem"};
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  resize: none;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
  &:read-only {
    border: 2px solid var(--color-grey-100) !important;
    background-color: unset;
    pointer-events: none;
  }
`;

export const ExpandableDiv = styled.div<{ height?: string }>`
  position: relative;
  grid-column: 1/-1;
  border-radius: var(--border-radius-2l);
  height: ${(props) => props.height || "4.8rem"};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const PositionedSvg = styled.div`
  cursor: pointer;
  padding: 1rem;
  background-color: var(--color-grey-100);
  border-radius: 50%;
  height: 4.8rem;
  width: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PositionedInput = styled(LabelAndInput)`
  position: relative;
`;

export const PositionedSVG = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 2rem;
  z-index: 10;
  cursor: pointer;
`;

export const PositionedCalendar = styled(PositionedSVG)`
  top: 3.8rem;
`;

export const PositionedPlaceholder = styled(PositionedSVG)`
  right: 5rem;
  top: 4rem;
  cursor: default;
`;

export const SecondaryPositionSvg = styled(PositionedSVG)`
  top: 25%;
`;

export const AllSortableItems = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  & span:first-child {
    font-size: 1.2rem;
    font-weight: 700;
  }
  touch-action: none;
`;

export const SortableItemsList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 400;
  overflow-y: auto;
`;

export const SortableItem = styled.div<{ disabled?: boolean }>`
  display: grid;
  margin-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 2rem;
  grid-template-columns: 1fr 5fr 0.3fr;
  background-color: white;
  border: 2px dashed var(--color-grey-400);
  height: 8rem;
  gap: 2rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  width: 100%;
  & svg {
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
  }
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
`;

const ScopeSortableItem = styled(SortableItem)`
  grid-template-columns: 0.5fr 1.2fr 2fr 3fr 0.3fr;
`;

const SortableSuccessItem = styled(SortableItem)`
  grid-template-columns: 1fr 2fr 3fr 0.3fr;
`;

export const SortActions = styled.div<{
  disabled: boolean;
  $deleteDisabled?: boolean;
}>`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 2rem;
  & svg:first-child {
    height: 2rem;
    width: 2rem;
  }
  & svg:last-child {
    pointer-events: ${(props) => (props.disabled ? "none" : "")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
  }
  & svg:nth-child(2) {
    pointer-events: ${(props) => (props.$deleteDisabled ? "none" : "")};
    opacity: ${(props) => (props.$deleteDisabled ? "0.5" : "1")};
    cursor: ${(props) => (props.$deleteDisabled ? "not-allowed" : "")};
  }
`;

export const AddItemP = styled.p`
  font-size: 1.4rem;
  color: var(--color-button-secondary);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin-top: 1rem;
`;

export const StyledErrorMessage = styled.p`
  color: var(--color-button-secondary);
  font-size: 1.2rem;
  width: 100%;
`;

export const ClearMessage = styled(StyledErrorMessage)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
  width: fit-content;
`;

export const ErrorMessage = ({
  errorMessage,
}: {
  errorMessage: string | undefined;
}) => {
  return (
    <WarningMessage>
      <Warning />
      {errorMessage}
    </WarningMessage>
  );
};

export const SortableListRenderer = ({
  item,
  index,
  length,
  detailName,
  handleEdit,
  handleDelete,
  canUserDelete,
  canUserEdit,
}: {
  item: ProjectDetailsType;
  index: number;
  length: number;
  detailName: string;
  handleEdit: (
    description: string,
    detailId: number,
    sequence: number,
    detailName: string,
  ) => void;
  handleDelete: (detailId: number) => void;
  canUserDelete?: boolean;
  canUserEdit?: boolean;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: `${length === 1 ? "not-allowed" : "grab"}`,
  };

  const { setDayModalName } = useDayModalContext();

  return (
    <AllSortableItems key={item.id} style={style} ref={setNodeRef}>
      <SortableItem>
        <span>{index + 1}</span>
        <span>
          {item.description.length > 100
            ? `${item.description.substring(0, 100)}...`
            : item.description}
        </span>
        <SortActions
          disabled={length === 1 || !canUserEdit}
          $deleteDisabled={!canUserDelete}
        >
          <Eye
            onClick={() =>
              handleEdit(item.description, item.id, item.sequence, detailName)
            }
          />
          <DeleteCon
            onClick={() =>
              setDayModalName(`${DATE_MODAL_NAMES.DELETE}-${item.id}`)
            }
          />
          <DeletePopup
            wrapperId={`${DATE_MODAL_NAMES.DELETE}-${item.id}`}
            onDelete={() => handleDelete(item.id)}
          />
          <Rearrange style={svgStyle} {...attributes} {...listeners} />
        </SortActions>
      </SortableItem>
    </AllSortableItems>
  );
};

export const SortableScopeListRenderer = ({
  item,
  index,
  length,
  handleEdit,
  handleDelete,
  canUserDelete,
  canUserEdit,
}: {
  item: ProjectScope;
  index: number;
  length: number;
  handleEdit: (details: ProjectScope) => void;
  handleDelete: (detailId: number) => void;
  canUserDelete?: boolean;
  canUserEdit?: boolean;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: `${length === 1 ? "not-allowed" : "grab"}`,
  };

  const { setDayModalName } = useDayModalContext();

  return (
    <AllSortableItems key={item.id} style={style} ref={setNodeRef}>
      <ScopeSortableItem>
        <span>{index + 1}</span>
        <span>{item.in_scope ? "Yes" : "No"}</span>
        <span>{item.title}</span>
        <span>{item.description}</span>
        <SortActions
          disabled={length === 1 || !canUserEdit}
          $deleteDisabled={!canUserDelete}
        >
          <Eye onClick={() => handleEdit(item)} />
          <DeleteCon
            onClick={() =>
              setDayModalName(`${DATE_MODAL_NAMES.DELETE}-${item.id}`)
            }
          />
          <DeletePopup
            wrapperId={`${DATE_MODAL_NAMES.DELETE}-${item.id}`}
            onDelete={() => handleDelete(item.id)}
          />
          <Rearrange style={svgStyle} {...attributes} {...listeners} />
        </SortActions>
      </ScopeSortableItem>
    </AllSortableItems>
  );
};

export const SortableSuccessListRenderer = ({
  item,
  index,
  length,
  handleEdit,
  handleDelete,
  canUserDelete,
  canUserEdit,
}: {
  item: ProjectSuccessMeasure;
  index: number;
  length: number;
  handleEdit: (details: ProjectSuccessMeasure) => void;
  handleDelete: (detailId: number) => void;
  canUserDelete?: boolean;
  canUserEdit?: boolean;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id: item.id,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const svgStyle = {
    cursor: `${length === 1 ? "not-allowed" : "grab"}`,
  };

  const { setDayModalName } = useDayModalContext();

  return (
    <AllSortableItems key={item.id} style={style} ref={setNodeRef}>
      <SortableSuccessItem>
        <span>{index + 1}</span>
        <span>{item.description}</span>
        <span>{item.source_of_benefit}</span>
        <SortActions
          disabled={length === 1 || !canUserEdit}
          $deleteDisabled={!canUserDelete}
        >
          <Eye onClick={() => handleEdit(item)} />
          <DeleteCon
            onClick={() =>
              setDayModalName(`${DATE_MODAL_NAMES.DELETE}-${item.id}`)
            }
          />
          <DeletePopup
            wrapperId={`${DATE_MODAL_NAMES.DELETE}-${item.id}`}
            onDelete={() => handleDelete(item.id)}
          />
          <Rearrange style={svgStyle} {...attributes} {...listeners} />
        </SortActions>
      </SortableSuccessItem>
    </AllSortableItems>
  );
};

export const BottomNav = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  padding: 2rem 2rem 4rem;
`;

export const WarningMessage = styled.div`
  display: flex;
  gap: 0.5rem;
  color: var(--color-warning);
  font-size: 1.2rem;
`;
