import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
  PHONE_REGEX,
} from "../../common/Constants";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetTenant, useUpdateTenant } from "../../hooks/auth/useTenant";
import { TenantUpdateType } from "../../types/authTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import {
  BottomNav,
  ErrorMessage,
  LabelAndInput,
  StyledCheckboxLabel,
  StyledSection,
  StyledThreeFieldSection,
  StyledTwoFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import { useDayModalContext } from "../../context/DayPickerModalContext";

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: fit-content;
`;

const StyledInput = styled.input<{ warning?: string }>`
  background-color: var(--color-grey-100);
  ::placeholder {
    color: var(-color-placeholder-grey);
  }
  height: 4.8rem;
  min-width: 30rem;
  border: transparent;
  border-radius: var(--border-radius-2l);
  padding: 0 1rem;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  & p {
    font-size: 1.2rem;
    align-self: center;
  }
`;

const SignUpFormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

export const StyledCheckbox = styled.input`
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  &:checked {
    accent-color: var(--color-grey-900);
  }
`;

/**
 * OrganizationForm component for updating organization details.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.autoRedirect - Flag indicating if the form should auto-redirect after submission.
 * @param {Function} [props.handleClose] - Function to handle closing the form (optional).
 * @returns {JSX.Element} The rendered component.
 */
const OrganizationForm = ({
  autoRedirect,
  handleClose,
}: {
  autoRedirect: boolean;
  handleClose?: () => void;
}) => {
  const { isLoading, user } = useGetCurrentUser();

  const domain = user?.email.split("@")[1] || "";
  const { isLoading: isTenantLoading, tenant } = useGetTenant(domain);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors: errorsSignUp, isDirty },
  } = useForm<TenantUpdateType>({
    defaultValues: {
      title: tenant?.title || "",
      registration_id: tenant?.registration_id || "",
      phone_number: tenant?.phone_number || "",
      physical_address: tenant?.physical_address || "",
      postal_address: tenant?.postal_address || "",
    },
  });

  const navigate = useNavigate();

  const [isSameAsPhysical, setIsSameAsPhysical] = useState(false);
  const { setDayModalName } = useDayModalContext();

  useEffect(() => {
    if (isSameAsPhysical) {
      setValue("postal_address", getValues().physical_address);
    }
  }, [isSameAsPhysical, setValue, getValues]);

  const { isUpdating, updateTenant } = useUpdateTenant();

  const onSubmit: SubmitHandler<TenantUpdateType> = (data) => {
    updateTenant(
      {
        ...data,
        azure_container_name: tenant?.azure_container_name || "",
        code: tenant?.code || "",
        config: tenant?.config
          ? { ...tenant.config, is_org_updated: true }
          : { is_org_updated: true },
        is_active: !!tenant?.is_active,
        postal_address: isSameAsPhysical
          ? data.physical_address
          : data.postal_address,
        id: tenant?.id || "",
      },
      {
        onSuccess: () => {
          if (autoRedirect) {
            toast.success(
              "Organization details updated successfully, redirecting you to the home page.",
            );
            navigate("/home");
          } else {
            toast.success("Organization details updated successfully.");
            handleClose?.();
          }
        },
      },
    );
  };

  if (isLoading || isTenantLoading || isUpdating)
    return (
      <FormContainer>
        <Spinner />
      </FormContainer>
    );

  return (
    <>
      {autoRedirect ? (
        <FormContainer>
          <h1>Organization Details</h1>
          <SignUpForm onSubmit={handleSubmit(onSubmit)}>
            <SignUpFormLayout>
              <LabelAndInput>
                <label>Organization Name *</label>
                <StyledInput
                  type="text"
                  {...register("title", { required: "This field is required" })}
                  defaultValue={tenant?.title}
                  warning={errorsSignUp?.title?.message}
                />
                {errorsSignUp.title && (
                  <ErrorMessage errorMessage={errorsSignUp.title.message} />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>ABN/ACN or Trading number *</label>
                <StyledInput
                  type="text"
                  {...register("registration_id", {
                    required: "This field is required",
                  })}
                  defaultValue={tenant?.registration_id}
                  warning={errorsSignUp?.registration_id?.message}
                />
                {errorsSignUp.registration_id && (
                  <ErrorMessage
                    errorMessage={errorsSignUp.registration_id.message}
                  />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Phone *</label>
                <StyledInput
                  type="text"
                  {...register("phone_number", {
                    required: "This field is required",
                    pattern: {
                      value: PHONE_REGEX,
                      message: "Please enter a valid phone number",
                    },
                  })}
                  defaultValue={tenant?.phone_number}
                  warning={errorsSignUp?.phone_number?.message}
                />
                {errorsSignUp.phone_number && (
                  <ErrorMessage
                    errorMessage={errorsSignUp.phone_number.message}
                  />
                )}
              </LabelAndInput>
              <LabelAndInput style={{ gridColumn: "span 2" }}>
                <label>Physical Address</label>
                <StyledInput
                  type="text"
                  {...register("physical_address")}
                  defaultValue={tenant?.physical_address}
                  warning={errorsSignUp?.physical_address?.message}
                />
              </LabelAndInput>
              <LabelAndInput style={{ gridColumn: "span 2" }}>
                <label>Postal Address</label>
                <StyledInput
                  type="text"
                  {...register("postal_address")}
                  readOnly={isSameAsPhysical}
                  defaultValue={tenant?.postal_address}
                  warning={errorsSignUp?.postal_address?.message}
                />
                <StyledCheckboxLabel>
                  <StyledCheckbox
                    type="checkbox"
                    defaultChecked={isSameAsPhysical}
                    onChange={() => setIsSameAsPhysical((prev) => !prev)}
                  />
                  <label
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                  >
                    Select if same as <strong>physical address.</strong>
                  </label>
                </StyledCheckboxLabel>
              </LabelAndInput>
            </SignUpFormLayout>
            <ButtonCTA
              $buttonType={BUTTON_TYPES.SECONDARY}
              $buttonSize={BUTTON_SIZES.MEDIUM_LARGE}
            >
              Update
            </ButtonCTA>
          </SignUpForm>
        </FormContainer>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledSection>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Organization Name *</label>
                <StyledInput
                  type="text"
                  {...register("title", { required: "This field is required" })}
                  warning={errorsSignUp?.title?.message}
                />
                {errorsSignUp.title && (
                  <ErrorMessage errorMessage={errorsSignUp.title.message} />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>ABN/ACN or Trading number *</label>
                <StyledInput
                  type="text"
                  {...register("registration_id", {
                    required: "This field is required",
                  })}
                  warning={errorsSignUp?.registration_id?.message}
                />
                {errorsSignUp.registration_id && (
                  <ErrorMessage
                    errorMessage={errorsSignUp.registration_id.message}
                  />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Phone *</label>
                <StyledInput
                  type="text"
                  {...register("phone_number", {
                    required: "This field is required",
                    pattern: {
                      value: PHONE_REGEX,
                      message: "Please enter a valid phone number",
                    },
                  })}
                  warning={errorsSignUp?.phone_number?.message}
                />
                {errorsSignUp.phone_number && (
                  <ErrorMessage
                    errorMessage={errorsSignUp.phone_number.message}
                  />
                )}
              </LabelAndInput>
            </StyledThreeFieldSection>
            <StyledTwoFieldSection>
              <LabelAndInput style={{ gridColumn: "span 2" }}>
                <label>Physical Address</label>
                <StyledInput
                  type="text"
                  {...register("physical_address")}
                  warning={errorsSignUp?.physical_address?.message}
                />
              </LabelAndInput>
              <LabelAndInput style={{ gridColumn: "span 2" }}>
                <label>Postal Address</label>
                <StyledInput
                  type="text"
                  {...register("postal_address")}
                  readOnly={isSameAsPhysical}
                  warning={errorsSignUp?.postal_address?.message}
                />
                <StyledCheckboxLabel>
                  <StyledCheckbox
                    type="checkbox"
                    defaultChecked={isSameAsPhysical}
                    onChange={() => setIsSameAsPhysical((prev) => !prev)}
                  />
                  <label
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                  >
                    Select if same as <strong>physical address.</strong>
                  </label>
                </StyledCheckboxLabel>
              </LabelAndInput>
            </StyledTwoFieldSection>
          </StyledSection>
          <BottomNav>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={() => {
                if (isDirty) {
                  setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
                } else {
                  handleClose?.();
                }
              }}
            >
              Cancel
              <Discard />
            </ButtonCTA>
            <ButtonCTA
              $buttonType={BUTTON_TYPES.SECONDARY}
              $buttonSize={BUTTON_SIZES.SMALL}
            >
              Save
              <CheckMark />
            </ButtonCTA>
          </BottomNav>
        </form>
      )}
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={() => handleClose?.()}
      />
    </>
  );
};

export default OrganizationForm;
