import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import styled from "styled-components";
import DownArrow from "../assets/ChevronDown.svg?react";

interface DropdownItem {
  id: string;
  name: string;
  imageUrl?: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  data: DropdownItem[];
  selectedId: string;
  onSelect: (id: string, name?: string) => void;
  bgColor?: string;
  search?: boolean;
  showOnTop?: boolean;
  isViewingMode?: boolean;
}

const StyledDrop = styled.div`
  position: relative;
  margin-left: auto;
  font-size: 1.4rem;
  font-weight: 400;
  width: 100%;
`;

const StyledDropdownButton = styled.button<{
  $bgColor?: string;
  $isViewingMode?: boolean;
  $isOpen?: boolean;
}>`
  width: 100%;
  min-width: 13.5rem;
  height: 4.8rem;
  border: ${(props) =>
    props.$isViewingMode
      ? "2px solid var(--color-grey-100)"
      : props.$isOpen
        ? "2px solid var(--color-grey-900)"
        : "unset"};
  border-radius: var(--border-radius-2l);
  padding: 0 1.5rem;
  color: var(--color-recent);
  background-color: ${(props) =>
    props.$isViewingMode ? "unset" : props.$bgColor || "white"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: 1.5;
  pointer-events: ${(props) => props.$isViewingMode && "none"};
  transition: border 0s;
`;

const StyledDropdownMenu = styled.div<{ $showOnTop: boolean }>`
  position: absolute;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
  z-index: 999;
  top: ${(props) => !props.$showOnTop && "95%"};
  bottom: ${(props) => props.$showOnTop && "105%"};
  border-radius: var(--border-radius-2l);
  margin-top: 0.5rem;
  border: 2px solid var(--color-grey-400);
  background-color: white;
  padding: 0 1rem;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2rem;
  height: 4.8rem;
  width: 100%;
  background-color: white;
  &:hover {
    font-weight: 600;
  }
  border-bottom: 3px dashed #ebeff6;

  &:last-child {
    border-bottom: unset;
  }
`;

const StyledSearchInput = styled.input`
  height: 4.8rem;
  border: unset;
  width: 100%;
  background: url("/Search.svg") no-repeat right;
  background-color: white;
  /* border-right: inset 2rem transparent; */
  border-bottom: 3px dashed #ebeff6;
  padding: 2rem;

  &:focus {
    outline: none;
  }
`;

/**
 *
 * @returns a dropdown menu with passed in data
 */
const Dropdown = ({
  id,
  title = "Recent",
  data,
  selectedId,
  onSelect,
  bgColor,
  search = true,
  showOnTop = false,
  isViewingMode = false,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    selectedId ? data?.find((item) => item.id === selectedId) : undefined,
  );
  const [dropdownData, setDropdownData] = useState(data);

  const handleChange = (item: DropdownItem, id: string) => {
    setSelectedItem(item);
    onSelect(item.id, id);
    setIsOpen(false);
    setDropdownData(data);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item.id === selectedId);
      setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => {
    setIsOpen(false);
    setDropdownData(data);
  });

  const filterDropdownItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.toLowerCase();
    const newData = data.filter((item) =>
      item.name.toLowerCase().includes(value),
    );
    setDropdownData(newData);
  };

  return (
    <StyledDrop ref={dropdownRef}>
      <StyledDropdownButton
        id={id}
        aria-label="Toggle dropdown"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        $bgColor={bgColor}
        type="button"
        $isViewingMode={isViewingMode}
        $isOpen={isOpen}
      >
        {(selectedItem?.name || title).length > 25
          ? (selectedItem?.name || title).slice(0, 25) + "..."
          : selectedItem?.name || title}
        <DownArrow style={{ marginLeft: "auto" }} />
      </StyledDropdownButton>
      {/* Open */}
      {isOpen && (
        <StyledDropdownMenu $showOnTop={showOnTop}>
          <ul role="menu">
            {search && (
              <StyledSearchInput
                type="text"
                onChange={(e) => filterDropdownItems(e)}
                placeholder="Type to search"
              />
            )}
            {dropdownData?.map((item) => (
              <StyledListItem
                key={item.id}
                onClick={() => handleChange(item, id)}
              >
                <span>{item.name}</span>
              </StyledListItem>
            ))}
          </ul>
        </StyledDropdownMenu>
      )}
    </StyledDrop>
  );
};

export default Dropdown;
