import { Helmet } from "react-helmet";

/**
 * @description this is the reports page component used for routing
 * @returns Reports page overview
 */
const Reports = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Reports</title>
      </Helmet>
      <div>Reports</div>
    </>
  );
};

export default Reports;
