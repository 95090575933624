import styled from "styled-components";
import ButtonCTA from "../ui/ButtonCTA";
import { useNavigate } from "react-router-dom";
import { BUTTON_TYPES } from "../common/Constants";

const StyledError = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  color: white;
  gap: 2rem;
  & h1 {
    font-size: 2.5rem;
  }
`;

/**
 * NotFound component displays a message indicating that the requested page does not exist
 * and provides a button to navigate back to the previous page.
 * @returns {JSX.Element} The rendered NotFound component.
 */
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledError>
        <h1> Oops, the page you're looking for does not exist. 🔎</h1>
        <ButtonCTA
          onClick={() => navigate(-1)}
          $buttonType={BUTTON_TYPES.SECONDARY}
        >
          Go Back
        </ButtonCTA>
      </StyledError>
    </>
  );
};

export default NotFound;
