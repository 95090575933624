import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";

import { DayPicker } from "react-day-picker";
import styled from "styled-components";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  MODAL_NAMES,
  TASK_STATUS,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetProjectWBS } from "../../hooks/finances/useProjectWBS";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useGetStatusValues } from "../../hooks/useGetStatusValues";
import { MilestoneFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getItemDropdownValues,
} from "../../utils/GetDropdownValue";
import { useGetMilestoneByProjectId } from "../projects/useGetMilestoneByProjectId";
import { useGetProjectById } from "../projects/useGetProjectById";
import { useProjects } from "../projects/useProjects";
import {
  useCreateProjectMilestone,
  useEditProjectMilestone,
} from "./useMilestones";

const EachMilestone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const MilestoneTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const StatusSpan = styled.span<{ $progressStatus?: keyof typeof SCHEDULE_MAP }>`
  color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span<{
  $progressStatus?: keyof typeof SCHEDULE_MAP;
}>`
  height: 0.8rem;
  width: 0.8rem;
  background-color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  border-radius: var(--border-radius-2l);
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const StatusView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * MilestoneForm component for creating and editing project milestones.
 *
 * @param {Object} props - Component props
 * @param {string} props.projectId - The ID of the project
 * @param {() => void} props.handleClose - Function to handle form close
 * @param {boolean} props.isEditingMode - Flag to indicate if the form is in editing mode
 * @param {MilestoneFormValues} [props.details] - Details of the milestone (optional)
 * @param {boolean} [props.showProjectSelector] - Flag to indicate if the project selector should be shown
 * @param {boolean} [props.canUserEdit] - Flag to indicate if the user can edit the form
 * @returns {JSX.Element} The rendered component
 */
const MilestoneForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  showProjectSelector,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: MilestoneFormValues;
  showProjectSelector?: boolean;
  canUserEdit?: boolean;
}) => {
  const { setDayModalName } = useDayModalContext();
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.description?.length > 60,
  );
  const [newProjectId, setNewProjectId] = useState(projectId);
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  const { isCreating, createProjectMilestone } = useCreateProjectMilestone();
  const { isEditing, editProjectMilestone } = useEditProjectMilestone();
  const { isContactsLoading, contacts } = useGetContacts();
  const { isStatusLoading, statuses } = useGetStatusValues();
  const { isServiceLoading, services } = useGetServiceValues();
  const { isLoading: isWBSLoading, projectWBS } =
    useGetProjectWBS(+newProjectId);
  const { isLoading: isProjectsLoading, projects, error } = useProjects();
  const { isLoading, project } = useGetProjectById(newProjectId);
  const {
    isLoading: isMilestonesLoading,
    milestones,
    error: milestoneError,
  } = useGetMilestoneByProjectId(newProjectId);

  const defaultNotStartedStatus = useMemo(
    () =>
      statuses?.find(
        (status) =>
          status.title.toLowerCase() === TASK_STATUS.TODO.toLowerCase(),
      ),
    [statuses],
  );

  const {
    start_year: projectStartYear,
    end_year: projectEndYear,
    est_start_year: projectEstStart,
    est_end_year: projectEstEnd,
    service_id: projectServiceId,
  } = project || {};

  const projectDropdown = useMemo(
    () => getItemDropdownValues(projects),
    [projects],
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
    setFocus,
    getValues,
    resetField,
  } = useForm<MilestoneFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      title: details?.title || "",
      description: details?.description || "",
      project_id: details?.project_id || +newProjectId,
      service_id: details?.service_id || projectServiceId || null,
      wbs_id: details?.wbs_id || null,
      est_start_date: details?.est_start_date || "",
      est_end_date: details?.est_end_date || "",
      start_year: details?.start_year || "",
      end_year: details?.end_year || "",
      percentage_of_project: details?.percentage_of_project || 0,
      responsible_person_id: details?.responsible_person_id || 0,
      status_id: details?.status_id || defaultNotStartedStatus?.id || 0,
    },
  });

  useEffect(() => {
    if (!isEditingMode) setFocus("title");
  }, [setFocus, isEditingMode]);

  const usedProjectPercentage = useMemo(() => {
    if (milestones) {
      return milestones
        .filter((milestone) => milestone.id !== details?.id)
        .reduce(
          (acc, milestone) => acc + (milestone.percentage_of_project || 0),
          0,
        );
    }
    return 0;
  }, [milestones, details?.id]);

  const onSubmit: SubmitHandler<MilestoneFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectMilestone(
        {
          milestoneDetails: {
            ...data,
            sequence: "sequence",
            project_id: +newProjectId,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project milestone saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectMilestone(
        {
          milestoneDetails: {
            ...data,
            sequence: "sequence",
            project_id: +projectId,
            start_year: data.start_year || null,
            end_year: data.end_year || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
          milestoneId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project milestone saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof MilestoneFormValues, id, { shouldDirty: true });
    if (name === "project_id") {
      setNewProjectId(id);
    }
  };

  if (error || milestoneError)
    throw new Error("Unable to fetch data, please retry.");

  if (
    isCreating ||
    isEditing ||
    isContactsLoading ||
    isStatusLoading ||
    isServiceLoading ||
    isLoading ||
    isMilestonesLoading ||
    isProjectsLoading ||
    isWBSLoading
  )
    return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {newProjectId && project && (
            <EachMilestone key={project.id}>
              <MilestoneTitle>
                {"Project - " + project.title}
                <StatusView>
                  <StatusP>
                    <StatusCircle
                      $progressStatus={
                        project.status?.title as keyof typeof SCHEDULE_MAP
                      }
                    ></StatusCircle>
                    <StatusSpan
                      $progressStatus={
                        project.status?.title as keyof typeof SCHEDULE_MAP
                      }
                    >
                      {project.status?.title}
                    </StatusSpan>
                  </StatusP>
                </StatusView>
              </MilestoneTitle>
              <ProjectInfoGrid>
                <ProjectGridItem>
                  <h2>Start Date</h2>
                  <h1>
                    {project?.start_year
                      ? moment(project.start_year).format("DD/MM/YYYY")
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>End Date</h2>
                  <h1>
                    {project?.end_year
                      ? moment(project.end_year).format("DD/MM/YYYY")
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Service</h2>
                  <h1>
                    {services?.find(
                      (service) => service.id === project.service_id,
                    )?.title || "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>responsible Person</h2>
                  <h1>{`${project?.responsible?.first_name} ${project?.responsible?.last_name}`}</h1>
                </ProjectGridItem>
              </ProjectInfoGrid>
            </EachMilestone>
          )}
          <StyledThreeFieldSection>
            {showProjectSelector && (
              <LabelAndInput>
                <label>Project *</label>
                <Controller
                  control={control}
                  name="project_id"
                  rules={{ required: "This field is required" }}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="project_id"
                      title=""
                      data={projectDropdown}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      search
                      isViewingMode={isViewingMode}
                    />
                  )}
                />
                {errors?.project_id && (
                  <ErrorMessage errorMessage={errors.project_id.message} />
                )}
              </LabelAndInput>
            )}
            <LabelAndInput>
              <label>Title *</label>
              <StyledInput
                type="text"
                {...register("title", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                autoComplete="off"
                warning={errors.title?.message}
                readOnly={isViewingMode}
              />
              {errors?.title && (
                <ErrorMessage errorMessage={errors.title.message} />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>Department *</label>
              <Controller
                control={control}
                name="service_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="service_id"
                    title=""
                    data={getItemDropdownValues(services)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.service_id && (
                <ErrorMessage errorMessage={errors.service_id.message} />
              )}
            </LabelAndInput>{" "}
            <LabelAndInput>
              <label>WBS</label>
              <Controller
                control={control}
                name="wbs_id"
                render={({ field: { value } }) => (
                  <Dropdown
                    id="wbs_id"
                    title=""
                    data={getItemDropdownValues(
                      projectWBS?.map((wbs) => wbs.wbs),
                    )}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.wbs_id && (
                <ErrorMessage errorMessage={errors.wbs_id.message} />
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <PositionedInput>
              <label>Estimated Start Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("est_start_date")
                    ? moment(getValues("est_start_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.est_start_date
                      ? moment(details?.est_start_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors.est_start_date?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.est_start_date && (
                <ErrorMessage errorMessage={errors.est_start_date.message} />
              )}
              {getValues("est_start_date") || details?.est_start_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("est_start_date", { defaultValue: "" });
                        if (details) {
                          details.est_start_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode &&
                    setDayModalName(DATE_MODAL_NAMES.EST_START)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="est_start_date"
                rules={{
                  // Minimum date validation
                  min: {
                    value: projectEstStart
                      ? moment(projectEstStart).format(DAY_FORMATS.YEAR_FIRST)
                      : getMinDate(isEditingMode),
                    message: "Please enter a valid start date",
                  },
                  // Maximum date validation
                  max: {
                    value: getValues("est_end_date")
                      ? moment(getValues("est_end_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : projectEstEnd
                        ? moment(projectEstEnd).format(DAY_FORMATS.YEAR_FIRST)
                        : "",
                    message: "Please enter a valid start date",
                  },
                  deps: ["est_end_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().est_start_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "est_start_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().est_start_date
                          ? new Date(getValues().est_start_date)
                          : new Date()
                      }
                      disabled={{
                        before: projectEstStart
                          ? new Date(projectEstStart)
                          : new Date(),
                        after: getValues("est_end_date")
                          ? new Date(getValues("est_end_date"))
                          : projectEstEnd
                            ? new Date(projectEstEnd)
                            : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
            <PositionedInput>
              <label>Estimated End Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("est_end_date")
                    ? moment(getValues("est_end_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.est_end_date
                      ? moment(details?.est_end_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors.est_end_date?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.est_end_date && (
                <ErrorMessage errorMessage={errors.est_end_date.message} />
              )}
              {getValues("est_end_date") || details?.est_end_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("est_end_date", { defaultValue: "" });
                        if (details) {
                          details.est_end_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.EST_END)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="est_end_date"
                rules={{
                  // Minimum date validation
                  min: {
                    value: getValues("est_start_date")
                      ? moment(getValues("est_start_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : projectEstStart
                        ? moment(projectEstStart).format(DAY_FORMATS.YEAR_FIRST)
                        : getMinDate(isEditingMode),
                    message: "Please enter a valid end date",
                  },
                  // Maximum date validation
                  max: {
                    value: projectEstEnd
                      ? moment(projectEstEnd).format(DAY_FORMATS.YEAR_FIRST)
                      : "",
                    message: "Please enter a valid end date",
                  },
                  deps: ["est_start_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_END}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().est_end_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "est_end_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().est_start_date
                          ? new Date(getValues().est_start_date)
                          : new Date()
                      }
                      disabled={{
                        before: getValues("est_start_date")
                          ? new Date(getValues("est_start_date"))
                          : projectEstStart
                            ? new Date(projectEstStart)
                            : new Date(),
                        after: projectEstEnd
                          ? new Date(projectEstEnd)
                          : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <PositionedInput>
              <label>Actual Start Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("start_year")
                    ? moment(getValues("start_year")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.start_year
                      ? moment(details?.start_year).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors.start_year?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.start_year && (
                <ErrorMessage errorMessage={errors.start_year.message} />
              )}
              {getValues("start_year") || details?.start_year
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("start_year", { defaultValue: "" });
                        if (details) {
                          details.start_year = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.START)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="start_year"
                rules={{
                  min: {
                    value: projectStartYear
                      ? moment(projectStartYear).format(DAY_FORMATS.YEAR_FIRST)
                      : getMinDate(isEditingMode),
                    message: "Please enter a valid start year",
                  },
                  max: {
                    value: getValues("end_year")
                      ? moment(getValues("end_year")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : projectEndYear
                        ? moment(projectEndYear).format(DAY_FORMATS.YEAR_FIRST)
                        : "",
                    message: "Please enter a valid start year",
                  },
                  deps: ["end_year"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().start_year)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "start_year",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().start_year
                          ? new Date(getValues().start_year)
                          : new Date()
                      }
                      disabled={{
                        before: projectStartYear
                          ? new Date(projectStartYear)
                          : new Date(),
                        after: getValues("end_year")
                          ? new Date(getValues("end_year"))
                          : projectEndYear
                            ? new Date(projectEndYear)
                            : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
            <PositionedInput>
              <label>Actual End Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("end_year")
                    ? moment(getValues("end_year")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.end_year
                      ? moment(details?.end_year).format(DAY_FORMATS.DAY_FIRST)
                      : ""
                }
                warning={errors.end_year?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.end_year && (
                <ErrorMessage errorMessage={errors.end_year.message} />
              )}
              {getValues("end_year") || details?.end_year
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("end_year", { defaultValue: "" });
                        if (details) {
                          details.end_year = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.END)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="end_year"
                rules={{
                  min: {
                    value: getValues("start_year")
                      ? moment(getValues("start_year")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : projectStartYear
                        ? moment(projectStartYear).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : getMinDate(isEditingMode),
                    message: "Please enter a valid end year",
                  },
                  max: {
                    value: projectEndYear
                      ? moment(projectEndYear).format(DAY_FORMATS.YEAR_FIRST)
                      : "",
                    message: "Please enter a valid end year",
                  },
                  deps: ["est_end_date", "start_year"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().end_year)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "end_year",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().start_year
                          ? new Date(getValues().start_year)
                          : new Date()
                      }
                      disabled={{
                        before: getValues("start_year")
                          ? new Date(getValues("start_year"))
                          : projectStartYear
                            ? new Date(projectStartYear)
                            : new Date(),
                        after: projectEndYear
                          ? new Date(projectEndYear)
                          : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <LabelAndInput>
              <label>% of Project *</label>
              <StyledInput
                type="number"
                {...register("percentage_of_project", {
                  max: {
                    value: 100 - usedProjectPercentage,
                    message: `Max ${100 - usedProjectPercentage}%`,
                  },
                  min: {
                    value: 1,
                    message: "Min 1%",
                  },
                  required: "This field is required",
                })}
                autoComplete="off"
                warning={errors.percentage_of_project?.message}
                readOnly={isViewingMode}
              />
              {errors?.percentage_of_project && (
                <ErrorMessage
                  errorMessage={errors.percentage_of_project.message}
                />
              )}
            </LabelAndInput>{" "}
            <LabelAndInput>
              <label>Responsible Person *</label>
              <Controller
                control={control}
                name="responsible_person_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="responsible_person_id"
                    title=""
                    data={getContactDropdownValues(contacts)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.responsible_person_id && (
                <ErrorMessage
                  errorMessage={errors.responsible_person_id.message}
                />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>Status *</label>
              <Controller
                control={control}
                name="status_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="status_id"
                    title=""
                    data={getItemDropdownValues(statuses)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.status_id && (
                <ErrorMessage errorMessage={errors.status_id.message} />
              )}
            </LabelAndInput>
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors.description?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description.message} />
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default MilestoneForm;
