import { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Plus from "../../assets/PlusIcon.svg?react";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DROPDOWN_CATEGORIES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateBulkDropdownItems,
  useCreateBulkProjectType,
  useDeleteBulkDropdownItems,
  useDeleteBulkProjectType,
} from "../../hooks/useDropdownValues";
import {
  useCreateBulkServiceValues,
  useDeleteBulkServiceValues,
} from "../../hooks/useGetServiceValues";
import { DropdownValue, GroupedDropdownType } from "../../types/dropdownTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import {
  BottomNav,
  LabelAndInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import { SideContainer } from "../../ui/HeaderComponents";
import Icons from "../../ui/Icons";
import Spinner from "../../ui/Spinner";

const AllItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
`;

const H1 = styled.h1`
  font-size: 1.4rem;
  font-weight: 600;
  padding: 2rem 2rem 0;
`;

const EachItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  border: 3px dashed var(--color-grey-400);
  border-radius: var(--border-radius-3l);
  font-size: 1.2rem;
  text-transform: capitalize;
  height: 4.8rem;
  & svg {
    cursor: pointer;
  }
`;

const UpdateDropdownForm = ({
  dropdownItems,
  dropdownCategory,
  handleClose,
}: {
  dropdownItems: GroupedDropdownType;
  dropdownCategory: string;
  handleClose: () => void;
}) => {
  const [itemsToAdd, setItemsToAdd] = useState<string[]>([]);
  const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);
  const [newItemValue, setNewItemValue] = useState<string>("");

  const [modifiedDropdownItems, setModifiedDropdownItems] = useState<
    DropdownValue[]
  >(dropdownItems.values);
  const handleInputChange = (value: string) => {
    setNewItemValue(value);
  };

  const { setDayModalName } = useDayModalContext();
  const { isPending, createBulkDropdownItems } = useCreateBulkDropdownItems();
  const { isPending: isDeleting, deleteBulkDropdownItems } =
    useDeleteBulkDropdownItems();

  const { isPending: isCreatingServiceValues, createBulkServiceValues } =
    useCreateBulkServiceValues();
  const { isPending: isDeletingServiceValues, deleteBulkServiceValues } =
    useDeleteBulkServiceValues();
  const { isPending: isCreatingProjectType, createBulkProjectType } =
    useCreateBulkProjectType();
  const { isPending: isDeletingProjectType, deleteBulkProjectType } =
    useDeleteBulkProjectType();

  const isDirty = itemsToAdd.length > 0 || itemsToDelete.length > 0;

  const handleDelete = (id: number) => {
    setItemsToDelete([...itemsToDelete, id]);
    const filteredItems = modifiedDropdownItems.filter(
      (item) => +item.id !== id,
    );
    setModifiedDropdownItems(filteredItems);
  };

  const handleAdd = (item: string) => {
    setItemsToAdd([...itemsToAdd, item]);
    const newId = modifiedDropdownItems.length + 1;
    const newItem = {
      id: newId,
      title: item.trim(),
    };
    setModifiedDropdownItems([...modifiedDropdownItems, newItem]);
    handleInputChange("");
  };

  const handleSave = () => {
    const addItems = (
      items: string[],
      onSuccessMessage: string,
      createFn: (
        items: { title: string; category: string; description: string }[],
        options: { onSuccess: () => void },
      ) => void,
    ) => {
      createFn(
        items.map((item) => ({
          title: item,
          category: dropdownCategory,
          description: dropdownCategory,
        })),
        {
          onSuccess: () => {
            setItemsToAdd([]);
            toast.success(onSuccessMessage);
            handleClose();
          },
        },
      );
    };

    const deleteItems = (
      items: number[],
      onSuccessMessage: string,
      deleteFn: (items: number[], options: { onSuccess: () => void }) => void,
    ) => {
      deleteFn(items, {
        onSuccess: () => {
          toast.success(onSuccessMessage);
          setItemsToDelete([]);
          handleClose();
        },
      });
    };

    if (itemsToAdd.length > 0) {
      switch (dropdownCategory) {
        case DROPDOWN_CATEGORIES.PROJECT_TYPE:
          addItems(
            itemsToAdd,
            "Project types added successfully",
            createBulkProjectType,
          );
          break;
        case DROPDOWN_CATEGORIES.SERVICES:
          addItems(
            itemsToAdd,
            "Departments added successfully",
            createBulkServiceValues,
          );
          break;
        default:
          addItems(
            itemsToAdd,
            "Dropdown items added successfully",
            createBulkDropdownItems,
          );
      }
    }

    if (itemsToDelete.length > 0) {
      switch (dropdownCategory) {
        case DROPDOWN_CATEGORIES.PROJECT_TYPE:
          deleteItems(
            itemsToDelete,
            "Project types removed successfully",
            deleteBulkProjectType,
          );
          break;
        case DROPDOWN_CATEGORIES.SERVICES:
          deleteItems(
            itemsToDelete,
            "Departments removed successfully",
            deleteBulkServiceValues,
          );
          break;
        default:
          deleteItems(
            itemsToDelete,
            "Dropdown items removed successfully",
            deleteBulkDropdownItems,
          );
      }
    }
  };

  if (
    isPending ||
    isDeleting ||
    isCreatingProjectType ||
    isCreatingServiceValues ||
    isDeletingProjectType ||
    isDeletingServiceValues
  )
    return <Spinner />;

  return (
    <>
      <H1>Dropdown Items</H1>
      {
        // Display existing dropdown items
        <AllItems>
          {modifiedDropdownItems.map((item, index) => (
            <EachItem key={index}>
              {item.title}
              <Discard onClick={() => handleDelete(item.id)} />
            </EachItem>
          ))}
        </AllItems>
      }
      <StyledSection>
        <StyledSingleFieldSection>
          <LabelAndInput>
            <label>Add Dropdown Item</label>
            <SideContainer>
              <StyledInput
                type="text"
                autoComplete="off"
                style={{ width: "100%" }}
                onChange={(e) => handleInputChange(e.target.value)}
                value={newItemValue}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleAdd(newItemValue);
                  }
                }}
              />
              <Icons $bgColor={"var(--color-grey-100)"}>
                <Plus onClick={() => handleAdd(newItemValue)} />
              </Icons>
            </SideContainer>
          </LabelAndInput>{" "}
        </StyledSingleFieldSection>
      </StyledSection>
      <BottomNav>
        <ButtonCTA
          $buttonSize={BUTTON_SIZES.SMALL}
          onClick={() => {
            if (isDirty) {
              setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
            } else {
              handleClose();
            }
          }}
        >
          Cancel
          <Discard />
        </ButtonCTA>
        <ButtonCTA
          $buttonSize={BUTTON_SIZES.SMALL}
          $buttonType={BUTTON_TYPES.SECONDARY}
          onClick={handleSave}
        >
          Save
          <CheckMark />
        </ButtonCTA>
      </BottomNav>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default UpdateDropdownForm;
