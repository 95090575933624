import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";

import moment from "moment";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import toast from "react-hot-toast";
import styled from "styled-components";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";

import {
  useCreateProjectExpense,
  useUpdateProjectExpense,
} from "../../hooks/finances/useCost";
import { useGetCostCentres } from "../../hooks/finances/useCostCentre";
import { useGetProjectCostCentres } from "../../hooks/finances/useProjectCostCentres";
import { useGetProjectWBS } from "../../hooks/finances/useProjectWBS";
import {
  useEditProjectFinance,
  useGetProjectFinances,
} from "../../hooks/projects/useProjectFinance";
import { useGetContacts } from "../../hooks/useGetContacts";
import { CostCentre, UpsertProjectExpense } from "../../types/financeTypes";
import { ProjectFinanceType } from "../../types/projectTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import { getFormattedCurrency } from "../../utils/common";
import { getItemDropdownValues } from "../../utils/GetDropdownValue";

const EachCostCentre = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const CostCentreTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr) 2fr;
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const StyledCurrencyInput = styled(CurrencyInput)<{ warning?: string }>`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid
    ${(props) =>
      props.warning ? "var(--color-warning)" : "var(--color-grey-100)"};
  height: 4.8rem;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

const ProjectExpenseForm = ({
  projectId,
  handleClose,
  details,
  isEditingMode = false,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  details?: UpsertProjectExpense;
  isEditingMode?: boolean;
  canUserEdit?: boolean;
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm<UpsertProjectExpense>({
    defaultValues: {
      cost_centre_id: details?.cost_centre_id || 0,
      wbs_id: details?.wbs_id || 0,
      est_amount: details?.est_amount || 0,
      actual_amount: details?.actual_amount || 0,
      description: details?.description || "",
      project_financial_id: details?.project_financial_id || 0,
    },
  });

  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);
  const { setDayModalName } = useDayModalContext();
  const { isLoading: isProjectWBSLoading, projectWBS } =
    useGetProjectWBS(+projectId);

  const { isFinanceLoading, financeDetails } = useGetProjectFinances(projectId);
  const { isEditing: isEditingFinance, editProjectFinance } =
    useEditProjectFinance();

  const { isPending, createProjectExpense } = useCreateProjectExpense();
  const { isPending: isUpdatePending, updateProjectExpense } =
    useUpdateProjectExpense();

  const { isLoading, costCentres } = useGetCostCentres();
  const { isLoading: isProjectExpenseLoading, projectCostCentres } =
    useGetProjectCostCentres(+projectId);

  const [selectedCostCentre, setSelectedCostCentre] =
    useState<CostCentre | null>(
      costCentres?.find((cc) => cc.id === details?.cost_centre_id) || null,
    );
  const [selectedERPId, setSelectedERPId] = useState<string | null>(
    costCentres?.find((cc) => cc.id === details?.cost_centre_id)?.ext_erp_id ||
      null,
  );

  const [selectedFunding, setSelectedFunding] =
    useState<ProjectFinanceType | null>(
      financeDetails?.find(
        (finance) => finance.id === details?.project_financial_id,
      ) || null,
    );

  const { isContactsLoading, contacts } = useGetContacts();
  const selectedContact = contacts?.find(
    (contact) => contact.id === selectedCostCentre?.contact_id,
  );

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof UpsertProjectExpense, +id);
    if (name === "cost_centre_id") {
      const selectedCostCentre = costCentres?.find((cc) => cc.id === +id);
      setSelectedCostCentre(selectedCostCentre || null);
      setSelectedERPId(selectedCostCentre?.ext_erp_id || null);
    }
    if (name === "project_financial_id") {
      setSelectedFunding(
        financeDetails?.find((finance) => finance.id === +id) || null,
      );
    }
  };

  const onSubmit: SubmitHandler<UpsertProjectExpense> = (data, event) => {
    event?.preventDefault();
    if (isEditingMode) {
      updateProjectExpense(
        {
          id: details?.id as number,
          data: {
            ...data,
            project_id: +projectId,
            ext_erp_id: selectedERPId,
          },
        },
        {
          onSuccess: () => {
            toast.success("Expense updated successfully");
          },
        },
      );
    } else {
      createProjectExpense(
        {
          ...data,
          project_id: +projectId,
          ext_erp_id: selectedERPId,
        },
        {
          onSuccess: () => {
            toast.success("Expense added to project successfully");
          },
        },
      );
    }
    if (selectedFunding) {
      const actual_amount =
        +data.actual_amount + (selectedFunding?.actual_amount || 0);
      const balance_amount =
        (selectedFunding?.budgeted_amount || 0) - actual_amount;
      editProjectFinance(
        {
          detailId: data.project_financial_id || 0,
          financeDetails: {
            ...selectedFunding,
            actual_amount,
            balance_amount,
          },
        },
        {
          onSuccess: () => {
            toast.success("Funding amounts updated successfully");
            handleClose();
          },
        },
      );
    }
  };

  if (
    isLoading ||
    isProjectExpenseLoading ||
    isPending ||
    isUpdatePending ||
    isContactsLoading ||
    isProjectWBSLoading ||
    isFinanceLoading ||
    isEditingFinance
  ) {
    return <Spinner />;
  }

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {selectedCostCentre && (
            <EachCostCentre key={selectedCostCentre.id}>
              <CostCentreTitle>
                {"Cost Centre - " + selectedCostCentre.title}
              </CostCentreTitle>
              <ProjectInfoGrid>
                <ProjectGridItem>
                  <h2>Description</h2>
                  <h1>
                    {selectedCostCentre.description
                      ? selectedCostCentre.description.length > 40
                        ? selectedCostCentre.description.substring(0, 40) +
                          "..."
                        : selectedCostCentre.description
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>External ERP</h2>
                  <h1>{selectedCostCentre.ext_erp_id || "N/A"}</h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Start Date</h2>
                  <h1>
                    {selectedCostCentre?.start_date
                      ? moment(selectedCostCentre.start_date).format(
                          "DD/MM/YYYY",
                        )
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>End Date</h2>
                  <h1>
                    {selectedCostCentre?.end_date
                      ? moment(selectedCostCentre.end_date).format("DD/MM/YYYY")
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Responsible Person</h2>
                  <h1>{`${selectedContact?.first_name} ${selectedContact?.last_name}`}</h1>
                </ProjectGridItem>
              </ProjectInfoGrid>
            </EachCostCentre>
          )}
          {selectedFunding && (
            <>
              <EachCostCentre key={selectedFunding.id}>
                <CostCentreTitle>
                  {"Funding - " + selectedFunding.financial_type}
                </CostCentreTitle>
                <ProjectInfoGrid>
                  <ProjectGridItem>
                    <h2>Description</h2>
                    <h1>
                      {selectedFunding.details
                        ? selectedFunding.details.length > 40
                          ? selectedFunding.details.substring(0, 40) + "..."
                          : selectedFunding.details
                        : "N/A"}
                    </h1>
                  </ProjectGridItem>
                  <ProjectGridItem>
                    <h2>Funding Source</h2>
                    <h1>{selectedFunding.funding_source || "N/A"}</h1>
                  </ProjectGridItem>
                  <ProjectGridItem>
                    <h2>Est Amount</h2>
                    <h1>
                      {getFormattedCurrency(selectedFunding.est_amount || 0) ||
                        "N/A"}
                    </h1>
                  </ProjectGridItem>
                  <ProjectGridItem>
                    <h2>Actual Amount</h2>
                    <h1>
                      {getFormattedCurrency(
                        selectedFunding.actual_amount || 0,
                      ) || "N/A"}
                    </h1>
                  </ProjectGridItem>
                  <ProjectGridItem>
                    <h2>Balance Amount</h2>
                    <h1>
                      {getFormattedCurrency(
                        selectedFunding.balance_amount || 0,
                      )}
                    </h1>
                  </ProjectGridItem>
                </ProjectInfoGrid>
              </EachCostCentre>
            </>
          )}
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Cost Centre *</label>
              <Controller
                control={control}
                name="cost_centre_id"
                rules={{ required: "This field is required" }}
                defaultValue={details?.cost_centre_id}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="cost_centre_id"
                    title=""
                    data={getItemDropdownValues(
                      projectCostCentres?.map((cc) => cc.costcentre),
                    )}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={!!details?.cost_centre_id}
                  />
                )}
              />

              {errors?.cost_centre_id && (
                <ErrorMessage errorMessage={errors.cost_centre_id.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>WBS</label>
              <Controller
                control={control}
                name="wbs_id"
                defaultValue={details?.wbs_id}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="wbs_id"
                    title=""
                    data={getItemDropdownValues(
                      projectWBS?.map((wbs) => wbs.wbs),
                    )}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={!!details?.wbs_id}
                  />
                )}
              />

              {errors?.wbs_id && (
                <ErrorMessage errorMessage={errors.wbs_id.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Funding Source *</label>
              <Controller
                control={control}
                name="project_financial_id"
                rules={{ required: "This field is required" }}
                defaultValue={details?.project_financial_id}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="project_financial_id"
                    title=""
                    data={getItemDropdownValues(
                      financeDetails?.map((finance) => ({
                        title: finance.financial_type,
                        id: finance.id,
                      })),
                    )}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={!!details?.project_financial_id}
                  />
                )}
              />

              {errors?.project_financial_id && (
                <ErrorMessage
                  errorMessage={errors.project_financial_id.message}
                />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Estimated Amount *</label>
              <Controller
                control={control}
                name="est_amount"
                rules={{
                  required: "This field is required",
                }}
                render={({ field: { onChange } }) => (
                  <StyledCurrencyInput
                    id="est_amount"
                    placeholder="$"
                    allowDecimals={false}
                    onValueChange={onChange}
                    prefix={"$"}
                    step={10}
                    defaultValue={details?.est_amount || 0}
                    autoComplete="off"
                    aria-label="Estimated Amount"
                    readOnly={isViewingMode}
                  />
                )}
              />
            </LabelAndInput>{" "}
            {errors?.est_amount && (
              <ErrorMessage errorMessage={errors.est_amount.message} />
            )}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Actual Amount</label>
              <Controller
                control={control}
                name="actual_amount"
                render={({ field: { onChange } }) => (
                  <StyledCurrencyInput
                    id="actual_amount"
                    placeholder="$"
                    allowDecimals={false}
                    onValueChange={onChange}
                    prefix={"$"}
                    step={10}
                    defaultValue={details?.actual_amount || 0}
                    autoComplete="off"
                    aria-label="Actual Amount"
                    readOnly={isViewingMode}
                  />
                )}
              />
            </LabelAndInput>
            {errors?.actual_amount && (
              <ErrorMessage errorMessage={errors.actual_amount.message} />
            )}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.description?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description.message} />
              )}
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default ProjectExpenseForm;
