import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createUserProfileAPI,
  deleteUserProfileAPI,
  registerUserAPI,
  updateUserProfileAPI,
} from "../../services/apiAuth";
import { SignUpCred, UserProfileUpdate } from "../../types/authTypes";

/**
 * @returns To register a new user
 */
export const useRegisterUser = () => {
  const {
    isPending: isRegistering,
    mutate: registerUser,
    error,
  } = useMutation({
    mutationFn: ({
      email,
      password,
      first_name,
      last_name,
      config,
    }: SignUpCred) =>
      registerUserAPI({
        email,
        password,
        first_name,
        last_name,
        config,
        isactive: true,
      }),
    onError: () => {
      localStorage.clear();
    },
  });

  return { isRegistering, registerUser, error };
};

export const useCreateUserProfile = () => {
  const queryClient = useQueryClient();

  const {
    isPending: isCreating,
    mutate: createUserProfile,
    error,
  } = useMutation({
    mutationFn: ({
      email,
      password,
      first_name,
      last_name,
      role_id,
      config,
    }: SignUpCred) =>
      createUserProfileAPI({
        email,
        password,
        first_name,
        last_name,
        role_id: role_id || "",
        config,
        isactive: true,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ALL_USERS],
      });
    },
  });

  return { isCreating, createUserProfile, error };
};

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();

  const {
    isPending: isUpdating,
    mutate: updateUserProfile,
    error,
  } = useMutation({
    mutationFn: ({
      userDetails,
      current_role_id,
    }: {
      userDetails: UserProfileUpdate;
      current_role_id: number;
    }) => updateUserProfileAPI(userDetails, current_role_id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ALL_USERS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ALL_USER_ROLES],
      });
    },
  });

  return { isUpdating, updateUserProfile, error };
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const {
    isPending: isDeleting,
    mutate: deleteUser,
    error,
  } = useMutation({
    mutationFn: ({ userId }: { userId: string }) =>
      deleteUserProfileAPI(userId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ALL_USERS],
      });
    },
  });

  return { isDeleting, deleteUser, error };
};
