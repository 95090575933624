import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import {
  createBulkServiceValuesAPI,
  deleteBulkServiceValuesAPI,
  getAllServiceValuesAPI,
} from "../services/apiStatus";
import { UpsertServiceType } from "../types/statusTypes";

export const useGetServiceValues = (fetchEnabled = true) => {
  const {
    isFetching: isServiceLoading,
    data: services,
    error,
  } = useQuery({
    queryFn: () => getAllServiceValuesAPI({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.SERVICE],
    enabled: fetchEnabled,
  });

  return { isServiceLoading, services, error };
};

export const useCreateBulkServiceValues = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: (data: UpsertServiceType[]) => createBulkServiceValuesAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.SERVICE],
      });
    },
  });

  return { createBulkServiceValues: mutate, isPending, error };
};

export const useDeleteBulkServiceValues = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: (data: number[]) => deleteBulkServiceValuesAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.SERVICE],
      });
    },
  });

  return { deleteBulkServiceValues: mutate, isPending, error };
};
