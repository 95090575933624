import BarLoader from "react-spinners/BarLoader";
import styled from "styled-components";

const MainDiv = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

/**
 * @returns Loader centered in current div
 */
const Spinner = () => (
  <MainDiv data-testid="spinner">
    <BarLoader color="#25252A" />
  </MainDiv>
);

export default Spinner;
