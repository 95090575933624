import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";

import { useMemo, useState } from "react";
import styled from "styled-components";
import { BUTTON_SIZES, BUTTON_TYPES } from "../../common/Constants";
import {
  useCreateProjectWBS,
  useGetProjectWBS,
} from "../../hooks/finances/useProjectWBS";
import { useGetAllWBS } from "../../hooks/finances/useWBS";
import { WBS } from "../../types/financeTypes";
import { ProjectWBSFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  LabelAndInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getItemDropdownValues } from "../../utils/GetDropdownValue";
import { useGetProjectCostCentres } from "../../hooks/finances/useProjectCostCentres";
import toast from "react-hot-toast";

const EachWBS = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const WBSTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

/**
 * ProjectWBSForm component for creating and editing project details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @returns {JSX.Element} The rendered component.
 */
const ProjectWBSForm = ({
  projectId,
  handleClose,
  details,
  isViewingMode,
}: {
  projectId: string;
  handleClose: () => void;
  details?: ProjectWBSFormValues;
  isViewingMode?: boolean;
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectWBSFormValues>();

  const { isPending, createProjectWBS } = useCreateProjectWBS();
  const { isLoading, wbs } = useGetAllWBS();
  const { isLoading: isCostCentreLoading, projectCostCentres } =
    useGetProjectCostCentres(+projectId);
  const {
    isLoading: isProjectWBSLoading,
    projectWBS,
    error,
  } = useGetProjectWBS(+projectId);

  const [selectedWBS, setSelectedWBS] = useState<WBS | null>(
    wbs?.find((wbs) => wbs.id === details?.wbs_id) || null,
  );

  // Filter out WBS that are already in the project, and the selected WBS, include only WBS from project cost centres
  const filteredWBS = useMemo(
    () =>
      wbs?.filter(
        (wbs) =>
          (projectCostCentres?.find(
            (pcc) => pcc.costcentre_id === wbs.cost_centre_id,
          ) &&
            !projectWBS?.find((pcc) => pcc.wbs_id === wbs.id)) ||
          wbs.id === details?.wbs_id,
      ),
    [wbs, projectWBS, details?.wbs_id, projectCostCentres],
  );

  const costCentreTitle = selectedWBS?.cost_centre_id
    ? projectCostCentres?.find(
        (cc) => cc.costcentre_id === selectedWBS?.cost_centre_id,
      )?.costcentre.title || "N/A"
    : "N/A";

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof ProjectWBSFormValues, +id);
    setSelectedWBS(wbs?.find((cc) => cc.id === +id) || null);
  };

  const onSubmit: SubmitHandler<ProjectWBSFormValues> = (data, event) => {
    event?.preventDefault();
    createProjectWBS(
      {
        project_id: +projectId,
        wbs_id: data.wbs_id,
      },
      {
        onSuccess: () => {
          toast.success("WBS associated to project successfully");
          handleClose();
        },
      },
    );
  };

  if (error) throw new Error(error.message);

  if (isPending || isLoading || isProjectWBSLoading || isCostCentreLoading)
    return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {selectedWBS && (
            <EachWBS key={selectedWBS.id}>
              <WBSTitle>{"WBS - " + selectedWBS.title}</WBSTitle>
              <ProjectInfoGrid>
                <ProjectGridItem>
                  <h2>Description</h2>
                  <h1>{selectedWBS.description || "N/A"}</h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Cost Centre</h2>
                  <h1>{costCentreTitle || "N/A"}</h1>
                </ProjectGridItem>
              </ProjectInfoGrid>
            </EachWBS>
          )}
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>WBS</label>
              <Controller
                control={control}
                name="wbs_id"
                rules={{ required: "This field is required" }}
                defaultValue={details?.wbs_id}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="wbs_id"
                    title=""
                    data={getItemDropdownValues(filteredWBS)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={!!details?.wbs_id}
                  />
                )}
              />

              {errors?.wbs_id && (
                <ErrorMessage errorMessage={errors.wbs_id.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={handleClose}
          >
            {!isViewingMode ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
    </>
  );
};

export default ProjectWBSForm;
