import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectCostCentreApi,
  deleteProjectCostCentreApi,
  getProjectCostCentresApi,
} from "../../services/apiFinances";
import { UpsertProjectCostCentre } from "../../types/financeTypes";

/**
 * Custom hook to get project cost centres.
 * @param {number} projectId - The ID of the project.
 * @returns {Object} - An object containing project cost centres data, loading state, and error state.
 */
export const useGetProjectCostCentres = (projectId: number) => {
  const { data, isLoading, error } = useQuery({
    queryFn: () => getProjectCostCentresApi(projectId),
    queryKey: [QUERY_KEYS.PROJECT_COST_CENTRES, projectId],
    enabled: !!projectId,
  });

  return { projectCostCentres: data, isLoading, error };
};

/**
 * Custom hook to create a project cost centre.
 * @returns {Object} - An object containing the createProjectCostCentre function, pending state, and error state.
 */
export const useCreateProjectCostCentre = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createProjectCostCentre,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertProjectCostCentre) =>
      createProjectCostCentreApi(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_COST_CENTRES],
      });
    },
  });

  return { createProjectCostCentre, isPending, error };
};

/**
 * Custom hook to delete a project cost centre.
 * @returns {Object} - An object containing the deleteProjectCostCentre function, pending state, and error state.
 */
export const useDeleteProjectCostCentre = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteProjectCostCentre,
    isPending,
    error,
  } = useMutation({
    mutationFn: (id: number) => deleteProjectCostCentreApi(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_COST_CENTRES],
      });
    },
  });

  return { deleteProjectCostCentre, isPending, error };
};
