import { Helmet } from "react-helmet";
import ProjectOverview from "../features/projects/ProjectOverview";

/**
 * @description this is the project overview page component used for routing
 * @returns Project overview page
 */
const ProjectOverviewPage = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Overview</title>
      </Helmet>
      <ProjectOverview />
    </>
  );
};

export default ProjectOverviewPage;
