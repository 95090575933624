import { Helmet } from "react-helmet";

/**
 * @description this is the programs page component used for routing
 * @returns Programs page overview
 */
const Programs = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Programs</title>
      </Helmet>
      <div>Programs</div>
    </>
  );
};

export default Programs;
