import { RefObject, useEffect } from "react";

// interface OutsideClickHandlerProps {
//   ref: React.RefObject<HTMLElement>;
//   handler: () => void;
// }

const useOutsideClick = (
  ref: RefObject<HTMLInputElement>,
  callback: (event: MouseEvent) => void,
  capture = false,
) => {
  const listener = (event: MouseEvent) => {
    if (!ref.current || ref.current.contains(event.target as Node)) return;
    callback(event);
  };

  useEffect(() => {
    document.addEventListener("click", listener, capture);

    return () => document.removeEventListener("click", listener);
    // eslint-disable-next-line
  }, []);
};

export default useOutsideClick;
