import styled from "styled-components";
import ArrowUpRight from "../../assets/ArrowUpRight.svg?react";
import BackButton from "../../assets/BackButton.svg?react";
import Check from "../../assets/CheckCircle.svg?react";
import Dollar from "../../assets/CurrencyCircleDollar.svg?react";
import Hourglass from "../../assets/Hourglass.svg?react";
import Info from "../../assets/Info.svg?react";
import Location from "../../assets/LocationBlack.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Search from "../../assets/Search.svg?react";

import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import NoDataCard from "../../ui/NoDataCard";
import ProgressBar from "../../ui/ProgressBar";
import Spinner from "../../ui/Spinner";
import ToggleButton from "../../ui/ToggleButton";
import { getFormattedCurrency } from "../../utils/common";
import ProfileLink from "../navigation/ProfileLink";
import { useGetMilestoneByProjectId } from "./useGetMilestoneByProjectId";
import { useGetProjectById } from "./useGetProjectById";

import Edit from "../../assets/Edit.svg?react";
import Filter from "../../assets/Filter.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import PushPinSlash from "../../assets/PushPinSlash.svg?react";
import SortIcon from "../../assets/SortIcon.svg?react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Swiper.css";

import toast from "react-hot-toast";
import { Navigation, Pagination as PaginationSlide } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  BUTTON_TYPES,
  DAY_FORMATS,
  PINNED_ITEM_TYPES,
  TASK_STATUS,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { Task } from "../../types/milestoneTypes";
import { PinHandler } from "../../types/savedItemTypes";
import ContextMenu from "../../ui/ContextMenu";
import { InfoTooltip } from "../../ui/InfoToolTip";
import NumberSpan from "../../ui/NumberSpan";
import PageNumbers from "../../ui/PageNumbers";
import { NextButton, PrevButton } from "../../utils/CarouselButtons";
import { getOffset } from "../../utils/GetOffset";

import "chart.js/auto";
import {
  Chart as ChartJs,
  ChartOptions,
  Plugin,
  TimeScale,
} from "chart.js/auto";
import "chartjs-adapter-moment";
import { Bar } from "react-chartjs-2";

ChartJs.register(TimeScale);

const SideContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-left: auto;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 4.2rem 0;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  gap: 2rem;
  & span {
    font-size: 1.4rem;
    font-weight: 400;
  }
  & h2 {
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const ProjectEstimateContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  align-items: center;
  gap: 2rem;
`;

const ProjectCostEstimate = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: 400;
  & span {
    font-size: 4rem;
    color: var(--color-grey-300);
  }
  & p {
    font-size: 8rem;
    font-weight: 300;
  }
  letter-spacing: -4px;
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const LocationDiv = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const BudgetContainer = styled.div<{
  $frame1: number;
  $frame2: number;
  $frame3: number;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    `${props.$frame1}fr ${props.$frame2}fr ${props.$frame3}fr`};
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  & p {
    text-transform: uppercase;
  }
`;

const ProgressDiv = styled.div`
  background-color: #cfd5e3;
  border-radius: var(--border-radius-2xl);
  width: 100%;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const MilestonesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: 100rem;
  max-width: 80dvw;

  @media (min-width: 90em) {
    max-width: 90dvw;
  }
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
`;

const ProjectInfoHeader = styled.div`
  display: flex;
  padding: 0 0 2rem 0;
  align-items: center;
  & h1 {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  gap: 2rem;
  width: 100%;
`;

const MilestoneHeader = styled(ProjectInfoHeader)<{ disabled?: boolean }>`
  justify-content: unset;
  & span {
    font-style: italic;
  }
  gap: 1rem;
  padding: unset;
  margin-top: 4rem;
  opacity: ${(props) => props.disabled && "0.4"};
`;

const EachMilestone = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  border: 0.2rem dashed
    ${(props) => (props.$isActive ? "var(--color-grey-900)" : "transparent")};
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 18rem;
  cursor: pointer;
`;

const MilestoneTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  text-decoration: underline;
  height: 2.4rem;
  align-items: center;
`;

const StatusSpan = styled.span<{ $progressStatus?: keyof typeof SCHEDULE_MAP }>`
  color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: end;
`;

const StatusCircle = styled.span<{
  $progressStatus?: keyof typeof SCHEDULE_MAP;
}>`
  height: 0.8rem;
  width: 0.8rem;
  background-color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  border-radius: var(--border-radius-2l);
`;

const ViewSpan = styled.span`
  color: var(--color-button-secondary);
  font-weight: 800;
  justify-self: end;
  align-self: end;
  cursor: pointer;
`;

const ProjectPara = styled.p`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 10rem;
`;

const StatusView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
`;

const TasksSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 4rem;
  height: 100%;
`;

const TaskToggleSection = styled.div`
  display: flex;
  gap: 6rem;
`;

const TaskToggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr) 0.4fr;
  background-color: var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 500;
  font-size: 1.2rem;
  justify-items: start;
  padding: 0 2.5rem;
  min-width: 100rem;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr) 0.2fr 0.2fr;
  background-color: white;
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
  padding: 0 2.5rem;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const KanbanGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 40rem;
  gap: 1rem;
`;

const KanbanGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-grey-400-50);
  border-radius: var(--border-radius-xl);
  padding: 1rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem;
  }
`;

const TaskCard = styled(EachMilestone)<{ $isCompleted?: boolean }>`
  background-color: ${(props) => props.$isCompleted && "unset"};
  border: 2px dashed rgba(126, 134, 155, 0.5);
  cursor: unset;
  & svg {
    cursor: pointer;
  }
  height: 15rem;
`;

const BottomDate = styled.span`
  color: var(--color-ref-no);
  font-weight: 700;
  font-size: 1.2rem;
`;

const NoTasks = styled.div`
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
  font-style: italic;
  color: var(--color-grey-300);
`;

const SliderButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
`;

const StyledPinButton = styled.button`
  :disabled {
    cursor: not-allowed;
  }
  border: none;
  background-color: transparent;
`;

const ReportAndActivityContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  gap: 2rem;
  margin-top: 4rem;
  min-width: 100rem;
`;

const ChartDiv = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 43rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-2l);
  padding: 2rem;
`;

const GanttChartDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40rem;
  background-color: white;
  border-radius: var(--border-radius-2l);
  padding: 2rem;
`;

const ActivityContainer = styled.div`
  background-color: white;
  border-radius: var(--border-radius-2l);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;

const DateDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;

  & p {
    color: var(--color-grey-400);
    &:first-child {
      color: var(--color-percent-complete-sec);
    }
  }
`;

const PercentDiv = styled.div`
  & p:last-child {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
`;

const MilestonePercentage = styled.div`
  height: 6rem;
  width: 5rem;
  display: flex;
  align-items: center;
  position: relative;
`;

const MilestonePercentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  font-size: 1.2rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
`;

const Switch = styled.div`
  position: relative;
  width: 3.9rem;
  height: 2rem;
  background: #b3b3b3;
  border-radius: var(--border-radius-2xl);
  padding: 0.5rem;

  &:before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--border-radius-2xl);
    top: 50%;
    left: 0.2rem;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: var(--color-button-secondary);

    &:before {
      transform: translate(2rem, -50%);
    }
  }
`;

const EditDeleteContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  position: relative;
  z-index: 1;
  & svg {
    &:first-child {
      height: 2rem;
      width: 2rem;
    }
    &:last-child {
      pointer-events: ${(props) => (props.disabled ? "none" : "")};
      opacity: ${(props) => (props.disabled ? "0.5" : "1")};
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
  }
`;

/**
 * @returns individual project summary by project ID
 */
const ProjectOverview = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  // state
  const [tasks, setTasks] = useState<Task[]>();
  const [currentTaskView, setCurrentTaskView] = useState<
    "table" | "kanban" | "gantt"
  >("table");

  const [assignedToMe, setAssignedToMe] = useState(false);

  // swiper state
  const [swiperControl, setSwiperControl] = useState<SwiperType>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  // API data
  const { isLoading: isProjectLoading, project } = useGetProjectById(
    projectId || "1",
  );
  const { isLoading: isSavingPin, pinItem } = useSavePinItem();
  const { isLoading: isRemovingPin, removePinItem } = useRemovePinItem();

  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || "",
  );
  const pinnedMilestoneIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.MILESTONE)
        return { value: item.value, id: item.id };
    }) || [];

  const pinnedTaskIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.TASK)
        return { value: item.value, id: item.id };
    }) || [];

  const {
    isLoading: isMilestoneLoading,
    milestones,
    error: milestoneError,
  } = useGetMilestoneByProjectId(
    projectId || "1",
    pinnedMilestoneIds,
    pinnedTaskIds,
  );

  const [activeMilestoneId, setActiveMilestoneId] = useState<number | null>(
    milestones?.[0]?.id || null,
  );

  // to pre-select the first milestone and it's tasks
  useEffect(() => {
    if (milestones && milestones.length > 0) {
      setActiveMilestoneId(milestones[0].id);
      const initialTasks = milestones[0].tasks
        .flat()
        .filter((task) => !task.is_deleted);
      setTasks(initialTasks);
    }
  }, [milestones]);

  // handlers
  const handleMilestoneClick = useCallback(
    (milestoneId: number) => {
      if (milestoneId === activeMilestoneId) {
        setActiveMilestoneId(null);
        setTasks([]);
      } else {
        setActiveMilestoneId(milestoneId);
        const updatedTasks =
          milestones
            ?.find((milestone) => milestone.id === milestoneId)
            ?.tasks.flat()
            ?.filter((task) => !task.is_deleted) || [];
        setTasks(updatedTasks);
      }
    },
    [activeMilestoneId, milestones],
  );

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLSpanElement>,
    pinId?: number,
  ) => {
    event?.stopPropagation();
    handlePinningFromContext(type, value, savePin, pinId);
  };

  const handlePinningFromContext = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number,
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        },
      );
    } else if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        },
      );
    }
    // need to manually update tasks, since it's a stable reference
    if (type === PINNED_ITEM_TYPES.TASK) {
      const newTasks = tasks?.map((task) => {
        if (task.id === +value) {
          task.isPinned = savePin;
          task.pinId = pinId;
        }
        return task;
      });
      setTasks(newTasks);
    }
  };

  const financials = useMemo(
    () => project?.financials || [],
    [project?.financials],
  );

  const budgetAmount = useMemo(
    () =>
      financials?.reduce((acc, item) => (item.budgeted_amount || 0) + acc, 0),
    [financials],
  );

  const committedAmount = useMemo(
    () =>
      financials?.reduce((acc, item) => (item.commited_amount || 0) + acc, 0),
    [financials],
  );

  const actualAmount = useMemo(
    () => financials?.reduce((acc, item) => (item.actual_amount || 0) + acc, 0),
    [financials],
  );

  const projectEstimate = getFormattedCurrency(budgetAmount)
    .replace("$", "")
    .split(".");

  const disablePin = isSavingPin || isRemovingPin;

  const milestoneChartData = useMemo(
    () => [
      ["Milestone", "Percentage Completed"],
      ...(milestones
        ? milestones.map((milestone) => [
            milestone.title,
            milestone.percentage_completed,
          ])
        : []),
    ],
    [milestones],
  );

  const milestoneChartOptions = {
    curveType: "function",
    vAxis: {
      title: "Percentage Complete",
    },
    legend: { position: "none" },
    chartArea: { height: "80%", width: "85%" },
    colors: ["#34C9FA"],
  };

  const milestonePercentOptions = {
    pieHole: 0.6,
    is3D: false,
    chartArea: { height: "100%", width: "100%" },
    legend: "none",
    tooltip: { trigger: "none" },
    enableInteractivity: false,
    backgroundColor: "transparent",
    pieSliceText: "none",
  };

  if (isProjectLoading || isMilestoneLoading || isPinnedItemsLoading)
    return <Spinner />;
  return (
    <>
      <ProjectHeader>
        <Heading>
          <Icons onClick={() => navigate(-1)}>
            <BackButton />
          </Icons>
          <div>
            <h1>
              Projects <span>| Overview </span>
            </h1>
            <h2>{project?.title}</h2>
          </div>
        </Heading>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            onClick={() => navigate(`/projects/details/${projectId}`)}
          >
            <span>Project Details</span>
            <ArrowUpRight />
          </ButtonCTA>
        </SideContainer>
      </ProjectHeader>
      <ProjectEstimateContainer>
        <ProjectGridItem>
          <h2>Project Budget</h2>
          <ProjectCostEstimate>
            <p>
              <span>$</span>
              {projectEstimate[0]}
              <span>
                {projectEstimate?.[1] ? `.${projectEstimate[1]}` : ".00"}
              </span>
            </p>
          </ProjectCostEstimate>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Cost centre</h2>
          {/* get cost centre from project */}
          <h1>Housing crisis review</h1>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Project owner</h2>
          <h1>{project?.owner.first_name + " " + project?.owner.last_name}</h1>
        </ProjectGridItem>
        <ProjectGridItem>
          <h2>Project manager</h2>
          <h1>
            {project?.responsible.first_name +
              " " +
              project?.responsible.last_name}{" "}
          </h1>
        </ProjectGridItem>
        <LocationDiv>
          <Icons>
            <Location />
          </Icons>
          <ProjectGridItem>
            <h2>Location</h2>
            <h1>{project?.location}</h1>
          </ProjectGridItem>
        </LocationDiv>
      </ProjectEstimateContainer>
      <BudgetContainer
        $frame1={actualAmount}
        $frame2={committedAmount}
        $frame3={budgetAmount - actualAmount}
      >
        <ProgressContainer>
          <p>Actual</p>
          <ProgressBar $bgColor="var(--color-budget-used-sec)">
            <Icons $bgColor="#22252A1A">
              <Check />
            </Icons>
            <p>{getFormattedCurrency(actualAmount)}</p>
          </ProgressBar>
        </ProgressContainer>
        <ProgressContainer>
          <p>Committed</p>
          <ProgressBar $bgColor="var(--color-budget-in-use)">
            <Icons $bgColor="#22252A1A">
              <Hourglass />
            </Icons>
            <p>{getFormattedCurrency(committedAmount)}</p>
          </ProgressBar>
        </ProgressContainer>
        <ProgressContainer>
          <p>Balance</p>
          <ProgressBar $bgColor="var(--color-budget-remaining)">
            <Icons $bgColor="#22252A1A">
              <Dollar />
            </Icons>
            <p>{getFormattedCurrency(budgetAmount - actualAmount)}</p>
          </ProgressBar>
        </ProgressContainer>
      </BudgetContainer>
      <ReportAndActivityContainer>
        <ChartDiv>
          <SectionHeader>
            <h1>Milestone Progress</h1>
          </SectionHeader>
          {milestones?.length ? (
            <Chart
              chartType="LineChart"
              width="100%"
              height="100%"
              data={milestoneChartData}
              options={milestoneChartOptions}
            />
          ) : (
            <NoDataCard title="milestones" />
          )}
        </ChartDiv>
        <ActivityContainer>
          <SectionHeader>
            <h1>Activity</h1>
            <StatusP>
              <StatusCircle></StatusCircle>
              <StatusSpan>
                Updated {moment().diff(moment(project?.updated), "days")} days
                ago
              </StatusSpan>
            </StatusP>
          </SectionHeader>
          <ProjectGridItem>
            <h2>Percentage Complete | Days Remaining</h2>
            <ProgressDiv>
              <ProgressBar
                $bgColor="#4BC7A2"
                width={project?.percentage_complete?.toFixed(0)}
              >
                <Icons $bgColor="#22252A1A">
                  <Check />
                </Icons>
                <PercentDiv>
                  <p>{project?.percentage_complete?.toFixed(2)}%</p>
                  <p>
                    {project?.end_year
                      ? moment(project?.end_year).diff(moment(), "days") < 0
                        ? 0
                        : moment(project?.end_year).diff(moment(), "days")
                      : 0}{" "}
                    Days
                  </p>
                </PercentDiv>
              </ProgressBar>
            </ProgressDiv>
            <DateDiv>
              <p>
                {project?.start_year
                  ? moment(project?.start_year).format("DD/MM/YYYY")
                  : "N/A"}
              </p>
              <p>
                {project?.end_year
                  ? moment(project?.end_year).format("DD/MM/YYYY")
                  : "N/A"}
              </p>
            </DateDiv>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Project Status</h2>
            {/* get overall status from project */}
            <h1>{project?.status.title}</h1>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Progress Status</h2>
            <h1>{project?.status.title}</h1>
          </ProjectGridItem>{" "}
          <ProjectGridItem>
            <h2>Project Definition</h2>
            <h1>{project?.project_definition}</h1>
          </ProjectGridItem>
          <ProjectGridItem>
            <h2>Project Type</h2>
            <h1>{project?.project_type.title}</h1>
          </ProjectGridItem>
        </ActivityContainer>
      </ReportAndActivityContainer>
      <MilestonesSection>
        <MilestoneHeader>
          <h1>Milestones {`[${milestones?.length || 0}]`}</h1>
          <span>{`(Click on a milestone to view it's tasks)`}</span>
        </MilestoneHeader>
        {milestoneError && !milestones?.length && (
          <NoDataCard
            title="milestones"
            createLink={`/projects/details/${projectId}?activeToggle=Milestones%2FTasks&expand=milestones`}
          />
        )}
        <SwiperContainer>
          {milestones && (
            <Swiper
              cssMode
              navigation
              pagination
              modules={[Navigation, PaginationSlide]}
              className="milestoneSwiper"
              spaceBetween={20}
              slidesPerView={3}
              onSwiper={(swiper) => setSwiperControl(swiper)}
              onSlideChange={() =>
                setActiveSlideIndex(swiperControl?.activeIndex || 0)
              }
              id="milestone-swiper"
              enabled={milestones.length > 3}
            >
              {milestones?.map((milestone) => (
                <SwiperSlide
                  key={milestone.id}
                  className="milestone-swiper-slide"
                >
                  <EachMilestone
                    key={milestone.id}
                    onClick={() => handleMilestoneClick(milestone.id)}
                    $isActive={milestone.id === activeMilestoneId}
                  >
                    <MilestoneTitle>
                      <span
                        onClick={() =>
                          navigate(
                            `/projects/details/${projectId}?activeToggle=Milestones%2FTasks&expand=milestones`,
                          )
                        }
                      >
                        {milestone.title}
                      </span>
                      {milestone.isPinned ? (
                        <StyledPinButton
                          onClick={(event) =>
                            handlePinning(
                              PINNED_ITEM_TYPES.MILESTONE,
                              `${milestone.id}`,
                              false,
                              event,
                              milestone.pinId,
                            )
                          }
                          disabled={disablePin}
                        >
                          <PinFilled />
                        </StyledPinButton>
                      ) : (
                        <StyledPinButton
                          onClick={(event) =>
                            handlePinning(
                              PINNED_ITEM_TYPES.MILESTONE,
                              `${milestone.id}`,
                              true,
                              event,
                            )
                          }
                          disabled={disablePin}
                        >
                          <PushPin />
                        </StyledPinButton>
                      )}
                    </MilestoneTitle>
                    <ProjectPara>
                      <span>
                        {milestone.start_year
                          ? moment(milestone.start_year).format("DD/MM/YYYY")
                          : "N/A"}{" "}
                        -{" "}
                        {milestone.end_year
                          ? moment(milestone.end_year).format("DD/MM/YYYY")
                          : "N/A"}
                      </span>
                      <span>
                        {milestone.service?.title} |{" "}
                        {milestone.description?.length > 75
                          ? milestone.description?.substring(0, 75) + "..."
                          : milestone.description}
                      </span>
                    </ProjectPara>
                    <StatusView>
                      <StatusP>
                        <StatusCircle
                          $progressStatus={
                            milestone.status?.title as keyof typeof SCHEDULE_MAP
                          }
                        ></StatusCircle>
                        <StatusSpan
                          $progressStatus={
                            milestone.status?.title as keyof typeof SCHEDULE_MAP
                          }
                        >
                          {milestone.status?.title}
                        </StatusSpan>
                      </StatusP>
                      <MilestonePercentage>
                        <Chart
                          chartType="PieChart"
                          width="100%"
                          height="100%"
                          data={getMilestoneProgressData(
                            milestone.percentage_completed,
                          )}
                          options={{
                            ...milestonePercentOptions,
                            colors: [
                              getColor(milestone.percentage_completed),
                              "#D9E1E1",
                            ],
                          }}
                        />
                        <MilestonePercentDiv>
                          <span>
                            {milestone.percentage_completed.toFixed(0)}%
                          </span>
                        </MilestonePercentDiv>
                      </MilestonePercentage>
                    </StatusView>
                  </EachMilestone>
                </SwiperSlide>
              ))}
              {milestones.length > 3 && (
                <SliderButtonDiv>
                  <PrevButton
                    onClick={() => swiperControl?.slideTo(activeSlideIndex - 1)}
                  />
                  <NextButton onClick={() => swiperControl?.slideNext()} />
                </SliderButtonDiv>
              )}
            </Swiper>
          )}
        </SwiperContainer>
        {activeMilestoneId && tasks?.length ? (
          <TasksSection>
            <ProjectInfoHeader>
              <h1>Tasks {`[${tasks?.length || 0}]`}</h1>
              <Label>
                <Input
                  checked={assignedToMe}
                  type="checkbox"
                  onChange={() => setAssignedToMe((prev) => !prev)}
                />
                <Switch />
                <span>Assigned to me</span>
              </Label>
              <SideContainer>
                <Icons>
                  <SortIcon />{" "}
                </Icons>
                <Icons>
                  <Filter />{" "}
                </Icons>
                <TaskToggleSection>
                  <TaskToggles>
                    <ToggleButton
                      $buttonActive={currentTaskView === "table"}
                      onClick={() => setCurrentTaskView("table")}
                    >
                      Table View
                    </ToggleButton>
                    <ToggleButton
                      $buttonActive={currentTaskView === "kanban"}
                      onClick={() => setCurrentTaskView("kanban")}
                    >
                      KanBan View
                    </ToggleButton>
                    <ToggleButton
                      $buttonActive={currentTaskView === "gantt"}
                      onClick={() => setCurrentTaskView("gantt")}
                    >
                      GANTT View
                    </ToggleButton>
                  </TaskToggles>
                </TaskToggleSection>
              </SideContainer>
            </ProjectInfoHeader>
            {
              <TaskViewRender
                currentView={currentTaskView}
                tasks={tasks}
                handlePinning={handlePinningFromContext}
                disablePin={disablePin}
                projectId={projectId ? +projectId : 0}
                assignedToMe={assignedToMe}
                currentUserEmail={user?.email || ""}
              />
            }
          </TasksSection>
        ) : (
          <MilestoneHeader disabled>
            <h1>Tasks</h1>
            <span>{`[no tasks to show]`}</span>
          </MilestoneHeader>
        )}
      </MilestonesSection>
    </>
  );
};

export default ProjectOverview;

const getTaskContextMenu = (
  taskId: number,
  isPinned: boolean,
  projectId: number,
  pinId?: number,
  pinningHandler?: PinHandler,
) => {
  return [
    {
      title: isPinned ? "Unpin" : "Pin",
      to: "",
      imageComponent: () => (isPinned ? PushPinSlash : PushPin),
      pinningHandler,
      pinningDetails: {
        type: PINNED_ITEM_TYPES.TASK,
        value: taskId,
        savePin: !isPinned,
        pinId,
      },
    },
    {
      title: "Edit & Complete",
      to: `/projects/details/${projectId}?activeToggle=Milestones%2FTasks&expand=tasks`,
      imageComponent: () => Edit,
    },
  ];
};

const TaskViewRender = ({
  currentView,
  tasks,
  projectId,
  handlePinning,
  disablePin,
  assignedToMe,
  currentUserEmail,
}: {
  currentView: string;
  tasks: Task[];
  projectId: number;
  handlePinning?: PinHandler;
  disablePin?: boolean;
  assignedToMe?: boolean;
  currentUserEmail?: string;
}) => {
  const toDoTasks: Task[] = [],
    inProgressTasks: Task[] = [],
    onHoldTasks: Task[] = [],
    completedTasks: Task[] = [];

  const [sorting, setSorting] = useState<SortingState>([
    { id: "isPinned", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const filteredTasks = useMemo(() => {
    return tasks.filter((task) => {
      if (assignedToMe) return task.responsible.email === currentUserEmail;
      return true;
    });
  }, [tasks, assignedToMe, currentUserEmail]);

  // tasks table setup
  const columnHelper = createColumnHelper<Task>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("isPinned", {
        header: "isPinned",
        id: "isPinned",
      }),
      columnHelper.accessor("pinId", {
        header: "pinId",
        id: "pinId",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor((row) => row.start_date, {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor((row) => row.due_date, {
        header: "Due Date",
        id: "due_date",
      }),
      columnHelper.accessor(
        (row) => row.responsible.first_name + " " + row.responsible.last_name,
        {
          header: "Responsible",
          id: "responsible",
        },
      ),
      columnHelper.accessor("percentage_completed", {
        header: "% Complete",
        id: "percentage_complete",
      }),
      columnHelper.accessor("status.title", {
        header: "Status",
        id: "status",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: filteredTasks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        isPinned: false,
        pinId: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  for (const task of filteredTasks) {
    // todo check if there can be more status values
    if (
      task?.status?.title?.toLowerCase() === TASK_STATUS.TODO.toLowerCase() ||
      task.status.title === TASK_STATUS.PENDING ||
      task.status.title === TASK_STATUS.ASSIGNED
    )
      toDoTasks.push(task);
    else if (task.status.title === TASK_STATUS.HOLD) onHoldTasks.push(task);
    else if (task.status.title === TASK_STATUS.INPROGRESS)
      inProgressTasks.push(task);
    else if (task.status.title === TASK_STATUS.COMPLETED)
      completedTasks.push(task);
  }

  if (currentView === "table") {
    return (
      <>
        <TableHeader id="task-table-header">
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <span
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{ cursor: "pointer" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                {
                  { asc: " 🔼", desc: " 🔽" }[
                    (header.column.getIsSorted() as string) ?? null
                  ]
                }
              </span>
            )),
          )}
          <EditDeleteContainer>
            <Info
              onMouseEnter={() => setIsInfoVisible(true)}
              onMouseLeave={() => setIsInfoVisible(false)}
              style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
            />
            {isInfoVisible && (
              <InfoTooltip>
                <span>
                  Click on a header to sort the table by the corresponding
                  column.
                </span>
              </InfoTooltip>
            )}
            <Info style={{ visibility: "hidden" }} />
          </EditDeleteContainer>
        </TableHeader>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} id={`${row.id}-task`}>
            <span>{row.getValue("title")}</span>
            <span>
              {row.getValue("start_date")
                ? moment(row.getValue("start_date")).format(
                    DAY_FORMATS.DAY_FIRST,
                  )
                : "N/A"}
            </span>
            <span>
              {row.getValue("due_date")
                ? moment(row.getValue("due_date")).format(DAY_FORMATS.DAY_FIRST)
                : "N/A"}
            </span>
            <span>{row.getValue("responsible")}</span>
            <span>
              {Number(row.getValue("percentage_complete") || 0).toFixed(2)}
            </span>
            <Pill
              $progressStatus={
                row.getValue("status") as keyof typeof SCHEDULE_MAP
              }
            >
              <span>{row.getValue("status")}</span>
            </Pill>
            <ContextMenu
              listItems={getTaskContextMenu(
                row.getValue("id"),
                row.getValue("isPinned"),
                projectId,
                row.getValue("pinId"),
                handlePinning,
              )}
              id="tasks-table-menu"
              top={() => getOffset("task-table-header", `${row.id}-task`)}
            />
            {!!row.getValue("isPinned") && <PinFilled />}
          </TableRow>
        ))}
        <Pagination>
          <ItemCount>
            <span style={{ fontSize: "1.2rem" }}>Show:</span>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 5}
              onClick={() => table.setPageSize(5)}
            >
              5
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 10}
              onClick={() => table.setPageSize(10)}
            >
              10
            </NumberSpan>
          </ItemCount>
          <PageNumbers table={table} />
        </Pagination>
      </>
    );
  } else if (currentView === "kanban")
    return (
      <KanbanGrid>
        <KanbanGridItem>
          <h1>To-Do</h1>
          {toDoTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={toDoTasks}
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>In-progress</h1>
          {inProgressTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={inProgressTasks}
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>On-hold</h1>
          {onHoldTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={onHoldTasks}
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
        <KanbanGridItem>
          <h1>Completed</h1>
          {completedTasks.length ? (
            <TaskCardRenderer
              handlePinning={handlePinning}
              disablePin={disablePin}
              tasks={completedTasks}
              completed
              projectId={projectId}
            />
          ) : (
            <NoTasks>No tasks.</NoTasks>
          )}
        </KanbanGridItem>
      </KanbanGrid>
    );
  else return <GanttChartRenderer tasks={filteredTasks} />;
};

const ContextMenuDiv = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const TaskCardRenderer = ({
  tasks,
  projectId,
  completed = false,
  handlePinning,
  disablePin,
}: {
  tasks: Task[];
  projectId: number;
  completed?: boolean;
  handlePinning?: PinHandler;
  disablePin?: boolean;
}) => {
  const navigate = useNavigate();

  const handlePinningDirect = (
    type: string,
    value: string,
    savePin: boolean,
    event?: React.MouseEvent<HTMLOrSVGElement>,
    pinId?: number,
  ) => {
    event?.stopPropagation();
    handlePinning?.(type, value, savePin, pinId);
  };

  return (
    <>
      {tasks?.map((task) => (
        <TaskCard key={task.id} $isCompleted={completed} id="task-card-header">
          <MilestoneTitle>
            {task.title}
            <ContextMenuDiv>
              {task.isPinned ? (
                <StyledPinButton
                  onClick={(e) =>
                    handlePinningDirect(
                      PINNED_ITEM_TYPES.TASK,
                      `${task.id}`,
                      false,
                      e,
                      task.pinId,
                    )
                  }
                  disabled={disablePin}
                >
                  <PinFilled />
                </StyledPinButton>
              ) : (
                <StyledPinButton
                  onClick={(e) =>
                    handlePinningDirect(
                      PINNED_ITEM_TYPES.TASK,
                      `${task.id}`,
                      true,
                      e,
                    )
                  }
                  disabled={disablePin}
                >
                  <PushPin />
                </StyledPinButton>
              )}
            </ContextMenuDiv>
          </MilestoneTitle>
          <ProjectPara>
            <span>{task.service.title}</span>
          </ProjectPara>
          <span>{task.description}</span>
          <StatusView>
            <StatusP>
              <BottomDate>
                {task.start_date
                  ? moment(task.start_date).format("DD/MM/YYYY")
                  : "N/A"}{" "}
                -{" "}
                {task.due_date
                  ? moment(task.due_date).format("DD/MM/YYYY")
                  : "N/A"}
              </BottomDate>
            </StatusP>
            <ViewSpan
              onClick={() =>
                navigate(
                  `/projects/details/${projectId}?activeToggle=Milestones%2FTasks&expand=tasks`,
                )
              }
            >
              {"View >"}
            </ViewSpan>
          </StatusView>
        </TaskCard>
      ))}
    </>
  );
};

const GanttChartRenderer = ({ tasks }: { tasks: Task[] }) => {
  const taskRows = tasks
    .filter((task) => {
      // filter out tasks with no start or end date, needed for GANTT
      if (task.start_date) return task.due_date;
      if (task.est_start_date) return task.est_end_date;
      return false;
    })
    .map((task) => ({
      y: task.title,
      x: [
        new Date(task.start_date || task.est_start_date),
        new Date(task.due_date || task.est_end_date),
      ],
      name:
        task.responsible.first_name.charAt(0).toUpperCase() +
        task.responsible.last_name.charAt(0).toUpperCase(),
      status: task.status.title,
    }));

  // plugin to draw a line at today's date
  const todayLine: Plugin<"bar"> = {
    id: "todayLine",
    afterDatasetDraw(chart) {
      const {
        ctx,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;

      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = "#22252A";
      ctx.lineWidth = 2;
      ctx.setLineDash([5, 5]);
      ctx.moveTo(x.getPixelForValue(new Date().getTime()), top);
      ctx.lineTo(x.getPixelForValue(new Date().getTime()), bottom);
      ctx.stroke();
      ctx.setLineDash([]);
    },
  };

  const namesPlugin: Plugin = {
    id: "namesPlugin",
    afterDatasetsDraw(chart) {
      const {
        ctx,
        data,
        scales: { y },
      } = chart;

      ctx.save();
      ctx.font = "bolder 12px Rubik";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      data.datasets[0].data.forEach((dataPoint, i) => {
        ctx.beginPath();
        ctx.fillStyle = "#F0F2F7";
        ctx.arc(30, y.getPixelForValue(i), 20, 0, 2 * Math.PI, false);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = "#22252A";
        // @ts-expect-error: Ignore type issues on the next line, name will exist on data type
        ctx.fillText(dataPoint?.name, 30, y.getPixelForValue(i));
      });
      ctx.fillText("Responsible", 45, y.getPixelForValue(0) - 45);
      ctx.restore();
    },
  };

  const statusPlugin: Plugin = {
    id: "statusPlugin",
    afterDatasetsDraw(chart) {
      const {
        ctx,
        data,
        chartArea: { right },
        scales: { y },
      } = chart;

      ctx.save();
      ctx.font = "bolder 12px Rubik";
      ctx.fillStyle = "black";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      data.datasets[0].data.forEach((dataPoint, i) => {
        ctx.fillStyle = // @ts-expect-error: Ignore type issues on the next line, status will exist on data type
          SCHEDULE_MAP[dataPoint?.status as keyof typeof SCHEDULE_MAP];
        // @ts-expect-error: Ignore type issues on the next line, name will exist on data type
        ctx.fillText(dataPoint?.status, right + 50, y.getPixelForValue(i));
      });
      ctx.fillStyle = "#22252A";
      ctx.fillText("Status", right + 50, y.getPixelForValue(0) - 45);

      ctx.restore();
    },
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 80,
        right: 100,
      },
    },
    indexAxis: "y",
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        min: moment().startOf("week").format(DAY_FORMATS.YEAR_FIRST),
        max: moment().endOf("week").format(DAY_FORMATS.YEAR_FIRST),
        position: "top",
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
  };

  const data = {
    datasets: [
      {
        label: "Task Progress",
        data: taskRows,
        backgroundColor: ["#5CE5B0", "#FC90F7", "#FFD84F"],
        borderColor: ["#2EA174", "#EB3C7C", "#B88627"],
        borderWidth: 1,
        borderSkipped: false,
        borderRadius: 10,
        barPercentage: 0.6,
      },
    ],
  };

  // cleanup Chart.js instances on unmount
  useEffect(() => {
    return () => {
      // Cleanup all Chart.js instances on unmount
      Object.keys(ChartJs.instances).forEach((key) => {
        ChartJs.instances[key].destroy();
      });
    };
  }, []);

  return (
    <GanttChartDiv>
      <Bar
        data={data}
        options={options}
        plugins={[todayLine, namesPlugin, statusPlugin]}
      />
    </GanttChartDiv>
  );
};

const getMilestoneProgressData = (percentage: number) => {
  return [
    ["Percentage", "Value"],
    ["% completed", +percentage.toFixed(0)],
    ["% remaining", 100 - +percentage.toFixed(0)],
  ];
};

const getColor = (percentage: number) => {
  if (percentage < 20) return "#E53D3D";
  if (percentage < 50) return "#FE642B";
  if (percentage < 75) return "#FAC300";
  return "#8ED914";
};
