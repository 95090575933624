import { SubmitHandler, useForm } from "react-hook-form";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";
import { SuccessMeasureFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectSuccess,
  useEditProjectSuccess,
} from "../../hooks/projects/useProjectSuccess";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
  StyledTwoFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";

/**
 * SuccessMeasureForm component for creating and editing success measure details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag indicating if the form is in editing mode.
 * @param {SuccessMeasureFormValues} [props.details] - The details of the success measure (optional).
 * @param {number} [props.detailLength=0] - The length of the detail list (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the details (optional).
 * @returns {JSX.Element} The rendered component.
 */
const SuccessMeasureForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  detailLength = 0,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: SuccessMeasureFormValues;
  detailLength: number;
  canUserEdit?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.description?.length > 90,
  );
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setFocus,
  } = useForm<SuccessMeasureFormValues>({
    defaultValues: {
      source_of_benefit: details?.source_of_benefit || "",
      description: details?.description || "",
    },
  });

  useEffect(() => {
    if (!isEditingMode) setFocus("description");
  }, [setFocus, isEditingMode]);

  const { isCreating, createProjectSuccessMeasure } = useCreateProjectSuccess();
  const { isEditing, editProjectSuccessMeasure } = useEditProjectSuccess();
  const { setDayModalName } = useDayModalContext();

  const onSubmit: SubmitHandler<SuccessMeasureFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectSuccessMeasure(
        {
          successDetails: {
            ...data,
            project_id: +projectId,
            section_id: null,
            sequence: detailLength + 1,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project success measure saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectSuccessMeasure(
        {
          successDetails: {
            ...data,
            project_id: +projectId,
            section_id: null,
            sequence: details?.sequence || 1,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project success measure saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };

  if (isEditing || isCreating) return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledTwoFieldSection>
            <LabelAndInput>
              <label>Source of Benefit</label>
              <StyledInput
                type="text"
                {...register("source_of_benefit")}
                autoComplete="off"
                warning={errors?.source_of_benefit?.message}
                readOnly={isViewingMode}
              />
            </LabelAndInput>{" "}
          </StyledTwoFieldSection>
          <StyledThreeFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.description?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description.message} />
              )}
            </LabelAndInput>
          </StyledThreeFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default SuccessMeasureForm;
