import { Helmet } from "react-helmet";
import AllTasks from "../features/tasks/AllTasks";

/**
 * @description this is the Tasks page component used for routing
 * @returns Tasks page overview
 */
const Tasks = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Tasks</title>
      </Helmet>
      <AllTasks />
    </>
  );
};

export default Tasks;
