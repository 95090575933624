import styled from "styled-components";
import Profile from "../../assets/profile.svg?react";
import Spinner from "../../ui/Spinner";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetAllRoles, useGetUserRoles } from "../../hooks/auth/useRoles";
import { useNavigate } from "react-router-dom";

const ProfileDiv = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledDesc = styled.p`
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span<{ $isTitle?: boolean }>`
  font-size: 1.2rem;
  font-weight: ${(props) => (props.$isTitle ? "800" : "")};
  color: var(--color-grey-900) !important;
  text-transform: capitalize;
`;

const ProfileLink = () => {
  const navigate = useNavigate();
  const { isLoading, user } = useGetCurrentUser();

  const { isRolesLoading, roles } = useGetAllRoles();
  const { isUserRoleLoading, userRoles } = useGetUserRoles(user?.id || "");

  const role_id = userRoles?.find((role) => !role.project_id)?.role_id;
  const role_title = roles?.find((role) => role.id === role_id)?.title;

  if (isLoading || isUserRoleLoading || isRolesLoading) return <Spinner />;

  return (
    <ProfileDiv onClick={() => navigate("/profile")}>
      <Profile />
      <StyledDesc>
        <StyledSpan $isTitle={true}>{user?.full_name}</StyledSpan>
        <StyledSpan>{role_title}</StyledSpan>
      </StyledDesc>
    </ProfileDiv>
  );
};

export default ProfileLink;
