import moment from "moment";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useGetCostCentres } from "../../hooks/finances/useCostCentre";
import { useCreateWBS, useUpdateWBS } from "../../hooks/finances/useWBS";
import { CostCentre } from "../../types/financeTypes";
import { WBSFormValues } from "../../types/formFields";
import Dropdown from "../../ui/Dropdown";

import Contract from "../../assets/Contract.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getItemDropdownValues } from "../../utils/GetDropdownValue";
import { useGetContacts } from "../../hooks/useGetContacts";
import ButtonCTA from "../../ui/ButtonCTA";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";

const EachCostCentre = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const CostCentreTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr) 2fr;
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const WBSForm = ({
  handleClose,
  details,
  isEditingMode = false,
}: {
  handleClose: () => void;
  details?: WBSFormValues;
  isEditingMode?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] =
    useState(false);
  const { setDayModalName } = useDayModalContext();

  const { isLoading, costCentres } = useGetCostCentres();
  const { isPending, createWBS } = useCreateWBS();
  const { isPending: isUpdatePending, updateWBS } = useUpdateWBS();
  const { isContactsLoading, contacts } = useGetContacts();

  const [selectedCostCentre, setSelectedCostCentre] =
    useState<CostCentre | null>(
      costCentres?.find((cc) => cc.id === details?.cost_centre_id) || null,
    );

  const selectedContact = contacts?.find(
    (contact) => contact.id === selectedCostCentre?.contact_id,
  );

  const {
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm<WBSFormValues>({
    defaultValues: {
      title: details?.title || "",
      description: details?.description || "",
      cost_centre_id: details?.cost_centre_id || 0,
    },
  });

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof WBSFormValues, +id);
    setSelectedCostCentre(costCentres?.find((cc) => cc.id === +id) || null);
  };

  const onSubmit: SubmitHandler<WBSFormValues> = (data, event) => {
    event?.preventDefault();

    data = {
      ...data,
      level: 1,
      ext_erp_id: selectedCostCentre?.ext_erp_id || null,
    };

    if (isEditingMode) {
      updateWBS(
        { data, id: details?.id || 0 },
        {
          onSuccess: () => {
            toast.success("WBS updated successfully");
            handleClose();
          },
        },
      );
    } else {
      createWBS(data, {
        onSuccess: () => {
          toast.success("WBS created successfully");
          handleClose();
        },
      });
    }
  };

  if (isLoading || isPending || isUpdatePending || isContactsLoading)
    return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {selectedCostCentre && (
            <EachCostCentre key={selectedCostCentre.id}>
              <CostCentreTitle>
                {"Cost Centre - " + selectedCostCentre.title}
              </CostCentreTitle>
              <ProjectInfoGrid>
                <ProjectGridItem>
                  <h2>Description</h2>
                  <h1>{selectedCostCentre.description || "N/A"}</h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>External ERP</h2>
                  <h1>{selectedCostCentre.ext_erp_id || "N/A"}</h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Start Date</h2>
                  <h1>
                    {selectedCostCentre?.start_date
                      ? moment(selectedCostCentre.start_date).format(
                          "DD/MM/YYYY",
                        )
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>End Date</h2>
                  <h1>
                    {selectedCostCentre?.end_date
                      ? moment(selectedCostCentre.end_date).format("DD/MM/YYYY")
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Responsible Person</h2>
                  <h1>{`${selectedContact?.first_name} ${selectedContact?.last_name}`}</h1>
                </ProjectGridItem>
              </ProjectInfoGrid>
            </EachCostCentre>
          )}
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Title</label>
              <StyledInput
                type="text"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <ErrorMessage errorMessage={errors.title.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Description</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.description?.message}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Cost Centre</label>
              <Controller
                control={control}
                name="cost_centre_id"
                rules={{ required: "This field is required" }}
                defaultValue={details?.cost_centre_id}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="cost_centre_id"
                    title=""
                    data={getItemDropdownValues(costCentres)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    showOnTop
                  />
                )}
              />
              {errors?.cost_centre_id && (
                <ErrorMessage errorMessage={errors.cost_centre_id.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            Cancel
            <Discard />
          </ButtonCTA>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            $buttonType={BUTTON_TYPES.SECONDARY}
            type="submit"
          >
            Save
            <CheckMark />
          </ButtonCTA>
        </BottomNav>
      </form>
      <ConfirmClosePopup
        onClose={handleClose}
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
      />
    </>
  );
};

export default WBSForm;
