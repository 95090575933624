import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectWBSApi,
  deleteProjectWBSApi,
  getProjectWBSApi,
} from "../../services/apiFinances";
import { UpsertProjectWBS } from "../../types/financeTypes";

/**
 *
 * @param projectId - The ID of the project.
 * @returns
 */
export const useGetProjectWBS = (projectId: number) => {
  const { data, isLoading, error } = useQuery({
    queryFn: () => getProjectWBSApi(projectId),
    queryKey: [QUERY_KEYS.PROJECT_WBS, projectId],
    enabled: !!projectId,
  });

  return { projectWBS: data, isLoading, error };
};

/**
 * @description Custom hook to create a project WBS.
 * @returns {Object} - An object containing the createProjectWBS function, pending state, and error state.
 */
export const useCreateProjectWBS = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createProjectWBS,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertProjectWBS) => createProjectWBSApi(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_WBS],
      });
    },
  });

  return { createProjectWBS, isPending, error };
};

/**
 * @description Custom hook to delete a project WBS.
 * @returns {Object} - An object containing the deleteProjectWBS function, pending state, and error state.
 * @param id - The ID of the project WBS to delete.
 */

export const useDeleteProjectWBS = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteProjectWBS,
    isPending,
    error,
  } = useMutation({
    mutationFn: (id: number) => deleteProjectWBSApi(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_WBS],
      });
    },
  });

  return { deleteProjectWBS, isPending, error };
};
