import { Helmet } from "react-helmet";
import AllProjects from "../features/projects/AllProjects";

/**
 * @description this is the projects page component used for routing
 * @returns Projects page overview
 */
const Projects = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Projects</title>
      </Helmet>
      <AllProjects />
    </>
  );
};

export default Projects;
