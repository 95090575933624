import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Expand from "../../assets/Expand.svg?react";

import moment from "moment";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateCostCentre,
  useUpdateCostCentre,
} from "../../hooks/finances/useCostCentre";
import { useGetContacts } from "../../hooks/useGetContacts";
import { CostCentreFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import { getContactDropdownValues } from "../../utils/GetDropdownValue";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import toast from "react-hot-toast";

/**
 * CostCentreForm component for creating and editing project details.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @returns {JSX.Element} The rendered component.
 */
const CostCentreForm = ({
  handleClose,
  details,
  isEditingMode = false,
}: {
  handleClose: () => void;
  details?: CostCentreFormValues;
  isEditingMode?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details?.description && details?.description?.length > 90,
  );

  const { setDayModalName } = useDayModalContext();

  const { isContactsLoading, contacts } = useGetContacts();
  const { isPending, createCostCentre } = useCreateCostCentre();
  const { isPending: isUpdatePending, updateCostCentre } =
    useUpdateCostCentre();

  const {
    control,
    setValue,
    handleSubmit,
    register,
    getValues,
    resetField,
    formState: { errors, isDirty },
  } = useForm<CostCentreFormValues>({
    defaultValues: {
      title: details?.title || "",
      description: details?.description || "",
      ext_erp_id: details?.ext_erp_id || "",
      start_date: details?.start_date || "",
      end_date: details?.end_date || "",
      contact_id: details?.contact_id || 0,
    },
  });

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof CostCentreFormValues, +id, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<CostCentreFormValues> = (data, event) => {
    event?.preventDefault();
    data = { ...data, ext_erp_id: data.ext_erp_id || null };
    if (isEditingMode) {
      updateCostCentre(
        {
          id: details?.id || 0,
          data,
        },
        {
          onSuccess: () => {
            toast.success("Cost Centre updated successfully");
            handleClose();
          },
        },
      );
    } else {
      createCostCentre(data, {
        onSuccess: () => {
          toast.success("Cost Centre created successfully");
          handleClose();
        },
      });
    }
  };

  if (isPending || isContactsLoading || isUpdatePending) return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Title *</label>
              <StyledInput
                type="text"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <ErrorMessage errorMessage={errors.title.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.description?.message}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <PositionedInput>
              <label>Start Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("start_date")
                    ? moment(getValues("start_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.start_date
                      ? moment(details?.start_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors?.start_date?.message}
                onChange={() => {}}
              />
              {errors?.start_date && (
                <ErrorMessage errorMessage={errors.start_date.message} />
              )}
              {getValues("start_date") || details?.start_date ? (
                <ClearMessage
                  onClick={() => {
                    resetField("start_date", { defaultValue: "" });
                    if (details) {
                      details.start_date = "";
                    }
                  }}
                >
                  Clear
                </ClearMessage>
              ) : (
                ""
              )}
              <PositionedCalendar>
                <Calendar
                  onClick={() => {
                    setDayModalName(DATE_MODAL_NAMES.START);
                  }}
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="start_date"
                rules={{
                  max: {
                    value: getValues("end_date")
                      ? moment(getValues("end_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : "",
                    message: "Please enter a valid start date",
                  },
                  deps: ["end_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().start_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "start_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().start_date
                          ? new Date(getValues().start_date)
                          : new Date()
                      }
                      disabled={{
                        // before: details?.start_date
                        //   ? new Date(details?.start_date)
                        //   : new Date(),
                        after: getValues("end_date")
                          ? new Date(getValues("end_date"))
                          : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <PositionedInput>
              <label>End Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("end_date")
                    ? moment(getValues("end_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.end_date
                      ? moment(details?.end_date).format(DAY_FORMATS.DAY_FIRST)
                      : ""
                }
                warning={errors?.end_date?.message}
                onChange={() => {}}
              />
              {errors?.end_date && (
                <ErrorMessage errorMessage={errors.end_date.message} />
              )}
              {getValues("end_date") || details?.end_date ? (
                <ClearMessage
                  onClick={() => {
                    resetField("end_date", { defaultValue: "" });
                    if (details) {
                      details.end_date = "";
                    }
                  }}
                >
                  Clear
                </ClearMessage>
              ) : (
                ""
              )}
              <PositionedCalendar>
                <Calendar
                  onClick={() => {
                    setDayModalName(DATE_MODAL_NAMES.END);
                  }}
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="end_date"
                rules={{
                  min: {
                    value:
                      (getValues("start_date") &&
                        moment(getValues("start_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      getMinDate(isEditingMode),
                    message: "Please enter a valid end date",
                  },
                  deps: ["start_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().end_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "end_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().end_date
                          ? new Date(getValues().end_date)
                          : new Date()
                      }
                      disabled={{
                        before: getValues("start_date")
                          ? new Date(getValues("start_date"))
                          : new Date(),
                        after: getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Manager *</label>
              <Controller
                control={control}
                name="contact_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="contact_id"
                    title=""
                    data={getContactDropdownValues(contacts)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    showOnTop
                  />
                )}
              />
              {errors.contact_id && (
                <ErrorMessage errorMessage={errors.contact_id.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>External ERP (if any)</label>
              <StyledInput type="text" {...register("ext_erp_id")} />
            </LabelAndInput>
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            Cancel
            <Discard />
          </ButtonCTA>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            $buttonType={BUTTON_TYPES.SECONDARY}
            type="submit"
          >
            Save
            <CheckMark />
          </ButtonCTA>
        </BottomNav>
      </form>
      <ConfirmClosePopup
        onClose={handleClose}
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
      />
    </>
  );
};

export default CostCentreForm;
