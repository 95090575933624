import { Helmet } from "react-helmet";
import AllMilestones from "../features/milestones/AllMilestones";

/**
 * @description this is the Milestones page component used for routing
 * @returns Milestones page overview
 */
const Milestones = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Milestones</title>
      </Helmet>
      <AllMilestones />
    </>
  );
};

export default Milestones;
