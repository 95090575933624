import Edit from "../../assets/Edit.svg?react";
import Filter from "../../assets/Filter.svg?react";
import Info from "../../assets/Info.svg?react";
import More from "../../assets/More.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Overview from "../../assets/Overview.svg?react";
import PinFilled from "../../assets/PinFilled.svg?react";
import Plus from "../../assets/Plus.svg?react";
import PushPin from "../../assets/PushPin.svg?react";
import PushPinSlash from "../../assets/PushPinSlash.svg?react";
import Search from "../../assets/Search.svg?react";
import Upload from "../../assets/Upload.svg?react";
import ViewReports from "../../assets/ViewReports.svg?react";

import styled from "styled-components";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import ButtonCTA from "../../ui/ButtonCTA";
import Icons from "../../ui/Icons";
import NumberSpan from "../../ui/NumberSpan";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  BUTTON_TYPES,
  DEFAULT_SORT_OPTIONS,
  MODAL_NAMES,
  PINNED_ITEM_TYPES,
  ROLES,
  SORT_OPTIONS,
} from "../../common/Constants";
import { useActiveModalContext } from "../../context/ModalContext";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useGetUserRoles } from "../../hooks/auth/useRoles";
import { useGetPinnedItems } from "../../hooks/useGetPinnedItems";
import { useRemovePinItem } from "../../hooks/useRemovePinnedItem";
import { useSavePinItem } from "../../hooks/useSavePinItem";
import { Project } from "../../types/projectTypes";
import { PinHandler } from "../../types/savedItemTypes";
import ContextMenu from "../../ui/ContextMenu";
import Dropdown from "../../ui/Dropdown";
import { ProjectHeader, SideContainer } from "../../ui/HeaderComponents";
import { InfoTooltip } from "../../ui/InfoToolTip";
import Modal from "../../ui/Modal";
import NoDataCard from "../../ui/NoDataCard";
import PageNumbers from "../../ui/PageNumbers";
import Spinner from "../../ui/Spinner";
import { getFormattedCurrency, getRAGStatus } from "../../utils/common";
import DebouncedInput from "../../utils/DebouncedInput";
import { getOffset } from "../../utils/GetOffset";
import ProfileLink from "../navigation/ProfileLink";
import CreateEditProject from "./CreateProject";
import { useProjects } from "./useProjects";
import { RAGIndicator } from "../../ui/RAGIndicator";

const HeaderSpan = styled.span`
  font-size: 1.6rem;
  color: var(--color-grey-300);
`;

const SearchFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const TableRowsContainer = styled.div`
  max-height: 62dvh;
  overflow: auto;
`;

const TableHeader = styled.div`
  display: grid;
  margin-top: 1.5rem;
  padding-left: 2.5rem;
  padding-right: 1rem;
  grid-template-columns: 1.2fr repeat(7, 1fr) 1.2fr 0.5fr;
  gap: 1.2rem;
  background-color: var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 700;
  font-size: 1.2rem;
  justify-items: start;
  min-width: 120rem;
`;

const TableRow = styled.div`
  display: grid;
  margin: 1.5rem 0;
  padding-left: 2.5rem;
  padding-right: 1rem;
  grid-template-columns: 1.2fr repeat(7, 1fr) 1.2fr 0.2fr 0.2fr;
  gap: 1rem;
  background-color: white;
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
`;

const Pill = styled.p<{ $progressStatus: keyof typeof SCHEDULE_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => SCHEDULE_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
  min-width: 9.6rem;
`;

const Title = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  color: var(--color-grey-500);
  align-items: center;

  :last-child {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--color-grey-900);
    text-decoration: underline;
    text-transform: capitalize;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 2rem 0;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const NoDataDiv = styled.div`
  margin-top: 2rem;
`;

const EditDeleteContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  position: relative;
  z-index: 1;
  & svg {
    &:first-child {
      height: 2rem;
      width: 2rem;
    }
    &:last-child {
      pointer-events: ${(props) => (props.disabled ? "none" : "")};
      opacity: ${(props) => (props.disabled ? "0.5" : "1")};
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
  }
`;

const fallbackData: Project[] = [];

/**
 * @returns Project overview with a table of all projects
 */
const AllProjects = () => {
  const navigate = useNavigate();
  const [isNewProjectOpen, setIsNewProjectOpen] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  // state
  const [sorting, setSorting] = useState<SortingState>([
    { id: "isPinned", desc: true },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { setCurrentModalName } = useActiveModalContext();

  // API data
  const { pinItem } = useSavePinItem();
  const { removePinItem } = useRemovePinItem();
  const { user } = useGetCurrentUser();
  const { isPinnedItemsLoading, pinnedItems } = useGetPinnedItems(
    user?.id || "",
  );
  const pinnedProjectIds =
    pinnedItems?.map((item) => {
      if (item.type === PINNED_ITEM_TYPES.PROJECT)
        return { value: item.value, id: item.id };
    }) || [];

  const { isLoading: isProjectsLoading, projects } =
    useProjects(pinnedProjectIds);

  const { isUserRoleLoading, userRoles } = useGetUserRoles(user?.id || "");

  const userRole =
    userRoles?.find((role) => {
      return !role.project_id;
    })?.role?.title || "";

  const canUserEdit = userRole !== ROLES.VIEWER;

  // projects table setup
  const columnHelper = createColumnHelper<Project>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("isPinned", {
        header: "isPinned",
        id: "isPinned",
        sortDescFirst: true,
      }),
      columnHelper.accessor("pinId", {
        header: "pinId",
        id: "pinId",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("location", {
        header: "Location",
        id: "location",
      }),
      columnHelper.accessor("start_year", {
        header: "Start Year",
        id: "start_year",
      }),
      columnHelper.accessor("end_year", {
        header: "End Year",
        id: "end_year",
      }),
      columnHelper.accessor("est_start_year", {
        header: "Start Year",
        id: "est_start_year",
      }),
      columnHelper.accessor("est_end_year", {
        header: "End Year",
        id: "est_end_year",
      }),
      columnHelper.accessor(
        (row) => row.owner.first_name + " " + row.owner.last_name,
        {
          header: "Owner",
          id: "owner",
        },
      ),
      columnHelper.accessor(
        (row) =>
          row.financials?.reduce(
            (acc, item) => acc + (item.budgeted_amount || 0),
            0,
          ),
        {
          header: "Budget",
          id: "budget",
        },
      ),
      columnHelper.accessor("project_type.title", {
        header: "Project Type",
        id: "project_type",
      }),
      columnHelper.accessor("percentage_complete", {
        header: "% Complete",
        id: "percentage_complete",
      }),
      columnHelper.accessor("status.title", {
        header: "Progress Status",
        id: "status",
      }),
      columnHelper.accessor("updated", {
        id: "updated",
        sortingFn: (a, b) => {
          const diff = moment(a.original.updated).diff(
            moment(b.original.updated),
          );
          return diff > 0 ? 1 : diff < 0 ? -1 : 0;
        },
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: projects ?? fallbackData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        isPinned: false,
        pinId: false,
        updated: false,
        est_start_year: false,
        est_end_year: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  const handleSelect = (id: string) => {
    const sortOrder = DEFAULT_SORT_OPTIONS.find((item) => item.id === id);
    const startYearColumn = table.getColumn("start_year");
    const endYearColumn = table.getColumn("end_year");
    const updateColumn = table.getColumn("updated");

    if (sortOrder?.name === SORT_OPTIONS.RECENT) {
      updateColumn?.toggleSorting(true);
      startYearColumn?.clearSorting();
      endYearColumn?.clearSorting();
    } else if (sortOrder?.name === SORT_OPTIONS.PAST) {
      startYearColumn?.toggleSorting(true);
      endYearColumn?.clearSorting();
    } else {
      startYearColumn?.clearSorting();
      endYearColumn?.toggleSorting(true);
    }
  };

  const handlePinning = (
    type: string,
    value: string,
    savePin: boolean,
    pinId?: number,
  ) => {
    if (savePin && user) {
      pinItem(
        { type, value, user_id: user.id },
        {
          onSuccess: () => toast.success(`${type} pinned successfully.`),
        },
      );
    }
    if (!savePin && pinId) {
      removePinItem(
        { pinId },
        {
          onSuccess: () => toast.success(`${type} unpinned successfully.`),
        },
      );
    }
  };

  if (isProjectsLoading || isPinnedItemsLoading || isUserRoleLoading)
    return <Spinner />;

  return (
    <>
      <ProjectHeader>
        <h1>
          Projects
          <HeaderSpan>{` [${projects?.length || 0}]`}</HeaderSpan>
        </h1>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
          {canUserEdit && (
            <ButtonCTA
              $buttonType={BUTTON_TYPES.SECONDARY}
              onClick={() => {
                setCurrentModalName(MODAL_NAMES.PROJECT);
                setIsNewProjectOpen(true);
              }}
            >
              new project
              <Plus />
            </ButtonCTA>
          )}
          <Modal
            isOpen={isNewProjectOpen}
            title="Create Project"
            wrapperId={MODAL_NAMES.PROJECT}
            modalWidth="60%"
          >
            <CreateEditProject
              handleClose={() => {
                setCurrentModalName("");
                setIsNewProjectOpen(false);
              }}
              isEditingMode={false}
            />
          </Modal>
        </SideContainer>
      </ProjectHeader>
      <SearchFilter>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
          {/* <ButtonCTA $buttonSize={BUTTON_SIZES.SMALL}>
            <Draft />
            Drafts
          </ButtonCTA> */}
        </FlexContainer>
        <FlexContainer>
          <Dropdown
            id="all-projects"
            title="Recent"
            data={DEFAULT_SORT_OPTIONS}
            onSelect={handleSelect}
            selectedId="1"
            search={false}
          />
          <Icons>
            <Filter />
          </Icons>
          <Icons>
            <Upload />
          </Icons>
          <Icons>
            <More height={20} width={20} />
          </Icons>
        </FlexContainer>
      </SearchFilter>
      <TableHeader id="project-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          )),
        )}
        <EditDeleteContainer>
          <Info
            onMouseEnter={() => setIsInfoVisible(true)}
            onMouseLeave={() => setIsInfoVisible(false)}
            style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
          />
          {isInfoVisible && (
            <InfoTooltip>
              <span>Click on a header to sort the table by that column.</span>
            </InfoTooltip>
          )}
          <PinFilled style={{ visibility: "hidden" }} />
        </EditDeleteContainer>
      </TableHeader>
      {projects?.length === 0 && (
        <NoDataDiv>
          <NoDataCard
            title="Projects"
            buttonLinked
            height={"8rem"}
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.PROJECT);
              setIsNewProjectOpen(true);
            }}
          />
        </NoDataDiv>
      )}
      {projects && projects.length > 0 && (
        <>
          <TableRowsContainer>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} id={`${row.id}-project`}>
                <Title>
                  <RAGIndicator
                    $color={getRAGStatus(
                      row.getValue("est_start_year"),
                      row.getValue("est_end_year"),
                      row.getValue("start_year"),
                      row.getValue("end_year"),
                      row.getValue("status"),
                    )}
                  />
                  <span
                    onClick={() =>
                      navigate(`/projects/overview/${row.getValue("id")}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {row.getValue("title")}
                  </span>
                </Title>
                <span>{row.getValue("location") || "N/A"}</span>
                <span>
                  {row.getValue("start_year")
                    ? moment(row.getValue("start_year")).format("YYYY")
                    : "N/A"}
                </span>
                <span>
                  {row.getValue("end_year")
                    ? moment(row.getValue("end_year")).format("YYYY")
                    : "N/A"}
                </span>
                <span>{row.getValue("owner")}</span>
                <span>
                  {getFormattedCurrency(row.getValue("budget")) || "N/A"}
                </span>
                {/* need project cost */}
                <span>{row.getValue("project_type")}</span>
                <span style={{ paddingLeft: "2rem" }}>
                  {Number(row.getValue("percentage_complete") || 0).toFixed(2)}
                </span>
                <Pill
                  $progressStatus={
                    row.getValue("status") as keyof typeof SCHEDULE_MAP
                  }
                >
                  <span>{row.getValue("status") || "N/A"}</span>
                </Pill>
                <ContextMenu
                  listItems={getProjectContextMenu(
                    row.getValue("id"),
                    row.getValue("isPinned"),
                    row.getValue("pinId"),
                    handlePinning,
                  )}
                  id="projects-table-menu"
                  top={() =>
                    getOffset("project-table-header", `${row.id}-project`)
                  }
                />
                {!!row.getValue("isPinned") && <PinFilled />}
              </TableRow>
            ))}
          </TableRowsContainer>

          <Pagination>
            <ItemCount>
              <span style={{ fontSize: "1.2rem" }}>Show:</span>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 10}
                onClick={() => table.setPageSize(10)}
              >
                10
              </NumberSpan>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 20}
                onClick={() => table.setPageSize(20)}
              >
                20
              </NumberSpan>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 50}
                onClick={() => table.setPageSize(50)}
              >
                50
              </NumberSpan>
              <NumberSpan
                $isSelected={table.getState().pagination.pageSize === 100}
                onClick={() => table.setPageSize(100)}
              >
                100
              </NumberSpan>
            </ItemCount>
            <PageNumbers table={table} />
          </Pagination>
        </>
      )}
    </>
  );
};

const getProjectContextMenu = (
  projectId: number,
  isPinned: boolean,
  pinId?: number,
  pinningHandler?: PinHandler,
) => {
  return [
    {
      title: isPinned ? "Unpin" : "Pin",
      to: "",
      imageComponent: () => (isPinned ? PushPinSlash : PushPin),
      pinningHandler,
      pinningDetails: {
        type: PINNED_ITEM_TYPES.PROJECT,
        value: projectId,
        savePin: !isPinned,
        pinId,
      },
    },
    {
      title: "Go to Project Overview",
      to: `/projects/overview/${projectId}`,
      imageComponent: () => Overview,
    },
    {
      title: "Edit Project Details",
      to: `/projects/details/${projectId}`,
      imageComponent: () => Edit,
    },
    {
      title: "View Reports",
      to: "/reports",
      imageComponent: () => ViewReports,
    },
  ];
};

export default AllProjects;
