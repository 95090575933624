import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";

import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DROPDOWN_CATEGORIES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectStakeholder,
  useEditProjectStakeholder,
} from "../../hooks/projects/useProjectStakeholders";
import { useGetDropdownValues } from "../../hooks/useDropdownValues";
import { StakeholderFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import { getDropdownValues } from "../../utils/GetDropdownValue";

/**
 * StakeholderForm component for creating and editing stakeholder details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag indicating if the form is in editing mode.
 * @param {StakeholderFormValues} [props.details] - The details of the stakeholder (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the details (optional).
 * @returns {JSX.Element} The rendered component.
 */
const StakeholderForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: StakeholderFormValues;
  canUserEdit?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.details?.length > 60,
  );

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();

  const roleDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.STAKEHOLDER_ROLES,
    dropdownItems,
  );

  const typeDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.STAKEHOLDER_TYPE,
    dropdownItems,
  );

  const typeId = useMemo(
    () => typeDropdown.find((value) => value.name === details?.type)?.id,
    [typeDropdown, details?.type],
  );

  const defaultTypeId = useMemo(
    () =>
      typeDropdown.find((value) => value.name.toLowerCase() === "internal")?.id,
    [typeDropdown],
  );

  const roleId = useMemo(
    () =>
      roleDropdown.find((value) => value.name === details?.team_role)?.id || "",
    [roleDropdown, details?.team_role],
  );

  const { setDayModalName } = useDayModalContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
    setFocus,
    reset,
  } = useForm<StakeholderFormValues>({
    defaultValues: {
      type: typeId || defaultTypeId || "",
      team_role: roleId || "",
      name: details?.name || "",
      email: details?.email || "",
      details: details?.details,
    },
  });

  useEffect(() => {
    if (!isEditingMode) setFocus("type");
    if (dropdownItems) {
      reset({
        type: typeId || defaultTypeId || "",
        team_role: roleId || "",
      });
    }
  }, [
    setFocus,
    isEditingMode,
    dropdownItems,
    typeId,
    defaultTypeId,
    roleId,
    reset,
  ]);

  const { isCreating, createProjectStakeholder } =
    useCreateProjectStakeholder();
  const { isEditing, editProjectStakeholder } = useEditProjectStakeholder();

  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  const onSubmit: SubmitHandler<StakeholderFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectStakeholder(
        {
          stakeholderDetails: {
            project_id: +projectId,
            project_team_role:
              roleDropdown.find((value) => value.id === data.team_role)?.name ||
              "",
            section_id: null,
            stakeholder_type: data.type,
            email: data.email,
            name: data.name,
            details: data.details,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project stakeholder details saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectStakeholder(
        {
          stakeholderDetails: {
            project_id: +projectId,
            project_team_role:
              roleDropdown.find((value) => value.id === data.team_role)?.name ||
              "",
            section_id: null,
            stakeholder_type: data.type,
            email: data.email,
            name: data.name,
            details: data.details,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project stakeholder details saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof StakeholderFormValues, id, { shouldDirty: true });
  };

  if (isDropdownLoading || isCreating || isEditing) return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Type *</label>
              <Controller
                control={control}
                name="type"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="type"
                    title=""
                    data={typeDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.type && (
                <ErrorMessage errorMessage={errors.type.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Role *</label>
              <Controller
                control={control}
                name="team_role"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="team_role"
                    title=""
                    data={roleDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.team_role && (
                <ErrorMessage errorMessage={errors.team_role.message} />
              )}
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Name *</label>
              <StyledInput
                type="text"
                {...register("name", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                autoComplete="off"
                warning={errors?.name?.message}
                readOnly={isViewingMode}
              />
              {errors?.name && (
                <ErrorMessage errorMessage={errors.name.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Email *</label>
              <StyledInput
                type="text"
                {...register("email", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                autoComplete="off"
                warning={errors?.email?.message}
                readOnly={isViewingMode}
              />
              {errors?.email && (
                <ErrorMessage errorMessage={errors.email.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("details", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.details?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.details && (
                <ErrorMessage errorMessage={errors.details.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default StakeholderForm;
