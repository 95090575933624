import styled from "styled-components";

export const SideContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 4.2rem;
`;

export const CountSpan = styled.span`
  color: var(--color-grey-300);
  font-weight: 700;
`;
