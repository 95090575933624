import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  PHONE_REGEX,
} from "../../common/Constants";
import { useGetCurrentUser } from "../../hooks/auth/useGetCurrentUser";
import { useUpdateUserProfile } from "../../hooks/auth/useRegisterUser";
import { useGetUserRoles } from "../../hooks/auth/useRoles";
import { UserProfile } from "../../types/authTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import {
  BottomNav,
  ErrorMessage,
  LabelAndInput,
  StyledInput as StyledInputGlobal,
  StyledSection,
  StyledTwoFieldSection,
} from "../../ui/FormElements";
import Spinner from "../../ui/Spinner";

import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: fit-content;
`;

const StyledInput = styled.input<{ warning?: string }>`
  background-color: var(--color-grey-100);
  ::placeholder {
    color: var(-color-placeholder-grey);
  }
  width: 30rem;
  height: 4.8rem;
  border: transparent;
  border-radius: var(--border-radius-2l);
  padding: 0 1rem;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: space-between;
  & p {
    font-size: 1.2rem;
    align-self: center;
  }
`;

const SignUpFormLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

/**
 * UserProfileForm component allows users to update their profile information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.autoRedirect - Flag to indicate if the user should be redirected after updating.
 * @param {() => void} [props.handleClose] - Function to close the form.
 * @returns {JSX.Element} The rendered component.
 */
const UserProfileForm = ({
  autoRedirect,
  handleClose,
}: {
  autoRedirect: boolean;
  handleClose?: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: errorsSignUp },
  } = useForm<UserProfile>();

  const navigate = useNavigate();

  const { isLoading, user } = useGetCurrentUser();
  const { isUpdating, updateUserProfile } = useUpdateUserProfile();

  const { isUserRoleLoading, userRoles } = useGetUserRoles(user?.id || "");
  const role_id = userRoles?.find((role) => !role.project_id)?.role_id || 0;

  const onSubmitSignUp: SubmitHandler<UserProfile> = (data) => {
    if (!user) {
      toast.error("Unable to fetch user, please try again later.");
      return;
    }
    const userDetails = {
      ...user,
      ...data,
      role_id,
      config: {
        ...user?.config,
        job_title: data.job_title,
        location: data.location,
        department: data.department,
        phone: data.phone,
        is_profile_updated: true,
        status: "Active",
      },
    };
    updateUserProfile(
      { userDetails, current_role_id: role_id },
      {
        onSuccess: () => {
          if (autoRedirect) {
            toast.success("Profile updated successfully, redirecting...");
            setTimeout(() => {
              navigate("/organization/1");
            }, 2000);
          } else {
            toast.success("Profile updated successfully");
            handleClose?.();
          }
        },
      },
    );
  };

  if (isLoading || isUpdating || isUserRoleLoading) return <Spinner />;

  return (
    <>
      {autoRedirect ? (
        <FormContainer>
          <h1>Profile Details</h1>
          <SignUpForm onSubmit={handleSubmit(onSubmitSignUp)}>
            <SignUpFormLayout>
              <LabelAndInput>
                <label>Full Name *</label>
                <StyledInput
                  type="text"
                  {...register("full_name", {
                    required: "This field is required",
                  })}
                  defaultValue={user?.full_name}
                  warning={errorsSignUp?.full_name?.message}
                />
                {errorsSignUp?.full_name && (
                  <ErrorMessage
                    errorMessage={errorsSignUp?.full_name.message}
                  />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Email *</label>
                <StyledInput
                  type="text"
                  {...register("email", { required: "This field is required" })}
                  defaultValue={user?.email}
                  warning={errorsSignUp?.email?.message}
                  readOnly
                />
                {errorsSignUp?.email && (
                  <ErrorMessage errorMessage={errorsSignUp?.email.message} />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Job Title</label>
                <StyledInput
                  type="text"
                  {...register("job_title")}
                  defaultValue={user?.config?.job_title}
                  warning={errorsSignUp?.job_title?.message}
                />
              </LabelAndInput>
              <LabelAndInput>
                <label>Location</label>
                <StyledInput
                  type="text"
                  {...register("location")}
                  defaultValue={user?.config?.location}
                  warning={errorsSignUp?.location?.message}
                />
              </LabelAndInput>
              <LabelAndInput>
                <label>Department</label>
                <StyledInput
                  type="text"
                  {...register("department")}
                  defaultValue={user?.config?.department}
                  warning={errorsSignUp?.department?.message}
                />
              </LabelAndInput>
              <LabelAndInput>
                <label>Phone</label>
                <StyledInput
                  type="text"
                  {...register("phone", {
                    pattern: {
                      value: PHONE_REGEX,
                      message: "Enter a valid phone number",
                    },
                  })}
                  defaultValue={user?.config?.phone}
                  warning={errorsSignUp?.phone?.message}
                />
                {errorsSignUp?.phone && (
                  <ErrorMessage errorMessage={errorsSignUp?.phone.message} />
                )}
              </LabelAndInput>
            </SignUpFormLayout>
            <ButtonCTA
              $buttonType={BUTTON_TYPES.SECONDARY}
              $buttonSize={BUTTON_SIZES.MEDIUM_LARGE}
            >
              Update
            </ButtonCTA>
          </SignUpForm>
        </FormContainer>
      ) : (
        <form onSubmit={handleSubmit(onSubmitSignUp)}>
          <StyledSection>
            <StyledTwoFieldSection>
              <LabelAndInput>
                <label>Full Name *</label>
                <StyledInputGlobal
                  type="text"
                  {...register("full_name", {
                    required: "This field is required",
                  })}
                  defaultValue={user?.full_name}
                  warning={errorsSignUp?.full_name?.message}
                />
                {errorsSignUp?.full_name && (
                  <ErrorMessage
                    errorMessage={errorsSignUp?.full_name.message}
                  />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Email *</label>
                <StyledInputGlobal
                  type="text"
                  {...register("email", { required: "This field is required" })}
                  defaultValue={user?.email}
                  warning={errorsSignUp?.email?.message}
                  readOnly
                />
                {errorsSignUp?.email && (
                  <ErrorMessage errorMessage={errorsSignUp?.email.message} />
                )}
              </LabelAndInput>
            </StyledTwoFieldSection>
            <StyledTwoFieldSection>
              <LabelAndInput>
                <label>Job Title</label>
                <StyledInputGlobal
                  type="text"
                  {...register("job_title")}
                  defaultValue={user?.config?.job_title}
                  warning={errorsSignUp?.job_title?.message}
                />
              </LabelAndInput>
              <LabelAndInput>
                <label>Location</label>
                <StyledInputGlobal
                  type="text"
                  {...register("location")}
                  defaultValue={user?.config?.location}
                  warning={errorsSignUp?.location?.message}
                />
              </LabelAndInput>
            </StyledTwoFieldSection>
            <StyledTwoFieldSection>
              <LabelAndInput>
                <label>Department</label>
                <StyledInputGlobal
                  type="text"
                  {...register("department")}
                  defaultValue={user?.config?.department}
                  warning={errorsSignUp?.department?.message}
                />
              </LabelAndInput>
              <LabelAndInput>
                <label>Phone</label>
                <StyledInputGlobal
                  type="text"
                  {...register("phone", {
                    pattern: {
                      value: PHONE_REGEX,
                      message: "Enter a valid phone number",
                    },
                  })}
                  defaultValue={user?.config?.phone}
                  warning={errorsSignUp?.phone?.message}
                />
                {errorsSignUp?.phone && (
                  <ErrorMessage errorMessage={errorsSignUp?.phone.message} />
                )}
              </LabelAndInput>
            </StyledTwoFieldSection>
          </StyledSection>
          <BottomNav>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={handleClose}
            >
              Cancel
              <Discard />
            </ButtonCTA>
            <ButtonCTA
              $buttonType={BUTTON_TYPES.SECONDARY}
              $buttonSize={BUTTON_SIZES.SMALL}
            >
              Save
              <CheckMark />
            </ButtonCTA>
          </BottomNav>
        </form>
      )}
    </>
  );
};

export default UserProfileForm;
