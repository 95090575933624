import { InputHTMLAttributes, useEffect, useState } from "react";
import styled from "styled-components";

const SearchInput = styled.input`
  width: 37rem;
  height: 4.8rem;
  border: unset;
  border-radius: var(--border-radius-2l);
  background: url("/Search.svg") no-repeat right;
  background-color: white;
  border-right: inset 1rem transparent;
  padding: 0 1rem;
`;

/**
 * DebouncedInput component provides an input field with debounced onChange functionality.
 * @param {Object} props - The component props.
 * @param {string} props.value - The initial value of the input.
 * @param {function} props.onChange - The function to call when the input value changes.
 * @param {number} [props.debounce=500] - The debounce delay in milliseconds.
 * @returns {JSX.Element} The rendered component.
 */
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">): JSX.Element {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <SearchInput
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search Table"
    />
  );
}

export default DebouncedInput;
