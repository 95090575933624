import { Helmet } from "react-helmet";

/**
 * @description this is the documents page component used for routing
 * @returns Documents page overview
 */
const Documents = () => {
  return (
    <>
      <Helmet>
        <title>Capital Works | Documents</title>
      </Helmet>
      <div>Documents</div>
    </>
  );
};

export default Documents;
