import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../common/Constants";
import {
  createBulkProjectTypeAPI,
  createDropdownBulkAPI,
  deleteBulkDropdownAPI,
  deleteBulkProjectTypeAPI,
  getAllDropdownsAPI,
  getProjectTypeDropdown,
  updateDropdownItemsAPI,
} from "../services/apiDropdowns";
import { DropdownType, UpsertDropdownType } from "../types/dropdownTypes";
import { UpsertServiceType } from "../types/statusTypes";

export const useGetDropdownValues = () => {
  const {
    isPending: isDropdownLoading,
    data: dropdownItems,
    error,
  } = useQuery({
    queryFn: () => getAllDropdownsAPI({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.DROPDOWN_ITEMS],
  });

  return { isDropdownLoading, dropdownItems, error };
};

export const useGetProjectTypeDropdownValues = (fetchEnabled = true) => {
  const {
    isFetching: isProjectTypeLoading,
    data: projectTypeDropdown,
    error,
  } = useQuery({
    queryFn: () => getProjectTypeDropdown({ skip: 0, limit: -1 }),
    queryKey: [QUERY_KEYS.PROJECT_TYPE],
    enabled: fetchEnabled,
  });

  return { isProjectTypeLoading, projectTypeDropdown, error };
};

export const useUpdateDropdownItems = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateDropdownItems,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: DropdownType[]) => updateDropdownItemsAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DROPDOWN_ITEMS],
      });
    },
  });

  return { updateDropdownItems, isPending, error };
};

export const useCreateBulkDropdownItems = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createBulkDropdownItems,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertDropdownType[]) => createDropdownBulkAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DROPDOWN_ITEMS],
      });
    },
  });

  return { createBulkDropdownItems, isPending, error };
};

export const useDeleteBulkDropdownItems = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteBulkDropdownItems,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: number[]) => deleteBulkDropdownAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DROPDOWN_ITEMS],
      });
    },
  });

  return { deleteBulkDropdownItems, isPending, error };
};

export const useCreateBulkProjectType = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createBulkProjectType,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertServiceType[]) => createBulkProjectTypeAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_TYPE],
      });
    },
  });

  return { createBulkProjectType, isPending, error };
};

export const useDeleteBulkProjectType = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteBulkProjectType,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: number[]) => deleteBulkProjectTypeAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_TYPE],
      });
    },
  });

  return { deleteBulkProjectType, isPending, error };
};
