import moment from "moment";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Calendar from "../../assets/Calendar.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";
import Info from "../../assets/Info.svg?react";

import { DayPicker } from "react-day-picker";
import {
  Control,
  Controller,
  SubmitHandler,
  useForm,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import toast from "react-hot-toast";
import styled from "styled-components";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";

import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  DROPDOWN_CATEGORIES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectFinance,
  useEditProjectFinance,
} from "../../hooks/projects/useProjectFinance";
import { useGetDropdownValues } from "../../hooks/useDropdownValues";
import { FinanceFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  SectionPart,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import { getDropdownValues } from "../../utils/GetDropdownValue";
import { getEndMonth, getMinDate } from "../../utils/common";
import { useGetProjectById } from "../projects/useGetProjectById";

const StyledCurrencyInput = styled(CurrencyInput)<{ warning?: string }>`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid
    ${(props) =>
      props.warning ? "var(--color-warning)" : "var(--color-grey-100)"};
  height: 4.8rem;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

const SectionTitle = styled.div`
  & h1 {
    color: var(--color-section-headers);
  }
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
`;

const PopoverDiv = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 13rem;
  background-color: var(--color-grey-900);
  padding: 1.5rem;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  color: white;
  width: 25rem;
  box-shadow: var(--box-shadow-1);
  & p {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/**
 * FinanceForm component for creating and editing finance details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag indicating if the form is in editing mode.
 * @param {FinanceFormValues} [props.details] - The details of the finance (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the form (optional).
 * @returns {JSX.Element} The rendered component.
 */
const FinanceForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: FinanceFormValues;
  canUserEdit?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.details?.length > 60,
  );
  const [isCommentFieldExpanded, setIsCommentFieldExpanded] = useState(
    details && details.comments?.length > 60,
  );
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  const { setDayModalName } = useDayModalContext();
  const { isLoading, project } = useGetProjectById(projectId);
  const { start_year: projectStartYear, end_year: projectEndYear } =
    project || {};
  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();

  const expenseTypeDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.EXPENSE_TYPE,
    dropdownItems,
  );

  const expenseId =
    expenseTypeDropdown.find((value) => value.name === details?.financial_type)
      ?.id || "";

  const fundingSourceDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.FUNDING_SOURCE,
    dropdownItems,
  );

  const fundingSourceId =
    fundingSourceDropdown.find(
      (value) => value.name === details?.funding_source,
    )?.id || "";

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
    setFocus,
    getValues,
    resetField,
    reset,
  } = useForm<FinanceFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      financial_type: expenseId,
      funding_source: fundingSourceId,
      ongoing: details?.ongoing || "",
      details: details?.details || "",
      cost_type: details?.cost_type || "",
      start_year: details?.start_year || "",
      end_year: details?.end_year || "",
      percentage_per_year: details?.percentage_per_year || 0,
      est_amount: details?.est_amount || 0,
      budgeted_amount: details?.budgeted_amount || 0,
      commited_amount: details?.commited_amount || 0,
      actual_amount: details?.actual_amount || 0,
      balance_amount: details?.balance_amount || 0,
      comments: details?.comments || "",
    },
  });

  useEffect(() => {
    if (!isEditingMode) setFocus("financial_type");
    if (dropdownItems) {
      reset({
        financial_type: expenseId,
        funding_source: fundingSourceId,
      });
    }
  }, [
    setFocus,
    isEditingMode,
    expenseId,
    fundingSourceId,
    dropdownItems,
    reset,
  ]);

  const { isCreating, createProjectFinance } = useCreateProjectFinance();
  const { isEditing, editProjectFinance } = useEditProjectFinance();

  const [popoverVisible, setPopoverVisible] = useState(false);

  const onSubmit: SubmitHandler<FinanceFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectFinance(
        {
          financeDetails: {
            ...data,
            financial_type:
              expenseTypeDropdown.find(
                (value) => value.id === data.financial_type,
              )?.name || "",
            funding_source:
              fundingSourceDropdown.find(
                (value) => value.id === data.funding_source,
              )?.name || "",
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project finances saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectFinance(
        {
          financeDetails: {
            ...data,
            financial_type:
              expenseTypeDropdown.find(
                (value) => value.id === data.financial_type,
              )?.name || "",
            funding_source:
              fundingSourceDropdown.find(
                (value) => value.id === data.funding_source,
              )?.name || "",
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project finances saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof FinanceFormValues, id, { shouldDirty: true });
  };

  if (isDropdownLoading || isCreating || isEditing || isLoading)
    return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <SectionPart>
            <SectionTitle>
              <h1> General Information</h1>
            </SectionTitle>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Type *</label>
                <Controller
                  control={control}
                  name="financial_type"
                  rules={{ required: "This field is required" }}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="financial_type"
                      title=""
                      data={expenseTypeDropdown}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      isViewingMode={isViewingMode}
                    />
                  )}
                />
                {errors?.financial_type && (
                  <ErrorMessage errorMessage={errors.financial_type.message} />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Source of Funding *</label>
                <Controller
                  control={control}
                  name="funding_source"
                  rules={{ required: "This field is required" }}
                  render={({ field: { value } }) => (
                    <Dropdown
                      id="funding_source"
                      title=""
                      data={fundingSourceDropdown}
                      onSelect={handleSelect}
                      selectedId={`${value}`}
                      bgColor="var(--color-grey-100)"
                      isViewingMode={isViewingMode}
                    />
                  )}
                />
                {errors?.funding_source && (
                  <ErrorMessage errorMessage={errors.funding_source.message} />
                )}
              </LabelAndInput>
              <LabelAndInput>
                <label>Ongoing</label>
                <StyledInput
                  type="text"
                  {...register("ongoing")}
                  autoComplete="off"
                  warning={errors?.ongoing?.message}
                  aria-label="Ongoing"
                  readOnly={isViewingMode}
                />
              </LabelAndInput>{" "}
            </StyledThreeFieldSection>
            <StyledThreeFieldSection>
              <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
                <label>Description *</label>
                <ExpandableDiv
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                >
                  <StyledFullWidthInput
                    height={isBackgroundFieldExpanded ? "20rem" : ""}
                    {...register("details", {
                      required: "This field is required",
                      minLength: {
                        value: 3,
                        message: "Please enter at least 3 characters",
                      },
                      maxLength: {
                        value: 250,
                        message: "Please enter at most 250 characters",
                      },
                    })}
                    autoComplete="off"
                    warning={errors?.details?.message}
                    aria-label="Details"
                    readOnly={isViewingMode}
                  />
                  <PositionedSvg
                    onClick={() =>
                      setIsBackgroundFieldExpanded((prev) => !prev)
                    }
                  >
                    {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                  </PositionedSvg>
                </ExpandableDiv>
                {errors?.details && (
                  <ErrorMessage errorMessage={errors.details.message} />
                )}
              </LabelAndInput>{" "}
            </StyledThreeFieldSection>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Cost Type *</label>
                <StyledInput
                  type="text"
                  {...register("cost_type", {
                    required: "This field is required",
                  })}
                  autoComplete="off"
                  warning={errors?.cost_type?.message}
                  aria-label="Cost Type"
                  readOnly={isViewingMode}
                />
                {errors?.cost_type && (
                  <ErrorMessage errorMessage={errors.cost_type.message} />
                )}
              </LabelAndInput>
            </StyledThreeFieldSection>
          </SectionPart>
          <SectionPart>
            <SectionTitle>
              <h1>Timelines</h1>
            </SectionTitle>
            <StyledThreeFieldSection>
              <PositionedInput>
                <label>Start Year</label>
                <StyledInput
                  aria-label="Date"
                  type="text"
                  value={
                    getValues("start_year")
                      ? moment(getValues("start_year")).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : details?.start_year
                        ? moment(details?.start_year).format(
                            DAY_FORMATS.DAY_FIRST,
                          )
                        : ""
                  }
                  warning={errors?.start_year?.message}
                  onChange={() => {}}
                  readOnly={isViewingMode}
                />
                {errors?.start_year && (
                  <ErrorMessage errorMessage={errors.start_year.message} />
                )}
                {getValues("start_year") || details?.start_year
                  ? !isViewingMode && (
                      <ClearMessage
                        onClick={() => {
                          resetField("start_year", { defaultValue: "" });
                          if (details) {
                            details.start_year = "";
                          }
                        }}
                      >
                        Clear
                      </ClearMessage>
                    )
                  : ""}
                <PositionedCalendar
                  style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
                >
                  <Calendar
                    onClick={() =>
                      !isViewingMode && setDayModalName(DATE_MODAL_NAMES.START)
                    }
                  />
                </PositionedCalendar>
                <Controller
                  control={control}
                  name="start_year"
                  rules={{
                    // Ensure the start year is not before the project start year or the minimum allowed date
                    min: {
                      value: projectStartYear
                        ? moment(projectStartYear).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : getMinDate(isEditingMode),
                      message: "Please enter a valid start year",
                    },
                    // Ensure the start year is not after the end year or the project end year
                    max: {
                      value: getValues("end_year")
                        ? moment(getValues("end_year")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : projectEndYear
                          ? moment(projectEndYear).format(
                              DAY_FORMATS.YEAR_FIRST,
                            )
                          : "",
                      message: "Please enter a valid start year",
                    },
                    deps: ["end_year"], // Re-validate when end_year changes
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                      <DayPicker
                        mode="single"
                        selected={new Date(getValues().start_year)}
                        onSelect={(e) => {
                          onChange(() =>
                            setValue(
                              "start_year",
                              moment(e).format(DAY_FORMATS.YEAR_FIRST),
                            ),
                          );
                          setDayModalName("");
                        }}
                        onDayClick={onBlur}
                        captionLayout="dropdown"
                        endMonth={getEndMonth()}
                        defaultMonth={
                          getValues().start_year
                            ? new Date(getValues().start_year)
                            : new Date()
                        }
                      />
                    </DayPickerModal>
                  )}
                />
              </PositionedInput>
              <PositionedInput>
                <label>End Year</label>
                <StyledInput
                  aria-label="Date"
                  type="text"
                  value={
                    getValues("end_year")
                      ? moment(getValues("end_year")).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : details?.end_year
                        ? moment(details?.end_year).format(
                            DAY_FORMATS.DAY_FIRST,
                          )
                        : ""
                  }
                  warning={errors?.end_year?.message}
                  onChange={() => {}}
                  readOnly={isViewingMode}
                />
                {errors?.end_year && (
                  <ErrorMessage errorMessage={errors.end_year.message} />
                )}
                {getValues("end_year") || details?.end_year
                  ? !isViewingMode && (
                      <ClearMessage
                        onClick={() => {
                          resetField("end_year", { defaultValue: "" });
                          if (details) {
                            details.end_year = "";
                          }
                        }}
                      >
                        Clear
                      </ClearMessage>
                    )
                  : ""}
                <PositionedCalendar
                  style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
                >
                  <Calendar
                    onClick={() =>
                      !isViewingMode && setDayModalName(DATE_MODAL_NAMES.END)
                    }
                  />
                </PositionedCalendar>
                <Controller
                  control={control}
                  name="end_year"
                  rules={{
                    // Ensure the end year is not before the start year or the project start year
                    min: {
                      value: getValues("start_year")
                        ? moment(getValues("start_year")).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : projectStartYear
                          ? moment(projectStartYear).format(
                              DAY_FORMATS.YEAR_FIRST,
                            )
                          : getMinDate(isEditingMode),
                      message: "Please enter a valid end year",
                    },
                    // Ensure the end year is not after the project end year
                    max: {
                      value: projectEndYear
                        ? moment(projectEndYear).format(DAY_FORMATS.YEAR_FIRST)
                        : "",
                      message: "Please enter a valid end year",
                    },
                    deps: ["start_year"], // Re-validate when start_year changes
                  }}
                  render={({ field: { onChange, onBlur } }) => (
                    <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                      <DayPicker
                        mode="single"
                        selected={new Date(getValues().end_year)}
                        onSelect={(e) => {
                          onChange(() =>
                            setValue(
                              "end_year",
                              moment(e).format(DAY_FORMATS.YEAR_FIRST),
                            ),
                          );
                          setDayModalName("");
                        }}
                        showOutsideDays
                        onDayClick={onBlur}
                        captionLayout="dropdown"
                        endMonth={getEndMonth()}
                        defaultMonth={
                          getValues().end_year
                            ? new Date(getValues().end_year)
                            : new Date()
                        }
                      />
                    </DayPickerModal>
                  )}
                />
              </PositionedInput>
              <LabelAndInput>
                <label>% amount of total</label>
                <StyledInput
                  type="number"
                  {...register("percentage_per_year", {
                    max: { value: 100, message: "Max value allowed is 100" },
                  })}
                  autoComplete="off"
                  warning={errors?.percentage_per_year?.message}
                  aria-label="% amount of total"
                  readOnly={isViewingMode}
                />
                {errors?.percentage_per_year && (
                  <ErrorMessage
                    errorMessage={errors.percentage_per_year.message}
                  />
                )}
              </LabelAndInput>
            </StyledThreeFieldSection>
          </SectionPart>
          <SectionPart>
            <SectionTitle>
              <h1>Budget Criteria</h1>
              <Info
                onMouseEnter={() => setPopoverVisible(true)}
                onMouseLeave={() => setPopoverVisible(false)}
              />
              {popoverVisible && (
                <PopoverDiv>
                  <p>
                    <strong>Budgeted amount</strong> cannot be greater than
                    <strong> Estimated amount.</strong>
                  </p>
                  <p>
                    <strong>Committed amount</strong> cannot be greater than{" "}
                    <strong>Budgeted amount.</strong>
                  </p>
                  <p>
                    <strong>Actual amount </strong>cannot be greater than{" "}
                    <strong>Committed amount.</strong>
                  </p>
                </PopoverDiv>
              )}
            </SectionTitle>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Estimated Amount *</label>
                <Controller
                  control={control}
                  name="est_amount"
                  rules={{
                    required: "This field is required",
                    deps: ["budgeted_amount"],
                  }}
                  render={({ field: { onChange } }) => (
                    <StyledCurrencyInput
                      id="amount"
                      placeholder="$"
                      allowDecimals={false}
                      onValueChange={onChange}
                      prefix={"$"}
                      step={10}
                      defaultValue={details?.est_amount || 0}
                      autoComplete="off"
                      aria-label="Estimated Amount"
                      readOnly={isViewingMode}
                    />
                  )}
                />
              </LabelAndInput>{" "}
              <LabelAndInput>
                <label>Budgeted Amount</label>
                <Controller
                  control={control}
                  name="budgeted_amount"
                  rules={{
                    deps: ["est_amount", "commited_amount"],
                    validate: (value) =>
                      (value && +value <= +(getValues().est_amount || 0)) ||
                      !value ||
                      "Budgeted amount cannot be greater than Estimated amount",
                  }}
                  render={({ field: { onChange } }) => (
                    <StyledCurrencyInput
                      id="budgeted_amount"
                      placeholder="$"
                      allowDecimals={false}
                      onValueChange={onChange}
                      prefix={"$"}
                      step={10}
                      defaultValue={details?.budgeted_amount || 0}
                      autoComplete="off"
                      warning={errors?.budgeted_amount?.message}
                      aria-label="Budgeted Amount"
                      readOnly={isViewingMode}
                    />
                  )}
                />
                {errors?.budgeted_amount && (
                  <ErrorMessage errorMessage={errors.budgeted_amount.message} />
                )}
              </LabelAndInput>{" "}
              <LabelAndInput>
                <label>Committed Amount</label>
                <Controller
                  control={control}
                  name="commited_amount"
                  rules={{
                    deps: ["budgeted_amount", "actual_amount"],
                    validate: (value) =>
                      (value &&
                        +value <= +(getValues().budgeted_amount || 0)) ||
                      !value ||
                      "Committed amount cannot be greater than Budgeted amount",
                  }}
                  render={({ field: { onChange } }) => (
                    <StyledCurrencyInput
                      id="commited_amount"
                      placeholder="$"
                      allowDecimals={false}
                      onValueChange={onChange}
                      prefix={"$"}
                      step={10}
                      defaultValue={details?.commited_amount || 0}
                      autoComplete="off"
                      warning={errors?.commited_amount?.message}
                      aria-label="Committed Amount"
                      readOnly={isViewingMode}
                    />
                  )}
                />
                {errors?.commited_amount && (
                  <ErrorMessage errorMessage={errors.commited_amount.message} />
                )}
              </LabelAndInput>{" "}
            </StyledThreeFieldSection>
            <StyledThreeFieldSection>
              <LabelAndInput>
                <label>Actual Amount</label>
                <Controller
                  control={control}
                  name="actual_amount"
                  rules={{
                    deps: ["commited_amount"],
                    validate: (value) =>
                      (value &&
                        +value <= +(getValues().commited_amount || 0)) ||
                      !value ||
                      "Actual amount cannot be greater than Committed amount",
                  }}
                  render={({ field: { onChange } }) => (
                    <StyledCurrencyInput
                      id="actual_amount"
                      placeholder="$"
                      allowDecimals={false}
                      onValueChange={onChange}
                      prefix={"$"}
                      step={10}
                      defaultValue={details?.actual_amount || 0}
                      autoComplete="off"
                      warning={errors?.actual_amount?.message}
                      aria-label="Actual Amount"
                      readOnly
                    />
                  )}
                />
                {errors?.actual_amount && (
                  <ErrorMessage errorMessage={errors.actual_amount.message} />
                )}
              </LabelAndInput>{" "}
              <LabelAndInput>
                <label>Balance Amount</label>
                <GetBalanceAmount
                  control={control}
                  setValue={setValue}
                  defaultVal={details?.balance_amount || 0}
                />
              </LabelAndInput>{" "}
            </StyledThreeFieldSection>
          </SectionPart>
          <StyledThreeFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Comments</label>
              <ExpandableDiv height={isCommentFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isCommentFieldExpanded ? "20rem" : ""}
                  {...register("comments", {
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.comments?.message}
                  aria-label="Comments"
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsCommentFieldExpanded((prev) => !prev)}
                >
                  {isCommentFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
            </LabelAndInput>
          </StyledThreeFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default FinanceForm;

/**
 * GetBalanceAmount component for calculating and displaying the balance amount.
 *
 * @param {Object} props - The component props.
 * @param {Control<FinanceFormValues>} props.control - The control object from react-hook-form.
 * @param {UseFormSetValue<FinanceFormValues>} props.setValue - The setValue function from react-hook-form.
 * @param {number} props.defaultVal - The default value for the balance amount.
 * @returns {JSX.Element} The rendered component.
 */
const GetBalanceAmount = ({
  control,
  setValue,
  defaultVal,
}: {
  control: Control<FinanceFormValues>;
  setValue: UseFormSetValue<FinanceFormValues>;
  defaultVal: number;
}) => {
  const amounts = useWatch({
    control,
    name: ["budgeted_amount", "actual_amount"],
  });

  const result = (amounts[0] || 0) - (amounts[1] || 0);
  setValue("balance_amount", result);
  return (
    <StyledCurrencyInput
      value={result || defaultVal}
      placeholder="$"
      allowDecimals={false}
      prefix={"$"}
      readOnly
      defaultValue={defaultVal}
      aria-label="Balance Amount"
    />
  );
};
