import { useEffect, useRef } from "react";
import styled from "styled-components";
import WarningPopup from "../assets/WarningPopup.svg?react";
import { useDayModalContext } from "../context/DayPickerModalContext";
import ButtonCTA from "./ButtonCTA";
import { BottomNav } from "./FormElements";
import { BUTTON_SIZES, BUTTON_TYPES } from "../common/Constants";

const StyledModal = styled.div`
  position: fixed;
  border-radius: var(--border-radius-xl);
  border: 2px solid var(--color-grey-100);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 99;
  top: 60%;
  left: 55%;
  transform: translate(-50%, -50%);
  height: 30rem;
  width: 35rem;
`;

const StyledConfirmClosePopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  & h1 {
    font-size: 2rem;
    font-weight: 700;
    text-transform: none;
  }
  & p {
    font-size: 1.4rem;
  }
  & svg {
    height: 4.2rem !important;
    width: 4.2rem !important;
  }
`;

const StyledDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (this: HTMLElement, ev: KeyboardEvent) => any;
const useOnEscapeClick = (callback: () => void) => {
  useEffect(() => {
    const closeOnEscapeKey: Listener = (e) =>
      e.key === "Escape" ? callback() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [callback]);
};

function ConfirmClosePopup({
  wrapperId,
  onClose,
}: {
  wrapperId: string;
  onClose: () => void;
}) {
  const nodeRef = useRef(null);
  useOnEscapeClick(() => setDayModalName(""));

  const { currentModalName, setDayModalName } = useDayModalContext();

  if (currentModalName !== wrapperId) return null;

  return (
    <>
      <StyledModal ref={nodeRef}>
        <StyledConfirmClosePopup>
          <WarningPopup />
          <h1>Lose progress?</h1>
          <StyledDesc>
            <p>Are you sure you want to exit?</p>
            <p>You have unsaved changes.</p>
          </StyledDesc>
          <BottomNav style={{ padding: "2rem" }}>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              type="button"
              onClick={() => setDayModalName("")}
            >
              Go back
            </ButtonCTA>
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              onClick={() => {
                onClose();
                setDayModalName("");
              }}
            >
              Yes
            </ButtonCTA>
          </BottomNav>
        </StyledConfirmClosePopup>
      </StyledModal>
    </>
  );
}
export default ConfirmClosePopup;
