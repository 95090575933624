import {
  Project,
  ProjectConsultationType,
  ProjectDetailsType,
  ProjectFinanceType,
  ProjectImpactType,
  ProjectRiskType,
  ProjectScope,
  ProjectStakeholdersType,
  ProjectSuccessMeasure,
  ProjectWorkforceType,
  RelatedProjects,
  UpsertProject,
  UpsertProjectConsultation,
  UpsertProjectDetails,
  UpsertProjectFinance,
  UpsertProjectImpact,
  UpsertProjectRisk,
  UpsertProjectScope,
  UpsertProjectStakeholder,
  UpsertProjectSuccessMeasure,
  UpsertProjectWorkforce,
} from "../types/projectTypes";
import { client } from "./AxiosClient";

export const getAllProjectsAPI = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const response = await client.get(import.meta.env.VITE_ALL_PROJECTS_EP, {
    params: {
      skip,
      limit,
    },
  });
  if (response.status === 204) return [];
  return response.data as Project[];
};

export const getProjectByProjectIdAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_ALL_PROJECTS_EP + `${projectId}`,
  );
  return response.data as Project;
};

export const createProjectAPI = async (projectDetails: UpsertProject) => {
  const response = await client.post(import.meta.env.VITE_ALL_PROJECTS_EP, {
    ...projectDetails,
  });
  return response.data as Project;
};

export const updateProjectAPI = async (
  projectId: number,
  projectDetails: UpsertProject,
) => {
  const response = await client.patch(
    import.meta.env.VITE_ALL_PROJECTS_EP + `${projectId}`,
    {
      ...projectDetails,
    },
  );
  return response.data as Project;
};

export const getRelatedProjectsAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_RELATED_PROJECTS + `${projectId}`,
  );
  if (response.status === 204) return [];
  return response.data as RelatedProjects[];
};

export const getProjectDetailsAPI = async (projectId: number, type: string) => {
  const response = await client.get(
    import.meta.env[`${type}`] + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectDetailsType[];
};

export const createProjectDetailsAPI = async (
  projectDetails: UpsertProjectDetails,
  type: string,
) => {
  const response = await client.post(import.meta.env[`${type}`], {
    ...projectDetails,
  });
  return response.data as ProjectDetailsType;
};

export const updateProjectDetailsAPI = async (
  detailId: number,
  projectDetails: UpsertProjectDetails,
  type: string,
) => {
  const response = await client.patch(
    import.meta.env[`${type}`] + `${detailId}`,
    {
      ...projectDetails,
    },
  );
  return response.data as ProjectDetailsType;
};

export const deleteProjectDetailsAPI = async (
  detailId: number,
  type: string,
) => {
  const response = await client.delete(
    import.meta.env[`${type}`] + `${detailId}`,
  );
  return response.data as string;
};

export const updateRankingAPI = async (
  rankDetails: { id: number; sequence: number }[],
  type: string,
) => {
  const response = await client.patch(
    import.meta.env[`${type}`] + "bulkupdate/",
    rankDetails,
  );
  return response.data as string;
};

export const getProjectScopeAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_SCOPE + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectScope[];
};

export const createProjectScopeAPI = async (
  scopeDetails: UpsertProjectScope,
) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_SCOPE, {
    ...scopeDetails,
  });
  return response.data as ProjectScope;
};

export const updateProjectScopeAPI = async (
  scopeDetails: UpsertProjectScope,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_SCOPE + `${detailId}`,
    {
      ...scopeDetails,
    },
  );
  return response.data as ProjectScope;
};

export const deleteProjectScopeAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_SCOPE + `${detailId}`,
  );
  return response.data as string;
};

export const updateScopeRankingAPI = async (
  rankDetails: { id: number; sequence: number }[],
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_SCOPE + "bulkupdate/",
    rankDetails,
  );
  return response.data as string;
};

export const getProjectSuccessAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_SUCCESS + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectSuccessMeasure[];
};

export const createProjectSuccessAPI = async (
  successDetails: UpsertProjectSuccessMeasure,
) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_SUCCESS, {
    ...successDetails,
  });
  return response.data as ProjectSuccessMeasure;
};

export const updateProjectSuccessAPI = async (
  successDetails: UpsertProjectSuccessMeasure,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_SUCCESS + `${detailId}`,
    {
      ...successDetails,
    },
  );
  return response.data as ProjectSuccessMeasure;
};

export const deleteProjectSuccessAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_SUCCESS + `${detailId}`,
  );
  return response.data as string;
};

export const updateSuccessRankingAPI = async (
  rankDetails: { id: number; sequence: number }[],
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_SUCCESS + "bulkupdate/",
    rankDetails,
  );
  return response.data as string;
};

export const getProjectStakeholdersAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_STAKEHOLDERS + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectStakeholdersType[];
};

export const createProjectStakeholderAPI = async (
  stakeholderDetails: UpsertProjectStakeholder,
) => {
  const response = await client.post(
    import.meta.env.VITE_PROJECT_STAKEHOLDERS,
    {
      ...stakeholderDetails,
    },
  );
  return response.data as ProjectStakeholdersType;
};

export const updateProjectStakeholderAPI = async (
  stakeholderDetails: UpsertProjectStakeholder,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_STAKEHOLDERS + `${detailId}`,
    {
      ...stakeholderDetails,
    },
  );
  return response.data as ProjectStakeholdersType;
};

export const deleteProjectStakeholderAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_STAKEHOLDERS + `${detailId}`,
  );
  return response.data as string;
};

export const getProjectRisksAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_RISK + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectRiskType[];
};

export const createProjectRiskAPI = async (riskDetails: UpsertProjectRisk) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_RISK, {
    ...riskDetails,
  });
  return response.data as ProjectRiskType;
};

export const updateProjectRiskAPI = async (
  riskDetails: UpsertProjectRisk,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_RISK + `${detailId}`,
    {
      ...riskDetails,
    },
  );
  return response.data as ProjectRiskType;
};

export const deleteProjectRiskAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_RISK + `${detailId}`,
  );
  return response.data as string;
};

export const getProjectConsultationAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_CONSULTATION + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectConsultationType[];
};

export const createProjectConsultationAPI = async (
  consultationDetails: UpsertProjectConsultation,
) => {
  const response = await client.post(
    import.meta.env.VITE_PROJECT_CONSULTATION,
    {
      ...consultationDetails,
    },
  );
  return response.data as ProjectConsultationType;
};

export const updateProjectConsultationAPI = async (
  consultationDetails: UpsertProjectConsultation,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_CONSULTATION + `${detailId}`,
    {
      ...consultationDetails,
    },
  );
  return response.data as ProjectConsultationType;
};

export const deleteProjectConsultationAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_CONSULTATION + `${detailId}`,
  );
  return response.data as string;
};

export const getProjectImpactAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_IMPACT + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectImpactType[];
};

export const createProjectImpactAPI = async (
  impactDetails: UpsertProjectImpact,
) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_IMPACT, {
    ...impactDetails,
  });
  return response.data as ProjectImpactType;
};

export const updateProjectImpactAPI = async (
  impactDetails: UpsertProjectImpact,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_IMPACT + `${detailId}`,
    {
      ...impactDetails,
    },
  );
  return response.data as ProjectImpactType;
};

export const deleteProjectImpactAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_IMPACT + `${detailId}`,
  );
  return response.data as string;
};

export const getProjectWorkforcesAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_WORKFORCE + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectWorkforceType[];
};

export const createProjectWorkforceAPI = async (
  workforceDetails: UpsertProjectWorkforce,
) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_WORKFORCE, {
    ...workforceDetails,
  });
  return response.data as ProjectWorkforceType;
};

export const updateProjectWorkforceAPI = async (
  workforceDetails: UpsertProjectWorkforce,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_WORKFORCE + `${detailId}`,
    {
      ...workforceDetails,
    },
  );
  return response.data as ProjectWorkforceType;
};

export const deleteProjectWorkforceAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_WORKFORCE + `${detailId}`,
  );
  return response.data as string;
};

export const getProjectFinancesAPI = async (projectId: number) => {
  const response = await client.get(
    import.meta.env.VITE_PROJECT_FINANCIAL + `${projectId}/project/`,
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as ProjectFinanceType[];
};

export const createProjectFinanceAPI = async (
  financeDetails: UpsertProjectFinance,
) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_FINANCIAL, {
    ...financeDetails,
  });
  return response.data as ProjectFinanceType;
};

export const updateProjectFinanceAPI = async (
  financeDetails: UpsertProjectFinance,
  detailId: number,
) => {
  const response = await client.patch(
    import.meta.env.VITE_PROJECT_FINANCIAL + `${detailId}`,
    {
      ...financeDetails,
    },
  );
  return response.data as ProjectFinanceType;
};

export const deleteProjectFinanceAPI = async (detailId: number) => {
  const response = await client.delete(
    import.meta.env.VITE_PROJECT_FINANCIAL + `${detailId}`,
  );
  return response.data as string;
};
