import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createWBSApi,
  deleteWBSApi,
  getAllWBSApi,
  getWBSByCostCentreApi,
  getWBSByIdApi,
  getWBSByProjectIDApi,
  updateWBSApi,
} from "../../services/apiFinances";
import { UpsertWBS } from "../../types/financeTypes";

/**
 * Custom hook to fetch all WBS.
 * @returns {Object} An object containing loading state, WBS data, and error state.
 */
export const useGetAllWBS = () => {
  const {
    isPending: isLoading,
    data: wbs,
    error,
  } = useQuery({
    queryFn: () => getAllWBSApi(),
    queryKey: [QUERY_KEYS.WBS],
  });

  return { isLoading, wbs, error };
};

/**
 * Custom hook to fetch WBS by ID.
 * @param {string} id - The ID of the WBS to fetch.
 * @returns {Object} An object containing loading state, WBS data by ID, and error state.
 */
export const useGetWBSById = (id: string) => {
  const {
    isPending: isLoading,
    data: wbsById,
    error,
  } = useQuery({
    queryFn: () => getWBSByIdApi(id),
    queryKey: [QUERY_KEYS.WBS, id],
    enabled: !!id,
  });

  return { isLoading, wbsById, error };
};

/**
 * Custom hook to create a new WBS.
 * @returns {Object} An object containing the createWBS function, loading state, and error state.
 */
export const useCreateWBS = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createWBS,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertWBS) => createWBSApi(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WBS] });
    },
  });

  return { createWBS, isPending, error };
};

/**
 * Custom hook to update an existing WBS.
 * @returns {Object} An object containing the updateWBS function, loading state, and error state.
 */
export const useUpdateWBS = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateWBS,
    isPending,
    error,
  } = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpsertWBS }) =>
      updateWBSApi(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WBS] });
    },
  });

  return { updateWBS, isPending, error };
};

/**
 * Custom hook to delete a WBS.
 * @returns {Object} An object containing the deleteWBS function, loading state, and error state.
 */
export const useDeleteWBS = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteWBS,
    isPending,
    error,
  } = useMutation({
    mutationFn: (id: number) => deleteWBSApi(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WBS] });
    },
  });

  return { deleteWBS, isPending, error };
};

/**
 * Custom hook to fetch WBS by Project ID.
 * @param {number} projectId - The Project ID of the WBS to fetch.
 * @returns {Object} An object containing loading state, WBS data by Project ID, and error state.
 */
export const useGetWBSByProjectID = (projectId: number) => {
  const {
    isFetching: isLoading,
    data: wbsByProjectID,
    error,
  } = useQuery({
    queryFn: () => getWBSByProjectIDApi(projectId),
    queryKey: [QUERY_KEYS.WBS, projectId],
    enabled: !!projectId,
  });

  return { isLoading, wbsByProjectID, error };
};

/**
 * Custom hook to fetch WBS by Cost Centre ID.
 * @param {number} costCentreId - The Cost Centre ID of the WBS to fetch.
 * @returns {Object} An object containing loading state, WBS data by Cost Centre ID, and error state.
 */
export const useGetWBSByCostCentre = (costCentreId: number) => {
  const {
    isPending: isLoading,
    data: wbsByCostCentre,
    error,
  } = useQuery({
    queryFn: () => getWBSByCostCentreApi(costCentreId),
    queryKey: [QUERY_KEYS.WBS, costCentreId],
    enabled: !!costCentreId,
  });

  return { isLoading, wbsByCostCentre, error };
};
