import moment from "moment";
import { RAG, ROLES, TASK_STATUS } from "../common/Constants";

export const getFormattedCurrency = (value: number) => {
  if (!value) return "$0";

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export function* chunks<T>(
  arr: T[] | undefined,
  n: number,
): Generator<T[], void> {
  if (!arr || arr.length === 0) return;
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const getMinDate = (editing: boolean) =>
  editing ? "" : moment().format("YYYY-MM-DD");

export const getStartMonth = (currentDate: string | undefined) => {
  if (!currentDate) {
    return new Date(+moment().format("YYYY"), moment().month());
  }
  return new Date(
    +moment(currentDate).format("YYYY"),
    moment(currentDate).month(),
  );
};

export const getEndMonth = () =>
  new Date(+moment().add(10, "years").format("YYYY"), moment().month());

export const getDisabledValue = (when?: string) => ({
  before: when ? new Date(when) : new Date(),
});

export const getUserPermissions = (role: string) => {
  const canUserDelete = role === ROLES.SUPERUSER || role === ROLES.ADMIN;
  const canUserEdit = role !== ROLES.VIEWER;
  return { canUserDelete, canUserEdit };
};

/**
 * @description Get RAG status based on estimated and actual dates
 * @param est_start_date
 * @param est_end_date
 * @param actual_start_date
 * @param actual_end_date
 * @param status
 * @returns
 */
export const getRAGStatus = (
  est_start_date: string,
  est_end_date: string,
  actual_start_date: string,
  actual_end_date: string,
  status: string,
) => {
  if (!est_start_date) return RAG.GREEN;

  let ragStatus = RAG.GREEN;
  const today = moment();
  const estStartDate = moment(est_start_date);
  const actualStartDate = actual_start_date ? moment(actual_start_date) : today;
  const estStartDiff = estStartDate.diff(actualStartDate, "days");

  if (estStartDiff < 0) ragStatus = RAG.RED;
  if (estStartDiff > 0 && estStartDiff < 6) ragStatus = RAG.AMBER;

  if (!est_end_date && status.toLowerCase() === TASK_STATUS.TODO.toLowerCase())
    return ragStatus;

  const estEndDate = moment(est_end_date);
  const estDuration = estEndDate.diff(estStartDate, "days");
  const actualEndDate = actual_end_date ? moment(actual_end_date) : today;
  const estEndDiff = estEndDate.diff(actualEndDate, "days");
  const estEndDiffPercentage = (estEndDiff / estDuration) * 100;

  if (estEndDiffPercentage < 0) ragStatus = RAG.RED;
  else if (estEndDiffPercentage < 20) ragStatus = RAG.AMBER;
  else ragStatus = RAG.GREEN;

  if (
    status.toLowerCase() === TASK_STATUS.COMPLETED.toLowerCase() &&
    actualEndDate.isSameOrBefore(estEndDate)
  )
    ragStatus = RAG.GREEN;

  return ragStatus;
};
