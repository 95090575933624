import styled from "styled-components";

export const EditViewButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: end;
  align-items: center;
  margin-right: 2rem;
  margin-top: -4rem;
  font-weight: 500;
`;

export const Toggles = styled.div`
  background-color: var(--color-grey-100);
  display: flex;
  height: 4.8rem;
  border-radius: 24px;
  align-items: center;
  width: fit-content;
  justify-content: end;
  cursor: pointer;
`;

export const EditToggleButton = styled.div<{ $active?: boolean }>`
  background-color: ${(props) =>
    props.$active ? "var(--color-grey-900)" : "var(--color-grey-100)"};
  display: flex;
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;

  & svg {
    /* fill: ${(props) => !props.$active && "#22252A"}; */
    & path {
      fill: ${(props) => !props.$active && "#22252A"};
    }
  }
`;
