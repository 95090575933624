import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AtenLogo from "../assets/aten-logo.svg?react";
import UserProfileForm from "../features/forms/UserProfileForm";
import { useGetCurrentUser } from "../hooks/auth/useGetCurrentUser";
import Spinner from "../ui/Spinner";

const LoginLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  min-height: 100dvh;
  flex-direction: column;
  background-image: url("/BGLines.svg");
  background-repeat: repeat;
  gap: 4rem;
`;

const FormsContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  gap: 3rem;
  width: fit-content;
  border-radius: var(--border-radius-2xl);
  padding: 4rem;
`;

const Title = styled.h1`
  color: white;
  font-weight: 400;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: var(--color-version);
`;

/**
 * UpdateProfile component displays the user's profile form.
 * @returns {JSX.Element} The rendered UpdateProfile component.
 */
const UpdateProfile = () => {
  const navigate = useNavigate();
  const { autoRedirect } = useParams();

  const { isLoading, user } = useGetCurrentUser();
  const isProfileUpdated = !!user?.config?.is_profile_updated;

  // redirect user to home page if tenant and user profile are updated
  useEffect(() => {
    if (isProfileUpdated && autoRedirect === "1") {
      navigate("/home");
    }
  }, [isProfileUpdated, autoRedirect, navigate]);

  if (isLoading)
    return (
      <LoginLayout>
        <FormsContainer>
          <Spinner />
        </FormsContainer>
      </LoginLayout>
    );

  return (
    <>
      <LoginLayout>
        <Helmet>
          <title>Capital Works | Profile</title>
        </Helmet>
        <Title>
          capital<strong>WORX</strong>
        </Title>
        <FormsContainer>
          <UserProfileForm autoRedirect={autoRedirect === "1"} />
        </FormsContainer>
        <LogoDiv>
          <p>{import.meta.env.VITE_APP_VERSION} | Powered by</p>
          <AtenLogo />
        </LogoDiv>
      </LoginLayout>
    </>
  );
};

export default UpdateProfile;
