import {
  CostCentre,
  ProjectExpense,
  ProjectCostCentre,
  ProjectWBS,
  UpsertCostCentre,
  UpsertProjectExpense,
  UpsertProjectCostCentre,
  UpsertProjectWBS,
  UpsertWBS,
  WBS,
} from "../types/financeTypes";
import { client } from "./AxiosClient";

export const getAllWBSApi = async () => {
  const response = await client.get(import.meta.env.VITE_WBS);
  if (response.status === 204) return [];

  return response.data as WBS[];
};

export const getWBSByIdApi = async (id: string) => {
  const response = await client.get(`${import.meta.env.VITE_WBS}${id}`);
  return response.data as WBS;
};

export const createWBSApi = async (data: UpsertWBS) => {
  const response = await client.post(import.meta.env.VITE_WBS, data);
  return response.data as WBS;
};

export const updateWBSApi = async (id: number, data: UpsertWBS) => {
  const response = await client.patch(`${import.meta.env.VITE_WBS}${id}`, data);
  return response.data as WBS;
};

export const deleteWBSApi = async (id: number) => {
  const response = await client.delete(`${import.meta.env.VITE_WBS}${id}`);
  return response.data as string;
};

export const getWBSByProjectIDApi = async (projectId: number) => {
  const response = await client.get(
    `${import.meta.env.VITE_WBS}${projectId}/project/`,
  );

  if (response.status === 204) return [];

  return response.data as WBS[];
};

export const getWBSByCostCentreApi = async (costCentreId: number) => {
  const response = await client.get(
    `${import.meta.env.VITE_WBS}${costCentreId}/costcentre/`,
  );

  if (response.status === 204) return [];

  return response.data as WBS[];
};

export const getCostCentresApi = async () => {
  const response = await client.get(import.meta.env.VITE_COST_CENTRE);
  if (response.status === 204) return [];
  return response.data as CostCentre[];
};

export const getCostCentreByIdApi = async (id: number) => {
  const response = await client.get(`${import.meta.env.VITE_COST_CENTRE}${id}`);
  return response.data as CostCentre;
};

export const createCostCentreApi = async (data: UpsertCostCentre) => {
  const response = await client.post(import.meta.env.VITE_COST_CENTRE, data);
  return response.data as CostCentre;
};

export const updateCostCentreApi = async (
  id: number,
  data: UpsertCostCentre,
) => {
  const response = await client.patch(
    `${import.meta.env.VITE_COST_CENTRE}${id}`,
    data,
  );
  return response.data as CostCentre;
};

export const deleteCostCentreApi = async (id: number) => {
  const response = await client.delete(
    `${import.meta.env.VITE_COST_CENTRE}${id}`,
  );
  return response.data as string;
};

export const getProjectWBSApi = async (projectId: number) => {
  const response = await client.get(
    `${import.meta.env.VITE_PROJECT_WBS}${projectId}/project/`,
  );
  if (response.status === 204) return [];
  return response.data as ProjectWBS[];
};

export const createProjectWBSApi = async (data: UpsertProjectWBS) => {
  const response = await client.post(import.meta.env.VITE_PROJECT_WBS, data);
  return response.data as ProjectWBS;
};

export const deleteProjectWBSApi = async (id: number) => {
  const response = await client.delete(
    `${import.meta.env.VITE_PROJECT_WBS}${id}`,
  );
  return response.data as string;
};

export const getProjectCostCentresApi = async (projectId: number) => {
  const response = await client.get(
    `${import.meta.env.VITE_PROJECT_COST_CENTRES}${projectId}/project/`,
  );

  if (response.status === 204) return [];

  return response.data as ProjectCostCentre[];
};

export const createProjectCostCentreApi = async (
  data: UpsertProjectCostCentre,
) => {
  const response = await client.post(
    import.meta.env.VITE_PROJECT_COST_CENTRES,
    data,
  );
  return response.data as ProjectCostCentre;
};

export const deleteProjectCostCentreApi = async (id: number) => {
  const response = await client.delete(
    `${import.meta.env.VITE_PROJECT_COST_CENTRES}${id}`,
  );
  return response.data as string;
};

export const createProjectExpenseAPI = async (data: UpsertProjectExpense) => {
  const response = await client.post(import.meta.env.VITE_COST, data);
  return response.data as ProjectExpense;
};

export const updateProjectExpenseAPI = async (
  id: number,
  data: UpsertProjectExpense,
) => {
  const response = await client.patch(
    `${import.meta.env.VITE_COST}${id}`,
    data,
  );
  return response.data as ProjectExpense;
};

export const deleteProjectExpenseAPI = async (id: number) => {
  const response = await client.delete(`${import.meta.env.VITE_COST}${id}`);
  return response.data as string;
};

export const getProjectExpensesAPI = async (projectId: number) => {
  const response = await client.get(
    `${import.meta.env.VITE_COST}${projectId}/project/`,
  );
  return response.data as ProjectExpense[];
};
