import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AtenLogo from "../assets/aten-logo.svg?react";
import OrganizationForm from "../features/forms/OrganizationForm";
import { useGetCurrentUser } from "../hooks/auth/useGetCurrentUser";
import { useGetTenant } from "../hooks/auth/useTenant";
import Spinner from "../ui/Spinner";

const LoginLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  min-height: 100dvh;
  flex-direction: column;
  background-image: url("/BGLines.svg");
  background-repeat: repeat;
  gap: 4rem;
`;

const FormsContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  gap: 3rem;
  width: fit-content;
  border-radius: var(--border-radius-2xl);
  padding: 4rem;
`;

const Title = styled.h1`
  color: white;
  font-weight: 400;
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: var(--color-version);
`;

/**
 * UpdateOrganization component allows users to update their organization details.
 * Redirects to the home page if the organization is already updated on initial load.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const UpdateOrganization = () => {
  const navigate = useNavigate();
  const { autoRedirect } = useParams();

  const { isLoading, user } = useGetCurrentUser();

  // get user roles and check if tenant is updated with org details
  const domain = user?.email.split("@")[1] || "";
  const { isLoading: isTenantLoading, tenant } = useGetTenant(domain);
  const isOrgUpdated = !!tenant?.config?.is_org_updated;

  // redirect user to home page if tenant is updated
  useEffect(() => {
    if (isOrgUpdated && autoRedirect === "1") {
      navigate("/home");
    }
  }, [isOrgUpdated, autoRedirect, navigate]);

  if (isLoading || isTenantLoading)
    return (
      <LoginLayout>
        <FormsContainer>
          <Spinner />
        </FormsContainer>
      </LoginLayout>
    );

  return (
    <>
      <LoginLayout>
        <Helmet>
          <title>Capital Works | Profile</title>
        </Helmet>
        <Title>
          capital<strong>WORX</strong>
        </Title>
        <FormsContainer>
          <OrganizationForm autoRedirect />
        </FormsContainer>
        <LogoDiv>
          <p>{import.meta.env.VITE_APP_VERSION} | Powered by</p>
          <AtenLogo />
        </LogoDiv>
      </LoginLayout>
    </>
  );
};

export default UpdateOrganization;
