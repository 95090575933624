import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../common/Constants";
import {
  createProjectExpenseAPI,
  deleteProjectExpenseAPI,
  getProjectExpensesAPI,
  updateProjectExpenseAPI,
} from "../../services/apiFinances";
import { UpsertProjectExpense } from "../../types/financeTypes";

/**
 *
 * @param projectId - The ID of the project.
 * @returns
 */
export const useGetProjectExpenses = (projectId: number) => {
  const { data, isLoading, error } = useQuery({
    queryFn: () => getProjectExpensesAPI(projectId),
    queryKey: [QUERY_KEYS.PROJECT_EXPENSE, projectId],
    enabled: !!projectId,
  });

  return { projectExpenses: data, isLoading, error };
};

/**
 * Custom hook to create a project cost.
 * @returns {Object} - An object containing the createProjectExpense function, pending state, and error state.
 */
export const useCreateProjectExpense = () => {
  const queryClient = useQueryClient();
  const {
    mutate: createProjectExpense,
    isPending,
    error,
  } = useMutation({
    mutationFn: (data: UpsertProjectExpense) => createProjectExpenseAPI(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_EXPENSE],
      });
    },
  });

  return { createProjectExpense, isPending, error };
};

/**
 * Custom hook to update a project cost.
 * @returns {Object} - An object containing the updateProjectExpense function, pending state, and error state.
 */
export const useUpdateProjectExpense = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateProjectExpense,
    isPending,
    error,
  } = useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpsertProjectExpense }) =>
      updateProjectExpenseAPI(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_EXPENSE],
      });
    },
  });

  return { updateProjectExpense, isPending, error };
};

/**
 *
 * @returns {Object} - An object containing the deleteProjectExpense function, pending state, and error state.
 */
export const useDeleteProjectExpense = () => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteProjectExpense,
    isPending,
    error,
  } = useMutation({
    mutationFn: (id: number) => deleteProjectExpenseAPI(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROJECT_EXPENSE],
      });
    },
  });

  return { deleteProjectExpense, isPending, error };
};
