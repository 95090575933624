import { Role, SearchUserRole } from "../types/authTypes";
import { client } from "./AxiosClient";

export const getAllRolesAPI = async () => {
  const response = await client.get(import.meta.env.VITE_GET_ALL_ROLES, {
    params: {
      skip: 0,
      limit: -1,
    },
  });
  if (response.status === 204) return [];
  return response.data as Role[];
};

export const getUserRolesAPI = async (userId: string) => {
  const response = await client.get(
    import.meta.env.VITE_GET_USER_ROLES + userId + "/user/",
    {
      params: {
        skip: 0,
        limit: -1,
      },
    },
  );
  if (response.status === 204) return [];
  return response.data as SearchUserRole[];
};

export const getAllUserRolesAPI = async () => {
  const response = await client.get(import.meta.env.VITE_GET_USER_ROLES);
  if (response.status === 204) return [];
  return response.data as SearchUserRole[];
};
