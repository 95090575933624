import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";

import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  MODAL_NAMES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectDetails,
  useEditProjectDetails,
} from "../../hooks/projects/useUpsertProjectDetails";
import { DetailsFormValues } from "../../types/formFields";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import {
  BottomNav,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";

/**
 * DetailsForm component for creating and editing project details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {string} props.type - The type of the details.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditing - Flag indicating if the form is in editing mode.
 * @param {Object} [props.details] - The details of the project (optional).
 * @param {number} [props.details.detailId] - The ID of the detail (optional).
 * @param {string} [props.details.description] - The description of the detail (optional).
 * @param {number} [props.details.sequence] - The sequence of the detail (optional).
 * @param {number} [props.detailLength=0] - The length of the detail list (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the details (optional).
 * @returns {JSX.Element} The rendered component.
 */
const DetailsForm = ({
  projectId,
  type,
  handleClose,
  isEditing,
  details,
  detailLength = 0,
  canUserEdit,
}: {
  projectId: string;
  type: string;
  handleClose: () => void;
  isEditing: boolean;
  details?: {
    detailId: number;
    description: string;
    sequence: number;
  };
  detailLength?: number;
  canUserEdit?: boolean;
}) => {
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.description?.length > 90,
  );
  const [isViewingMode, setIsViewingMode] = useState(isEditing);
  const { setDayModalName } = useDayModalContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setFocus,
  } = useForm<DetailsFormValues>({
    defaultValues: {
      description: details?.description || "",
    },
  });

  useEffect(() => {
    if (!isEditing) setFocus("description");
  }, [setFocus, isEditing]);

  const { isLoading, createProjectDetail } = useCreateProjectDetails(type);
  const { isLoading: isEditLoading, editProjectDetails } =
    useEditProjectDetails(type);

  const onSubmit: SubmitHandler<DetailsFormValues> = (data, event) => {
    event?.preventDefault();
    if (isEditing) {
      editProjectDetails(
        {
          projectDetails: {
            project_id: +projectId,
            description: data.description,
            section_id: null,
            sequence: details?.sequence || 1,
          },
          detailId: details?.detailId,
        },
        {
          onSuccess: () => {
            toast.success(`Project details saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      createProjectDetail(
        {
          projectDetails: {
            project_id: +projectId,
            description: data.description,
            section_id: null,
            sequence: detailLength + 1,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project details saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };

  if (isLoading || isEditLoading) return <Spinner />;

  return (
    <>
      {isEditing && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledThreeFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors?.description?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description.message} />
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default DetailsForm;
