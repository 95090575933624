import moment from "moment";
import {
  Control,
  Controller,
  SubmitHandler,
  useForm,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Discard from "../../assets/Discard.svg?react";
import ButtonCTA from "../../ui/ButtonCTA";

import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { DayPicker } from "react-day-picker";
import toast from "react-hot-toast";
import styled from "styled-components";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  DROPDOWN_CATEGORIES,
} from "../../common/Constants";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import {
  useCreateProjectWorkforce,
  useEditProjectWorkforce,
} from "../../hooks/projects/useProjectWorkforce";
import { useGetDropdownValues } from "../../hooks/useDropdownValues";
import { WorkforceFormValues } from "../../types/formFields";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  StyledInput,
  StyledSection,
  StyledSingleFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getDropdownValues,
} from "../../utils/GetDropdownValue";
import { useGetProjectById } from "../projects/useGetProjectById";
import { useGetContacts } from "../../hooks/useGetContacts";

const StyledEffortInput = styled(CurrencyInput)<{ warning?: string }>`
  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-2l);
  padding: 1rem;
  border: 2px solid var(--color-grey-100);
  height: 4.8rem;
  font-weight: 500;
  outline-color: ${(props) => props.warning && "var(--color-warning)"};
`;

/**
 * WorkforceForm component for creating and editing workforce details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.handleClose - Function to handle closing the form.
 * @param {boolean} props.isEditingMode - Flag indicating if the form is in editing mode.
 * @param {WorkforceFormValues} [props.details] - The details of the workforce (optional).
 * @param {boolean} [props.canUserEdit] - Flag indicating if the user can edit the form.
 * @returns {JSX.Element} The rendered component.
 */
const WorkforceForm = ({
  projectId,
  handleClose,
  isEditingMode,
  details,
  canUserEdit,
}: {
  projectId: string;
  handleClose: () => void;
  isEditingMode: boolean;
  details?: WorkforceFormValues;
  canUserEdit?: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    setFocus,
    getValues,
    resetField,
  } = useForm<WorkforceFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { setDayModalName } = useDayModalContext();
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  useEffect(() => {
    if (!isEditingMode) setFocus("resource_type");
  }, [setFocus, isEditingMode]);

  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const { isContactsLoading, contacts } = useGetContacts();
  const { isCreating, createProjectWorkforce } = useCreateProjectWorkforce();
  const { isEditing, editProjectWorkforce } = useEditProjectWorkforce();
  const { isLoading, project } = useGetProjectById(projectId);

  const { start_year: projectStartYear, end_year: projectEndYear } =
    project || {};

  const resourceDropdown = getDropdownValues(
    DROPDOWN_CATEGORIES.RESOURCE_TYPE,
    dropdownItems,
  );

  const resourceId =
    resourceDropdown.find((value) => value.name === details?.resource_type)
      ?.id || "";

  const onSubmit: SubmitHandler<WorkforceFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectWorkforce(
        {
          workforceDetails: {
            ...data,
            resource_type:
              resourceDropdown.find((value) => value.id === data.resource_type)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project workforce saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectWorkforce(
        {
          workforceDetails: {
            ...data,
            resource_type:
              resourceDropdown.find((value) => value.id === data.resource_type)
                ?.name || "",
            project_id: +projectId,
            section_id: null,
          },
          detailId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project workforce saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };
  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof WorkforceFormValues, id, { shouldDirty: true });
  };

  if (
    isEditing ||
    isCreating ||
    isDropdownLoading ||
    isLoading ||
    isContactsLoading
  )
    return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Employment Type *</label>
              <Controller
                control={control}
                name="resource_type"
                defaultValue={resourceId}
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="resource_type"
                    title=""
                    data={resourceDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.resource_type && (
                <ErrorMessage errorMessage={errors.resource_type.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <PositionedInput>
              <label>Start Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("start_date")
                    ? moment(getValues("start_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.start_date
                      ? moment(details?.start_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                onChange={() => {}}
                warning={errors.start_date?.message}
                readOnly={isViewingMode}
              />
              {errors?.start_date && (
                <ErrorMessage errorMessage={errors.start_date.message} />
              )}
              {getValues("start_date") || details?.start_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("start_date", { defaultValue: "" });
                        if (details) {
                          details.start_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.START)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="start_date"
                defaultValue={details?.start_date}
                rules={{
                  min: {
                    value: projectStartYear
                      ? moment(projectStartYear).format(DAY_FORMATS.YEAR_FIRST)
                      : getMinDate(isEditingMode),
                    message: "Please enter a valid start date",
                  },
                  max: {
                    value: getValues("end_date")
                      ? moment(getValues("end_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : projectEndYear
                        ? moment(projectEndYear).format(DAY_FORMATS.YEAR_FIRST)
                        : "",
                    message: "Please enter a valid start date",
                  },
                  deps: ["end_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().start_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "start_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().start_date
                          ? new Date(getValues().start_date)
                          : new Date()
                      }
                      showOutsideDays
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <PositionedInput>
              <label>End date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("end_date")
                    ? moment(getValues("end_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.end_date
                      ? moment(details?.end_date).format(DAY_FORMATS.DAY_FIRST)
                      : ""
                }
                onChange={() => {}}
                warning={errors.end_date?.message}
                readOnly={isViewingMode}
              />
              {errors?.end_date && (
                <ErrorMessage errorMessage={errors.end_date.message} />
              )}
              {getValues("end_date") || details?.end_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("end_date", { defaultValue: "" });
                        if (details) {
                          details.end_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.END)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="end_date"
                defaultValue={details?.end_date}
                rules={{
                  min: {
                    value: getValues("start_date")
                      ? moment(getValues("start_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )
                      : projectStartYear
                        ? moment(projectStartYear).format(
                            DAY_FORMATS.YEAR_FIRST,
                          )
                        : getMinDate(isEditingMode),
                    message: "Please enter a valid end date",
                  },
                  max: {
                    value: projectEndYear
                      ? moment(projectEndYear).format(DAY_FORMATS.YEAR_FIRST)
                      : "",
                    message: "Please enter a valid end date",
                  },
                  deps: ["start_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().end_date)}
                      onSelect={(e) => {
                        const val = moment(e).format(DAY_FORMATS.YEAR_FIRST);
                        onChange(val);
                        setDayModalName("");
                      }}
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().end_date
                          ? new Date(getValues().end_date)
                          : new Date()
                      }
                      showOutsideDays
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Name</label>
              <Controller
                control={control}
                name="staff"
                defaultValue={details?.staff}
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="staff"
                    title=""
                    data={getContactDropdownValues(contacts)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Estimated Effort *</label>
              <GetEffortDays
                control={control}
                setValue={setValue}
                defaultVal={details?.hours || 0}
              />
              {errors?.hours && (
                <ErrorMessage errorMessage={errors.hours.message} />
              )}
            </LabelAndInput>
          </StyledSingleFieldSection>
          <StyledSingleFieldSection>
            <LabelAndInput>
              <label>Ongoing</label>
              <StyledInput
                type="text"
                {...register("ongoing")}
                defaultValue={details?.ongoing}
                autoComplete="off"
                warning={errors.ongoing?.message}
                readOnly={isViewingMode}
              />
            </LabelAndInput>{" "}
          </StyledSingleFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={handleClose}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
    </>
  );
};

export default WorkforceForm;

const GetEffortDays = ({
  control,
  setValue,
  defaultVal,
}: {
  control: Control<WorkforceFormValues>;
  setValue: UseFormSetValue<WorkforceFormValues>;
  defaultVal: number;
}) => {
  const dates = useWatch({
    control,
    name: ["start_date", "end_date"],
  });

  const result =
    dates[0] && dates[1] ? moment(dates[1]).diff(moment(dates[0]), "days") : 0;
  setValue("hours", result);
  return (
    <StyledEffortInput
      id="hours"
      placeholder="days"
      allowDecimals
      suffix={result === 1 ? " day" : " days"}
      step={1}
      autoComplete="off"
      defaultValue={defaultVal}
      readOnly
      value={result || defaultVal}
      aria-label="Estimated Effort"
    />
  );
};
