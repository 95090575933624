import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import CurrencyDollar from "../../assets/CurrencyDollar.svg?react";
import DeleteCon from "../../assets/DeleteCon.svg?react";
import EditAddIcon from "../../assets/EditAddIcon.svg?react";
import Edit from "../../assets/EditPencil.svg?react";
import File from "../../assets/File.svg?react";
import GeneralIcon from "../../assets/GeneralIcon.svg?react";
import GridFourIcon from "../../assets/GridFourIcon.svg?react";
import Groups from "../../assets/Groups.svg?react";
import Info from "../../assets/Info.svg?react";
import MembersIcon from "../../assets/MembersIcon.svg?react";

import Filter from "../../assets/Filter.svg?react";
import Notification from "../../assets/Notification.svg?react";
import Plus from "../../assets/Plus.svg?react";
import Search from "../../assets/Search.svg?react";

import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  DROPDOWN_CATEGORIES,
  MODAL_NAMES,
  MODAL_TYPE,
} from "../../common/Constants";
import { useActiveModalContext } from "../../context/ModalContext";
import {
  useGetAllUsers,
  useGetCurrentUser,
} from "../../hooks/auth/useGetCurrentUser";
import { User, UserDetailType } from "../../types/authTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import { ProjectHeader, SideContainer } from "../../ui/HeaderComponents";
import Icons from "../../ui/Icons";
import Modal from "../../ui/Modal";
import Spinner from "../../ui/Spinner";
import AddUserForm from "../forms/AddUserForm";
import ProfileLink from "../navigation/ProfileLink";

import styled from "styled-components";
import { USER_STATUS_MAP } from "../../common/ScheduleMap";
import NumberSpan from "../../ui/NumberSpan";

import { flexRender } from "@tanstack/react-table";
import moment from "moment";
import toast from "react-hot-toast";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useDeleteUser } from "../../hooks/auth/useRegisterUser";
import { useGetAllUserRoles } from "../../hooks/auth/useRoles";
import { useGetCostCentres } from "../../hooks/finances/useCostCentre";
import { useGetAllWBS } from "../../hooks/finances/useWBS";
import {
  useGetDropdownValues,
  useGetProjectTypeDropdownValues,
} from "../../hooks/useDropdownValues";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { DropdownType, GroupedDropdownType } from "../../types/dropdownTypes";
import { CostCentre, WBS } from "../../types/financeTypes";
import { CostCentreFormValues, WBSFormValues } from "../../types/formFields";
import DeletePopup from "../../ui/DeletePopup";
import { InfoTooltip } from "../../ui/InfoToolTip";
import NoDataCard from "../../ui/NoDataCard";
import PageNumbers from "../../ui/PageNumbers";
import ToggleButton from "../../ui/ToggleButton";
import DebouncedInput from "../../utils/DebouncedInput";
import CostCentreForm from "../forms/CostCentreForm";
import UpdateDropdownForm from "../forms/UpdateDropdownForm";
import WBSForm from "../forms/WBSForm";

const SearchFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const TableRowsContainer = styled.div`
  max-height: 62dvh;
  overflow: auto;
`;

const TableHeader = styled.div`
  display: grid;
  margin-top: 1.5rem;
  padding-left: 2.5rem;
  grid-template-columns: 1.5fr 2fr 4fr 1.5fr 0.5fr;
  gap: 1.2rem;
  background-color: var(--color-grey-400);
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-weight: 700;
  font-size: 1.2rem;
  justify-items: start;
  min-width: 100rem;
`;

const TableRow = styled.div`
  display: grid;
  margin: 1.5rem 0;
  padding-left: 2.5rem;
  grid-template-columns: 1.5fr 2fr 4fr 1.5fr 0.5fr;
  gap: 1.2rem;
  background-color: white;
  height: 8rem;
  align-items: center;
  border-radius: var(--border-radius-2l);
  font-size: 1.2rem;
  justify-items: start;
`;

const ProjectTableHeader = styled(TableHeader)`
  grid-template-columns: 1.5fr 1fr 6fr 0.25fr;
`;

const ProjectTableRow = styled(TableRow)`
  grid-template-columns: 1.5fr 1fr 6fr 0.25fr;
`;

const CostCentreTableHeader = styled(TableHeader)`
  grid-template-columns: 1.5fr 2fr repeat(2, 0.8fr) 1.2fr 1fr 0.3fr;
`;

const CostCentreTableRow = styled(TableRow)`
  grid-template-columns: 1.5fr 2fr repeat(2, 0.8fr) 1.2fr 1fr 0.3fr;
`;

const WBSTableHeader = styled(TableHeader)`
  grid-template-columns: 1.5fr 2fr 1.2fr 1fr 0.3fr;
`;

const WBSTableRow = styled(TableRow)`
  grid-template-columns: 1.5fr 2fr 1.2fr 1fr 0.3fr;
`;

const Pill = styled.p<{ $progressStatus: keyof typeof USER_STATUS_MAP }>`
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  color: white;
  background-color: ${(props) => USER_STATUS_MAP[props.$progressStatus]};
  border-radius: var(--border-radius-xl);
  font-weight: 500;
  min-width: 9.6rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  color: var(--color-grey-500);

  :first-child {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--color-grey-900);
    text-transform: capitalize;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 2rem 0;
`;

const ItemCount = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const NoDataDiv = styled.div`
  margin-top: 2rem;
`;

const EditDeleteContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  padding-right: 2.5rem;
  align-items: center;
  position: relative;
  & svg {
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const Toggles = styled.div`
  background-color: white;
  display: flex;
  border-radius: var(--border-radius-2l);
  height: 4.8rem;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const SectionSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(25rem, 1fr));
  gap: 2rem;
  margin: 2rem 0 4rem;
`;

const SectionTile = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: var(--border-radius-xl);
  background-color: white;
  min-height: 14rem;
  padding: 2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-grey-900);
  cursor: pointer;
  border: 2px dashed
    ${(props) => (props.$isActive ? "var(--color-grey-900)" : "transparent")};
`;

const ExtraItemSpan = styled.span`
  color: var(--color-button-secondary);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

const SECTIONS = {
  MEMBERS: "Members",
  PROJECTS: "Projects",
  FINANCIALS: "Financials",
  GENERAL: "General",
};

const projectCategories = [
  DROPDOWN_CATEGORIES.RISK_TYPE,
  DROPDOWN_CATEGORIES.STAKEHOLDER_ROLES,
  DROPDOWN_CATEGORIES.STAKEHOLDER_TYPE,
  DROPDOWN_CATEGORIES.RESOURCE_TYPE,
];

const financialCategories = [
  DROPDOWN_CATEGORIES.FUNDING_SOURCE,
  DROPDOWN_CATEGORIES.EXPENSE_TYPE,
];

const FinanceTabs = {
  GROUPS: "Groups",
  COST_CENTRE: "Cost Centres",
  WBS: "WBS",
};

/**
 * AdminOverview component displays the admin dashboard with user management functionalities.
 * @returns {JSX.Element} The rendered component.
 */
const AdminOverview = () => {
  const [selectedSelection, setSelectedSelection] = useState(SECTIONS.MEMBERS);
  return (
    <>
      <ProjectHeader>
        <h1>Settings & Configuration</h1>
        <SideContainer>
          <Icons>
            {/* todo: search functionality */}
            <Search />
          </Icons>
          <Icons>
            {/* todo: notifications functionality */}
            <Notification />
          </Icons>
          <ProfileLink />
        </SideContainer>
      </ProjectHeader>
      <Header>
        <File />
        <p>Sections</p>
      </Header>
      <SectionSelector>
        <SectionTile
          $isActive={selectedSelection === SECTIONS.MEMBERS}
          onClick={() => setSelectedSelection(SECTIONS.MEMBERS)}
        >
          <MembersIcon />
          <span>Members</span>
        </SectionTile>
        <SectionTile
          $isActive={selectedSelection === SECTIONS.PROJECTS}
          onClick={() => setSelectedSelection(SECTIONS.PROJECTS)}
        >
          <GridFourIcon />
          <span>Projects</span>
        </SectionTile>{" "}
        <SectionTile
          $isActive={selectedSelection === SECTIONS.FINANCIALS}
          onClick={() => setSelectedSelection(SECTIONS.FINANCIALS)}
        >
          <CurrencyDollar />
          <span>Financials</span>
        </SectionTile>{" "}
        <SectionTile
          $isActive={selectedSelection === SECTIONS.GENERAL}
          onClick={() => setSelectedSelection(SECTIONS.GENERAL)}
        >
          <GeneralIcon />
          <span>General</span>
        </SectionTile>
      </SectionSelector>
      {selectedSelection === SECTIONS.MEMBERS && <UserTableRenderer />}
      {selectedSelection === SECTIONS.PROJECTS && (
        <GroupTableRenderer
          categories={projectCategories}
          fetchIndependentItems
        />
      )}
      {selectedSelection === SECTIONS.FINANCIALS && <FinanceTabRenderer />}
      {selectedSelection === SECTIONS.GENERAL && <GeneralTableRenderer />}
    </>
  );
};

export default AdminOverview;

const UserTableRenderer = () => {
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);
  const [isUserTabActive, setIsUserTabActive] = useState(true);
  const { setCurrentModalName } = useActiveModalContext();
  const { setDayModalName } = useDayModalContext();
  const [details, setDetails] = useState<UserDetailType | null>(null);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { isLoading: isUserLoading, user } = useGetCurrentUser();
  const { isLoading, users, error } = useGetAllUsers();
  const { isLoading: isUserRolesLoading, allUserRoles } = useGetAllUserRoles();
  const { isDeleting, deleteUser } = useDeleteUser();

  const handleDelete = (userId: string) => {
    deleteUser(
      { userId },
      {
        onSuccess: () => toast.success("User deleted successfully."),
      },
    );
  };

  const finalUsers = useMemo(
    () =>
      users
        ?.map((user) => {
          const userRoles =
            allUserRoles?.filter((userRole) => userRole.user_id === user.id) ||
            [];
          return {
            ...user,
            role: userRoles?.find((role) => !role.project_id)?.role,
          };
        })
        .filter((userData) => user?.email !== userData.email),
    [user, users, allUserRoles],
  );

  // users table setup
  const columnHelper = createColumnHelper<User>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("full_name", {
        header: "Name",
        id: "full_name",
      }),
      columnHelper.accessor("email", {
        header: "Email",
        id: "email",
      }),
      columnHelper.accessor("role.title", {
        header: "Role",
        id: "role",
      }),
      columnHelper.accessor("role.id", {
        header: "Role",
        id: "role_id",
      }),
      columnHelper.accessor("config", {
        header: "Config",
        id: "config",
      }),
      columnHelper.accessor("config.status", {
        header: "Status",
        id: "status",
      }),
    ],
    [columnHelper],
  );

  // React table config
  const table = useReactTable({
    data: finalUsers ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
        config: false,
        role_id: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  if (isLoading || isDeleting || isUserRolesLoading || isUserLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  if (!users?.length || error) {
    return (
      <>
        <NoDataDiv>
          <NoDataCard
            height="8rem"
            title="Users"
            buttonLinked
            handleClick={() => {
              setCurrentModalName(MODAL_NAMES.NEW_USER);
              setIsNewUserOpen(true);
            }}
          />
        </NoDataDiv>
        <Modal
          isOpen={isNewUserOpen}
          title="User"
          wrapperId={MODAL_NAMES.NEW_USER}
          modalWidth="50%"
          type={MODAL_TYPE.NEW}
        >
          <AddUserForm
            handleClose={() => {
              setCurrentModalName("");
              setIsNewUserOpen(false);
            }}
            userDetails={null}
          />
        </Modal>
      </>
    );
  }

  return (
    <>
      <SearchFilter>
        <Toggles>
          <ToggleButton
            $buttonSize="xl"
            $buttonActive={isUserTabActive}
            onClick={() => setIsUserTabActive(true)}
          >
            Organization Users
          </ToggleButton>
          <ToggleButton
            $buttonSize="xl"
            $buttonActive={!isUserTabActive}
            onClick={() => setIsUserTabActive(false)}
          >
            External Contacts
          </ToggleButton>
        </Toggles>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
          <Icons>
            <Filter />
          </Icons>
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            $buttonSize={BUTTON_SIZES.SMALL}
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.NEW_USER);
              setIsNewUserOpen(true);
            }}
          >
            add
            <Plus />
          </ButtonCTA>
          <Modal
            isOpen={isNewUserOpen}
            title="User"
            wrapperId={MODAL_NAMES.NEW_USER}
            modalWidth="50%"
            type={MODAL_TYPE.NEW}
          >
            <AddUserForm
              handleClose={() => {
                setCurrentModalName("");
                setIsNewUserOpen(false);
              }}
              userDetails={null}
            />
          </Modal>
        </FlexContainer>
      </SearchFilter>
      <TableHeader id="user-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          )),
        )}
        <EditDeleteContainer>
          <Info
            onMouseEnter={() => setIsInfoVisible(true)}
            onMouseLeave={() => setIsInfoVisible(false)}
            style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
          />
          {isInfoVisible && (
            <InfoTooltip>
              <span>Click on a header to sort the table by that column.</span>
            </InfoTooltip>
          )}
          <DeleteCon style={{ visibility: "hidden" }} />
        </EditDeleteContainer>
      </TableHeader>
      <>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} id={`${row.id}-user`}>
              <Title>
                <span>{row.getValue("full_name")}</span>
              </Title>
              <span>{row.getValue("email") || "N/A"}</span>
              <span>{row.getValue("role") || "N/A"}</span>
              <Pill
                $progressStatus={
                  row.getValue("status") as keyof typeof USER_STATUS_MAP
                }
              >
                <span>{row.getValue("status")}</span>
              </Pill>
              <EditDeleteContainer>
                <Edit
                  onClick={() => {
                    setDetails({
                      id: row.getValue("id"),
                      full_name: row.getValue("full_name"),
                      email: row.getValue("email"),
                      role_id: row.getValue("role_id"),
                      config: row.getValue("config"),
                    });
                    setCurrentModalName(`Edit ${MODAL_NAMES.NEW_USER}`);
                    setIsNewUserOpen(true);
                  }}
                />
                <DeleteCon
                  onClick={() =>
                    setDayModalName(
                      `${DATE_MODAL_NAMES.DELETE}-${row.getValue("id")}`,
                    )
                  }
                />
                <DeletePopup
                  wrapperId={`${DATE_MODAL_NAMES.DELETE}-${row.getValue("id")}`}
                  onDelete={() => handleDelete(row.getValue("id"))}
                />
              </EditDeleteContainer>
            </TableRow>
          ))}
        </TableRowsContainer>
        <Modal
          isOpen={isNewUserOpen}
          title="User"
          wrapperId={`Edit ${MODAL_NAMES.NEW_USER}`}
          modalWidth="50%"
          type={MODAL_TYPE.EDIT}
        >
          <AddUserForm
            handleClose={() => {
              setCurrentModalName("");
              setIsNewUserOpen(false);
            }}
            isEditMode
            userDetails={details}
          />
        </Modal>
        <Pagination>
          <ItemCount>
            <span style={{ fontSize: "1.2rem" }}>Show:</span>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 10}
              onClick={() => table.setPageSize(10)}
            >
              10
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 20}
              onClick={() => table.setPageSize(20)}
            >
              20
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 50}
              onClick={() => table.setPageSize(50)}
            >
              50
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 100}
              onClick={() => table.setPageSize(100)}
            >
              100
            </NumberSpan>
          </ItemCount>
          <PageNumbers table={table} />
        </Pagination>
      </>
    </>
  );
};

function groupDropdownItems(
  dropdownItems: DropdownType[] | undefined,
  categories: string[],
) {
  const groupedItems = dropdownItems
    ?.filter((item) => categories.includes(item.category))
    .reduce(
      (acc, item) => {
        if (!acc[item.category]) {
          acc[item.category] = [];
        }
        acc[item.category].push({ id: item.id, title: item.title });
        return acc;
      },
      {} as Record<string, { id: number; title: string }[]>,
    );

  return Object.keys(groupedItems || {}).map((heading) => ({
    heading,
    values: groupedItems?.[heading] ?? [],
  }));
}

const GroupTableRenderer = ({
  categories,
  showTitle = true,
  fetchIndependentItems = false,
}: {
  categories: string[];
  showTitle?: boolean;
  fetchIndependentItems?: boolean;
}) => {
  const { isDropdownLoading, dropdownItems } = useGetDropdownValues();
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "heading",
      desc: false,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const { setCurrentModalName } = useActiveModalContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const groupedArray: GroupedDropdownType[] = useMemo(
    () => groupDropdownItems(dropdownItems, categories),
    [dropdownItems, categories],
  );

  const { isProjectTypeLoading, projectTypeDropdown } =
    useGetProjectTypeDropdownValues(fetchIndependentItems);
  const { isServiceLoading, services } = useGetServiceValues(
    fetchIndependentItems,
  );

  const additionalGroups = useMemo(() => {
    const groups = [];
    if (projectTypeDropdown?.length && fetchIndependentItems) {
      groups.push({
        heading: DROPDOWN_CATEGORIES.PROJECT_TYPE,
        values: projectTypeDropdown.map((item) => ({
          id: item.id,
          title: item.title,
        })),
      });
    }

    if (services?.length && fetchIndependentItems) {
      groups.push({
        heading: DROPDOWN_CATEGORIES.SERVICES,
        values: services.map((item) => ({
          id: item.id,
          title: item.title,
        })),
      });
    }
    return groups;
  }, [projectTypeDropdown, services, fetchIndependentItems]);

  const groupedArrayWithAdditionalGroups = useMemo(
    () => [...groupedArray, ...additionalGroups],
    [groupedArray, additionalGroups],
  );

  const columnHelper = createColumnHelper<GroupedDropdownType>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("heading", {
        header: "Group",
        id: "heading",
      }),
      columnHelper.accessor((row) => row.values.length, {
        header: "Count",
        id: "count",
      }),
      columnHelper.accessor("values", {
        header: "Items",
        id: "values",
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: groupedArrayWithAdditionalGroups ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
  });

  if (isDropdownLoading || isProjectTypeLoading || isServiceLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  return (
    <>
      <SearchFilter style={{ marginTop: !showTitle ? "-4.5rem" : "" }}>
        <Header style={{ visibility: showTitle ? "visible" : "hidden" }}>
          <Groups />
          <p>Groups</p>
        </Header>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
        </FlexContainer>
      </SearchFilter>
      <ProjectTableHeader id="project-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          )),
        )}
        <EditDeleteContainer>
          <Info
            onMouseEnter={() => setIsInfoVisible(true)}
            onMouseLeave={() => setIsInfoVisible(false)}
            style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
          />
          {isInfoVisible && (
            <InfoTooltip>
              <span>Click on a header to sort the table by that column.</span>
            </InfoTooltip>
          )}
        </EditDeleteContainer>
      </ProjectTableHeader>
      {groupedArray?.length ? (
        <>
          <TableRowsContainer>
            {table.getRowModel().rows.map((row) => (
              <ProjectTableRow key={row.id} id={`${row.id}-project`}>
                <Title>
                  <span>{row.getValue("heading")}</span>
                </Title>
                <span>{row.getValue("count")}</span>
                <p>
                  {(
                    row.getValue("values") as { id: string; title: string }[]
                  ).map((value, index) => (
                    <span key={value.id}>
                      {index < 5
                        ? value.title +
                          (index + 1 === row.getValue("count") || index === 4
                            ? ""
                            : ", ")
                        : ""}
                      <ExtraItemSpan
                        onClick={() => {
                          setCurrentModalName(MODAL_NAMES.UPDATE_DROPDOWN);
                          setCategoryName(row.getValue("heading") as string);
                          setIsModalOpen(true);
                        }}
                      >
                        {index === 4 && (row.getValue("count") as number) > 5
                          ? `  +${(row.getValue("count") as number) - 5} more`
                          : ""}
                      </ExtraItemSpan>
                    </span>
                  ))}
                </p>
                <EditDeleteContainer>
                  <EditAddIcon
                    style={{
                      height: "2rem",
                      width: "2rem",
                    }}
                    onClick={() => {
                      setCurrentModalName(MODAL_NAMES.UPDATE_DROPDOWN);
                      setCategoryName(row.getValue("heading") as string);
                      setIsModalOpen(true);
                    }}
                  />
                </EditDeleteContainer>
              </ProjectTableRow>
            ))}
          </TableRowsContainer>
          <Modal
            isOpen={isModalOpen}
            title={categoryName}
            wrapperId={MODAL_NAMES.UPDATE_DROPDOWN}
            modalWidth="40%"
          >
            <UpdateDropdownForm
              dropdownItems={
                groupedArrayWithAdditionalGroups.find(
                  (group) => group.heading === categoryName,
                ) || { heading: "", values: [] }
              }
              dropdownCategory={categoryName}
              handleClose={() => {
                setCurrentModalName("");
                setIsModalOpen(false);
              }}
            />
          </Modal>
        </>
      ) : (
        <NoDataDiv>
          <NoDataCard height="8rem" title="Items" buttonLinked />
        </NoDataDiv>
      )}
    </>
  );
};

const FinanceTabRenderer = () => {
  const [selectedTab, setSelectedTab] = useState(FinanceTabs.GROUPS);

  return (
    <>
      <SearchFilter>
        <Toggles>
          <ToggleButton
            $buttonSize=""
            $buttonActive={selectedTab === FinanceTabs.GROUPS}
            onClick={() => setSelectedTab(FinanceTabs.GROUPS)}
          >
            {FinanceTabs.GROUPS}
          </ToggleButton>
          <ToggleButton
            $buttonSize=""
            $buttonActive={selectedTab === FinanceTabs.COST_CENTRE}
            onClick={() => setSelectedTab(FinanceTabs.COST_CENTRE)}
          >
            {FinanceTabs.COST_CENTRE}
          </ToggleButton>
          <ToggleButton
            $buttonSize=""
            $buttonActive={selectedTab === FinanceTabs.WBS}
            onClick={() => setSelectedTab(FinanceTabs.WBS)}
          >
            {FinanceTabs.WBS}
          </ToggleButton>
        </Toggles>
      </SearchFilter>
      {selectedTab === FinanceTabs.GROUPS && (
        <GroupTableRenderer
          categories={financialCategories}
          showTitle={false}
        />
      )}
      {selectedTab === FinanceTabs.COST_CENTRE && <CostCentreTableRenderer />}
      {selectedTab === FinanceTabs.WBS && <WBSTableRenderer />}
    </>
  );
};

const GeneralTableRenderer = () => {
  return <NoDataCard title="data" height="6rem" />;
};

const initialCostCentreState: CostCentreFormValues = {
  title: "",
  description: "",
  start_date: "",
  end_date: "",
  contact_id: 0,
  ext_erp_id: "",
};
const CostCentreTableRenderer = () => {
  const [isNewCostCentreOpen, setIsNewCostCentreOpen] = useState(false);
  const { setCurrentModalName } = useActiveModalContext();
  const [details, setDetails] = useState<CostCentreFormValues>(
    initialCostCentreState,
  );

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "title",
      desc: false,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { isLoading, costCentres, error } = useGetCostCentres();
  const { isContactsLoading, contacts } = useGetContacts();

  // cost centre table setup
  const columnHelper = createColumnHelper<CostCentre>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("start_date", {
        header: "Start Date",
        id: "start_date",
      }),
      columnHelper.accessor("end_date", {
        header: "End Date",
        id: "end_date",
      }),
      columnHelper.accessor("contact_id", {
        header: "Manager",
        id: "contact_id",
      }),
      columnHelper.accessor("ext_erp_id", {
        header: "External ERP",
        id: "ext_erp_id",
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: costCentres || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  if (isLoading || isContactsLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  if (!costCentres?.length || error) {
    return (
      <div style={{ marginTop: "2rem" }}>
        <NoDataCard
          title="Cost Centre"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.COST_CENTRE);
            setIsNewCostCentreOpen(true);
          }}
        />
        <Modal
          isOpen={isNewCostCentreOpen}
          title={MODAL_NAMES.COST_CENTRE}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.COST_CENTRE}
          modalWidth="50%"
        >
          <CostCentreForm
            handleClose={() => {
              setCurrentModalName("");
              setIsNewCostCentreOpen(false);
            }}
          />
        </Modal>
      </div>
    );
  }

  return (
    <>
      <SearchFilter style={{ marginTop: "-4.5rem" }}>
        <Header style={{ visibility: "hidden" }}>
          <Groups />
          <p>Groups</p>
        </Header>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            $buttonSize={BUTTON_SIZES.SMALL}
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.COST_CENTRE);
              setIsNewCostCentreOpen(true);
            }}
          >
            add
            <Plus />
          </ButtonCTA>
        </FlexContainer>
      </SearchFilter>
      <Modal
        isOpen={isNewCostCentreOpen}
        title={MODAL_NAMES.COST_CENTRE}
        wrapperId={MODAL_NAMES.COST_CENTRE}
        modalWidth="40%"
        type={MODAL_TYPE.NEW}
      >
        <CostCentreForm
          handleClose={() => {
            setCurrentModalName("");
            setIsNewCostCentreOpen(false);
          }}
        />
      </Modal>
      <CostCentreTableHeader id="cost-centre-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          )),
        )}
        <EditDeleteContainer>
          <Info
            onMouseEnter={() => setIsInfoVisible(true)}
            onMouseLeave={() => setIsInfoVisible(false)}
            style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
          />
          {isInfoVisible && (
            <InfoTooltip>
              <span>Click on a header to sort the table by that column.</span>
            </InfoTooltip>
          )}
          <DeleteCon style={{ visibility: "hidden" }} />
        </EditDeleteContainer>
      </CostCentreTableHeader>
      <>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <CostCentreTableRow key={row.id} id={`${row.id}-cost-centre`}>
              <Title>
                <span>{row.getValue("title")}</span>
              </Title>
              <span>{row.getValue("description")}</span>
              <span>
                {row.getValue("start_date")
                  ? moment(row.getValue("start_date")).format(
                      DAY_FORMATS.YEAR_FIRST,
                    )
                  : "N/A"}
              </span>
              <span>
                {row.getValue("end_date")
                  ? moment(row.getValue("end_date")).format(
                      DAY_FORMATS.YEAR_FIRST,
                    )
                  : "N/A"}
              </span>
              <span>
                {
                  contacts?.find(
                    (contact) => contact.id === row.getValue("contact_id"),
                  )?.email
                }
              </span>
              <span>{row.getValue("ext_erp_id") || "N/A"}</span>
              <EditDeleteContainer>
                <Edit
                  onClick={() => {
                    setCurrentModalName(`Edit ${MODAL_NAMES.COST_CENTRE}`);
                    setIsNewCostCentreOpen(true);
                    setDetails({
                      title: row.getValue("title"),
                      description: row.getValue("description"),
                      start_date: row.getValue("start_date"),
                      end_date: row.getValue("end_date"),
                      contact_id: row.getValue("contact_id"),
                      ext_erp_id: row.getValue("ext_erp_id"),
                      id: row.getValue("id"),
                    });
                  }}
                />
                <DeleteCon />
              </EditDeleteContainer>
            </CostCentreTableRow>
          ))}
        </TableRowsContainer>
        <Modal
          isOpen={isNewCostCentreOpen}
          title={MODAL_NAMES.COST_CENTRE}
          wrapperId={`Edit ${MODAL_NAMES.COST_CENTRE}`}
          modalWidth="40%"
          type={MODAL_TYPE.EDIT}
        >
          <CostCentreForm
            handleClose={() => {
              setCurrentModalName("");
              setIsNewCostCentreOpen(false);
            }}
            isEditingMode
            details={details}
          />
        </Modal>
        <Pagination>
          <ItemCount>
            <span style={{ fontSize: "1.2rem" }}>Show:</span>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 10}
              onClick={() => table.setPageSize(10)}
            >
              10
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 20}
              onClick={() => table.setPageSize(20)}
            >
              20
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 50}
              onClick={() => table.setPageSize(50)}
            >
              50
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 100}
              onClick={() => table.setPageSize(100)}
            >
              100
            </NumberSpan>
          </ItemCount>
          <PageNumbers table={table} />
        </Pagination>
      </>
    </>
  );
};

const initialWBSState: WBSFormValues = {
  title: "",
  description: "",
  ext_erp_id: "",
  cost_centre_id: 0,
  level: 1,
  parent_id: 0,
};

const WBSTableRenderer = () => {
  const { isLoading, wbs, error } = useGetAllWBS();
  const [isNewWBSOpen, setIsNewWBSOpen] = useState(false);
  const { setCurrentModalName } = useActiveModalContext();
  const [details, setDetails] = useState<WBSFormValues>(initialWBSState);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "title",
      desc: false,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const { isLoading: isCostCentreLoading, costCentres } = useGetCostCentres();

  // cost centre table setup
  const columnHelper = createColumnHelper<WBS>();
  // Column config
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "id",
        id: "id",
      }),
      columnHelper.accessor("title", {
        header: "Title",
        id: "title",
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("cost_centre_id", {
        header: "Cost Centre",
        id: "cost_centre_id",
      }),
      columnHelper.accessor("ext_erp_id", {
        header: "External ERP",
        id: "ext_erp_id",
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: wbs || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
      globalFilter,
      columnVisibility: {
        id: false,
      },
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
  });

  if (isLoading || isCostCentreLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  if (!wbs?.length || error) {
    return (
      <div style={{ marginTop: "2rem" }}>
        <NoDataCard
          title="WBS"
          buttonLinked
          height="6rem"
          handleClick={() => {
            setCurrentModalName(MODAL_NAMES.WBS);
            setIsNewWBSOpen(true);
          }}
        />
        <Modal
          isOpen={isNewWBSOpen}
          title={MODAL_NAMES.WBS}
          type={MODAL_TYPE.NEW}
          wrapperId={MODAL_NAMES.WBS}
          modalWidth="50%"
        >
          <WBSForm
            handleClose={() => {
              setCurrentModalName("");
              setIsNewWBSOpen(false);
            }}
          />
        </Modal>
      </div>
    );
  }

  return (
    <>
      <SearchFilter style={{ marginTop: "-4.5rem" }}>
        <Header style={{ visibility: "hidden" }}>
          <Groups />
          <p>Groups</p>
        </Header>
        <FlexContainer>
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
          />
          <ButtonCTA
            $buttonType={BUTTON_TYPES.SECONDARY}
            $buttonSize={BUTTON_SIZES.SMALL}
            onClick={() => {
              setCurrentModalName(MODAL_NAMES.WBS);
              setIsNewWBSOpen(true);
            }}
          >
            add
            <Plus />
          </ButtonCTA>
        </FlexContainer>
      </SearchFilter>
      <Modal
        isOpen={isNewWBSOpen}
        title={MODAL_NAMES.WBS}
        wrapperId={MODAL_NAMES.WBS}
        modalWidth="40%"
        type={MODAL_TYPE.NEW}
      >
        <WBSForm
          handleClose={() => {
            setCurrentModalName("");
            setIsNewWBSOpen(false);
          }}
        />
      </Modal>
      <WBSTableHeader id="wbs-table-header">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => (
            <span
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              style={{ cursor: "pointer" }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {
                { asc: " 🔼", desc: " 🔽" }[
                  (header.column.getIsSorted() as string) ?? null
                ]
              }
            </span>
          )),
        )}
        <EditDeleteContainer>
          <Info
            onMouseEnter={() => setIsInfoVisible(true)}
            onMouseLeave={() => setIsInfoVisible(false)}
            style={{ cursor: "pointer", width: "2.2rem", height: "2.2rem" }}
          />
          {isInfoVisible && (
            <InfoTooltip>
              <span>Click on a header to sort the table by that column.</span>
            </InfoTooltip>
          )}
          <DeleteCon style={{ visibility: "hidden" }} />
        </EditDeleteContainer>
      </WBSTableHeader>
      <>
        <TableRowsContainer>
          {table.getRowModel().rows.map((row) => (
            <WBSTableRow key={row.id} id={`${row.id}-wbs`}>
              <Title>
                <span>{row.getValue("title")}</span>
              </Title>
              <span>{row.getValue("description")}</span>
              <span>
                {costCentres?.find(
                  (costCentre) =>
                    costCentre.id === row.getValue("cost_centre_id"),
                )?.title || "N/A"}
              </span>
              <span>{row.getValue("ext_erp_id") || "N/A"}</span>
              <EditDeleteContainer>
                <Edit
                  onClick={() => {
                    setCurrentModalName(`Edit ${MODAL_NAMES.WBS}`);
                    setIsNewWBSOpen(true);
                    setDetails({
                      ...details,
                      title: row.getValue("title"),
                      description: row.getValue("description"),
                      cost_centre_id: row.getValue("cost_centre_id"),
                      ext_erp_id: row.getValue("ext_erp_id"),
                      id: row.getValue("id"),
                    });
                  }}
                />
                <DeleteCon />
              </EditDeleteContainer>
            </WBSTableRow>
          ))}
        </TableRowsContainer>
        <Modal
          isOpen={isNewWBSOpen}
          title={MODAL_NAMES.WBS}
          wrapperId={`Edit ${MODAL_NAMES.WBS}`}
          modalWidth="40%"
          type={MODAL_TYPE.EDIT}
        >
          <WBSForm
            handleClose={() => {
              setCurrentModalName("");
              setIsNewWBSOpen(false);
            }}
            isEditingMode
            details={details}
          />
        </Modal>
        <Pagination>
          <ItemCount>
            <span style={{ fontSize: "1.2rem" }}>Show:</span>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 10}
              onClick={() => table.setPageSize(10)}
            >
              10
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 20}
              onClick={() => table.setPageSize(20)}
            >
              20
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 50}
              onClick={() => table.setPageSize(50)}
            >
              50
            </NumberSpan>
            <NumberSpan
              $isSelected={table.getState().pagination.pageSize === 100}
              onClick={() => table.setPageSize(100)}
            >
              100
            </NumberSpan>
          </ItemCount>
          <PageNumbers table={table} />
        </Pagination>
      </>
    </>
  );
};
