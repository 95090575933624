import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Calendar from "../../assets/Calendar.svg?react";
import CheckMark from "../../assets/CheckMarkWhite.svg?react";
import Contract from "../../assets/Contract.svg?react";
import Discard from "../../assets/Discard.svg?react";
import Edit from "../../assets/EditPencilWhite.svg?react";
import Expand from "../../assets/Expand.svg?react";
import Eye from "../../assets/EyeWhite.svg?react";

import moment from "moment";
import { DayPicker } from "react-day-picker";
import styled from "styled-components";
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  DATE_MODAL_NAMES,
  DAY_FORMATS,
  MODAL_NAMES,
  TASK_STATUS,
} from "../../common/Constants";
import { SCHEDULE_MAP } from "../../common/ScheduleMap";
import { useDayModalContext } from "../../context/DayPickerModalContext";
import { useGetProjectWBS } from "../../hooks/finances/useProjectWBS";
import { useGetContacts } from "../../hooks/useGetContacts";
import { useGetServiceValues } from "../../hooks/useGetServiceValues";
import { useGetStatusValues } from "../../hooks/useGetStatusValues";
import { TaskFormValues } from "../../types/formFields";
import { Milestone } from "../../types/milestoneTypes";
import ButtonCTA from "../../ui/ButtonCTA";
import ConfirmClosePopup from "../../ui/ConfirmClosePopup";
import DayPickerModal from "../../ui/DayPickerModal";
import Dropdown from "../../ui/Dropdown";
import {
  BottomNav,
  ClearMessage,
  ErrorMessage,
  ExpandableDiv,
  LabelAndInput,
  PositionedCalendar,
  PositionedInput,
  PositionedSvg,
  StyledFullWidthInput,
  StyledInput,
  StyledSection,
  StyledThreeFieldSection,
} from "../../ui/FormElements";
import {
  EditToggleButton,
  EditViewButtons,
  Toggles,
} from "../../ui/IconToggles";
import Spinner from "../../ui/Spinner";
import { getEndMonth, getMinDate } from "../../utils/common";
import {
  getContactDropdownValues,
  getItemDropdownValues,
} from "../../utils/GetDropdownValue";
import { useCreateProjectTask, useEditProjectTask } from "./useTasks";

const EachMilestone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 0.2rem dashed var(--color-grey-400);
  border-radius: var(--border-radius-xl);
  padding: 2rem;
  font-size: 1.2rem;
  height: 14.5rem;
`;

const MilestoneTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

const StatusSpan = styled.span<{ $progressStatus?: keyof typeof SCHEDULE_MAP }>`
  color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  font-weight: 700;
  font-size: 1.2rem;
`;

const StatusP = styled.p`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-self: end;
  align-self: self-start;
`;

const StatusCircle = styled.span<{
  $progressStatus?: keyof typeof SCHEDULE_MAP;
}>`
  height: 0.8rem;
  width: 0.8rem;
  background-color: ${(props) =>
    props.$progressStatus
      ? SCHEDULE_MAP[props.$progressStatus]
      : "var(--color-in-progress)"};
  border-radius: var(--border-radius-2l);
`;

const ProjectInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding-top: 2rem;
  border-top: 2px dashed var(--color-grey-400);
`;

const ProjectGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & h1 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  & h2 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }
`;

const StatusView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * TaskForm component for creating and editing project tasks.
 *
 * @param {Object} props - Component props
 * @param {() => void} props.handleClose - Function to handle form close
 * @param {boolean} props.isEditingMode - Flag to indicate if the form is in editing mode
 * @param {TaskFormValues} [props.details] - Details of the task (optional)
 * @param {Milestone[]} [props.milestones] - List of milestones (optional)
 * @param {boolean} props.canUserEdit - Flag to indicate if the user can edit the form
 * @returns {JSX.Element} The rendered component
 */
const TaskForm = ({
  handleClose,
  isEditingMode,
  details,
  milestones,
  canUserEdit,
}: {
  handleClose: () => void;
  isEditingMode: boolean;
  details?: TaskFormValues;
  milestones?: Milestone[] | undefined;
  canUserEdit: boolean;
}) => {
  const { isContactsLoading, contacts } = useGetContacts();
  const { isStatusLoading, statuses } = useGetStatusValues();
  const { isServiceLoading, services } = useGetServiceValues();

  const milestoneDropdown = useMemo(
    () => getItemDropdownValues(milestones),
    [milestones],
  );

  const statusDropdown = useMemo(
    () => getItemDropdownValues(statuses),
    [statuses],
  );

  const defaultNotStartedStatus = useMemo(
    () =>
      statuses?.find(
        (status) =>
          status.title.toLowerCase() === TASK_STATUS.TODO.toLowerCase(),
      ),
    [statuses],
  );

  const serviceDropdown = useMemo(
    () => getItemDropdownValues(services),
    [services],
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
    setFocus,
    getValues,
    resetField,
  } = useForm<TaskFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      title: details?.title || "",
      description: details?.description || "",
      milestone_id: details?.milestone_id || 0,
      percentage_of_milestone: details?.percentage_of_milestone || 0,
      service_id: details?.service_id || null,
      wbs_id: details?.wbs_id || null,
      responsible_person_id: details?.responsible_person_id || 0,
      status_id: details?.status_id || defaultNotStartedStatus?.id,
      percentage_completed: details?.percentage_completed || 0,
      est_start_date: details?.est_start_date || "",
      est_end_date: details?.est_end_date || "",
      start_date: details?.start_date || "",
      due_date: details?.due_date || "",
    },
  });

  const { setDayModalName } = useDayModalContext();
  const [isBackgroundFieldExpanded, setIsBackgroundFieldExpanded] = useState(
    details && details.description?.length > 60,
  );
  const [selectedMilestone, setSelectedMilestone] = useState<
    Milestone | undefined
  >(
    milestones?.find(
      (milestone) => milestone.id === (details?.milestone_id || 0),
    ),
  );
  const [isViewingMode, setIsViewingMode] = useState(isEditingMode);

  useEffect(() => {
    if (!isEditingMode) setFocus("title");
  }, [setFocus, isEditingMode]);

  const { isCreating, createProjectTask } = useCreateProjectTask();
  const { isEditing, editProjectTask } = useEditProjectTask();

  const {
    est_end_date: milestoneEstEnd,
    est_start_date: milestoneEstStart,
    start_year: milestoneStartYear,
    end_year: milestoneEndYear,
    tasks: selectedMilestoneTasks,
    project_id: selectedMilestoneProjectId,
  } = selectedMilestone || {};

  const { isLoading: isWBSLoading, projectWBS } = useGetProjectWBS(
    +(selectedMilestoneProjectId || 0),
  );

  const usedMilestonePercentage = useMemo(() => {
    if (selectedMilestoneTasks) {
      const totalPercentage = selectedMilestoneTasks
        .filter((task) => task.id !== details?.id)
        .reduce((acc, task) => acc + (task.percentage_of_milestone || 0), 0);
      return totalPercentage;
    }
    return 0;
  }, [selectedMilestoneTasks, details?.id]);

  const onSubmit: SubmitHandler<TaskFormValues> = (data, event) => {
    event?.preventDefault();
    if (!isEditingMode) {
      createProjectTask(
        {
          taskDetails: {
            ...data,
            parent_id: null,
            path: null,
            priority: "sequence",
            start_date: data.start_date || null,
            due_date: data.due_date || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
        },
        {
          onSuccess: () => {
            toast.success(`Project task saved successfully.`);
            handleClose();
          },
        },
      );
    } else {
      editProjectTask(
        {
          taskDetails: {
            ...data,
            parent_id: null,
            path: null,
            priority: "sequence",
            start_date: data.start_date || null,
            due_date: data.due_date || null,
            est_start_date: data.est_start_date || null,
            est_end_date: data.est_end_date || null,
          },
          taskId: details?.id || 0,
        },
        {
          onSuccess: () => {
            toast.success(`Project task saved successfully.`);
            handleClose();
          },
        },
      );
    }
  };

  const handleSelect = (id: string, name?: string) => {
    setValue(name as keyof TaskFormValues, id, { shouldDirty: true });
    if (name === "milestone_id") {
      const selectedMilestone = milestones?.find(
        (milestone) => milestone.id === +id,
      );
      setSelectedMilestone(selectedMilestone);
      setValue("service_id", selectedMilestone?.service_id || 0);
      setValue("wbs_id", selectedMilestone?.wbs_id || 0);
    }
  };

  if (
    isCreating ||
    isEditing ||
    isContactsLoading ||
    isStatusLoading ||
    isServiceLoading ||
    isWBSLoading
  )
    return <Spinner />;

  return (
    <>
      {isEditingMode && canUserEdit && (
        <EditViewButtons>
          <Toggles>
            <EditToggleButton
              $active={isViewingMode}
              onClick={() => setIsViewingMode(true)}
            >
              <Eye />
            </EditToggleButton>
            <EditToggleButton
              $active={!isViewingMode}
              onClick={() => setIsViewingMode(false)}
            >
              <Edit />
            </EditToggleButton>
          </Toggles>
        </EditViewButtons>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSection>
          {selectedMilestone && (
            <EachMilestone key={selectedMilestone.id}>
              <MilestoneTitle>
                {"Milestone - " + selectedMilestone.title}
                <StatusView>
                  <StatusP>
                    <StatusCircle
                      $progressStatus={
                        selectedMilestone.status
                          ?.title as keyof typeof SCHEDULE_MAP
                      }
                    ></StatusCircle>
                    <StatusSpan
                      $progressStatus={
                        selectedMilestone.status
                          ?.title as keyof typeof SCHEDULE_MAP
                      }
                    >
                      {selectedMilestone.status?.title}
                    </StatusSpan>
                  </StatusP>
                </StatusView>
              </MilestoneTitle>
              <ProjectInfoGrid>
                <ProjectGridItem>
                  <h2>Start Date</h2>
                  <h1>
                    {selectedMilestone?.start_year
                      ? moment(selectedMilestone.start_year).format(
                          "DD/MM/YYYY",
                        )
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>End Date</h2>
                  <h1>
                    {selectedMilestone?.end_year
                      ? moment(selectedMilestone.end_year).format("DD/MM/YYYY")
                      : "N/A"}
                  </h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>Service</h2>
                  <h1>{selectedMilestone.service?.title || "N/A"}</h1>
                </ProjectGridItem>
                <ProjectGridItem>
                  <h2>responsible Person</h2>
                  <h1>{`${selectedMilestone?.responsible.first_name} ${selectedMilestone?.responsible.last_name}`}</h1>
                </ProjectGridItem>
              </ProjectInfoGrid>
            </EachMilestone>
          )}
          <StyledThreeFieldSection>
            <LabelAndInput>
              <label>Milestone *</label>
              <Controller
                control={control}
                name="milestone_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="milestone_id"
                    title=""
                    data={milestoneDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.milestone_id && (
                <ErrorMessage errorMessage={errors.milestone_id.message} />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>% of Milestone *</label>
              <StyledInput
                type="number"
                {...register("percentage_of_milestone", {
                  max: {
                    value: 100 - usedMilestonePercentage,
                    message: `Max ${100 - usedMilestonePercentage}%`,
                  },
                  min: { value: 1, message: "Min 1%" },
                  required: "This field is required",
                })}
                autoComplete="off"
                warning={errors.percentage_of_milestone?.message}
                readOnly={isViewingMode}
              />
              {errors?.percentage_of_milestone && (
                <ErrorMessage
                  errorMessage={errors.percentage_of_milestone?.message}
                />
              )}
            </LabelAndInput>{" "}
            <LabelAndInput>
              <label>Department *</label>
              <Controller
                control={control}
                name="service_id"
                render={({ field: { value } }) => (
                  <Dropdown
                    id="service_id"
                    title=""
                    data={serviceDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <LabelAndInput>
              <label>Title *</label>
              <StyledInput
                type="text"
                {...register("title", {
                  required: "This field is required",
                  minLength: {
                    value: 3,
                    message: "Please enter at least 3 characters",
                  },
                })}
                autoComplete="off"
                warning={errors.title?.message}
                readOnly={isViewingMode}
              />
              {errors?.title && (
                <ErrorMessage errorMessage={errors.title?.message} />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>WBS</label>
              <Controller
                control={control}
                name="wbs_id"
                render={({ field: { value } }) => (
                  <Dropdown
                    id="wbs_id"
                    title=""
                    data={getItemDropdownValues(
                      projectWBS?.map((wbs) => wbs.wbs),
                    )}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.wbs_id && (
                <ErrorMessage errorMessage={errors.wbs_id.message} />
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <LabelAndInput style={{ gridColumnStart: 1, gridColumnEnd: -1 }}>
              <label>Description *</label>
              <ExpandableDiv height={isBackgroundFieldExpanded ? "20rem" : ""}>
                <StyledFullWidthInput
                  height={isBackgroundFieldExpanded ? "20rem" : ""}
                  {...register("description", {
                    required: "This field is required",
                    minLength: {
                      value: 3,
                      message: "Please enter at least 3 characters",
                    },
                    maxLength: {
                      value: 250,
                      message: "Please enter at most 250 characters",
                    },
                  })}
                  autoComplete="off"
                  warning={errors.description?.message}
                  readOnly={isViewingMode}
                />
                <PositionedSvg
                  onClick={() => setIsBackgroundFieldExpanded((prev) => !prev)}
                >
                  {isBackgroundFieldExpanded ? <Contract /> : <Expand />}
                </PositionedSvg>
              </ExpandableDiv>
              {errors?.description && (
                <ErrorMessage errorMessage={errors.description?.message} />
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <LabelAndInput>
              <label>Responsible Person *</label>
              <Controller
                control={control}
                name="responsible_person_id"
                rules={{ required: "This field is required" }}
                render={({ field: { value } }) => (
                  <Dropdown
                    id="responsible_person_id"
                    title=""
                    data={getContactDropdownValues(contacts)}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    showOnTop
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.responsible_person_id && (
                <ErrorMessage
                  errorMessage={errors.responsible_person_id.message}
                />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>Status *</label>
              <Controller
                control={control}
                name="status_id"
                render={({ field: { value } }) => (
                  <Dropdown
                    id="status_id"
                    title=""
                    data={statusDropdown}
                    onSelect={handleSelect}
                    selectedId={`${value}`}
                    bgColor="var(--color-grey-100)"
                    search
                    showOnTop
                    isViewingMode={isViewingMode}
                  />
                )}
              />
              {errors?.status_id && (
                <ErrorMessage errorMessage={errors.status_id.message} />
              )}
            </LabelAndInput>
            <LabelAndInput>
              <label>% complete</label>
              <StyledInput
                type="number"
                {...register("percentage_completed", {
                  max: { value: 100, message: "Max 100%" },
                  min: { value: 0, message: "Min 0%" },
                })}
                autoComplete="off"
                warning={errors.percentage_completed?.message}
                readOnly={isViewingMode}
              />
              {errors?.percentage_completed && (
                <ErrorMessage
                  errorMessage={errors.percentage_completed?.message}
                />
              )}
            </LabelAndInput>{" "}
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <PositionedInput>
              <label>Estimated Start Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("est_start_date")
                    ? moment(getValues("est_start_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.est_start_date
                      ? moment(details?.est_start_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors.est_start_date?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.est_start_date && (
                <ErrorMessage errorMessage={errors.est_start_date?.message} />
              )}
              {getValues("est_start_date") || details?.est_start_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("est_start_date", { defaultValue: "" });
                        if (details) {
                          details.est_start_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode &&
                    setDayModalName(DATE_MODAL_NAMES.EST_START)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="est_start_date"
                rules={{
                  min: {
                    value:
                      (milestoneEstStart &&
                        moment(milestoneEstStart).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      getMinDate(isEditingMode),
                    message: "Please enter a valid start date",
                  },
                  max: {
                    value:
                      (getValues("est_end_date") &&
                        moment(getValues("est_end_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      (milestoneEstEnd &&
                        moment(milestoneEstEnd).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      "",
                    message: "Please enter a valid end date",
                  },
                  deps: ["est_end_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().est_start_date)}
                      onSelect={(e) => {
                        onChange(() => {
                          setValue(
                            "est_start_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          );
                        });
                        setDayModalName("");
                      }}
                      showOutsideDays
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().est_start_date
                          ? new Date(getValues().est_start_date)
                          : new Date()
                      }
                      disabled={{
                        before: milestoneEstStart
                          ? new Date(milestoneEstStart)
                          : new Date(),
                        after: getValues("est_end_date")
                          ? new Date(getValues("est_end_date"))
                          : milestoneEstEnd
                            ? new Date(milestoneEstEnd)
                            : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
            <PositionedInput>
              <label>Estimated End date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("est_end_date")
                    ? moment(getValues("est_end_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.est_end_date
                      ? moment(details?.est_end_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors.est_end_date?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.est_end_date && (
                <ErrorMessage errorMessage={errors.est_end_date?.message} />
              )}
              {getValues("est_end_date") || details?.est_end_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("est_end_date", { defaultValue: "" });
                        if (details) {
                          details.est_end_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.EST_END)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="est_end_date"
                rules={{
                  min: {
                    value:
                      (getValues("est_start_date") &&
                        moment(getValues("est_start_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      (milestoneEstStart &&
                        moment(milestoneEstStart).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      getMinDate(isEditingMode),
                    message: "Please enter a valid start date",
                  },
                  max: {
                    value:
                      (milestoneEstEnd &&
                        moment(milestoneEstEnd).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      "",
                    message: "Please enter a valid end date",
                  },
                  deps: ["est_start_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.EST_END}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().est_end_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "est_end_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      showOutsideDays
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().est_start_date
                          ? new Date(getValues().est_start_date)
                          : new Date()
                      }
                      disabled={{
                        before: getValues("est_start_date")
                          ? new Date(getValues("est_start_date"))
                          : milestoneEstStart
                            ? new Date(milestoneEstStart)
                            : new Date(),
                        after: milestoneEstEnd
                          ? new Date(milestoneEstEnd)
                          : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledThreeFieldSection>
          <StyledThreeFieldSection>
            <PositionedInput>
              <label>Actual Start Date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("start_date")
                    ? moment(getValues("start_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.start_date
                      ? moment(details?.start_date).format(
                          DAY_FORMATS.DAY_FIRST,
                        )
                      : ""
                }
                warning={errors.start_date?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.start_date && (
                <ErrorMessage errorMessage={errors.start_date?.message} />
              )}
              {getValues("start_date") || details?.start_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("start_date", { defaultValue: "" });
                        if (details) {
                          details.start_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.START)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="start_date"
                rules={{
                  min: {
                    value:
                      (milestoneStartYear &&
                        moment(milestoneStartYear).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      getMinDate(isEditingMode),
                    message: "Please enter a valid start date",
                  },
                  max: {
                    value:
                      (getValues("due_date") &&
                        moment(getValues("due_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      (milestoneEndYear &&
                        moment(milestoneEndYear).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      "",
                    message: "Please enter a valid end date",
                  },
                  deps: ["due_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.START}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().start_date)}
                      onSelect={(e) => {
                        onChange(() => {
                          setValue(
                            "start_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          );
                        });
                        setDayModalName("");
                      }}
                      showOutsideDays
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().start_date
                          ? new Date(getValues().start_date)
                          : new Date()
                      }
                      disabled={{
                        before: milestoneStartYear
                          ? new Date(milestoneStartYear)
                          : new Date(),
                        after: getValues("due_date")
                          ? new Date(getValues("due_date"))
                          : milestoneEndYear
                            ? new Date(milestoneEndYear)
                            : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
            <PositionedInput>
              <label>Actual End date</label>
              <StyledInput
                aria-label="Date"
                type="text"
                value={
                  getValues("due_date")
                    ? moment(getValues("due_date")).format(
                        DAY_FORMATS.DAY_FIRST,
                      )
                    : details?.due_date
                      ? moment(details?.due_date).format(DAY_FORMATS.DAY_FIRST)
                      : ""
                }
                warning={errors.due_date?.message}
                onChange={() => {}}
                readOnly={isViewingMode}
              />
              {errors?.due_date && (
                <ErrorMessage errorMessage={errors.due_date?.message} />
              )}
              {getValues("due_date") || details?.due_date
                ? !isViewingMode && (
                    <ClearMessage
                      onClick={() => {
                        resetField("due_date", { defaultValue: "" });
                        if (details) {
                          details.due_date = "";
                        }
                      }}
                    >
                      Clear
                    </ClearMessage>
                  )
                : ""}
              <PositionedCalendar
                style={{ pointerEvents: isViewingMode ? "none" : "auto" }}
              >
                <Calendar
                  onClick={() =>
                    !isViewingMode && setDayModalName(DATE_MODAL_NAMES.END)
                  }
                />
              </PositionedCalendar>
              <Controller
                control={control}
                name="due_date"
                rules={{
                  min: {
                    value:
                      (getValues("start_date") &&
                        moment(getValues("start_date")).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      (milestoneStartYear &&
                        moment(milestoneStartYear).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      getMinDate(isEditingMode),
                    message: "Please enter a valid start date",
                  },
                  max: {
                    value:
                      (milestoneEndYear &&
                        moment(milestoneEndYear).format(
                          DAY_FORMATS.YEAR_FIRST,
                        )) ||
                      "",
                    message: "Please enter a valid end date",
                  },
                  deps: ["start_date"],
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <DayPickerModal wrapperId={DATE_MODAL_NAMES.END}>
                    <DayPicker
                      mode="single"
                      selected={new Date(getValues().due_date)}
                      onSelect={(e) => {
                        onChange(() =>
                          setValue(
                            "due_date",
                            moment(e).format(DAY_FORMATS.YEAR_FIRST),
                          ),
                        );
                        setDayModalName("");
                      }}
                      showOutsideDays
                      onDayClick={onBlur}
                      captionLayout="dropdown"
                      endMonth={getEndMonth()}
                      defaultMonth={
                        getValues().start_date
                          ? new Date(getValues().start_date)
                          : new Date()
                      }
                      disabled={{
                        before: getValues("start_date")
                          ? new Date(getValues("start_date"))
                          : milestoneStartYear
                            ? new Date(milestoneStartYear)
                            : new Date(),
                        after: milestoneEndYear
                          ? new Date(milestoneEndYear)
                          : getEndMonth(),
                      }}
                    />
                  </DayPickerModal>
                )}
              />
            </PositionedInput>
          </StyledThreeFieldSection>
        </StyledSection>
        <BottomNav>
          <ButtonCTA
            $buttonSize={BUTTON_SIZES.SMALL}
            type="button"
            onClick={() => {
              if (isDirty) {
                setDayModalName(MODAL_NAMES.CONFIRM_CANCEL);
              } else {
                handleClose();
              }
            }}
          >
            {!isViewingMode && canUserEdit ? "Cancel" : "Close"}
            <Discard />
          </ButtonCTA>
          {!isViewingMode && canUserEdit && (
            <ButtonCTA
              $buttonSize={BUTTON_SIZES.SMALL}
              $buttonType={BUTTON_TYPES.SECONDARY}
              type="submit"
            >
              Save
              <CheckMark />
            </ButtonCTA>
          )}
        </BottomNav>
      </form>
      <ConfirmClosePopup
        wrapperId={MODAL_NAMES.CONFIRM_CANCEL}
        onClose={handleClose}
      />
    </>
  );
};

export default TaskForm;
